<template>
  <div class="maintenance-work">
    <div class="maintenance-work__wrapper">
      <div class="maintenance-work__inner">
        <div class="maintenance-work__text">
          <div class="font-h2 color_white">Sorry, we are at the maintenance.</div>
          <div class="font-h2 color_white">Please come back later</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TlprtMaintenanceWork',
}
</script>
