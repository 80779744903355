<template>
  <div class="profile-change-password">
    <div class="profile-change-password__title">
      <span class="font-h5 color_dark-gray">Change my password</span>
      <span class="font-small color_gray"
        >Your password last modified on
        {{ createDateChangePassword(currentUser.changePasswordAt) }} (UTC)</span
      >
      <div class="title-down">
        <svg
          @click="resetValueFormPassword"
          :class="isFormChangePassword ? 'title-down--active' : ''">
          <use
            xlink:href="../../assets/img/sprite/sprite-domain-up.svg#arrow"></use>
        </svg>
      </div>
    </div>

    <div
      v-if="isFormChangePassword && !isFormChangePasswordSuccess"
      class="profile-change-password__form">
      <form @submit.prevent="changePassword(currentUser.id)">
        <div class="form-current-password">
          <div
            class="input-field"
            :class="{
              'error-password':
                ($v.currentPasswordUser.$error &&
                  !$v.currentPasswordUser.required) ||
                isViewErrorMessageCode,
            }">
            <input
              class="input"
              :type="typeViewCurrentPassword"
              placeholder="Confirm your current password"
              autocomplete="off"
              v-model.trim="currentPasswordUser" />
            <div
              v-if="currentPasswordUser.length > 0"
              class="password-control"
              @click="viewCurrentPassword()">
              <template v-if="!isViewCurrentPassword">
                <svg>
                  <use
                    xlink:href="../../assets/img/svg/sprite/sprite-form.svg#eye-off-gray"></use>
                </svg>
              </template>
              <template v-else>
                <svg>
                  <use
                    xlink:href="../../assets/img/svg/sprite/sprite-form.svg#eye-on-gray"></use>
                </svg>
              </template>
            </div>
          </div>
          <div class="validation-errors">
            <template v-if="isViewErrorMessageCode">
              <span class="font-small-2 color_coral">Incorrect password</span>
            </template>
          </div>
        </div>
        <div class="form-new-password">
          <div class="input-wrapper">
            <div
              class="input-field"
              :class="{
                'error-password':
                  ($v.newPasswordUser.$error && !$v.newPasswordUser.required) ||
                  (!(
                    ($v.newPasswordUser.$error &&
                      !$v.newPasswordUser.required) ||
                    ($v.currentPasswordUser.$error &&
                      !$v.currentPasswordUser.required) ||
                    ($v.retypeNewPasswordUser.$error &&
                      !$v.retypeNewPasswordUser.required)
                  ) &&
                    $v.newPasswordUser.$error &&
                    !$v.newPasswordUser.minLength),
              }">
              <input
                class="input"
                :type="typeViewPassword"
                placeholder="New password"
                autocomplete="off"
                v-model.trim="newPasswordUser" />
              <div
                v-if="newPasswordUser.length > 0"
                class="password-control"
                @click="viewPassword()">
                <template v-if="!isViewPassword">
                  <svg>
                    <use
                      xlink:href="../../assets/img/svg/sprite/sprite-form.svg#eye-off-gray"></use>
                  </svg>
                </template>
                <template v-else>
                  <svg>
                    <use
                      xlink:href="../../assets/img/svg/sprite/sprite-form.svg#eye-on-gray"></use>
                  </svg>
                </template>
              </div>
            </div>
            <div class="validation-errors">
              <template
                v-if="
                  ($v.newPasswordUser.$error && !$v.newPasswordUser.required) ||
                  ($v.currentPasswordUser.$error &&
                    !$v.currentPasswordUser.required) ||
                  ($v.retypeNewPasswordUser.$error &&
                    !$v.retypeNewPasswordUser.required)
                ">
                <span class="font-small-2 color_coral"
                  >All fields are required</span
                >
              </template>
              <template
                v-if="
                  !(
                    ($v.newPasswordUser.$error &&
                      !$v.newPasswordUser.required) ||
                    ($v.currentPasswordUser.$error &&
                      !$v.currentPasswordUser.required) ||
                    ($v.retypeNewPasswordUser.$error &&
                      !$v.retypeNewPasswordUser.required)
                  ) &&
                  $v.newPasswordUser.$error &&
                  !$v.newPasswordUser.minLength
                ">
                <span class="font-small-2 color_coral"
                  >Minimum 6 symbols are required</span
                >
              </template>
            </div>
          </div>
          <div class="input-wrapper">
            <div
              class="input-field"
              :class="{
                'error-password':
                  ($v.retypeNewPasswordUser.$error &&
                    !$v.retypeNewPasswordUser.required) ||
                  (!(
                    !(
                      $v.newPasswordUser.$error &&
                      !$v.newPasswordUser.required &&
                      $v.currentPasswordUser.$error &&
                      !$v.currentPasswordUser.required &&
                      $v.retypeNewPasswordUser.$error &&
                      !$v.retypeNewPasswordUser.required
                    ) &&
                    $v.newPasswordUser.$error &&
                    !$v.newPasswordUser.minLength
                  ) &&
                    !(
                      $v.newPasswordUser.$error && !$v.newPasswordUser.minLength
                    ) &&
                    $v.retypeNewPasswordUser.$error &&
                    !$v.retypeNewPasswordUser.sameAsPassword &&
                    !(
                      $v.newPasswordUser.$error && !$v.newPasswordUser.required
                    ) &&
                    !(
                      !(
                        $v.newPasswordUser.$error &&
                        !$v.newPasswordUser.required &&
                        $v.currentPasswordUser.$error &&
                        !$v.currentPasswordUser.required &&
                        $v.retypeNewPasswordUser.$error &&
                        !$v.retypeNewPasswordUser.required
                      ) &&
                      $v.newPasswordUser.$error &&
                      !$v.newPasswordUser.minLength
                    ) &&
                    !(
                      $v.newPasswordUser.$error && !$v.newPasswordUser.minLength
                    ) &&
                    $v.retypeNewPasswordUser.$error &&
                    !$v.retypeNewPasswordUser.sameAsPassword),
              }">
              <input
                class="input"
                :type="typeViewConfirmPassword"
                placeholder="Retype new password"
                v-model.trim="retypeNewPasswordUser"
                autocomplete="off" />
              <div
                v-if="retypeNewPasswordUser.length > 0"
                class="password-control"
                @click="viewConfirmPassword()">
                <template v-if="!isViewConfirmPassword">
                  <svg>
                    <use
                      xlink:href="../../assets/img/svg/sprite/sprite-form.svg#eye-off-gray"></use>
                  </svg>
                </template>
                <template v-else>
                  <svg>
                    <use
                      xlink:href="../../assets/img/svg/sprite/sprite-form.svg#eye-on-gray"></use>
                  </svg>
                </template>
              </div>
            </div>
            <div class="validation-errors">
              <template
                v-if="
                            !(($v.newPasswordUser.$error && !$v.newPasswordUser.required) ||
                            ($v.currentPasswordUser.$error &&
                            !$v.currentPasswordUser.required) ||
                            ($v.retypeNewPasswordUser.$error &&
                            !$v.retypeNewPasswordUser.required))

                            &&

                            (!(
                    !(
                      $v.newPasswordUser.$error &&
                      !$v.newPasswordUser.required &&
                      $v.currentPasswordUser.$error &&
                      !$v.currentPasswordUser.required &&
                      $v.retypeNewPasswordUser.$error &&
                      !$v.retypeNewPasswordUser.required
                    ) &&
                    $v.newPasswordUser.$error &&
                    !$v.newPasswordUser.minLength
                  ) &&
                  !(
                    $v.newPasswordUser.$error && !$v.newPasswordUser.minLength
                  ) &&
                  $v.retypeNewPasswordUser.$error &&
                  !$v.retypeNewPasswordUser.sameAsPassword &&
                  !(
                    $v.newPasswordUser.$error && !$v.newPasswordUser.required
                  ) &&
                  !(
                    !(
                      $v.newPasswordUser.$error &&
                      !$v.newPasswordUser.required &&
                      $v.currentPasswordUser.$error &&
                      !$v.currentPasswordUser.required &&
                      $v.retypeNewPasswordUser.$error &&
                      !$v.retypeNewPasswordUser.required
                    ) &&
                    $v.newPasswordUser.$error &&
                    !$v.newPasswordUser.minLength
                  ) &&
                  !(
                    $v.newPasswordUser.$error && !$v.newPasswordUser.minLength
                  ) &&
                  $v.retypeNewPasswordUser.$error &&
                  !$v.retypeNewPasswordUser.sameAsPassword)

                ">
                <span class="font-small-2 color_coral"
                  >Passwords do not match</span
                >
              </template>
            </div>
          </div>
        </div>
        <div class="form-btn">
          <button class="button button-loader-white color_white">
            <span v-if="!isLoadingChangePassword">Submit changes</span>
            <nodr-loader-white v-if="isLoadingChangePassword" />
          </button>
        </div>
      </form>
    </div>

    <div
      v-if="isFormChangePasswordSuccess"
      class="profile-change-password__success font-small color_dodger-blue">
      <svg>
        <use
          xlink:href="../../assets/img/svg/sprite/sprite-domains.svg#verification-check-success"></use>
      </svg>
      <span class="color_dodger-blue font-small-bold"
        >Password changed successfully</span
      >
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import {getterTypes} from '../../store/modules/user'
import {minLength, required, sameAs} from 'vuelidate/lib/validators'
import NodrLoaderWhite from '../Loaders/Loader-white'

export default {
  name: 'NodrProfileChangePassword',
  components: {NodrLoaderWhite},
  props: {
    currentUser: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      isFormChangePassword: false,
      isFormChangePasswordSuccess: false,

      isViewCurrentPassword: false,
      isViewPassword: false,
      isViewConfirmPassword: false,

      currentPasswordUser: '',
      newPasswordUser: '',
      retypeNewPasswordUser: '',

      typeViewCurrentPassword: 'password',
      typeViewPassword: 'password',
      typeViewConfirmPassword: 'password',

      isViewErrorMessageCode: false,
    }
  },
  validations: {
    currentPasswordUser: {
      required,
    },
    newPasswordUser: {
      required,
      minLength: minLength(6),
    },
    retypeNewPasswordUser: {
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs('newPasswordUser'),
    },
  },
  watch: {
    isChangePasswordSuccess() {
      this.isFormChangePasswordSuccess = this.isChangePasswordSuccess
    },
    currentPasswordUser() {
      this.$v.$reset()

      if (this.codeErrorMessageUserChangePassword) {
        this.isViewErrorMessageCode = false
      }
    },
    newPasswordUser() {
      this.$v.$reset()

      if (this.codeErrorMessageUserChangePassword) {
        this.isViewErrorMessageCode = false
      }
    },
    retypeNewPasswordUser() {
      this.$v.$reset()

      if (this.codeErrorMessageUserChangePassword) {
        this.isViewErrorMessageCode = false
      }
    },
    codeErrorMessageUserChangePassword() {
      if (this.codeErrorMessageUserChangePassword) {
        this.isViewErrorMessageCode = true
      }
    },
  },
  computed: {
    ...Vuex.mapGetters({
      isChangePasswordSuccess: getterTypes.isChangePasswordSuccess,
      codeErrorMessageUserChangePassword:
        getterTypes.codeErrorMessageUserChangePassword,
      isLoadingChangePassword: getterTypes.isLoadingChangePassword,
    }),
  },
  methods: {
    changePassword(userId) {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.$emit('optionChangePassword', {
        user_id: userId,
        team_id: this.currentUser.TeamsRender[0].id,
        password: this.currentPasswordUser,
        new_password: this.newPasswordUser,
        confirm: this.retypeNewPasswordUser,
      })
    },
    resetValueFormPassword() {
      this.isFormChangePassword = !this.isFormChangePassword
      this.currentPasswordUser = ''
      this.newPasswordUser = ''
      this.retypeNewPasswordUser = ''

      this.$v.$reset()

      if (this.isFormChangePasswordSuccess) {
        this.isFormChangePasswordSuccess = false
      }
    },
    createDateChangePassword(date) {
      let options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }
      return new Date(date).toLocaleString('en-GB', options)
    },
    viewCurrentPassword() {
      this.isViewCurrentPassword = !this.isViewCurrentPassword
      this.isViewCurrentPassword
        ? (this.typeViewCurrentPassword = 'text')
        : (this.typeViewCurrentPassword = 'password')
    },
    viewPassword() {
      this.isViewPassword = !this.isViewPassword
      this.isViewPassword
        ? (this.typeViewPassword = 'text')
        : (this.typeViewPassword = 'password')
    },
    viewConfirmPassword() {
      this.isViewConfirmPassword = !this.isViewConfirmPassword
      this.isViewConfirmPassword
        ? (this.typeViewConfirmPassword = 'text')
        : (this.typeViewConfirmPassword = 'password')
    },
  },
}
</script>
