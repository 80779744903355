<template>
  <div
    class="profile-account-deleting"
    :class="{
      'profile-account-deleting__process-active': currentUser.deleteProgress,
    }">
    <div
      v-if="!currentUser.deleteProgress"
      class="profile-account-deleting__wrapper-default">
      <div class="profile-account-deleting__title">
        <span class="font-h5 color_dark-gray">Delete my account</span>
        <div class="title-down">
          <svg
            @click="isOpenBlockAccountDeleting = !isOpenBlockAccountDeleting"
            :class="
              isOpenBlockAccountDeleting
                ? 'title-down--active'
                : 'title-down--blocked'
            ">
            <use
              xlink:href="../../assets/img/sprite/sprite-domain-up.svg#arrow"></use>
          </svg>
        </div>
      </div>

      <div
        v-if="isOpenBlockAccountDeleting"
        class="profile-account-deleting__text">
        <span class="font-small color_gray d-block">
          You will receive an email to confirm your decision
        </span>

        <span class="font-small color_gray d-block">
          By clicking the Delete account below you'll be forwarded to the
          confirmation menu. Please note that deleting account can't be undone,
          you will lose access to NODR dashboard and will be no longer
          able to work in any of the connected teams.
        </span>
        <div class="profile-account-deleting__text-btn">
          <button @click="getInfoDeleteUser" class="button button-border-w">
            <nodr-loader v-if="isLoadingPermissionToDelete" />
            <span v-if="!isLoadingPermissionToDelete">Delete account</span>
          </button>
        </div>
      </div>
    </div>

    <div
      v-if="currentUser.deleteProgress"
      class="profile-account-deleting__wrapper-process">
      <div class="profile-account-deleting__title">
        <svg>
          <use
            xlink:href="../../assets/img/sprite/sprite-settings.svg#info"></use>
        </svg>
        <span class="font-h5 color_white">Deleting in progress!</span>
      </div>

      <div class="profile-account-deleting__process">
        <span class="font-small color_white d-block">
          {{ currentUser.name }} initiated the closure of this team.
        </span>

        <span class="font-small color_white d-block">
          An e-mail has been sent to you with the link for confirming this action. Any of the Admins can complete this procedure or cancel it.
        </span>

        <span class="font-small color_white d-block">
          Until deleting is confirmed, NODR services will stay active for your team. Except, no user, including Admin, can change this team setting, invite new users, assign new roles.
        </span>

        <span class="font-small color_white d-block">
          Please keep in mind that deleted team can't be restored, and you will instantly lose access to all NODR services on all domains and in all apps bound with this team.
        </span>

        <div class="profile-account-deleting__process-btn">
          <button
            @click="resend(currentUser)"
            class="button button-border-white color_white">
            Resend link
          </button>
          <button
            @click="revoke(currentUser)"
            class="button button-border-w color_dark-gray">
            Revoke
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import {actionTypes, getterTypes} from '../../store/modules/user'
import NodrLoader from '../Loaders/Loader'

export default {
  name: 'NodrProfileAccountDeleting',
  components: {NodrLoader},
  props: {
    currentUser: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      isOpenBlockAccountDeleting: false,
      isProcessDeletingUser: false,
    }
  },
  computed: {
    ...Vuex.mapGetters({
      isLoadingPermissionToDelete: getterTypes.isLoadingPermissionToDelete,
    }),
  },
  methods: {
    ...Vuex.mapActions({
      resendDeleteAccountUser: actionTypes.resendDeleteAccountUser,
      revokeDeleteAccountUser: actionTypes.revokeDeleteAccountUser,
      check: actionTypes.check
    }),
    getInfoDeleteUser() {
      this.$emit('optionGetInfoDeleteUser', {
        userId: this.currentUser.id,
      })
    },
    resend(user) {
      this.resendDeleteAccountUser({
        id: user.id,
        url: origin + '/deleteUserAccount',
      })
    },
    revoke(user) {
      this.revokeDeleteAccountUser({
        id: user.id,
      }).then(() => this.check())
    },
  },
}
</script>
