import store from '@/store'
import {actionTypes} from '../store/modules/user'
import Login from '../views/Login'
import Register from '../views/Register'
import CreatePassword from '../views/CreatePassword'
import CreateAccount from '../views/CreateAccount'
import ForgotPassword from '../views/ForgotPassword'
import ResetPassword from '../views/ResetPassword'
import PasswordRecovery from '../views/PasswordRecovery'
import PendingRegistration from '../views/PendingRegistration'
import NotFound from '../views/404'
import Index from '../views/Index'
import MyNodr from '../components/Integration/MyNodr'
import Statistics from '../components/Statistics/Statistics'
import Settings from '../components/Settings/Settings'
import ChangeEmailResult from "@/components/Settings/ChangeEmailResult";
import DeleteAccountUserResult from "@/components/Settings/DeleteAccountUserResult";

const ifNotAuthenticated = (to, from, next) => {
  if (!store.state.auth.ifAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.state.auth.ifAuthenticated) {
    next()
    return
  }
  next('/')
}

const check = (to, from, next) => {
  store
    .dispatch(actionTypes.check)
    .then(() => {
      next()
    })
    .catch(() => {
      next('/')
    })
}

export default [
  {
    path: '/',
    name: 'login',
    component: Login,
    beforeEnter: ifNotAuthenticated,
  },
  {
    path: '/register',
    name: 'register',
    component: Register,
  },
  {
    path: '/createPassword/:hash',
    name: 'createPassword',
    component: CreatePassword,
  },
  {
    path: '/createAccount',
    name: 'createAccount',
    component: CreateAccount,
    beforeEnter: check,
    ifAuthenticated,
  },
  {
    path: '/forgotPassword',
    name: 'forgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/resPassword/:hash',
    name: 'resPassword',
    component: ResetPassword,
  },
  {
    path: '/passwordRecovery',
    name: 'passwordRecovery',
    component: PasswordRecovery,
  },
  {
    path: '/pendingRegistration',
    name: 'pendingRegistration',
    component: PendingRegistration,
  },
  {
    path: '/changeEmail/:hash',
    name: 'changeEmail',
    component: ChangeEmailResult
  },
  {
    path: '/deleteUserAccount/:hash',
    name: 'deleteUserAccount',
    component: DeleteAccountUserResult
  },
  {
    path: '*',
    component: NotFound,
  },
  {
    path: '/integration',
    component: Index,
    children: [
      {
        path: '/myNodr',
        name: 'myNodr',
        component: MyNodr,
        beforeEnter: check,
        ifAuthenticated,
      },
      {
        path: '/statistics',
        name: 'statistics',
        component: Statistics,
        beforeEnter: check,
        ifAuthenticated,
      },
      {
        path: '/settings',
        name: 'settings',
        component: Settings,
        beforeEnter: check,
        ifAuthenticated,
      },
    ],
  },
]
