<template>
  <div class="background-img">
    <div class="logo">
      <img src="../assets/img/png/logo-icon-text.png" alt="logo nodr" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodrBackgroundImg',
}
</script>
