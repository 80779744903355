<template>
  <div class="loader-logo">
    <svg
      width="130"
      height="130"
      viewBox="0 0 130 130"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M130 65C130 100.899 100.899 130 65 130C29.1015 130 0 100.899 0 65C0 29.1015 29.1015 0 65 0C100.899 0 130 29.1015 130 65Z"
        fill="#F3F7FE" />
      <mask
        id="mask0_4059_38801"
        style="mask-type: alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="130"
        height="130">
        <path
          d="M130 65C130 100.899 100.899 130 65 130C29.1015 130 0 100.899 0 65C0 29.1015 29.1015 0 65 0C100.899 0 130 29.1015 130 65Z"
          fill="url(#paint0_linear_4059_38801)" />
      </mask>
      <g mask="url(#mask0_4059_38801)">
        <rect
          x="0"
          :y="loadingColor"
          width="177"
          height="177"
          fill="url(#paint1_linear_4059_38801)" />
      </g>
      <path
        d="M47.9914 99.5728L47.6922 58.523L90.5259 99.5728H96.8073V30.4238H82.9881L83.2872 72.2459L40.3938 30.4238H34.1123V99.5728H38.3H47.9914Z"
        fill="white" />
      <defs>
        <linearGradient
          id="paint0_linear_4059_38801"
          x1="65"
          y1="130"
          x2="113.696"
          y2="21.9452"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#03113A" />
          <stop offset="1" stop-color="#002C8A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4059_38801"
          x1="65.5"
          y1="138"
          x2="87.2085"
          y2="48.2508"
          gradientUnits="userSpaceOnUse">
          <stop stop-color="#03113A" />
          <stop offset="1" stop-color="#002C8A" />
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
export default {
  name: 'NodrLoaderLogo',
  data() {
    return {
      loadingColor: 0,
      isLoading: false,
    }
  },
  methods: {
    loadingLogo() {
      this.loadingColor = 130
      const interval = setInterval(() => {
        this.loadingColor -= 10
        if (this.loadingColor <= 0) {
          clearInterval(interval)
          this.$emit('isLoading', {
            isL: false,
          })
        }
      }, 30)
    },
  },
  mounted() {
    this.loadingLogo()
  },
}
</script>

<style lang="scss">
.loader-logo {
  width: 100%;
  height: 100vh;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
