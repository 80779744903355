<template>
  <div class="reset-password">
    <div class="reset-password-block">
      <div class="reset-password__inner">
        <div class="reset-password__logo">
          <img src="../assets/img/svg/logo-icon-bg-blue60.svg" alt="logo" />
        </div>
        <div class="reset-password-block__form">
          <form @submit.prevent="resetPassword">
            <div class="form-title font-h2 color_white">Reset password</div>

            <div
              class="input-field"
              :class="{
                'error-email':
                  ($v.password.$error && !$v.password.required) ||
                  ($v.password.$error && !$v.password.minLength),
              }">
              <label class="visually-hidden" for="reset-password">
                New password
              </label>
              <input
                :type="typeViewPassword"
                id="reset-password"
                placeholder="New password"
                v-model.trim="password" />
              <div
                v-if="password.length > 0"
                class="password-control"
                @click="viewPassword()">
                <template v-if="!isViewPassword">
                  <svg>
                    <use
                      xlink:href="../assets/img/svg/sprite/sprite-form.svg#eye-off"></use>
                  </svg>
                </template>
                <template v-else>
                  <svg>
                    <use
                      xlink:href="../assets/img/svg/sprite/sprite-form.svg#eye-on"></use>
                  </svg>
                </template>
              </div>
            </div>

            <div class="validation-errors">
              <template
                v-if="
                  $v.confirmPassword.$dirty &&
                  $v.confirmPassword.required &&
                  $v.password.$error &&
                  !$v.password.required
                ">
                <span class="font-small-2 color_coral">Required field</span>
              </template>
              <template
                v-else-if="$v.password.$error && !$v.password.minLength">
                <span class="font-small-2 color_coral"
                  >Minimum 6 symbols are required</span
                >
              </template>
            </div>

            <div
              class="input-field"
              :class="{
                'error-email':
                  ($v.confirmPassword.$error &&
                    !$v.confirmPassword.required &&
                    $v.password.minLength) ||
                  ($v.confirmPassword.$dirty &&
                    !$v.confirmPassword.sameAsPassword &&
                    $v.password.minLength &&
                    !($v.password.$error && !$v.password.required)),
              }">
              <label class="visually-hidden" for="reset-confirm-password"
                >Confirm new password</label
              >
              <input
                :type="typeViewConfirmPassword"
                id="reset-confirm-password"
                placeholder="Confirm new password"
                v-model.trim="confirmPassword" />
              <div
                v-if="confirmPassword.length > 0"
                class="password-control"
                @click="viewConfirmPassword()">
                <template v-if="!isViewConfirmPassword">
                  <svg>
                    <use
                      xlink:href="../assets/img/svg/sprite/sprite-form.svg#eye-off"></use>
                  </svg>
                </template>
                <template v-else>
                  <svg>
                    <use
                      xlink:href="../assets/img/svg/sprite/sprite-form.svg#eye-on"></use>
                  </svg>
                </template>
              </div>
            </div>

            <div class="validation-errors">
              <template
                v-if="
                  $v.confirmPassword.$dirty &&
                  !$v.confirmPassword.required &&
                  $v.password.$error &&
                  !$v.password.required
                ">
                <span class="font-small-2 color_coral"
                  >All fields are required</span
                >
              </template>
              <template
                v-else-if="
                  $v.confirmPassword.$dirty && !$v.confirmPassword.required
                ">
                <span class="font-small-2 color_coral">Required field</span>
              </template>
              <template
                v-else-if="
                  $v.confirmPassword.$dirty &&
                  !$v.confirmPassword.sameAsPassword &&
                  !($v.password.$error && !$v.password.required)
                ">
                <span class="font-small-2 color_coral"
                  >Passwords do not match</span
                >
              </template>
            </div>

            <div class="block-actions">
              <div class="block-actions-btn">
                <button class="button button-white-w font-button">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <tlprt-background-img />
  </div>
</template>

<script>
import Vuex from 'vuex'
import TlprtBackgroundImg from '../components/BackgroundImg'
import {required, sameAs, minLength} from 'vuelidate/lib/validators'
import {actionTypes} from '../store/modules/auth'

export default {
  name: 'TlprtResetPassword',
  components: {TlprtBackgroundImg},
  data() {
    return {
      password: '',
      confirmPassword: '',
      isViewPassword: false,
      isViewConfirmPassword: false,
      typeViewPassword: 'password',
      typeViewConfirmPassword: 'password',
    }
  },
  validations: {
    password: {
      required,
      minLength: minLength(6),
    },
    confirmPassword: {
      required,
      minLength: minLength(6),
      sameAsPassword: sameAs('password'),
    },
  },
  watch: {
    password() {
      this.$v.$reset()
    },
    confirmPassword() {
      this.$v.$reset()
    },
  },
  methods: {
    ...Vuex.mapActions({
      changePassword: actionTypes.changePassword,
    }),
    resetPassword() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }
      this.changePassword({
        hash: this.$route.params.hash,
        password: this.password,
        confirm: this.confirmPassword,
      }).then(() => {
        this.password = ''
        this.confirmPassword = ''
        this.$router.push('/myNodr')
      })
    },
    viewPassword() {
      this.isViewPassword = !this.isViewPassword
      this.isViewPassword
        ? (this.typeViewPassword = 'text')
        : (this.typeViewPassword = 'password')
    },
    viewConfirmPassword() {
      this.isViewConfirmPassword = !this.isViewConfirmPassword
      this.isViewConfirmPassword
        ? (this.typeViewConfirmPassword = 'text')
        : (this.typeViewConfirmPassword = 'password')
    },
  },
}
</script>
