export const termsDeletingUser = (teams) => {
  // console.log(teams)
  // У пользователя есть команды
  if (teams.length > 0) {
    // У порльзователя есть команды которые блокируют удаление - формируются в отдельный массив
    let arrayCannotDelete = teams.filter(
      (item) => item.permissionToDelete === 20
    )

    // Есть ли команды блокирующие удаление?
    if (arrayCannotDelete.length === 0) {
      // Можно удалять пользователя - нет команд блокирующих удаление

      if (teams.length > 1) {
        // У пользователя больше одной команды
        return {
          status: '200',
          statusMessage: '203',
          teams: teams,
        }
      } else if (
        teams.length === 1 &&
        teams[0].countAdmin === 1 &&
        teams[0].countUser === 1
      ) {
        // У пользователя одна команда - где он админ и единственный участник
        return {
          status: '200',
          statusMessage: '201',
          teams: teams,
        }
      } else if (
        teams.length === 1 &&
        teams[0].countAdmin >= 2 &&
        teams[0].countUser >= 2
      ) {
        // У пользователя одна команда - где 2 админа и больше 2х человек
        return {
          status: '200',
          statusMessage: '202',
          teams: teams,
        }
      }
    } else {
      // Нельзя удалять пользователя - есть команды блокирующие удаление

      if (arrayCannotDelete.length === 1) {
        // У пользователя одна команда где он admin и есть другие пользователи
        return {
          status: '400',
          statusMessage: '402',
          arrayCannotDelete: arrayCannotDelete,
        }
      } else {
        // У пользователя больше одной команды где он admin и есть другие пользователи
        return {
          status: '400',
          statusMessage: '401',
          arrayCannotDelete: arrayCannotDelete,
        }
      }
    }
  } else {
    // У пользователя нет команд вообще и его можно удалить
    return {
      status: '200',
    }
  }
}
