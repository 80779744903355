<template>
  <div class="statistics-chart">
<!--    <tlprt-loader-animated v-if="isLoadedStat"/>-->
    <template>
      <div class="statistics-chart__block">
        <div class="block__description">
          <div class="description-title">
            <span class="font-h5 color_dark-gray">Traffic</span>
          </div>
          <div class="description-item">
            <span class="font-h5 color-middle-aqua">{{ !isLoadedStat ? bitesToStringTotal(statTotal.nodrUpLink) : '0'}}B</span>
            <span class="font-small-2 color_gray">NODR UPLINK</span>
          </div>
          <div class="description-item">
            <span class="font-h5 color_dodger-blue">{{ !isLoadedStat ? bitesToStringTotal(statTotal.deliveredToViewers) : '0'}}B</span>
            <span class="font-small-2 color_gray">DELIVERED TO VIEWERS</span>
          </div>
          <div class="description-item">
            <span class="font-h5 color_coral">{{ !isLoadedStat ? bitesToStringTotal(statTotal.inputFromCdn) : '0'}}B</span>
            <span class="font-small-2 color_gray">INPUT FROM CDN</span>
          </div>
        </div>
        <highcharts :options="chartOptionsTraffic"></highcharts>
        <div v-if="isActiveArrayLicenseKeyError" class="block-error">
          <div class="block-error__description">
            <svg>
              <use
                xlink:href="../../assets/img/sprite/sprite-stat.svg#error-select-key-stat"
              ></use>
            </svg>
            <span class="color_medium-gray font-small-bold">
                Please choose at least one NODR <br />
                 in the list to see stats
            </span>
          </div>
        </div>
        <div v-if="isLoadedStatError" class="block-error">
          <div class="block-error__description">
            <svg>
              <use
                xlink:href="../../assets/img/sprite/sprite-stat.svg#error-stat"
              ></use>
            </svg>
            <span class="color_coral font-small-bold">
              Sorry, something went wrong... <br />
              Please try again later
             </span>
          </div>
        </div>
        <div v-if="isLoadedStat" class="block-loaded-stat">
          <nodr-loader/>
        </div>
      </div>
<!--      <div class="statistics-chart__block">-->
<!--        <div class="block__description">-->
<!--          <div class="description-title">-->
<!--            <span class="font-h5 color_dark-gray">Viewers</span>-->
<!--          </div>-->
<!--          <div class="description-item">-->
<!--            <span class="font-h5 color_middle-sky">{{numberWithCommas(statTotal.totalViewers)}}</span>-->
<!--            <span class="font-small-2 color_gray">TOTAL VIEWERS</span>-->
<!--          </div>-->
<!--        </div>-->
<!--        <highcharts :options="chartOptionsViewers"></highcharts>-->
<!--      </div>-->
      <div class="statistics-chart__block">
        <div class="block__description">
          <div class="description-title">
            <span class="font-h5 color_dark-gray">Quality</span>
          </div>
          <div class="description-item">
            <span class="font-h5 color_middle-sunny">{{ !isLoadedStat ? statTotal.qualityIndex : '0'}}</span>
            <span class="font-small-2 color_gray">QUALITY INDEX</span>
          </div>
        </div>
        <highcharts :options="chartOptionsQuality"></highcharts>
        <div v-if="isActiveArrayLicenseKeyError" class="block-error">
          <div class="block-error__description">
            <svg>
              <use
                xlink:href="../../assets/img/sprite/sprite-stat.svg#error-select-key-stat"
              ></use>
            </svg>
            <span class="color_medium-gray font-small-bold">
                Please choose at least one NODR <br />
                 in the list to see stats
            </span>
          </div>
        </div>
        <div v-if="isLoadedStatError" class="block-error">
          <div class="block-error__description">
            <svg>
              <use
                xlink:href="../../assets/img/sprite/sprite-stat.svg#error-stat"
              ></use>
            </svg>
            <span class="color_coral font-small-bold">
              Sorry, something went wrong... <br />
              Please try again later
             </span>
          </div>
        </div>
        <div v-if="isLoadedStat" class="block-loaded-stat">
          <nodr-loader/>
        </div>
      </div>
      <div class="statistics-chart__block">
        <div class="block__description">
          <div class="description-title">
            <span class="font-h5 color_dark-gray">D/I ratio</span>
          </div>
          <div class="description-item">
            <span class="font-h5 color_middle-purple">{{ !isLoadedStat ? statTotal.deliverToInputRatio : '0'}}</span>
            <span class="font-small-2 color_gray">DELIVER-TO-INPUT RATIO</span>
          </div>

        </div>
        <highcharts :options="chartOptionsDIRatio"></highcharts>
        <div v-if="isActiveArrayLicenseKeyError" class="block-error">
          <div class="block-error__description">
            <svg>
              <use
                xlink:href="../../assets/img/sprite/sprite-stat.svg#error-select-key-stat"
              ></use>
            </svg>
            <span class="color_medium-gray font-small-bold">
                Please choose at least one NODR <br />
                 in the list to see stats
            </span>
          </div>
        </div>
        <div v-if="isLoadedStatError" class="block-error">
          <div class="block-error__description">
            <svg>
              <use
                xlink:href="../../assets/img/sprite/sprite-stat.svg#error-stat"
              ></use>
            </svg>
            <span class="color_coral font-small-bold">
              Sorry, something went wrong... <br />
              Please try again later
             </span>
          </div>
        </div>
        <div v-if="isLoadedStat" class="block-loaded-stat">
          <nodr-loader/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import Vuex from 'vuex'
import {Chart} from 'highcharts-vue'
import {getterTypes as getterTypesStatistics} from "@/store/modules/statistics";
import TlprtLoaderAnimated from "@/components/Loaders/LoaderAnimated";
import NodrLoader from "@/components/Loaders/Loader";

/**
 * @typedef {Object} statData
 * @property {number} dt
 * @property {string} deliverToInputRatio
 * @property {number} qualityIndex
 * @property {number} totalViewers
 * @property {number} inputFromCdn
 * @property {number} deliveredToViewers
 * @property {number} nodrUpLink
 */
/**
 * @param {Object.<string, statData>} stats
 * @returns {[number, number][]}
 */
function statsToDataUPLink(stats) {
  let data = []
  Object.keys(stats).forEach((k) => {
    let point = stats[k]
    data.push([point.dt * 1000, point.nodrUpLink])
  })
  // console.log(' >> data >> ',data)
  return data
}
/**
 * @param {Object.<string, statData>} stats
 * @returns {[number, number][]}
 */
function statsToDataViewers(stats) {
  let data = []
  Object.keys(stats).forEach((k) => {
    let point = stats[k]
    data.push([point.dt * 1000, point.deliveredToViewers])
  })
  return data
}
/**
 * @param {Object.<string, statData>} stats
 * @returns {[number, number][]}
 */
function statsToDataCDN(stats) {
  let data = []
  Object.keys(stats).forEach((k) => {
    let point = stats[k]
    data.push([point.dt * 1000, +`-${point.inputFromCdn}`])
  })
  return data
}
/**
 * @param {Object.<string, statData>} stats
 * @returns {[number, number][]}
 */
function statsToDataTotalViewers(stats) {
  let data = []
  Object.keys(stats).forEach((k) => {
    let point = stats[k]
    data.push([point.dt * 1000, point.totalViewers])
  })
  return data
}
/**
 * @param {Object.<string, statData>} stats
 * @returns {[number, number][]}
 */
function statsToDataQualityIndex(stats) {
  let data = []
  Object.keys(stats).forEach((k) => {
    let point = stats[k]
    data.push([point.dt * 1000, point.qualityIndex])
  })
  return data
}
/**
 * @param {Object.<string, statData>} stats
 * @returns {[number, number][]}
 */
function statsToDataInputRatio(stats) {
  let data = []
  Object.keys(stats).forEach((k) => {
    let point = stats[k]
    data.push([point.dt * 1000, point.deliverToInputRatio])
  })
  return data
}

function bitesToString(value, discrete) {
  switch (true) {
    case value / discrete >= Math.pow(1024, 5):
      return `${
        (((10 * ((value * 8) / discrete)) / Math.pow(1024, 5)) | 0) / 10
      } P`
    case (value * 8) / discrete >= Math.pow(1024, 4) &&
    (value * 8) / discrete < Math.pow(1024, 5):
      return `${
        (((10 * ((value * 8) / discrete)) / Math.pow(1024, 4)) | 0) / 10
      } T`
    case (value * 8) / discrete >= Math.pow(1024, 3) &&
    (value * 8) / discrete < Math.pow(1024, 4):
      return `${
        (((10 * ((value * 8) / discrete)) / Math.pow(1024, 3)) | 0) / 10
      } G`
    case (value * 8) / discrete >= Math.pow(1024, 2) &&
    (value * 8) / discrete < Math.pow(1024, 3):
      return `${
        (((10 * ((value * 8) / discrete)) / Math.pow(1024, 2)) | 0) / 10
      } M`
    case (value * 8) / discrete / 8 >= Math.pow(1024, 1) &&
    (value * 8) / discrete < Math.pow(1024, 2):
      return `${
        (((10 * ((value * 8) / discrete)) / Math.pow(1024, 1)) | 0) / 10
      } K`
    default:
      return `${value} `
  }
}

function bitesToStringMinus(value, discrete) {
  switch (true) {
        case value >= Math.pow(1024, 5):
          return `${
            (((10 * ((value * 8) )) / Math.pow(1024, 5)) | 0) / 10
          } P`
        case (value * 8) >= Math.pow(1024, 4) &&
        (value * 8) < Math.pow(1024, 5):
          return `${
            (((10 * ((value * 8))) / Math.pow(1024, 4)) | 0) / 10
          } T`
        case (value * 8) >= Math.pow(1024, 3) &&
        (value * 8) < Math.pow(1024, 4):
          return `${
            (((10 * ((value * 8) )) / Math.pow(1024, 3)) | 0) / 10
          } G`
        case (value * 8) >= Math.pow(1024, 2) &&
        (value * 8) < Math.pow(1024, 3):
          return `${
            (((10 * ((value * 8) )) / Math.pow(1024, 2)) | 0) / 10
          } M`
        case (value * 8)  / 8 >= Math.pow(1024, 1) &&
        (value * 8) < Math.pow(1024, 2):
          return `${
            (((10 * ((value * 8) )) / Math.pow(1024, 1)) | 0) / 10
          } K`
        default:
          return `${value} `
      }
}

function minValueSpan(discrete) {
  if (discrete === 60) return 3600000
  if (discrete === 1200) return 3600000 * 20
  if (discrete === 3600) return 3600000 * 60
}

function dateToString(value, wrap) {
  let date = new Date(value)
  // let year = date.getFullYear();
  let month = ('0' + (date.getMonth() + 1)).slice(-2)
  let day = ('0' + date.getDate()).slice(-2)
  let hours = ('0' + date.getHours()).slice(-2)
  let minutes = ('0' + date.getMinutes()).slice(-2)
  return `${day}.${month}${wrap ? ' | ' : ', '}${hours}:${minutes}`
}

function tooltip1(timePoint, names, markers, volumes) {
  return `

<div class="main-tooltip-container">
    <div class="main-tooltip-container__title">
        <span>${timePoint}</span>
    </div>
     <div class="main-tooltip-container__block">
        <div class="circle" style="background-color: ${markers[0]}"></div>
        <span style="display: inline-block">${names[0]} | </span>
        <span style="display: inline-block;"> ${volumes[0]}bps</span>
    </div>
    <div class="main-tooltip-container__block">
        <div class="circle" style="background-color: ${markers[1]}"></div>
        <span style="display: inline-block">${names[1]} | </span>
        <span style="display: inline-block;"> ${volumes[1]}bps</span>
    </div>
    <div class="main-tooltip-container__block">
        <div class="circle" style="background-color: ${markers[2]}"></div>
        <span style="display: inline-block">${names[2]} | </span>
        <span style="display: inline-block;"> ${volumes[2] === 0 ? 0 : volumes[2]}bps</span>
    </div>
</div>`
}
function tooltip2(timePoint, names, markers, volumes) {
  return `

<div class="main-tooltip-container">
    <div class="main-tooltip-container__title">
        <span>${timePoint}</span>
    </div>
     <div class="main-tooltip-container__block">
        <div class="circle" style="background-color: ${markers[0]}"></div>
        <span style="display: inline-block">${names[0]} | </span>
        <span style="display: inline-block;"> ${volumes[0]} </span>
    </div>
</div>`
}

function tooltip3(timePoint, names, markers, volumes) {
  return `

<div class="main-tooltip-container">
    <div class="main-tooltip-container__title">
        <span>${timePoint}</span>
    </div>
     <div class="main-tooltip-container__block">
        <div class="circle" style="background-color: ${markers[0]}"></div>
        <span style="display: inline-block">${names[0]} | </span>
        <span style="display: inline-block;"> ${volumes[0]} </span>
    </div>
</div>`
}

export default {
  name: 'NodrStatisticsChart',
  props: {
    stat: {
      type: Object,
      require: true,
    },
    statTotal: {
      type: Object,
      require: true
    },
    isActiveArrayLicenseKeyError: {
      type: Boolean
    }
  },
  computed: {
    ...Vuex.mapGetters({
      discrete: getterTypesStatistics.discrete,
      isLoadedStat: getterTypesStatistics.isLoadedStat,
      isLoadedStatError: getterTypesStatistics.isLoadedStatError
    }),
    chartOptionsTraffic() {
      let discrete = this.discrete
      return {
        tooltip: {
          // show: true,
          trigger: 'axis',
          shared: true,
          useHTML: true,
          // axisPointer: {
          //   type: 'shadow'
          // },
          borderColor: 'none',
          // borderRadius: 8,
          transitionDuration: 0.7,
          backgroundColor: '#fff',
          padding: 0,
          formatter: function () {
            // console.log(">> this chart >> ",this)
            let datePoint = dateToString(this.x, false)
            let names = [this.points[0].series.name, this.points[1].series.name, this.points[2].series.name]
            let markers =  [this.points[0].series.userOptions.lineColor, this.points[1].series.userOptions.lineColor, this.points[2].series.userOptions.lineColor]

            let values = [
                  // bitesToString(this.points[0].y, discrete),
                  // bitesToString(this.points[1].y, discrete),
                  // bitesToString(-this.points[2].y, discrete),
                  bitesToStringMinus(this.points[0].y),
                  bitesToStringMinus(this.points[1].y),
                  bitesToStringMinus(-this.points[2].y),
                ]

            return tooltip1(datePoint, names, markers, values)
          },
          position: function (pos) {
            return [pos[0], pos[1] < 250 ? 36 : 286]
          },
        },
        title: {
          text: undefined
        },
        chart: {
          zoomBySingleTouch: true,
          zoomType: 'x',
        },
        navigator: {
          adaptToUpdatedData: false,
          enabled: true,

        },
        legend: {
          enabled: false
        },
        graphic: {
          elements: [
            // {
            //   type: 'text',
            //   silent: true,
            //   style: {
            //     fill: '#06283B',
            //     text: 'Bandwidth',
            //     font: '800 16px Montserrat, Roboto, sans-serif',
            //   },
            // },
            // {
            //   type: 'text',
            //   silent: true,
            //   position: [0, 250],
            //   style: {
            //     fill: '#06283B',
            //     text: 'Viewers',
            //     font: '800 16px Montserrat, Roboto, sans-serif',
            //   },
            // },
          ],
        },
        // grid: [
        //   {width: 'auto', height: '134px', top: 36, left: 75},
        //   {width: 'auto', height: '134px', top: 286, left: 75},
        // ],
        xAxis: [
          {
            type: 'datetime',
            labels: {
              formatter: function (value) {
                return dateToString(value.value, true)
              },
            },
            setExtremes: minValueSpan(discrete)
          },
        ],
        yAxis: [
          {
            type: 'value',
            title: {
              text: null
            },
            labels: {
              formatter: function (value) {
                // console.log(value)
                // switch (true) {
                //   case value.value / discrete >= Math.pow(1024, 5):
                //     return `${(((10 * ((value.value * 8) / discrete)) / Math.pow(1024, 5)) | 0) / 10} P`
                //   case (value.value * 8) / discrete >= Math.pow(1024, 4) && (value.value * 8) / discrete < Math.pow(1024, 5):
                //     return `${(((10 * ((value.value * 8) / discrete)) / Math.pow(1024, 4)) | 0) / 10} T`
                //   case (value.value * 8) / discrete >= Math.pow(1024, 3) && (value.value * 8) / discrete < Math.pow(1024, 4):
                //     return `${(((10 * ((value.value * 8) / discrete)) / Math.pow(1024, 3)) | 0) / 10} G`
                //   case (value.value * 8) / discrete >= Math.pow(1024, 2) && (value.value * 8) / discrete < Math.pow(1024, 3):
                //     return `${(((10 * ((value.value * 8) / discrete)) / Math.pow(1024, 2)) | 0) / 10} M`
                //   case (value.value * 8) / discrete / 8 >= Math.pow(1024, 1) && (value.value * 8) / discrete < Math.pow(1024, 2):
                //     return `${(((10 * ((value.value * 8) / discrete)) / Math.pow(1024, 1)) | 0) / 10} K`
                //
                //   case value.value / discrete < -Math.pow(1024, 5):
                //     return `${(((10 * ((value.value * 8) / discrete)) / Math.pow(1024, 5)) | 0) / 10} P`
                //   case (value.value * 8) / discrete < -Math.pow(1024, 4) && (value.value * 8) / discrete < Math.pow(1024, 5):
                //     return `${(((10 * ((value.value * 8) / discrete)) / Math.pow(1024, 4)) | 0) / 10} T`
                //   case (value.value * 8) / discrete < -Math.pow(1024, 3) && (value.value * 8) / discrete < Math.pow(1024, 4):
                //     return `${(((10 * ((value.value * 8) / discrete)) / Math.pow(1024, 3)) | 0) / 10} G`
                //   case (value.value * 8) / discrete < -Math.pow(1024, 2) && (value.value * 8) / discrete < Math.pow(1024, 3):
                //     return `${(((10 * ((value.value * 8) / discrete)) / Math.pow(1024, 2)) | 0) / 10} M`
                //   case (value.value * 8) / discrete / 8 < -Math.pow(1024, 1) && (value.value * 8) / discrete < Math.pow(1024, 2):
                //     return `${(((10 * ((value.value * 8) / discrete)) / Math.pow(1024, 1)) | 0) / 10} K`
                //
                //   default:
                //     return `${value.value}`
                // }
                switch (true) {
                  case value.value >= Math.pow(1024, 5):
                    return `${(((10 * ((value.value * 8))) / Math.pow(1024, 5)) | 0) / 10} P`
                  case (value.value * 8) >= Math.pow(1024, 4) && (value.value * 8) < Math.pow(1024, 5):
                    return `${(((10 * ((value.value * 8))) / Math.pow(1024, 4)) | 0) / 10} T`
                  case (value.value * 8) >= Math.pow(1024, 3) && (value.value * 8) < Math.pow(1024, 4):
                    return `${(((10 * ((value.value * 8))) / Math.pow(1024, 3)) | 0) / 10} G`
                  case (value.value * 8) >= Math.pow(1024, 2) && (value.value * 8) < Math.pow(1024, 3):
                    return `${(((10 * ((value.value * 8))) / Math.pow(1024, 2)) | 0) / 10} M`
                  case (value.value * 8) / 8 >= Math.pow(1024, 1) && (value.value * 8) < Math.pow(1024, 2):
                    return `${(((10 * ((value.value * 8))) / Math.pow(1024, 1)) | 0) / 10} K`

                  case value.value < -Math.pow(1024, 5):
                    return `${(((10 * ((value.value * 8))) / Math.pow(1024, 5)) | 0) / 10} P`
                  case (value.value * 8) < -Math.pow(1024, 4) && (value.value * 8) < Math.pow(1024, 5):
                    return `${(((10 * ((value.value * 8))) / Math.pow(1024, 4)) | 0) / 10} T`
                  case (value.value * 8)  < -Math.pow(1024, 3) && (value.value * 8) < Math.pow(1024, 4):
                    return `${(((10 * ((value.value * 8))) / Math.pow(1024, 3)) | 0) / 10} G`
                  case (value.value * 8) < -Math.pow(1024, 2) && (value.value * 8) < Math.pow(1024, 3):
                    return `${(((10 * ((value.value * 8))) / Math.pow(1024, 2)) | 0) / 10} M`
                  case (value.value * 8) / 8 < -Math.pow(1024, 1) && (value.value * 8) < Math.pow(1024, 2):
                    return `${(((10 * ((value.value * 8))) / Math.pow(1024, 1)) | 0) / 10} K`

                  default:
                    return `${value.value}`
                }
              },
            }
          },

        ],
        // dataZoom: [
        //   {
        //     type: 'inside',
        //     xAxisIndex: [0, 1],
        //     minValueSpan: minValueSpan(this.discrete),
        //     showDetail: false,
        //     zoomOnMouseWheel: true,
        //   },
        //   {
        //     type: 'slider',
        //     xAxisIndex: [0, 1],
        //     minValueSpan: minValueSpan(this.discrete),
        //     showDetail: false,
        //     zoomOnMouseWheel: true,
        //   },
        // ],

        series: [
          {
            name: 'Uplink',
            type: 'area',
            // smooth: true,
            // stack: 1,
            // xAxisIndex: 0,
            // yAxisIndex: 0,
            // symbol: 'none',
            // color: '#1AD8AA',
            lineColor: '#1AD8AA',
            color: '#CAFFF2',
            marker: {
              radius: 4,
              lineColor: '#1AD8AA',
              lineWidth: 1,
              symbol: 'circle',
              states: {
                hover: {
                  fillColor: '#1AD8AA',
                  // lineWidth: 2
                  // enabled: false,
                }
              }
            },
            // lineStyle: {
            //   width: 1,
            //   color: '#1AD8AA',
            // },
            // areaStyle: {
            //   color: '#1AD8AA',
            // },
            // showSymbol: false,
            data: this.stat && statsToDataUPLink(this.stat),
          },
          {
            name: 'Delivered',
            type: 'area',
            // smooth: true,
            // stack: 1,
            // xAxisIndex: 0,
            // yAxisIndex: 0,
            // symbol: 'none',
            lineColor: '#0062FF',
            color: '#E5EFFF',
            marker: {
              radius: 4,
              lineColor: '#0062FF',
              lineWidth: 1,
              symbol: 'circle',
              states: {
                hover: {
                  fillColor: '#0062FF',
                  // lineWidth: 2
                  // enabled: false,
                }
              }
            },
            // lineStyle: {
            //   width: 1,
            //   color: '#0062FF',
            // },
            // areaStyle: {
            //   color: '#0062FF',
            // },
            // showSymbol: false,
            data: this.stat && statsToDataViewers(this.stat),
          },
          {
            name: 'Input',
            type: 'area',
            // smooth: true,
            // stack: 1,
            // xAxisIndex: 0,
            // yAxisIndex: 0,
            // symbol: 'none',
            lineColor: '#E4005D',
            color: '#FFF1F7',
            marker: {
              radius: 4,
              lineColor: '#E4005D',
              lineWidth: 1,
              symbol: 'circle',
              states: {
                hover: {
                  fillColor: '#E4005D',
                  // lineWidth: 2
                  // enabled: false,
                }
              }
            },
            // lineStyle: {
            //   width: 1,
            //   color: '#E4005D',
            // },
            // areaStyle: {
            //   color: '#E4005D',
            // },
            // showSymbol: false,
            data: this.stat && statsToDataCDN(this.stat),
          },
        ]
      }
    },
    chartOptionsViewers(){
      let discrete = this.discrete
      return {
        title: {
          text: undefined
        },
        xAxis: {
          type: 'datetime'
        },
        yAxis: {
          title: {
            text: '2'
          }
        },
        series: [
          {
            name: 'Total Viewers',
            type: 'line',
            smooth: true,
            stack: 1,
            xAxisIndex: 0,
            yAxisIndex: 0,
            symbol: 'none',
            color: '#5BFFA3',
            lineStyle: {
              width: 1,
              color: '#5BFFA3',
            },
            areaStyle: {
              color: '#5BFFA3',
            },
            showSymbol: false,
            data: this.stat && statsToDataTotalViewers(this.stat),
          },
        ]
      }
    },
    chartOptionsQuality(){
      let discrete = this.discrete
      return {
        title: {
          text: undefined
        },
        legend: {
          enabled: false
        },
        chart: {
          zoomBySingleTouch: true,
          zoomType: 'x',
        },
        navigator: {
          adaptToUpdatedData: false,
          enabled: true,
        },
        tooltip: {
          // show: true,
          trigger: 'axis',
          shared: true,
          useHTML: true,
          // axisPointer: {
          //   type: 'shadow'
          // },
          borderColor: 'none',
          // borderRadius: 8,
          transitionDuration: 0.7,
          backgroundColor: '#fff',
          padding: 0,
          formatter: function () {
            // console.log(">> this chart >> ",this)
            let datePoint = dateToString(this.x, false)
            let names = [this.points[0].series.name]
            let markers =  [this.points[0].series.color]

            let values = [bitesToString(this.points[0].y, discrete)]

            return tooltip2(datePoint, names, markers, values)
          },
          position: function (pos) {
            return [pos[0], pos[1] < 250 ? 36 : 286]
          },
        },
        xAxis: {
          type: 'datetime',
          labels: {
            formatter: function (value) {
              return dateToString(value.value, true)
            },
          },
        },
        yAxis: {
          type: 'value',
          title: {
            text: null
          },
        },
        series: [
          {
            name: 'Quality',
            type: 'line',
            // smooth: true,
            // stack: 1,
            // xAxisIndex: 0,
            // yAxisIndex: 0,
            // symbol: 'none',
            color: '#D9AA03',
            // lineStyle: {
            //   width: 1,
            //   color: '#5BFFA3',
            // },
            // areaStyle: {
            //   color: '#5BFFA3',
            // },
            // showSymbol: false,
            data: this.stat && statsToDataQualityIndex(this.stat),
          },
        ]
      }
    },
    chartOptionsDIRatio(){
      let discrete = this.discrete
      return {
        title: {
          text: undefined
        },
        legend: {
          enabled: false
        },
        chart: {
          zoomBySingleTouch: true,
          zoomType: 'x',
        },
        navigator: {
          adaptToUpdatedData: false,
          enabled: true,
        },
        tooltip: {
          // show: true,
          trigger: 'axis',
          shared: true,
          useHTML: true,
          // axisPointer: {
          //   type: 'shadow'
          // },
          borderColor: 'none',
          // borderRadius: 8,
          transitionDuration: 0.7,
          backgroundColor: '#fff',
          padding: 0,
          formatter: function () {
            // console.log(">> this chart >> ",this)
            let datePoint = dateToString(this.x, false)
            let names = [this.points[0].series.name]
            let markers =  [this.points[0].series.color]

            let values = [bitesToString(this.points[0].y, discrete)]

            return tooltip3(datePoint, names, markers, values)
          },
          position: function (pos) {
            return [pos[0], pos[1] < 250 ? 36 : 286]
          },
        },
        xAxis: {
          type: 'datetime',
          labels: {
            formatter: function (value) {
              return dateToString(value.value, true)
            },
          },
        },
        yAxis: {
          type: 'value',
          title: {
            text: null
          },
        },
        series: [
          {
            name: 'Ratio',
            type: 'line',
            // smooth: true,
            // stack: 1,
            // xAxisIndex: 0,
            // yAxisIndex: 0,
            // symbol: 'none',
            color: '#CB93FF',
            // lineStyle: {
            //   width: 1,
            //   color: '#5BFFA3',
            // },
            // areaStyle: {
            //   color: '#5BFFA3',
            // },
            // showSymbol: false,
            data: this.stat && statsToDataInputRatio(this.stat),
          },
        ]
      }
    },
  },
  methods: {
    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    bitesToStringTotal(value) {
      console.log('>> value test data >> bites>>', value)
      switch (true) {
        case value >= Math.pow(1024, 5):
          return `${
            (((10 * ((value * 8))) / Math.pow(1024, 5)) | 0) / 10
          } P`
        case (value * 8) >= Math.pow(1024, 4) &&
        (value * 8) < Math.pow(1024, 5):
          return `${
            (((10 * ((value * 8))) / Math.pow(1024, 4)) | 0) / 10
          } T`
        case (value * 8) >= Math.pow(1024, 3) &&
        (value * 8) < Math.pow(1024, 4):
          return `${
            (((10 * ((value * 8))) / Math.pow(1024, 3)) | 0) / 10
          } G`
        case (value * 8) >= Math.pow(1024, 2) &&
        (value * 8) < Math.pow(1024, 3):
          return `${
            (((10 * ((value * 8))) / Math.pow(1024, 2)) | 0) / 10
          } M`
        case (value * 8) >= Math.pow(1024, 1) &&
        (value * 8) < Math.pow(1024, 2):
          return `${
            (((10 * ((value * 8))) / Math.pow(1024, 1)) | 0) / 10
          } K`
        default:
          return `${value}`
      }
    }
  },
  components: {
    NodrLoader,
    TlprtLoaderAnimated,
    highcharts: Chart,
  },
}
</script>

<style lang="scss">
.main-tooltip-container {
  background: #FFFFFF;
  //box-shadow: 0 0 20px rgba(195, 207, 224, 0.6);
  border-radius: 8px;

  padding: 11px 14px;
  span {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    color: #6F819B;
  }
  .main-tooltip-container__title {
    padding-bottom: 5px;
  }
  .main-tooltip-container__block {
    display: flex;
    align-items: center;

    .circle {
      width: 6px;
      height: 6px;
      border-radius: 50px;
      margin-right: 5px;
    }
  }
}

</style>
