<template>
  <div class="no-nodr">
    <div class="no-nodr__image">
      <img src="../assets/img/png/no-nodr.png" alt="No NODR" />
    </div>
    <div class="no-nodr__title">
      <span class="color_dark-gray font-h5"
        >You haven't added any NODR yet</span
      >
      <span class="color_gray font-text"
        >Please click the button below and follow instructions to add NODR</span
      >
      <button class="button button-blue color_white" @click="startNodr">
        Add my first NODR
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodrNoNodr',
  methods: {
    startNodr() {
      this.$emit('startNodrOptions', true)
    },
  },
}
</script>

<style lang="scss">
.no-nodr {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  &__image {
    //margin-top: 132px;
    margin-bottom: 5px;

    width: 444px;
    height: 290px;
  }

  &__title {
    span {
      display: block;
      margin-bottom: 20px;
    }

    .button {
      text-decoration: none;
    }
  }
}
@media (max-height: 900px) {
  .no-nodr {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    &__image {
      margin-top: 70px;
      margin-bottom: 15px;

      width: 444px;
      height: 290px;
    }

    &__title {
      span {
        display: block;
        margin-bottom: 20px;
      }

      .button {
        text-decoration: none;
      }
    }
  }
}
@media (max-height: 770px) {
  .no-nodr {
    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    text-align: center;

    &__image {
      margin-top: 50px;
      margin-bottom: 15px;

      width: 444px;
      height: 290px;
    }

    &__title {
      span {
        display: block;
        margin-bottom: 20px;
      }

      .button {
        text-decoration: none;
      }
    }
  }
}
</style>
