<template>
  <div class="forgot-password">
    <div class="forgot-password-block">
      <div class="forgot-password__inner">
        <div class="forgot-password__logo">
          <img src="../assets/img/svg/logo-icon-bg-blue60.svg" alt="logo" />
        </div>
        <div class="forgot-password-block__form">
          <form @submit.prevent="forgotPassword">
            <div class="font-h2 color_white">Forgot password?</div>
            <div class="form-title font-text color_white">
              Enter your e-mail address below and we will send you a link to
              reset your password
            </div>
            <div
              class="input-field"
              :class="{
                'error-email':
                  ($v.email.$dirty && !$v.email.required) ||
                  ($v.email.$dirty && !$v.email.email),
              }">
              <label class="visually-hidden" for="login-email"
                >Enter your e-mail</label
              >
              <input
                type="text"
                id="login-email"
                placeholder="Enter your e-mail"
                v-model.trim="email" />
            </div>
            <div class="validation-errors">
              <template v-if="$v.email.$dirty && !$v.email.required">
                <span class="font-small-2 color_coral">Required field</span>
              </template>
              <template v-if="$v.email.$dirty && !$v.email.email">
                <span class="font-small-2 color_coral">invalid email</span>
              </template>
            </div>
            <div class="block-actions">
              <div class="block-actions-btn">
                <button class="button button-white font-button">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="forgot-password-block__link-auth">
        <router-link class="links-white" :to="{name: 'login'}">
          Return to login page
          <svg>
            <use
              xlink:href="../assets/img/svg/sprite/sprite-domains.svg#arrow-right"></use>
          </svg>
        </router-link>
      </div>
    </div>

    <tlprt-background-img />
  </div>
</template>

<script>
import Vuex from 'vuex'
import TlprtBackgroundImg from '../components/BackgroundImg'
import {email, required} from 'vuelidate/lib/validators'
import {actionTypes} from '../store/modules/auth'

export default {
  name: 'TlprtForgotPassword',
  components: {TlprtBackgroundImg},
  data() {
    return {
      email: '',
    }
  },
  validations: {
    email: {email, required},
  },
  watch: {
    email() {
      this.$v.$reset()
    },
  },
  methods: {
    ...Vuex.mapActions({
      restorePassword: actionTypes.forgotPassword,
    }),
    forgotPassword() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.restorePassword({
        login: this.email,
        url: origin + '/resPassword',
      }).then(() => {
        this.$router.push({name: 'passwordRecovery'})
      })
    },
  },
}
</script>
