import apiStatistics from "@/services/api.statistics";
import {dayStat} from "@/components/Statistics/mokeStat/day";

const state = {
  stat: {},
  mokStat: dayStat,
  statTotal: {},
  isLoadedStat: false,
  isLoadedStatError: false,
  discrete: 60,
  statConfList: [],
  isLoadingStatConfList: false,
  statTabs: [],
  isLoadingStatTabs: false,
}

export const mutationTypes = {
  statStart: '[stat] Statistics start',
  statSuccess: '[stat] Statistics success',
  statFailure: '[stat] Statistics failure',

  discreteStart: '[stat] Discrete start',
  discreteSuccess: '[stat] Discrete success',
  discreteFailure: '[stat] Discrete failure',

  findStatConfListStart: '[stat] Find statistics configuration list start',
  findStatConfListSuccess: '[stat] Find statistics configuration list success',
  findStatConfListFailure: '[stat] Find statistics configuration list failure',

  saveStatConfListStart: '[stat] Save statistics configuration list start',
  saveStatConfListSuccess: '[stat] Save statistics configuration list success',
  saveStatConfListFailure: '[stat] Save statistics configuration list failure',

  mokStatStart: '[stat] Mok statistics start'

}
export const getterTypes = {
  stat: '[stat] Statistics',
  statTotal: '[stat] Statistics total',
  isLoadedStat: '[stat] Is loaded statistics',
  isLoadedStatError: '[stat] Is loaded statistics error',
  discrete: '[stat] Discrete',
  statConfList: '[stat] Statistics configuration list',
  isLoadingStatConfList: '[stat] Is loading statistics configuration list',
  statTabs: '[stat] Statistics tabs',
  isLoadingStatTabs: '[stat] Is loading statistics tabs',
}
export const actionTypes = {
  getStatDay: '[stat] Get statistics day',
  getStatWeek: '[stat] Get statistics week',
  getStatMonth: '[stat] Get statistics month',
  findStatConfList: '[stat] Find statistics configuration list',
  saveStatConfList: '[stat] Save statistics configuration list',
  getMokStat: '[stat] Get mok statistics'
}
const getters = {
  [getterTypes.stat]: (state) => {
    return state.stat
  },
  [getterTypes.statTotal]: (state) => {
    return state.statTotal
  },
  [getterTypes.isLoadedStatError]: (state) => {
    return state.isLoadedStatError
  },
  [getterTypes.isLoadedStat]: (state) => {
    return state.isLoadedStat
  },
  [getterTypes.discrete]: (state) => {
    return state.discrete
  },
  [getterTypes.statConfList]: (state) => {
    return state.statConfList
  },
  [getterTypes.isLoadingStatConfList]: (state) => {
    return state.isLoadingStatConfList
  },
  [getterTypes.statTabs]: (state) => {
    return state.statTabs
  },
  [getterTypes.isLoadingStatTabs]: (state) => {
    return state.isLoadingStatTabs
  }
}
const mutations = {
  [mutationTypes.statStart](state) {
    state.isLoadedStat = true
    state.isLoadedStatError = false
  },
  [mutationTypes.statSuccess](state, data) {
    state.isLoadedStat = false
    state.isLoadedStatError = false
    state.stat = data.result
    state.statTotal = data.total_result
  },
  [mutationTypes.statFailure](state){
    state.isLoadedStat = false
    state.isLoadedStatError = true
  },
  [mutationTypes.discreteSuccess](state, discrete) {
    state.discrete = discrete
  },
  [mutationTypes.findStatConfListStart](state) {
    state.isLoadingStatConfList = true
  },
  [mutationTypes.findStatConfListSuccess](state, data) {
    state.isLoadingStatConfList = false
    state.statConfList = data

    // console.log('>> statConfList >>', state.statConfList)
  },
  [mutationTypes.findStatConfListFailure](state){
    state.isLoadingStatConfList = false
  },
  [mutationTypes.saveStatConfListStart](state) {
    state.isLoadingStatTabs = true
  },
  [mutationTypes.saveStatConfListSuccess](state, data) {
    state.isLoadingStatTabs = false
    state.statTabs = JSON.parse(data.text)

    // console.log('>> statTabs >>', state.statTabs)
  },
  [mutationTypes.saveStatConfListFailure](state) {
    state.isLoadingStatTabs = false
  },
  [mutationTypes.mokStatStart](state){
    state.stat = []
    state.stat = state.mokStat
  },
}
const actions = {
  [actionTypes.getStatDay](context, payload) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.statStart)
      apiStatistics
        .getStatDay(payload)
        .then(response => {
          context.commit(mutationTypes.statSuccess, response.data)
          context.commit(mutationTypes.discreteSuccess, 60)
          resolve(response)
        })
        .catch(error => {
          context.commit(mutationTypes.statFailure)
          reject(error)
        })
    })
  },
  [actionTypes.getStatWeek](context, payload) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.statStart)
      apiStatistics
        .getStatWeek(payload)
        .then(response => {
          context.commit(mutationTypes.statSuccess, response.data)
          context.commit(mutationTypes.discreteSuccess, 1200)
          resolve(response)
        })
        .catch(error => {
          context.commit(mutationTypes.statFailure)
          reject(error)
        })
    })
  },
  [actionTypes.getStatMonth](context, payload) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.statStart)
      apiStatistics
        .getStatMonth(payload)
        .then(response => {
          context.commit(mutationTypes.statSuccess, response.data)
          context.commit(mutationTypes.discreteSuccess, 3600)
          resolve(response)
        })
        .catch(error => {
          context.commit(mutationTypes.statFailure)
          reject(error)
        })
    })
  },
  [actionTypes.findStatConfList](context, payload){
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.findStatConfListStart)
      apiStatistics
        .findStatConfList(payload)
        .then(response => {
          context.commit(mutationTypes.findStatConfListSuccess, response.data)
          resolve(response)
        })
        .catch(error => {
          context.commit(mutationTypes.findStatConfListFailure)
          reject(error)
        })
    })
  },
  [actionTypes.saveStatConfList](context, payload) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.saveStatConfListStart)
      apiStatistics
        .saveStatConfList(payload)
        .then(response => {
          context.commit(mutationTypes.saveStatConfListSuccess, response.data)
          resolve(response)
        })
        .catch(error => {
          context.commit(mutationTypes.saveStatConfListFailure)
          reject(error)
        })
    })
  },
  [actionTypes.getMokStat](context){
    context.commit(mutationTypes.mokStatStart)
  }
}

export default {
  state,
  actions,
  mutations,
  getters,
}
