export const osToString = (code) => {
  switch (true) {
    case +code === 10:
      return 'Linux'
    case +code === 20:
      return 'Windows'
    case +code === 30:
      return 'MacOS'
  }
}
