export const dayStat = {
  result: {
    1648440120: {
      datePoint: 1648440120,
      cdnDwSize: 0,
      cdnUsersCount: 0,
      p2pDwSize: 0,
      p2pUsersCount: 0,
    },
    1648440180: {
      datePoint: 1648440180,
      cdnDwSize: 23662711790.5,
      cdnUsersCount: 6124,
      p2pDwSize: 26330529478.9,
      p2pUsersCount: 12798,
    },
    1648440240: {
      datePoint: 1648440240,
      cdnDwSize: 24638487867.1,
      cdnUsersCount: 6326,
      p2pDwSize: 26914433690.7,
      p2pUsersCount: 12911,
    },
    1648440300: {
      datePoint: 1648440300,
      cdnDwSize: 24815993833.6,
      cdnUsersCount: 6499,
      p2pDwSize: 26913590798.9,
      p2pUsersCount: 12878,
    },
    1648440360: {
      datePoint: 1648440360,
      cdnDwSize: 24988577381.2,
      cdnUsersCount: 6536,
      p2pDwSize: 27298941166.9,
      p2pUsersCount: 13009,
    },
    1648440420: {
      datePoint: 1648440420,
      cdnDwSize: 25251648238.9,
      cdnUsersCount: 6713,
      p2pDwSize: 27322317763.7,
      p2pUsersCount: 13040,
    },
    1648440480: {
      datePoint: 1648440480,
      cdnDwSize: 25299850837.3,
      cdnUsersCount: 6833,
      p2pDwSize: 27577893110.4,
      p2pUsersCount: 13062,
    },
    1648440540: {
      datePoint: 1648440540,
      cdnDwSize: 25772617871.5,
      cdnUsersCount: 7077,
      p2pDwSize: 27708291990.9,
      p2pUsersCount: 12991,
    },
    1648440600: {
      datePoint: 1648440600,
      cdnDwSize: 26012396380.3,
      cdnUsersCount: 7378,
      p2pDwSize: 27548357132.3,
      p2pUsersCount: 12970,
    },
    1648440660: {
      datePoint: 1648440660,
      cdnDwSize: 28286635939.7,
      cdnUsersCount: 8493,
      p2pDwSize: 26827739922.1,
      p2pUsersCount: 14247,
    },
    1648440720: {
      datePoint: 1648440720,
      cdnDwSize: 26605842318.9,
      cdnUsersCount: 6952,
      p2pDwSize: 28281972862.9,
      p2pUsersCount: 13681,
    },
    1648440780: {
      datePoint: 1648440780,
      cdnDwSize: 26467391072.5,
      cdnUsersCount: 6671,
      p2pDwSize: 28491712029.9,
      p2pUsersCount: 13675,
    },
    1648440840: {
      datePoint: 1648440840,
      cdnDwSize: 26719844065.1,
      cdnUsersCount: 6876,
      p2pDwSize: 28689447271.5,
      p2pUsersCount: 13754,
    },
    1648440900: {
      datePoint: 1648440900,
      cdnDwSize: 26999215673.5,
      cdnUsersCount: 6938,
      p2pDwSize: 28754648178.1,
      p2pUsersCount: 13680,
    },
    1648440960: {
      datePoint: 1648440960,
      cdnDwSize: 27312390436.3,
      cdnUsersCount: 7154,
      p2pDwSize: 28664615153.6,
      p2pUsersCount: 13524,
    },
    1648441020: {
      datePoint: 1648441020,
      cdnDwSize: 27523018041.1,
      cdnUsersCount: 7277,
      p2pDwSize: 28796268871.5,
      p2pUsersCount: 13577,
    },
    1648441080: {
      datePoint: 1648441080,
      cdnDwSize: 27974999243.7,
      cdnUsersCount: 7300,
      p2pDwSize: 28920059577.1,
      p2pUsersCount: 13546,
    },
    1648441140: {
      datePoint: 1648441140,
      cdnDwSize: 27805073338.1,
      cdnUsersCount: 7492,
      p2pDwSize: 29048438034.1,
      p2pUsersCount: 13496,
    },
    1648441200: {
      datePoint: 1648441200,
      cdnDwSize: 29139791719.9,
      cdnUsersCount: 7694,
      p2pDwSize: 29127346797.9,
      p2pUsersCount: 13499,
    },
    1648441260: {
      datePoint: 1648441260,
      cdnDwSize: 28779331248.4,
      cdnUsersCount: 7852,
      p2pDwSize: 29097313923.2,
      p2pUsersCount: 13443,
    },
    1648441320: {
      datePoint: 1648441320,
      cdnDwSize: 28634583782.9,
      cdnUsersCount: 8084,
      p2pDwSize: 29196744716.8,
      p2pUsersCount: 13424,
    },
    1648441380: {
      datePoint: 1648441380,
      cdnDwSize: 28489212204,
      cdnUsersCount: 8043,
      p2pDwSize: 29230937530.1,
      p2pUsersCount: 13326,
    },
    1648441440: {
      datePoint: 1648441440,
      cdnDwSize: 28678898140.3,
      cdnUsersCount: 8148,
      p2pDwSize: 29217927378.7,
      p2pUsersCount: 13259,
    },
    1648441500: {
      datePoint: 1648441500,
      cdnDwSize: 29183256844.8,
      cdnUsersCount: 8189,
      p2pDwSize: 29124005937.6,
      p2pUsersCount: 13264,
    },
    1648441560: {
      datePoint: 1648441560,
      cdnDwSize: 28939556380.3,
      cdnUsersCount: 8148,
      p2pDwSize: 29020912152,
      p2pUsersCount: 13189,
    },
    1648441620: {
      datePoint: 1648441620,
      cdnDwSize: 29333791576.9,
      cdnUsersCount: 8251,
      p2pDwSize: 29279729821.9,
      p2pUsersCount: 13202,
    },
    1648441680: {
      datePoint: 1648441680,
      cdnDwSize: 29770050441.1,
      cdnUsersCount: 8433,
      p2pDwSize: 29371659164.8,
      p2pUsersCount: 13223,
    },
    1648441740: {
      datePoint: 1648441740,
      cdnDwSize: 30171647317.9,
      cdnUsersCount: 8583,
      p2pDwSize: 29577515429.9,
      p2pUsersCount: 13312,
    },
    1648441800: {
      datePoint: 1648441800,
      cdnDwSize: 29993839548.7,
      cdnUsersCount: 8568,
      p2pDwSize: 29558213344.5,
      p2pUsersCount: 13300,
    },
    1648441860: {
      datePoint: 1648441860,
      cdnDwSize: 30376359089.1,
      cdnUsersCount: 8629,
      p2pDwSize: 29767671639.5,
      p2pUsersCount: 13311,
    },
    1648441920: {
      datePoint: 1648441920,
      cdnDwSize: 30399500209.3,
      cdnUsersCount: 8607,
      p2pDwSize: 29922731432.5,
      p2pUsersCount: 13266,
    },
    1648441980: {
      datePoint: 1648441980,
      cdnDwSize: 30693679248.3,
      cdnUsersCount: 8794,
      p2pDwSize: 30096416686.4,
      p2pUsersCount: 13315,
    },
    1648442040: {
      datePoint: 1648442040,
      cdnDwSize: 30753265598.8,
      cdnUsersCount: 8793,
      p2pDwSize: 30153883047.5,
      p2pUsersCount: 13387,
    },
    1648442100: {
      datePoint: 1648442100,
      cdnDwSize: 30854210696,
      cdnUsersCount: 8738,
      p2pDwSize: 30296618763.7,
      p2pUsersCount: 13489,
    },
    1648442160: {
      datePoint: 1648442160,
      cdnDwSize: 30875356007.3,
      cdnUsersCount: 8790,
      p2pDwSize: 30580560603.2,
      p2pUsersCount: 13599,
    },
    1648442220: {
      datePoint: 1648442220,
      cdnDwSize: 31271776518.4,
      cdnUsersCount: 9064,
      p2pDwSize: 30303694908.3,
      p2pUsersCount: 13497,
    },
    1648442280: {
      datePoint: 1648442280,
      cdnDwSize: 31354349753.1,
      cdnUsersCount: 8872,
      p2pDwSize: 30644033664.5,
      p2pUsersCount: 13646,
    },
    1648442340: {
      datePoint: 1648442340,
      cdnDwSize: 31545235227.2,
      cdnUsersCount: 8967,
      p2pDwSize: 30355512445.9,
      p2pUsersCount: 13697,
    },
    1648442400: {
      datePoint: 1648442400,
      cdnDwSize: 31736425060.8,
      cdnUsersCount: 9010,
      p2pDwSize: 30345571707.7,
      p2pUsersCount: 13633,
    },
    1648442460: {
      datePoint: 1648442460,
      cdnDwSize: 31725850386.7,
      cdnUsersCount: 9065,
      p2pDwSize: 30406445481.1,
      p2pUsersCount: 13655,
    },
    1648442520: {
      datePoint: 1648442520,
      cdnDwSize: 32137976286.8,
      cdnUsersCount: 9151,
      p2pDwSize: 30175604442.1,
      p2pUsersCount: 13596,
    },
    1648442580: {
      datePoint: 1648442580,
      cdnDwSize: 32614044824.7,
      cdnUsersCount: 9278,
      p2pDwSize: 30621370740.8,
      p2pUsersCount: 13442,
    },
    1648442640: {
      datePoint: 1648442640,
      cdnDwSize: 32648739905.1,
      cdnUsersCount: 9273,
      p2pDwSize: 30555030353.6,
      p2pUsersCount: 13506,
    },
    1648442700: {
      datePoint: 1648442700,
      cdnDwSize: 32672006108.3,
      cdnUsersCount: 9393,
      p2pDwSize: 30957792228.8,
      p2pUsersCount: 13637,
    },
    1648442760: {
      datePoint: 1648442760,
      cdnDwSize: 32734746166.4,
      cdnUsersCount: 9435,
      p2pDwSize: 30998257699.7,
      p2pUsersCount: 13752,
    },
    1648442820: {
      datePoint: 1648442820,
      cdnDwSize: 33063635701.3,
      cdnUsersCount: 9431,
      p2pDwSize: 31193563154.1,
      p2pUsersCount: 13882,
    },
    1648442880: {
      datePoint: 1648442880,
      cdnDwSize: 33136164321.6,
      cdnUsersCount: 9531,
      p2pDwSize: 31132724875.2,
      p2pUsersCount: 13880,
    },
    1648442940: {
      datePoint: 1648442940,
      cdnDwSize: 33243293287.9,
      cdnUsersCount: 9550,
      p2pDwSize: 31142541257.6,
      p2pUsersCount: 13871,
    },
    1648443000: {
      datePoint: 1648443000,
      cdnDwSize: 33373198832.5,
      cdnUsersCount: 9597,
      p2pDwSize: 31077445305.1,
      p2pUsersCount: 13893,
    },
    1648443060: {
      datePoint: 1648443060,
      cdnDwSize: 33185668782.4,
      cdnUsersCount: 9513,
      p2pDwSize: 31427694819.7,
      p2pUsersCount: 14026,
    },
    1648443120: {
      datePoint: 1648443120,
      cdnDwSize: 33665697404.3,
      cdnUsersCount: 9675,
      p2pDwSize: 31621491937.6,
      p2pUsersCount: 14142,
    },
    1648443180: {
      datePoint: 1648443180,
      cdnDwSize: 33790301263.2,
      cdnUsersCount: 9574,
      p2pDwSize: 31494525611.2,
      p2pUsersCount: 14174,
    },
    1648443240: {
      datePoint: 1648443240,
      cdnDwSize: 34118682824.1,
      cdnUsersCount: 9628,
      p2pDwSize: 31683448039.5,
      p2pUsersCount: 14165,
    },
    1648443300: {
      datePoint: 1648443300,
      cdnDwSize: 34431182594.1,
      cdnUsersCount: 9772,
      p2pDwSize: 31690629915.2,
      p2pUsersCount: 14281,
    },
    1648443360: {
      datePoint: 1648443360,
      cdnDwSize: 34116620215.9,
      cdnUsersCount: 9680,
      p2pDwSize: 31933620993.1,
      p2pUsersCount: 14335,
    },
    1648443420: {
      datePoint: 1648443420,
      cdnDwSize: 34531509618.9,
      cdnUsersCount: 9862,
      p2pDwSize: 32112890122.7,
      p2pUsersCount: 14365,
    },
    1648443480: {
      datePoint: 1648443480,
      cdnDwSize: 35072904876.1,
      cdnUsersCount: 9974,
      p2pDwSize: 31992157650.7,
      p2pUsersCount: 14377,
    },
    1648443540: {
      datePoint: 1648443540,
      cdnDwSize: 35357460549.3,
      cdnUsersCount: 9985,
      p2pDwSize: 32449538288.5,
      p2pUsersCount: 14477,
    },
    1648443600: {
      datePoint: 1648443600,
      cdnDwSize: 35671969935.5,
      cdnUsersCount: 10305,
      p2pDwSize: 32556803067.2,
      p2pUsersCount: 14588,
    },
    1648443660: {
      datePoint: 1648443660,
      cdnDwSize: 37443057775.5,
      cdnUsersCount: 10918,
      p2pDwSize: 32162956026.7,
      p2pUsersCount: 16837,
    },
    1648443720: {
      datePoint: 1648443720,
      cdnDwSize: 36395777629.7,
      cdnUsersCount: 9145,
      p2pDwSize: 34116544716.3,
      p2pUsersCount: 16365,
    },
    1648443780: {
      datePoint: 1648443780,
      cdnDwSize: 36001634753.6,
      cdnUsersCount: 8797,
      p2pDwSize: 34233703233.1,
      p2pUsersCount: 16368,
    },
    1648443840: {
      datePoint: 1648443840,
      cdnDwSize: 36706319590.4,
      cdnUsersCount: 8940,
      p2pDwSize: 34401703617.6,
      p2pUsersCount: 16344,
    },
    1648443900: {
      datePoint: 1648443900,
      cdnDwSize: 36717446984.5,
      cdnUsersCount: 9098,
      p2pDwSize: 34387323171.7,
      p2pUsersCount: 16298,
    },
    1648443960: {
      datePoint: 1648443960,
      cdnDwSize: 36484844536,
      cdnUsersCount: 9126,
      p2pDwSize: 34785787166.4,
      p2pUsersCount: 16327,
    },
    1648444020: {
      datePoint: 1648444020,
      cdnDwSize: 36979077083.1,
      cdnUsersCount: 9425,
      p2pDwSize: 35117177573.9,
      p2pUsersCount: 16320,
    },
    1648444080: {
      datePoint: 1648444080,
      cdnDwSize: 37199710924,
      cdnUsersCount: 9572,
      p2pDwSize: 34757861195.2,
      p2pUsersCount: 16298,
    },
    1648444140: {
      datePoint: 1648444140,
      cdnDwSize: 37641115976,
      cdnUsersCount: 9867,
      p2pDwSize: 34824248356.8,
      p2pUsersCount: 16222,
    },
    1648444200: {
      datePoint: 1648444200,
      cdnDwSize: 37332981443.1,
      cdnUsersCount: 9802,
      p2pDwSize: 34809135613.3,
      p2pUsersCount: 16267,
    },
    1648444260: {
      datePoint: 1648444260,
      cdnDwSize: 37696730236.3,
      cdnUsersCount: 10133,
      p2pDwSize: 34508581091.2,
      p2pUsersCount: 16066,
    },
    1648444320: {
      datePoint: 1648444320,
      cdnDwSize: 37766796056.5,
      cdnUsersCount: 10126,
      p2pDwSize: 34605122926.4,
      p2pUsersCount: 15974,
    },
    1648444380: {
      datePoint: 1648444380,
      cdnDwSize: 37789454316.7,
      cdnUsersCount: 10138,
      p2pDwSize: 34917240576.5,
      p2pUsersCount: 15986,
    },
    1648444440: {
      datePoint: 1648444440,
      cdnDwSize: 38233633495.5,
      cdnUsersCount: 10286,
      p2pDwSize: 35106400386.1,
      p2pUsersCount: 16166,
    },
    1648444500: {
      datePoint: 1648444500,
      cdnDwSize: 38532472228.1,
      cdnUsersCount: 10485,
      p2pDwSize: 35111513835.7,
      p2pUsersCount: 16088,
    },
    1648444560: {
      datePoint: 1648444560,
      cdnDwSize: 38595323206.4,
      cdnUsersCount: 10528,
      p2pDwSize: 35152372026.1,
      p2pUsersCount: 16001,
    },
    1648444620: {
      datePoint: 1648444620,
      cdnDwSize: 39239324530.7,
      cdnUsersCount: 10782,
      p2pDwSize: 35235682722.1,
      p2pUsersCount: 16033,
    },
    1648444680: {
      datePoint: 1648444680,
      cdnDwSize: 39596140033.1,
      cdnUsersCount: 10879,
      p2pDwSize: 35431803595.2,
      p2pUsersCount: 16110,
    },
    1648444740: {
      datePoint: 1648444740,
      cdnDwSize: 39995369687.9,
      cdnUsersCount: 10876,
      p2pDwSize: 35491025400,
      p2pUsersCount: 16224,
    },
    1648444800: {
      datePoint: 1648444800,
      cdnDwSize: 40391078380.8,
      cdnUsersCount: 11154,
      p2pDwSize: 35467425058.1,
      p2pUsersCount: 16172,
    },
    1648444860: {
      datePoint: 1648444860,
      cdnDwSize: 40260267323.5,
      cdnUsersCount: 11174,
      p2pDwSize: 35655552271.5,
      p2pUsersCount: 16270,
    },
    1648444920: {
      datePoint: 1648444920,
      cdnDwSize: 40404062761.3,
      cdnUsersCount: 11223,
      p2pDwSize: 35749106987.2,
      p2pUsersCount: 16388,
    },
    1648444980: {
      datePoint: 1648444980,
      cdnDwSize: 40693357013.3,
      cdnUsersCount: 11399,
      p2pDwSize: 35980131113.1,
      p2pUsersCount: 16350,
    },
    1648445040: {
      datePoint: 1648445040,
      cdnDwSize: 41025419005.7,
      cdnUsersCount: 11447,
      p2pDwSize: 35742238570.1,
      p2pUsersCount: 16218,
    },
    1648445100: {
      datePoint: 1648445100,
      cdnDwSize: 41265871357.9,
      cdnUsersCount: 11576,
      p2pDwSize: 35686858532.8,
      p2pUsersCount: 16085,
    },
    1648445160: {
      datePoint: 1648445160,
      cdnDwSize: 41394411117.7,
      cdnUsersCount: 11640,
      p2pDwSize: 35737129482.1,
      p2pUsersCount: 16161,
    },
    1648445220: {
      datePoint: 1648445220,
      cdnDwSize: 42256976149.9,
      cdnUsersCount: 12179,
      p2pDwSize: 35273624330.7,
      p2pUsersCount: 16387,
    },
    1648445280: {
      datePoint: 1648445280,
      cdnDwSize: 43934329994.9,
      cdnUsersCount: 12045,
      p2pDwSize: 35175666053.3,
      p2pUsersCount: 18584,
    },
    1648445340: {
      datePoint: 1648445340,
      cdnDwSize: 41459639273.1,
      cdnUsersCount: 10204,
      p2pDwSize: 36830619700.8,
      p2pUsersCount: 17747,
    },
    1648445400: {
      datePoint: 1648445400,
      cdnDwSize: 41688201100.3,
      cdnUsersCount: 10117,
      p2pDwSize: 37131152064,
      p2pUsersCount: 17744,
    },
    1648445460: {
      datePoint: 1648445460,
      cdnDwSize: 42049089058.7,
      cdnUsersCount: 10228,
      p2pDwSize: 37278503506.1,
      p2pUsersCount: 17708,
    },
    1648445520: {
      datePoint: 1648445520,
      cdnDwSize: 42036225871.9,
      cdnUsersCount: 10395,
      p2pDwSize: 37419775379.2,
      p2pUsersCount: 17783,
    },
    1648445580: {
      datePoint: 1648445580,
      cdnDwSize: 42639140193.6,
      cdnUsersCount: 10643,
      p2pDwSize: 37472372640.5,
      p2pUsersCount: 17686,
    },
    1648445640: {
      datePoint: 1648445640,
      cdnDwSize: 43324118043.2,
      cdnUsersCount: 10889,
      p2pDwSize: 37146522843.7,
      p2pUsersCount: 17620,
    },
    1648445700: {
      datePoint: 1648445700,
      cdnDwSize: 42954463218.7,
      cdnUsersCount: 11120,
      p2pDwSize: 37355486824,
      p2pUsersCount: 17548,
    },
    1648445760: {
      datePoint: 1648445760,
      cdnDwSize: 43661740074.1,
      cdnUsersCount: 11447,
      p2pDwSize: 36877676501.9,
      p2pUsersCount: 17339,
    },
    1648445820: {
      datePoint: 1648445820,
      cdnDwSize: 44181008356.8,
      cdnUsersCount: 11633,
      p2pDwSize: 36776361647.5,
      p2pUsersCount: 17182,
    },
    1648445880: {
      datePoint: 1648445880,
      cdnDwSize: 46141130497.6,
      cdnUsersCount: 12703,
      p2pDwSize: 36150813480.5,
      p2pUsersCount: 18670,
    },
    1648445940: {
      datePoint: 1648445940,
      cdnDwSize: 45224714319.3,
      cdnUsersCount: 11698,
      p2pDwSize: 37112769774.9,
      p2pUsersCount: 18431,
    },
    1648446000: {
      datePoint: 1648446000,
      cdnDwSize: 44269638575.5,
      cdnUsersCount: 10864,
      p2pDwSize: 37831221952,
      p2pUsersCount: 18282,
    },
    1648446060: {
      datePoint: 1648446060,
      cdnDwSize: 44292163755.5,
      cdnUsersCount: 10982,
      p2pDwSize: 38111784102.9,
      p2pUsersCount: 18346,
    },
    1648446120: {
      datePoint: 1648446120,
      cdnDwSize: 44401063184,
      cdnUsersCount: 11131,
      p2pDwSize: 38065377004.8,
      p2pUsersCount: 18228,
    },
    1648446180: {
      datePoint: 1648446180,
      cdnDwSize: 44466452360.5,
      cdnUsersCount: 11218,
      p2pDwSize: 38040494427.7,
      p2pUsersCount: 18119,
    },
    1648446240: {
      datePoint: 1648446240,
      cdnDwSize: 44284540332.8,
      cdnUsersCount: 11403,
      p2pDwSize: 37903263577.1,
      p2pUsersCount: 18001,
    },
    1648446300: {
      datePoint: 1648446300,
      cdnDwSize: 45027546663.5,
      cdnUsersCount: 11555,
      p2pDwSize: 38051547098.1,
      p2pUsersCount: 17863,
    },
    1648446360: {
      datePoint: 1648446360,
      cdnDwSize: 45371369410.7,
      cdnUsersCount: 11798,
      p2pDwSize: 37566604732.8,
      p2pUsersCount: 17734,
    },
    1648446420: {
      datePoint: 1648446420,
      cdnDwSize: 45511065164.5,
      cdnUsersCount: 11924,
      p2pDwSize: 37924341685.9,
      p2pUsersCount: 17693,
    },
    1648446480: {
      datePoint: 1648446480,
      cdnDwSize: 45901352364.8,
      cdnUsersCount: 12248,
      p2pDwSize: 37899869675.7,
      p2pUsersCount: 17509,
    },
    1648446540: {
      datePoint: 1648446540,
      cdnDwSize: 46139870997.9,
      cdnUsersCount: 12354,
      p2pDwSize: 37868422790.4,
      p2pUsersCount: 17550,
    },
    1648446600: {
      datePoint: 1648446600,
      cdnDwSize: 46145217366.9,
      cdnUsersCount: 12401,
      p2pDwSize: 37900350579.7,
      p2pUsersCount: 17541,
    },
    1648446660: {
      datePoint: 1648446660,
      cdnDwSize: 46752215698,
      cdnUsersCount: 12563,
      p2pDwSize: 37840436107.7,
      p2pUsersCount: 17455,
    },
    1648446720: {
      datePoint: 1648446720,
      cdnDwSize: 46946302913.6,
      cdnUsersCount: 12689,
      p2pDwSize: 37849736192,
      p2pUsersCount: 17407,
    },
    1648446780: {
      datePoint: 1648446780,
      cdnDwSize: 47637038476.3,
      cdnUsersCount: 12863,
      p2pDwSize: 37899727873.6,
      p2pUsersCount: 17401,
    },
    1648446840: {
      datePoint: 1648446840,
      cdnDwSize: 47943193641.3,
      cdnUsersCount: 13115,
      p2pDwSize: 37956366032,
      p2pUsersCount: 17374,
    },
    1648446900: {
      datePoint: 1648446900,
      cdnDwSize: 48643392950.9,
      cdnUsersCount: 13376,
      p2pDwSize: 37912492321.6,
      p2pUsersCount: 17472,
    },
    1648446960: {
      datePoint: 1648446960,
      cdnDwSize: 50567538158.4,
      cdnUsersCount: 13996,
      p2pDwSize: 37476179314.7,
      p2pUsersCount: 19943,
    },
    1648447020: {
      datePoint: 1648447020,
      cdnDwSize: 48194656177.1,
      cdnUsersCount: 11784,
      p2pDwSize: 39503902746.1,
      p2pUsersCount: 19164,
    },
    1648447080: {
      datePoint: 1648447080,
      cdnDwSize: 47501731165.2,
      cdnUsersCount: 11590,
      p2pDwSize: 39553766412.3,
      p2pUsersCount: 19172,
    },
    1648447140: {
      datePoint: 1648447140,
      cdnDwSize: 47300114100.8,
      cdnUsersCount: 11467,
      p2pDwSize: 39745784724.3,
      p2pUsersCount: 19285,
    },
    1648447200: {
      datePoint: 1648447200,
      cdnDwSize: 47403195002.1,
      cdnUsersCount: 11659,
      p2pDwSize: 39568542084.3,
      p2pUsersCount: 19379,
    },
    1648447260: {
      datePoint: 1648447260,
      cdnDwSize: 49214874547.6,
      cdnUsersCount: 13081,
      p2pDwSize: 38626745581.3,
      p2pUsersCount: 19874,
    },
    1648447320: {
      datePoint: 1648447320,
      cdnDwSize: 50105621904.1,
      cdnUsersCount: 13037,
      p2pDwSize: 39070462354.1,
      p2pUsersCount: 20256,
    },
    1648447380: {
      datePoint: 1648447380,
      cdnDwSize: 48953228180.8,
      cdnUsersCount: 11758,
      p2pDwSize: 40200570210.1,
      p2pUsersCount: 19575,
    },
    1648447440: {
      datePoint: 1648447440,
      cdnDwSize: 48743214258.1,
      cdnUsersCount: 11784,
      p2pDwSize: 40291733766.4,
      p2pUsersCount: 19640,
    },
    1648447500: {
      datePoint: 1648447500,
      cdnDwSize: 48827889732.7,
      cdnUsersCount: 11842,
      p2pDwSize: 40648250298.7,
      p2pUsersCount: 19667,
    },
    1648447560: {
      datePoint: 1648447560,
      cdnDwSize: 48815251245.9,
      cdnUsersCount: 11819,
      p2pDwSize: 40644487992.5,
      p2pUsersCount: 19708,
    },
    1648447620: {
      datePoint: 1648447620,
      cdnDwSize: 49021478798.4,
      cdnUsersCount: 12221,
      p2pDwSize: 41013253928,
      p2pUsersCount: 19601,
    },
    1648447680: {
      datePoint: 1648447680,
      cdnDwSize: 49111426644,
      cdnUsersCount: 12225,
      p2pDwSize: 40897104219.2,
      p2pUsersCount: 19629,
    },
    1648447740: {
      datePoint: 1648447740,
      cdnDwSize: 49820343712.9,
      cdnUsersCount: 12669,
      p2pDwSize: 41050458726.9,
      p2pUsersCount: 19401,
    },
    1648447800: {
      datePoint: 1648447800,
      cdnDwSize: 50223257292.8,
      cdnUsersCount: 12788,
      p2pDwSize: 41027020616.5,
      p2pUsersCount: 19420,
    },
    1648447860: {
      datePoint: 1648447860,
      cdnDwSize: 50892522051.1,
      cdnUsersCount: 13098,
      p2pDwSize: 41382574237.9,
      p2pUsersCount: 19245,
    },
    1648447920: {
      datePoint: 1648447920,
      cdnDwSize: 51692928066.7,
      cdnUsersCount: 13413,
      p2pDwSize: 40966927619.7,
      p2pUsersCount: 19073,
    },
    1648447980: {
      datePoint: 1648447980,
      cdnDwSize: 53054888301.3,
      cdnUsersCount: 14374,
      p2pDwSize: 40345738671.5,
      p2pUsersCount: 20274,
    },
    1648448040: {
      datePoint: 1648448040,
      cdnDwSize: 53248788718.9,
      cdnUsersCount: 13753,
      p2pDwSize: 41228204078.9,
      p2pUsersCount: 21025,
    },
    1648448100: {
      datePoint: 1648448100,
      cdnDwSize: 51122422137.5,
      cdnUsersCount: 12330,
      p2pDwSize: 42659019539.2,
      p2pUsersCount: 20546,
    },
    1648448160: {
      datePoint: 1648448160,
      cdnDwSize: 51266165934.8,
      cdnUsersCount: 12261,
      p2pDwSize: 42779326128.5,
      p2pUsersCount: 20677,
    },
    1648448220: {
      datePoint: 1648448220,
      cdnDwSize: 51167987067.3,
      cdnUsersCount: 12253,
      p2pDwSize: 42535497949.3,
      p2pUsersCount: 20629,
    },
    1648448280: {
      datePoint: 1648448280,
      cdnDwSize: 51571221603.1,
      cdnUsersCount: 12606,
      p2pDwSize: 42761484101.3,
      p2pUsersCount: 20500,
    },
    1648448340: {
      datePoint: 1648448340,
      cdnDwSize: 51267347328,
      cdnUsersCount: 12623,
      p2pDwSize: 42664837362.1,
      p2pUsersCount: 20300,
    },
    1648448400: {
      datePoint: 1648448400,
      cdnDwSize: 52154668549.9,
      cdnUsersCount: 13053,
      p2pDwSize: 42188660785.1,
      p2pUsersCount: 20087,
    },
    1648448460: {
      datePoint: 1648448460,
      cdnDwSize: 52325158756.3,
      cdnUsersCount: 13203,
      p2pDwSize: 41860415542.4,
      p2pUsersCount: 19929,
    },
    1648448520: {
      datePoint: 1648448520,
      cdnDwSize: 52665785051.1,
      cdnUsersCount: 13303,
      p2pDwSize: 42201905410.1,
      p2pUsersCount: 19870,
    },
    1648448580: {
      datePoint: 1648448580,
      cdnDwSize: 52608479317.2,
      cdnUsersCount: 13445,
      p2pDwSize: 42391537450.7,
      p2pUsersCount: 19857,
    },
    1648448640: {
      datePoint: 1648448640,
      cdnDwSize: 53090429225.1,
      cdnUsersCount: 13516,
      p2pDwSize: 42484857918.4,
      p2pUsersCount: 19884,
    },
    1648448700: {
      datePoint: 1648448700,
      cdnDwSize: 53201215644.8,
      cdnUsersCount: 13659,
      p2pDwSize: 42787111258.7,
      p2pUsersCount: 19922,
    },
    1648448760: {
      datePoint: 1648448760,
      cdnDwSize: 53856045360.8,
      cdnUsersCount: 14013,
      p2pDwSize: 42763654774.4,
      p2pUsersCount: 19780,
    },
    1648448820: {
      datePoint: 1648448820,
      cdnDwSize: 54157513438.8,
      cdnUsersCount: 14192,
      p2pDwSize: 42933310636.8,
      p2pUsersCount: 19576,
    },
    1648448880: {
      datePoint: 1648448880,
      cdnDwSize: 54501708627.7,
      cdnUsersCount: 14351,
      p2pDwSize: 42548876731.2,
      p2pUsersCount: 19555,
    },
    1648448940: {
      datePoint: 1648448940,
      cdnDwSize: 54804158678.4,
      cdnUsersCount: 14437,
      p2pDwSize: 42762364818.7,
      p2pUsersCount: 19615,
    },
    1648449000: {
      datePoint: 1648449000,
      cdnDwSize: 54622022837.1,
      cdnUsersCount: 14585,
      p2pDwSize: 42512749498.1,
      p2pUsersCount: 19456,
    },
    1648449060: {
      datePoint: 1648449060,
      cdnDwSize: 54236330934.8,
      cdnUsersCount: 14519,
      p2pDwSize: 42437710050.1,
      p2pUsersCount: 19595,
    },
    1648449120: {
      datePoint: 1648449120,
      cdnDwSize: 54301765613.2,
      cdnUsersCount: 14598,
      p2pDwSize: 42159014638.9,
      p2pUsersCount: 19342,
    },
    1648449180: {
      datePoint: 1648449180,
      cdnDwSize: 54330006569.7,
      cdnUsersCount: 14648,
      p2pDwSize: 42219232818.7,
      p2pUsersCount: 19360,
    },
    1648449240: {
      datePoint: 1648449240,
      cdnDwSize: 54736966886,
      cdnUsersCount: 14748,
      p2pDwSize: 42175662189.3,
      p2pUsersCount: 19384,
    },
    1648449300: {
      datePoint: 1648449300,
      cdnDwSize: 54711413403.2,
      cdnUsersCount: 14651,
      p2pDwSize: 42357401689.1,
      p2pUsersCount: 19521,
    },
    1648449360: {
      datePoint: 1648449360,
      cdnDwSize: 54691270328.8,
      cdnUsersCount: 14961,
      p2pDwSize: 42329217405.9,
      p2pUsersCount: 19358,
    },
    1648449420: {
      datePoint: 1648449420,
      cdnDwSize: 55595384891.2,
      cdnUsersCount: 14951,
      p2pDwSize: 42335009836.3,
      p2pUsersCount: 19371,
    },
    1648449480: {
      datePoint: 1648449480,
      cdnDwSize: 55774917847.5,
      cdnUsersCount: 15069,
      p2pDwSize: 42461013051.2,
      p2pUsersCount: 19324,
    },
    1648449540: {
      datePoint: 1648449540,
      cdnDwSize: 55131330153.1,
      cdnUsersCount: 14991,
      p2pDwSize: 42557763691.7,
      p2pUsersCount: 19301,
    },
    1648449600: {
      datePoint: 1648449600,
      cdnDwSize: 55710976941.9,
      cdnUsersCount: 15268,
      p2pDwSize: 42601397062.9,
      p2pUsersCount: 19229,
    },
    1648449660: {
      datePoint: 1648449660,
      cdnDwSize: 55931812068.1,
      cdnUsersCount: 15328,
      p2pDwSize: 42903961355.2,
      p2pUsersCount: 19342,
    },
    1648449720: {
      datePoint: 1648449720,
      cdnDwSize: 55835936654.1,
      cdnUsersCount: 15371,
      p2pDwSize: 42496654191.5,
      p2pUsersCount: 19324,
    },
    1648449780: {
      datePoint: 1648449780,
      cdnDwSize: 56170621374.3,
      cdnUsersCount: 15591,
      p2pDwSize: 42392527157.9,
      p2pUsersCount: 19122,
    },
    1648449840: {
      datePoint: 1648449840,
      cdnDwSize: 56438134195.7,
      cdnUsersCount: 15532,
      p2pDwSize: 42561479699.7,
      p2pUsersCount: 19217,
    },
    1648449900: {
      datePoint: 1648449900,
      cdnDwSize: 56098341164.7,
      cdnUsersCount: 15489,
      p2pDwSize: 42598240718.4,
      p2pUsersCount: 19116,
    },
    1648449960: {
      datePoint: 1648449960,
      cdnDwSize: 56473356220.1,
      cdnUsersCount: 15512,
      p2pDwSize: 42363081820.8,
      p2pUsersCount: 19084,
    },
    1648450020: {
      datePoint: 1648450020,
      cdnDwSize: 56674163132.3,
      cdnUsersCount: 15550,
      p2pDwSize: 42604491041.6,
      p2pUsersCount: 19085,
    },
    1648450080: {
      datePoint: 1648450080,
      cdnDwSize: 56754469086.9,
      cdnUsersCount: 15649,
      p2pDwSize: 42856795814.9,
      p2pUsersCount: 19214,
    },
    1648450140: {
      datePoint: 1648450140,
      cdnDwSize: 57401996885.3,
      cdnUsersCount: 15714,
      p2pDwSize: 41643568045.3,
      p2pUsersCount: 19158,
    },
    1648450200: {
      datePoint: 1648450200,
      cdnDwSize: 58303658595.6,
      cdnUsersCount: 15837,
      p2pDwSize: 41567435665.6,
      p2pUsersCount: 19155,
    },
    1648450260: {
      datePoint: 1648450260,
      cdnDwSize: 58389721049.2,
      cdnUsersCount: 15904,
      p2pDwSize: 41760728442.1,
      p2pUsersCount: 19193,
    },
    1648450320: {
      datePoint: 1648450320,
      cdnDwSize: 58213894536.4,
      cdnUsersCount: 16041,
      p2pDwSize: 41934821129.6,
      p2pUsersCount: 19164,
    },
    1648450380: {
      datePoint: 1648450380,
      cdnDwSize: 58150719293.3,
      cdnUsersCount: 16029,
      p2pDwSize: 42005675647.5,
      p2pUsersCount: 19130,
    },
    1648450440: {
      datePoint: 1648450440,
      cdnDwSize: 58211162496.5,
      cdnUsersCount: 15975,
      p2pDwSize: 42054532736.5,
      p2pUsersCount: 19223,
    },
    1648450500: {
      datePoint: 1648450500,
      cdnDwSize: 58424621506.7,
      cdnUsersCount: 16263,
      p2pDwSize: 42049340828.3,
      p2pUsersCount: 19407,
    },
    1648450560: {
      datePoint: 1648450560,
      cdnDwSize: 58789303029.3,
      cdnUsersCount: 16105,
      p2pDwSize: 42366257968,
      p2pUsersCount: 19615,
    },
    1648450620: {
      datePoint: 1648450620,
      cdnDwSize: 57443675608,
      cdnUsersCount: 16068,
      p2pDwSize: 42763533577.1,
      p2pUsersCount: 19764,
    },
    1648450680: {
      datePoint: 1648450680,
      cdnDwSize: 57220093700.3,
      cdnUsersCount: 16052,
      p2pDwSize: 42789132534.4,
      p2pUsersCount: 19669,
    },
    1648450740: {
      datePoint: 1648450740,
      cdnDwSize: 57547864456,
      cdnUsersCount: 15932,
      p2pDwSize: 43096006262.9,
      p2pUsersCount: 19812,
    },
    1648450800: {
      datePoint: 1648450800,
      cdnDwSize: 59185507614.9,
      cdnUsersCount: 16936,
      p2pDwSize: 41429089197.9,
      p2pUsersCount: 21357,
    },
    1648450860: {
      datePoint: 1648450860,
      cdnDwSize: 59927371181.3,
      cdnUsersCount: 15678,
      p2pDwSize: 42936163875.2,
      p2pUsersCount: 22770,
    },
    1648450920: {
      datePoint: 1648450920,
      cdnDwSize: 56520157391.3,
      cdnUsersCount: 13592,
      p2pDwSize: 45205725518.4,
      p2pUsersCount: 22559,
    },
    1648450980: {
      datePoint: 1648450980,
      cdnDwSize: 55853794149.9,
      cdnUsersCount: 13402,
      p2pDwSize: 45722996163.2,
      p2pUsersCount: 22537,
    },
    1648451040: {
      datePoint: 1648451040,
      cdnDwSize: 55810203517.3,
      cdnUsersCount: 13537,
      p2pDwSize: 45465212368,
      p2pUsersCount: 22541,
    },
    1648451100: {
      datePoint: 1648451100,
      cdnDwSize: 55264105653.2,
      cdnUsersCount: 13749,
      p2pDwSize: 45571716222.9,
      p2pUsersCount: 22309,
    },
    1648451160: {
      datePoint: 1648451160,
      cdnDwSize: 55637871206.9,
      cdnUsersCount: 13881,
      p2pDwSize: 45637534245.9,
      p2pUsersCount: 22242,
    },
    1648451220: {
      datePoint: 1648451220,
      cdnDwSize: 55653440518.3,
      cdnUsersCount: 13977,
      p2pDwSize: 45309908726.4,
      p2pUsersCount: 22139,
    },
    1648451280: {
      datePoint: 1648451280,
      cdnDwSize: 55717736993.3,
      cdnUsersCount: 14353,
      p2pDwSize: 45017979644.3,
      p2pUsersCount: 21829,
    },
    1648451340: {
      datePoint: 1648451340,
      cdnDwSize: 55941091674.1,
      cdnUsersCount: 14581,
      p2pDwSize: 45529312421.9,
      p2pUsersCount: 21638,
    },
    1648451400: {
      datePoint: 1648451400,
      cdnDwSize: 55888785612.3,
      cdnUsersCount: 14730,
      p2pDwSize: 44987088411.7,
      p2pUsersCount: 21600,
    },
    1648451460: {
      datePoint: 1648451460,
      cdnDwSize: 56109486733.3,
      cdnUsersCount: 14848,
      p2pDwSize: 44778757189.3,
      p2pUsersCount: 21433,
    },
    1648451520: {
      datePoint: 1648451520,
      cdnDwSize: 56503249825.6,
      cdnUsersCount: 14996,
      p2pDwSize: 44508160692.8,
      p2pUsersCount: 21343,
    },
    1648451580: {
      datePoint: 1648451580,
      cdnDwSize: 57065049724.3,
      cdnUsersCount: 15393,
      p2pDwSize: 44193429792.5,
      p2pUsersCount: 21070,
    },
    1648451640: {
      datePoint: 1648451640,
      cdnDwSize: 57608214561.6,
      cdnUsersCount: 15660,
      p2pDwSize: 44417913323.2,
      p2pUsersCount: 20902,
    },
    1648451700: {
      datePoint: 1648451700,
      cdnDwSize: 58362010261.2,
      cdnUsersCount: 15754,
      p2pDwSize: 43906574855.5,
      p2pUsersCount: 20915,
    },
    1648451760: {
      datePoint: 1648451760,
      cdnDwSize: 58292858219.2,
      cdnUsersCount: 15840,
      p2pDwSize: 43566019197.3,
      p2pUsersCount: 20846,
    },
    1648451820: {
      datePoint: 1648451820,
      cdnDwSize: 59993604868.8,
      cdnUsersCount: 16059,
      p2pDwSize: 43016614138.7,
      p2pUsersCount: 20740,
    },
    1648451880: {
      datePoint: 1648451880,
      cdnDwSize: 58362628155.7,
      cdnUsersCount: 16199,
      p2pDwSize: 44086285985.6,
      p2pUsersCount: 20607,
    },
    1648451940: {
      datePoint: 1648451940,
      cdnDwSize: 58511418524.8,
      cdnUsersCount: 16277,
      p2pDwSize: 43742988284.8,
      p2pUsersCount: 20352,
    },
    1648452000: {
      datePoint: 1648452000,
      cdnDwSize: 58557323938.7,
      cdnUsersCount: 16194,
      p2pDwSize: 43328960672.5,
      p2pUsersCount: 20260,
    },
    1648452060: {
      datePoint: 1648452060,
      cdnDwSize: 58063484257.9,
      cdnUsersCount: 16266,
      p2pDwSize: 43219064244.8,
      p2pUsersCount: 20147,
    },
    1648452120: {
      datePoint: 1648452120,
      cdnDwSize: 58300035636.3,
      cdnUsersCount: 16474,
      p2pDwSize: 43316123305.6,
      p2pUsersCount: 20054,
    },
    1648452180: {
      datePoint: 1648452180,
      cdnDwSize: 58921502497.5,
      cdnUsersCount: 16537,
      p2pDwSize: 42837135752.5,
      p2pUsersCount: 20023,
    },
    1648452240: {
      datePoint: 1648452240,
      cdnDwSize: 58758022210.7,
      cdnUsersCount: 16493,
      p2pDwSize: 43119198770.1,
      p2pUsersCount: 20009,
    },
    1648452300: {
      datePoint: 1648452300,
      cdnDwSize: 59175450232.3,
      cdnUsersCount: 16781,
      p2pDwSize: 42735706168,
      p2pUsersCount: 19903,
    },
    1648452360: {
      datePoint: 1648452360,
      cdnDwSize: 59290650750.9,
      cdnUsersCount: 16706,
      p2pDwSize: 42777986214.9,
      p2pUsersCount: 19833,
    },
    1648452420: {
      datePoint: 1648452420,
      cdnDwSize: 59206213533.9,
      cdnUsersCount: 16674,
      p2pDwSize: 42547909057.6,
      p2pUsersCount: 19827,
    },
    1648452480: {
      datePoint: 1648452480,
      cdnDwSize: 59406536081.6,
      cdnUsersCount: 16647,
      p2pDwSize: 42807138547.7,
      p2pUsersCount: 19895,
    },
    1648452540: {
      datePoint: 1648452540,
      cdnDwSize: 59562964839.1,
      cdnUsersCount: 16700,
      p2pDwSize: 42686497117.9,
      p2pUsersCount: 19928,
    },
    1648452600: {
      datePoint: 1648452600,
      cdnDwSize: 59335457261.6,
      cdnUsersCount: 16831,
      p2pDwSize: 42451077025.6,
      p2pUsersCount: 19834,
    },
    1648452660: {
      datePoint: 1648452660,
      cdnDwSize: 60559496240,
      cdnUsersCount: 17056,
      p2pDwSize: 42289317213.3,
      p2pUsersCount: 19961,
    },
    1648452720: {
      datePoint: 1648452720,
      cdnDwSize: 59679942551.5,
      cdnUsersCount: 16771,
      p2pDwSize: 42942726579.2,
      p2pUsersCount: 19922,
    },
    1648452780: {
      datePoint: 1648452780,
      cdnDwSize: 59346594688,
      cdnUsersCount: 16668,
      p2pDwSize: 43080329293.9,
      p2pUsersCount: 19916,
    },
    1648452840: {
      datePoint: 1648452840,
      cdnDwSize: 59090935994.7,
      cdnUsersCount: 16738,
      p2pDwSize: 43179121890.1,
      p2pUsersCount: 19893,
    },
    1648452900: {
      datePoint: 1648452900,
      cdnDwSize: 59168921227.2,
      cdnUsersCount: 16803,
      p2pDwSize: 42619067283.2,
      p2pUsersCount: 19864,
    },
    1648452960: {
      datePoint: 1648452960,
      cdnDwSize: 59715979865.7,
      cdnUsersCount: 16922,
      p2pDwSize: 42223638034.7,
      p2pUsersCount: 19806,
    },
    1648453020: {
      datePoint: 1648453020,
      cdnDwSize: 59190576266.8,
      cdnUsersCount: 16913,
      p2pDwSize: 42132168514.7,
      p2pUsersCount: 19882,
    },
    1648453080: {
      datePoint: 1648453080,
      cdnDwSize: 59136066572.8,
      cdnUsersCount: 16778,
      p2pDwSize: 42109072062.9,
      p2pUsersCount: 19780,
    },
    1648453140: {
      datePoint: 1648453140,
      cdnDwSize: 59216393257.6,
      cdnUsersCount: 16836,
      p2pDwSize: 42372448933.3,
      p2pUsersCount: 19753,
    },
    1648453200: {
      datePoint: 1648453200,
      cdnDwSize: 59425644075.7,
      cdnUsersCount: 16952,
      p2pDwSize: 42214470277.3,
      p2pUsersCount: 19668,
    },
    1648453260: {
      datePoint: 1648453260,
      cdnDwSize: 59534795157.2,
      cdnUsersCount: 17008,
      p2pDwSize: 42281681831.5,
      p2pUsersCount: 19552,
    },
    1648453320: {
      datePoint: 1648453320,
      cdnDwSize: 59602401848.8,
      cdnUsersCount: 17114,
      p2pDwSize: 42236508589.9,
      p2pUsersCount: 19503,
    },
    1648453380: {
      datePoint: 1648453380,
      cdnDwSize: 59439671608,
      cdnUsersCount: 17069,
      p2pDwSize: 42359180870.9,
      p2pUsersCount: 19492,
    },
    1648453440: {
      datePoint: 1648453440,
      cdnDwSize: 59029375719.3,
      cdnUsersCount: 16900,
      p2pDwSize: 42383614754.7,
      p2pUsersCount: 19522,
    },
    1648453500: {
      datePoint: 1648453500,
      cdnDwSize: 59209675741.9,
      cdnUsersCount: 17006,
      p2pDwSize: 42457620553.6,
      p2pUsersCount: 19352,
    },
    1648453560: {
      datePoint: 1648453560,
      cdnDwSize: 59159764320.5,
      cdnUsersCount: 16861,
      p2pDwSize: 42270533481.6,
      p2pUsersCount: 19443,
    },
    1648453620: {
      datePoint: 1648453620,
      cdnDwSize: 59609209530.5,
      cdnUsersCount: 16941,
      p2pDwSize: 42243488302.9,
      p2pUsersCount: 19399,
    },
    1648453680: {
      datePoint: 1648453680,
      cdnDwSize: 59216745468.1,
      cdnUsersCount: 16781,
      p2pDwSize: 42289299842.1,
      p2pUsersCount: 19590,
    },
    1648453740: {
      datePoint: 1648453740,
      cdnDwSize: 59149579650.1,
      cdnUsersCount: 16958,
      p2pDwSize: 42230244505.1,
      p2pUsersCount: 19385,
    },
    1648453800: {
      datePoint: 1648453800,
      cdnDwSize: 59932188418.1,
      cdnUsersCount: 17055,
      p2pDwSize: 42270652046.9,
      p2pUsersCount: 19244,
    },
    1648453860: {
      datePoint: 1648453860,
      cdnDwSize: 59771119317.9,
      cdnUsersCount: 16956,
      p2pDwSize: 42227702770.1,
      p2pUsersCount: 19331,
    },
    1648453920: {
      datePoint: 1648453920,
      cdnDwSize: 59715692228,
      cdnUsersCount: 17109,
      p2pDwSize: 41959442787.7,
      p2pUsersCount: 19257,
    },
    1648453980: {
      datePoint: 1648453980,
      cdnDwSize: 59422280705.6,
      cdnUsersCount: 17016,
      p2pDwSize: 42365681685.3,
      p2pUsersCount: 19292,
    },
    1648454040: {
      datePoint: 1648454040,
      cdnDwSize: 59622456486.8,
      cdnUsersCount: 17141,
      p2pDwSize: 42264797977.6,
      p2pUsersCount: 19258,
    },
    1648454100: {
      datePoint: 1648454100,
      cdnDwSize: 60525160471.5,
      cdnUsersCount: 17342,
      p2pDwSize: 42289424448.5,
      p2pUsersCount: 19267,
    },
    1648454160: {
      datePoint: 1648454160,
      cdnDwSize: 60605979816.5,
      cdnUsersCount: 17393,
      p2pDwSize: 42070340578.7,
      p2pUsersCount: 19479,
    },
    1648454220: {
      datePoint: 1648454220,
      cdnDwSize: 61140602108.1,
      cdnUsersCount: 17417,
      p2pDwSize: 42094889794.1,
      p2pUsersCount: 19523,
    },
    1648454280: {
      datePoint: 1648454280,
      cdnDwSize: 60527956808.5,
      cdnUsersCount: 17084,
      p2pDwSize: 42312112138.1,
      p2pUsersCount: 19587,
    },
    1648454340: {
      datePoint: 1648454340,
      cdnDwSize: 60876617923.7,
      cdnUsersCount: 17145,
      p2pDwSize: 42435929990.9,
      p2pUsersCount: 19638,
    },
    1648454400: {
      datePoint: 1648454400,
      cdnDwSize: 63889832742.9,
      cdnUsersCount: 19299,
      p2pDwSize: 40685658112.5,
      p2pUsersCount: 21233,
    },
    1648454460: {
      datePoint: 1648454460,
      cdnDwSize: 62681217941.9,
      cdnUsersCount: 16163,
      p2pDwSize: 44772173905.6,
      p2pUsersCount: 22445,
    },
    1648454520: {
      datePoint: 1648454520,
      cdnDwSize: 52516613693.7,
      cdnUsersCount: 14178,
      p2pDwSize: 43607483825.6,
      p2pUsersCount: 22161,
    },
    1648454580: {
      datePoint: 1648454580,
      cdnDwSize: 51406230097.1,
      cdnUsersCount: 13700,
      p2pDwSize: 44805464594.7,
      p2pUsersCount: 22162,
    },
    1648454640: {
      datePoint: 1648454640,
      cdnDwSize: 51818148374.4,
      cdnUsersCount: 13990,
      p2pDwSize: 45412980879.5,
      p2pUsersCount: 22240,
    },
    1648454700: {
      datePoint: 1648454700,
      cdnDwSize: 52075201852.3,
      cdnUsersCount: 14023,
      p2pDwSize: 45730437428.8,
      p2pUsersCount: 22261,
    },
    1648454760: {
      datePoint: 1648454760,
      cdnDwSize: 53006956369.2,
      cdnUsersCount: 14162,
      p2pDwSize: 46339198624.5,
      p2pUsersCount: 22375,
    },
    1648454820: {
      datePoint: 1648454820,
      cdnDwSize: 52795267442.9,
      cdnUsersCount: 14537,
      p2pDwSize: 46555352602.1,
      p2pUsersCount: 22297,
    },
    1648454880: {
      datePoint: 1648454880,
      cdnDwSize: 53000584075.2,
      cdnUsersCount: 14594,
      p2pDwSize: 46799150475.7,
      p2pUsersCount: 22332,
    },
    1648454940: {
      datePoint: 1648454940,
      cdnDwSize: 53607345023.7,
      cdnUsersCount: 14627,
      p2pDwSize: 46871157433.6,
      p2pUsersCount: 22405,
    },
    1648455000: {
      datePoint: 1648455000,
      cdnDwSize: 54103575610.9,
      cdnUsersCount: 14963,
      p2pDwSize: 46798605977.6,
      p2pUsersCount: 22335,
    },
    1648455060: {
      datePoint: 1648455060,
      cdnDwSize: 54381123095.3,
      cdnUsersCount: 15173,
      p2pDwSize: 46986822879.5,
      p2pUsersCount: 22317,
    },
    1648455120: {
      datePoint: 1648455120,
      cdnDwSize: 54909620875.7,
      cdnUsersCount: 15426,
      p2pDwSize: 46928161364.8,
      p2pUsersCount: 22393,
    },
    1648455180: {
      datePoint: 1648455180,
      cdnDwSize: 54952679918.3,
      cdnUsersCount: 15158,
      p2pDwSize: 47072659242.7,
      p2pUsersCount: 22218,
    },
    1648455240: {
      datePoint: 1648455240,
      cdnDwSize: 54679154784,
      cdnUsersCount: 15246,
      p2pDwSize: 47216658318.9,
      p2pUsersCount: 22120,
    },
    1648455300: {
      datePoint: 1648455300,
      cdnDwSize: 56154599311.2,
      cdnUsersCount: 15442,
      p2pDwSize: 47022406566.9,
      p2pUsersCount: 22089,
    },
    1648455360: {
      datePoint: 1648455360,
      cdnDwSize: 55670428450.1,
      cdnUsersCount: 15496,
      p2pDwSize: 46574678576,
      p2pUsersCount: 22007,
    },
    1648455420: {
      datePoint: 1648455420,
      cdnDwSize: 56458556309.9,
      cdnUsersCount: 15414,
      p2pDwSize: 46501216498.1,
      p2pUsersCount: 22132,
    },
    1648455480: {
      datePoint: 1648455480,
      cdnDwSize: 57128490522.4,
      cdnUsersCount: 15613,
      p2pDwSize: 46818321286.9,
      p2pUsersCount: 21990,
    },
    1648455540: {
      datePoint: 1648455540,
      cdnDwSize: 56471374901.9,
      cdnUsersCount: 15534,
      p2pDwSize: 46503384288.5,
      p2pUsersCount: 22093,
    },
    1648455600: {
      datePoint: 1648455600,
      cdnDwSize: 56380727963.7,
      cdnUsersCount: 15728,
      p2pDwSize: 46956987129.1,
      p2pUsersCount: 21985,
    },
    1648455660: {
      datePoint: 1648455660,
      cdnDwSize: 56787530590.3,
      cdnUsersCount: 15822,
      p2pDwSize: 46884953450.1,
      p2pUsersCount: 21859,
    },
    1648455720: {
      datePoint: 1648455720,
      cdnDwSize: 57734670930.5,
      cdnUsersCount: 16119,
      p2pDwSize: 47100252955.2,
      p2pUsersCount: 21699,
    },
    1648455780: {
      datePoint: 1648455780,
      cdnDwSize: 58273359564.3,
      cdnUsersCount: 16091,
      p2pDwSize: 47232265151.5,
      p2pUsersCount: 21745,
    },
    1648455840: {
      datePoint: 1648455840,
      cdnDwSize: 58743895388.1,
      cdnUsersCount: 16191,
      p2pDwSize: 47182628764.8,
      p2pUsersCount: 21698,
    },
    1648455900: {
      datePoint: 1648455900,
      cdnDwSize: 58973085587.7,
      cdnUsersCount: 16384,
      p2pDwSize: 46997682060.3,
      p2pUsersCount: 21603,
    },
    1648455960: {
      datePoint: 1648455960,
      cdnDwSize: 59971466907.2,
      cdnUsersCount: 16476,
      p2pDwSize: 47652116753.1,
      p2pUsersCount: 21693,
    },
    1648456020: {
      datePoint: 1648456020,
      cdnDwSize: 60290337390.8,
      cdnUsersCount: 16685,
      p2pDwSize: 47500814202.7,
      p2pUsersCount: 21585,
    },
    1648456080: {
      datePoint: 1648456080,
      cdnDwSize: 60969682711.5,
      cdnUsersCount: 16720,
      p2pDwSize: 47954816280,
      p2pUsersCount: 21701,
    },
    1648456140: {
      datePoint: 1648456140,
      cdnDwSize: 61459882258.7,
      cdnUsersCount: 16855,
      p2pDwSize: 48438065291.7,
      p2pUsersCount: 21691,
    },
    1648456200: {
      datePoint: 1648456200,
      cdnDwSize: 62324071533.2,
      cdnUsersCount: 17031,
      p2pDwSize: 48458078042.1,
      p2pUsersCount: 21782,
    },
    1648456260: {
      datePoint: 1648456260,
      cdnDwSize: 63750032382.3,
      cdnUsersCount: 17169,
      p2pDwSize: 48021749193.1,
      p2pUsersCount: 21784,
    },
    1648456320: {
      datePoint: 1648456320,
      cdnDwSize: 62762932969.1,
      cdnUsersCount: 17018,
      p2pDwSize: 48670490042.7,
      p2pUsersCount: 22069,
    },
    1648456380: {
      datePoint: 1648456380,
      cdnDwSize: 64112180566.1,
      cdnUsersCount: 17339,
      p2pDwSize: 48962029638.9,
      p2pUsersCount: 21717,
    },
    1648456440: {
      datePoint: 1648456440,
      cdnDwSize: 63947988711.9,
      cdnUsersCount: 17375,
      p2pDwSize: 49208721434.1,
      p2pUsersCount: 21891,
    },
    1648456500: {
      datePoint: 1648456500,
      cdnDwSize: 64656978875.2,
      cdnUsersCount: 17489,
      p2pDwSize: 49385739652.3,
      p2pUsersCount: 21875,
    },
    1648456560: {
      datePoint: 1648456560,
      cdnDwSize: 64714466442.7,
      cdnUsersCount: 17476,
      p2pDwSize: 50258940469.9,
      p2pUsersCount: 21977,
    },
    1648456620: {
      datePoint: 1648456620,
      cdnDwSize: 64887834976.8,
      cdnUsersCount: 17685,
      p2pDwSize: 50299501896,
      p2pUsersCount: 21945,
    },
    1648456680: {
      datePoint: 1648456680,
      cdnDwSize: 65622812130.7,
      cdnUsersCount: 17720,
      p2pDwSize: 50286232981.3,
      p2pUsersCount: 22029,
    },
    1648456740: {
      datePoint: 1648456740,
      cdnDwSize: 65635407449.1,
      cdnUsersCount: 17691,
      p2pDwSize: 50871465200,
      p2pUsersCount: 22159,
    },
    1648456800: {
      datePoint: 1648456800,
      cdnDwSize: 65531612778,
      cdnUsersCount: 17690,
      p2pDwSize: 51091202633.1,
      p2pUsersCount: 22201,
    },
    1648456860: {
      datePoint: 1648456860,
      cdnDwSize: 66001667321.9,
      cdnUsersCount: 17735,
      p2pDwSize: 51105498102.9,
      p2pUsersCount: 22203,
    },
    1648456920: {
      datePoint: 1648456920,
      cdnDwSize: 66718336960.5,
      cdnUsersCount: 17702,
      p2pDwSize: 51387588317.9,
      p2pUsersCount: 22387,
    },
    1648456980: {
      datePoint: 1648456980,
      cdnDwSize: 67392839975.3,
      cdnUsersCount: 17803,
      p2pDwSize: 51555509040.5,
      p2pUsersCount: 22450,
    },
    1648457040: {
      datePoint: 1648457040,
      cdnDwSize: 67228912091.1,
      cdnUsersCount: 17886,
      p2pDwSize: 51667650564.3,
      p2pUsersCount: 22515,
    },
    1648457100: {
      datePoint: 1648457100,
      cdnDwSize: 67489359827.1,
      cdnUsersCount: 17983,
      p2pDwSize: 51467193433.6,
      p2pUsersCount: 22457,
    },
    1648457160: {
      datePoint: 1648457160,
      cdnDwSize: 67919043754.7,
      cdnUsersCount: 17962,
      p2pDwSize: 51671327668.8,
      p2pUsersCount: 22493,
    },
    1648457220: {
      datePoint: 1648457220,
      cdnDwSize: 68613684201.5,
      cdnUsersCount: 17941,
      p2pDwSize: 52402717404.3,
      p2pUsersCount: 22620,
    },
    1648457280: {
      datePoint: 1648457280,
      cdnDwSize: 68513571871.5,
      cdnUsersCount: 18096,
      p2pDwSize: 52130227346.7,
      p2pUsersCount: 22552,
    },
    1648457340: {
      datePoint: 1648457340,
      cdnDwSize: 69189134430.4,
      cdnUsersCount: 18141,
      p2pDwSize: 52211065993.1,
      p2pUsersCount: 22560,
    },
    1648457400: {
      datePoint: 1648457400,
      cdnDwSize: 69314938390.4,
      cdnUsersCount: 18166,
      p2pDwSize: 52620987033.6,
      p2pUsersCount: 22685,
    },
    1648457460: {
      datePoint: 1648457460,
      cdnDwSize: 69902242920.5,
      cdnUsersCount: 18293,
      p2pDwSize: 52249651388.8,
      p2pUsersCount: 22590,
    },
    1648457520: {
      datePoint: 1648457520,
      cdnDwSize: 70344485923.7,
      cdnUsersCount: 18224,
      p2pDwSize: 52380904992.5,
      p2pUsersCount: 22724,
    },
    1648457580: {
      datePoint: 1648457580,
      cdnDwSize: 70442756105.6,
      cdnUsersCount: 18453,
      p2pDwSize: 52564389733.9,
      p2pUsersCount: 22815,
    },
    1648457640: {
      datePoint: 1648457640,
      cdnDwSize: 70664670275.5,
      cdnUsersCount: 18481,
      p2pDwSize: 52298623834.7,
      p2pUsersCount: 22728,
    },
    1648457700: {
      datePoint: 1648457700,
      cdnDwSize: 71679034854.8,
      cdnUsersCount: 18619,
      p2pDwSize: 52225540163.2,
      p2pUsersCount: 22709,
    },
    1648457760: {
      datePoint: 1648457760,
      cdnDwSize: 71103935208.3,
      cdnUsersCount: 18583,
      p2pDwSize: 51921201376.5,
      p2pUsersCount: 22787,
    },
    1648457820: {
      datePoint: 1648457820,
      cdnDwSize: 70975684717.7,
      cdnUsersCount: 18644,
      p2pDwSize: 51455209035.2,
      p2pUsersCount: 22643,
    },
    1648457880: {
      datePoint: 1648457880,
      cdnDwSize: 70952150302.4,
      cdnUsersCount: 18302,
      p2pDwSize: 52120921822.9,
      p2pUsersCount: 23011,
    },
    1648457940: {
      datePoint: 1648457940,
      cdnDwSize: 70544972331.1,
      cdnUsersCount: 18383,
      p2pDwSize: 51715150894.9,
      p2pUsersCount: 22887,
    },
    1648458000: {
      datePoint: 1648458000,
      cdnDwSize: 71140465513.3,
      cdnUsersCount: 18956,
      p2pDwSize: 51089631278.9,
      p2pUsersCount: 22679,
    },
    1648458060: {
      datePoint: 1648458060,
      cdnDwSize: 73734864762.5,
      cdnUsersCount: 19807,
      p2pDwSize: 50746463924.8,
      p2pUsersCount: 23463,
    },
    1648458120: {
      datePoint: 1648458120,
      cdnDwSize: 77412768673.1,
      cdnUsersCount: 19313,
      p2pDwSize: 52775198069.3,
      p2pUsersCount: 25493,
    },
    1648458180: {
      datePoint: 1648458180,
      cdnDwSize: 72605866408.5,
      cdnUsersCount: 16323,
      p2pDwSize: 55138550556.3,
      p2pUsersCount: 25672,
    },
    1648458240: {
      datePoint: 1648458240,
      cdnDwSize: 71285069449.5,
      cdnUsersCount: 16266,
      p2pDwSize: 55448826307.7,
      p2pUsersCount: 25768,
    },
    1648458300: {
      datePoint: 1648458300,
      cdnDwSize: 72583343381.9,
      cdnUsersCount: 16557,
      p2pDwSize: 55111132134.9,
      p2pUsersCount: 25602,
    },
    1648458360: {
      datePoint: 1648458360,
      cdnDwSize: 72579588370.1,
      cdnUsersCount: 16534,
      p2pDwSize: 54942510046.9,
      p2pUsersCount: 25683,
    },
    1648458420: {
      datePoint: 1648458420,
      cdnDwSize: 72779707877.9,
      cdnUsersCount: 16742,
      p2pDwSize: 55194808102.4,
      p2pUsersCount: 25568,
    },
    1648458480: {
      datePoint: 1648458480,
      cdnDwSize: 73353910381.2,
      cdnUsersCount: 16982,
      p2pDwSize: 55059435970.1,
      p2pUsersCount: 25430,
    },
    1648458540: {
      datePoint: 1648458540,
      cdnDwSize: 73639641985.5,
      cdnUsersCount: 17322,
      p2pDwSize: 54757560735.5,
      p2pUsersCount: 25271,
    },
    1648458600: {
      datePoint: 1648458600,
      cdnDwSize: 74002873401.5,
      cdnUsersCount: 17464,
      p2pDwSize: 55073244795.7,
      p2pUsersCount: 25156,
    },
    1648458660: {
      datePoint: 1648458660,
      cdnDwSize: 74505261290.7,
      cdnUsersCount: 17765,
      p2pDwSize: 54371888310.9,
      p2pUsersCount: 24924,
    },
    1648458720: {
      datePoint: 1648458720,
      cdnDwSize: 74961261782.9,
      cdnUsersCount: 18012,
      p2pDwSize: 54611627740.8,
      p2pUsersCount: 24736,
    },
    1648458780: {
      datePoint: 1648458780,
      cdnDwSize: 75532731348.8,
      cdnUsersCount: 18133,
      p2pDwSize: 54494877309.3,
      p2pUsersCount: 24784,
    },
    1648458840: {
      datePoint: 1648458840,
      cdnDwSize: 75726992825.5,
      cdnUsersCount: 18264,
      p2pDwSize: 54500170286.4,
      p2pUsersCount: 24753,
    },
    1648458900: {
      datePoint: 1648458900,
      cdnDwSize: 76378247938.1,
      cdnUsersCount: 18498,
      p2pDwSize: 54222990756.8,
      p2pUsersCount: 24593,
    },
    1648458960: {
      datePoint: 1648458960,
      cdnDwSize: 77302491105.5,
      cdnUsersCount: 18831,
      p2pDwSize: 53959115536,
      p2pUsersCount: 24536,
    },
    1648459020: {
      datePoint: 1648459020,
      cdnDwSize: 77520438704.5,
      cdnUsersCount: 18858,
      p2pDwSize: 54371272222.4,
      p2pUsersCount: 24367,
    },
    1648459080: {
      datePoint: 1648459080,
      cdnDwSize: 77540428893.7,
      cdnUsersCount: 18987,
      p2pDwSize: 54355275177.1,
      p2pUsersCount: 24304,
    },
    1648459140: {
      datePoint: 1648459140,
      cdnDwSize: 78105768181.9,
      cdnUsersCount: 19111,
      p2pDwSize: 54704617529.1,
      p2pUsersCount: 24292,
    },
    1648459200: {
      datePoint: 1648459200,
      cdnDwSize: 78611464067.7,
      cdnUsersCount: 19308,
      p2pDwSize: 54142881324.8,
      p2pUsersCount: 24118,
    },
    1648459260: {
      datePoint: 1648459260,
      cdnDwSize: 80402023267.1,
      cdnUsersCount: 21331,
      p2pDwSize: 51852414507.7,
      p2pUsersCount: 24760,
    },
    1648459320: {
      datePoint: 1648459320,
      cdnDwSize: 86498467766.4,
      cdnUsersCount: 19779,
      p2pDwSize: 55448049491.7,
      p2pUsersCount: 25141,
    },
    1648459380: {
      datePoint: 1648459380,
      cdnDwSize: 77392183323.6,
      cdnUsersCount: 16811,
      p2pDwSize: 56949113032,
      p2pUsersCount: 26642,
    },
    1648459440: {
      datePoint: 1648459440,
      cdnDwSize: 72332058696.5,
      cdnUsersCount: 16932,
      p2pDwSize: 56218385257.1,
      p2pUsersCount: 26610,
    },
    1648459500: {
      datePoint: 1648459500,
      cdnDwSize: 65435696031.5,
      cdnUsersCount: 17065,
      p2pDwSize: 54491185515.7,
      p2pUsersCount: 25647,
    },
    1648459560: {
      datePoint: 1648459560,
      cdnDwSize: 74771100506.3,
      cdnUsersCount: 17117,
      p2pDwSize: 56785279404.3,
      p2pUsersCount: 25635,
    },
    1648459620: {
      datePoint: 1648459620,
      cdnDwSize: 75245431804.3,
      cdnUsersCount: 17249,
      p2pDwSize: 56976116650.1,
      p2pUsersCount: 25526,
    },
    1648459680: {
      datePoint: 1648459680,
      cdnDwSize: 76551060195.2,
      cdnUsersCount: 17429,
      p2pDwSize: 56871760557.3,
      p2pUsersCount: 25323,
    },
    1648459740: {
      datePoint: 1648459740,
      cdnDwSize: 75792854265.1,
      cdnUsersCount: 17506,
      p2pDwSize: 56644531123.7,
      p2pUsersCount: 25199,
    },
    1648459800: {
      datePoint: 1648459800,
      cdnDwSize: 76697010167.1,
      cdnUsersCount: 17767,
      p2pDwSize: 56266905675.7,
      p2pUsersCount: 24808,
    },
    1648459860: {
      datePoint: 1648459860,
      cdnDwSize: 76754083576,
      cdnUsersCount: 17869,
      p2pDwSize: 56380860597.3,
      p2pUsersCount: 24717,
    },
    1648459920: {
      datePoint: 1648459920,
      cdnDwSize: 76708858095.5,
      cdnUsersCount: 18130,
      p2pDwSize: 55694651297.6,
      p2pUsersCount: 24484,
    },
    1648459980: {
      datePoint: 1648459980,
      cdnDwSize: 77754027300.8,
      cdnUsersCount: 18224,
      p2pDwSize: 55514377445.9,
      p2pUsersCount: 24382,
    },
    1648460040: {
      datePoint: 1648460040,
      cdnDwSize: 77975633002.7,
      cdnUsersCount: 18600,
      p2pDwSize: 55643849010.1,
      p2pUsersCount: 24442,
    },
    1648460100: {
      datePoint: 1648460100,
      cdnDwSize: 76403941420.7,
      cdnUsersCount: 18380,
      p2pDwSize: 54289360121.1,
      p2pUsersCount: 25500,
    },
    1648460160: {
      datePoint: 1648460160,
      cdnDwSize: 78194411758.7,
      cdnUsersCount: 17313,
      p2pDwSize: 57018053258.7,
      p2pUsersCount: 25352,
    },
    1648460220: {
      datePoint: 1648460220,
      cdnDwSize: 79102392317.5,
      cdnUsersCount: 17267,
      p2pDwSize: 56964850411.7,
      p2pUsersCount: 25520,
    },
    1648460280: {
      datePoint: 1648460280,
      cdnDwSize: 79292429295.5,
      cdnUsersCount: 17245,
      p2pDwSize: 58165780098.7,
      p2pUsersCount: 25753,
    },
    1648460340: {
      datePoint: 1648460340,
      cdnDwSize: 79099609976.9,
      cdnUsersCount: 17483,
      p2pDwSize: 57862370307.7,
      p2pUsersCount: 25728,
    },
    1648460400: {
      datePoint: 1648460400,
      cdnDwSize: 80160650342.8,
      cdnUsersCount: 17671,
      p2pDwSize: 57267718136,
      p2pUsersCount: 25596,
    },
    1648460460: {
      datePoint: 1648460460,
      cdnDwSize: 78733903829.2,
      cdnUsersCount: 17684,
      p2pDwSize: 57666270265.1,
      p2pUsersCount: 25549,
    },
    1648460520: {
      datePoint: 1648460520,
      cdnDwSize: 80001134023.5,
      cdnUsersCount: 18016,
      p2pDwSize: 57605100554.7,
      p2pUsersCount: 25427,
    },
    1648460580: {
      datePoint: 1648460580,
      cdnDwSize: 80578346738.7,
      cdnUsersCount: 18373,
      p2pDwSize: 57456696838.9,
      p2pUsersCount: 25354,
    },
    1648460640: {
      datePoint: 1648460640,
      cdnDwSize: 80084045631.9,
      cdnUsersCount: 18547,
      p2pDwSize: 56798362374.4,
      p2pUsersCount: 25022,
    },
    1648460700: {
      datePoint: 1648460700,
      cdnDwSize: 80767745045.3,
      cdnUsersCount: 18792,
      p2pDwSize: 56703575682.1,
      p2pUsersCount: 24838,
    },
    1648460760: {
      datePoint: 1648460760,
      cdnDwSize: 80493533019.1,
      cdnUsersCount: 18793,
      p2pDwSize: 56724780778.7,
      p2pUsersCount: 24800,
    },
    1648460820: {
      datePoint: 1648460820,
      cdnDwSize: 81001565694.8,
      cdnUsersCount: 18958,
      p2pDwSize: 56574634202.7,
      p2pUsersCount: 24679,
    },
    1648460880: {
      datePoint: 1648460880,
      cdnDwSize: 81430554998.8,
      cdnUsersCount: 19153,
      p2pDwSize: 56526957352.5,
      p2pUsersCount: 24415,
    },
    1648460940: {
      datePoint: 1648460940,
      cdnDwSize: 82184873339.6,
      cdnUsersCount: 19283,
      p2pDwSize: 56291981566.9,
      p2pUsersCount: 24272,
    },
    1648461000: {
      datePoint: 1648461000,
      cdnDwSize: 81634431374.4,
      cdnUsersCount: 19364,
      p2pDwSize: 55903968417.1,
      p2pUsersCount: 24126,
    },
    1648461060: {
      datePoint: 1648461060,
      cdnDwSize: 82136468830.4,
      cdnUsersCount: 19509,
      p2pDwSize: 55603076046.4,
      p2pUsersCount: 24011,
    },
    1648461120: {
      datePoint: 1648461120,
      cdnDwSize: 81147831925.7,
      cdnUsersCount: 19448,
      p2pDwSize: 54773884750.4,
      p2pUsersCount: 23863,
    },
    1648461180: {
      datePoint: 1648461180,
      cdnDwSize: 80381589072,
      cdnUsersCount: 19358,
      p2pDwSize: 54395299750.4,
      p2pUsersCount: 23599,
    },
    1648461240: {
      datePoint: 1648461240,
      cdnDwSize: 80193672968.9,
      cdnUsersCount: 19700,
      p2pDwSize: 54309801912.5,
      p2pUsersCount: 23353,
    },
    1648461300: {
      datePoint: 1648461300,
      cdnDwSize: 80875555011.2,
      cdnUsersCount: 19584,
      p2pDwSize: 54010682582.4,
      p2pUsersCount: 23180,
    },
    1648461360: {
      datePoint: 1648461360,
      cdnDwSize: 80645983649.1,
      cdnUsersCount: 19528,
      p2pDwSize: 53470404171.7,
      p2pUsersCount: 23089,
    },
    1648461420: {
      datePoint: 1648461420,
      cdnDwSize: 80467843808.1,
      cdnUsersCount: 19494,
      p2pDwSize: 53834103732.8,
      p2pUsersCount: 23062,
    },
    1648461480: {
      datePoint: 1648461480,
      cdnDwSize: 81663661786.7,
      cdnUsersCount: 19577,
      p2pDwSize: 54059213478.9,
      p2pUsersCount: 22965,
    },
    1648461540: {
      datePoint: 1648461540,
      cdnDwSize: 81530176248.9,
      cdnUsersCount: 19655,
      p2pDwSize: 54157399862.9,
      p2pUsersCount: 22957,
    },
    1648461600: {
      datePoint: 1648461600,
      cdnDwSize: 80760936272.9,
      cdnUsersCount: 20106,
      p2pDwSize: 53799666770.1,
      p2pUsersCount: 22783,
    },
    1648461660: {
      datePoint: 1648461660,
      cdnDwSize: 79485957473.1,
      cdnUsersCount: 19792,
      p2pDwSize: 52485512002.7,
      p2pUsersCount: 24531,
    },
    1648461720: {
      datePoint: 1648461720,
      cdnDwSize: 79712013408,
      cdnUsersCount: 17456,
      p2pDwSize: 56619750542.9,
      p2pUsersCount: 25500,
    },
    1648461780: {
      datePoint: 1648461780,
      cdnDwSize: 78825583985.6,
      cdnUsersCount: 16928,
      p2pDwSize: 57696666656.5,
      p2pUsersCount: 25786,
    },
    1648461840: {
      datePoint: 1648461840,
      cdnDwSize: 78932014144.5,
      cdnUsersCount: 16966,
      p2pDwSize: 57150631786.1,
      p2pUsersCount: 25709,
    },
    1648461900: {
      datePoint: 1648461900,
      cdnDwSize: 78863635485.2,
      cdnUsersCount: 16959,
      p2pDwSize: 57022874982.4,
      p2pUsersCount: 25651,
    },
    1648461960: {
      datePoint: 1648461960,
      cdnDwSize: 78336600319.7,
      cdnUsersCount: 17104,
      p2pDwSize: 57492361742.4,
      p2pUsersCount: 25734,
    },
    1648462020: {
      datePoint: 1648462020,
      cdnDwSize: 79239192408,
      cdnUsersCount: 17393,
      p2pDwSize: 57535029169.6,
      p2pUsersCount: 25482,
    },
    1648462080: {
      datePoint: 1648462080,
      cdnDwSize: 80025322954.5,
      cdnUsersCount: 17701,
      p2pDwSize: 57616956361.1,
      p2pUsersCount: 25179,
    },
    1648462140: {
      datePoint: 1648462140,
      cdnDwSize: 80061912618.7,
      cdnUsersCount: 17959,
      p2pDwSize: 57754679069.9,
      p2pUsersCount: 24935,
    },
    1648462200: {
      datePoint: 1648462200,
      cdnDwSize: 80535676228,
      cdnUsersCount: 17952,
      p2pDwSize: 57608041501.3,
      p2pUsersCount: 24963,
    },
    1648462260: {
      datePoint: 1648462260,
      cdnDwSize: 80681023414.9,
      cdnUsersCount: 18131,
      p2pDwSize: 57632171251.2,
      p2pUsersCount: 24817,
    },
    1648462320: {
      datePoint: 1648462320,
      cdnDwSize: 80598429724.7,
      cdnUsersCount: 18216,
      p2pDwSize: 57423954032,
      p2pUsersCount: 24662,
    },
    1648462380: {
      datePoint: 1648462380,
      cdnDwSize: 81262591187.7,
      cdnUsersCount: 18406,
      p2pDwSize: 56901820002.7,
      p2pUsersCount: 24587,
    },
    1648462440: {
      datePoint: 1648462440,
      cdnDwSize: 81014703059.6,
      cdnUsersCount: 18708,
      p2pDwSize: 56622419892.3,
      p2pUsersCount: 24293,
    },
    1648462500: {
      datePoint: 1648462500,
      cdnDwSize: 81712588788.7,
      cdnUsersCount: 18909,
      p2pDwSize: 56365951996.8,
      p2pUsersCount: 24001,
    },
    1648462560: {
      datePoint: 1648462560,
      cdnDwSize: 81876401532,
      cdnUsersCount: 18901,
      p2pDwSize: 56640878885.3,
      p2pUsersCount: 24059,
    },
    1648462620: {
      datePoint: 1648462620,
      cdnDwSize: 82827157089.9,
      cdnUsersCount: 19096,
      p2pDwSize: 56521220169.1,
      p2pUsersCount: 23852,
    },
    1648462680: {
      datePoint: 1648462680,
      cdnDwSize: 82467396982.3,
      cdnUsersCount: 19053,
      p2pDwSize: 56549926590.9,
      p2pUsersCount: 23915,
    },
    1648462740: {
      datePoint: 1648462740,
      cdnDwSize: 83207792395.2,
      cdnUsersCount: 19168,
      p2pDwSize: 56887375912.5,
      p2pUsersCount: 23862,
    },
    1648462800: {
      datePoint: 1648462800,
      cdnDwSize: 82567292135.9,
      cdnUsersCount: 19185,
      p2pDwSize: 56510933611.2,
      p2pUsersCount: 23940,
    },
    1648462860: {
      datePoint: 1648462860,
      cdnDwSize: 83338008890.7,
      cdnUsersCount: 19285,
      p2pDwSize: 56420785330.1,
      p2pUsersCount: 23780,
    },
    1648462920: {
      datePoint: 1648462920,
      cdnDwSize: 83304820122.7,
      cdnUsersCount: 19465,
      p2pDwSize: 56415758761.6,
      p2pUsersCount: 23599,
    },
    1648462980: {
      datePoint: 1648462980,
      cdnDwSize: 83537509676.7,
      cdnUsersCount: 19496,
      p2pDwSize: 56490836260.8,
      p2pUsersCount: 23581,
    },
    1648463040: {
      datePoint: 1648463040,
      cdnDwSize: 83030845566.9,
      cdnUsersCount: 19543,
      p2pDwSize: 56068021277.9,
      p2pUsersCount: 23397,
    },
    1648463100: {
      datePoint: 1648463100,
      cdnDwSize: 83611744584.9,
      cdnUsersCount: 19588,
      p2pDwSize: 55839761378.7,
      p2pUsersCount: 23248,
    },
    1648463160: {
      datePoint: 1648463160,
      cdnDwSize: 84196967879.9,
      cdnUsersCount: 19679,
      p2pDwSize: 55738369945.6,
      p2pUsersCount: 23287,
    },
    1648463220: {
      datePoint: 1648463220,
      cdnDwSize: 83760407790.8,
      cdnUsersCount: 19759,
      p2pDwSize: 56126806797.3,
      p2pUsersCount: 23135,
    },
    1648463280: {
      datePoint: 1648463280,
      cdnDwSize: 85092194477.9,
      cdnUsersCount: 19855,
      p2pDwSize: 55841068555.2,
      p2pUsersCount: 23194,
    },
    1648463340: {
      datePoint: 1648463340,
      cdnDwSize: 85010016541.9,
      cdnUsersCount: 19826,
      p2pDwSize: 55690051388.8,
      p2pUsersCount: 23228,
    },
    1648463400: {
      datePoint: 1648463400,
      cdnDwSize: 84500755876.7,
      cdnUsersCount: 19840,
      p2pDwSize: 55149299644.3,
      p2pUsersCount: 23205,
    },
    1648463460: {
      datePoint: 1648463460,
      cdnDwSize: 84936125950.8,
      cdnUsersCount: 19905,
      p2pDwSize: 55030950912,
      p2pUsersCount: 23175,
    },
    1648463520: {
      datePoint: 1648463520,
      cdnDwSize: 84752230894.4,
      cdnUsersCount: 19666,
      p2pDwSize: 55518712675.7,
      p2pUsersCount: 23094,
    },
    1648463580: {
      datePoint: 1648463580,
      cdnDwSize: 83978940718,
      cdnUsersCount: 19745,
      p2pDwSize: 55173496171.7,
      p2pUsersCount: 22997,
    },
    1648463640: {
      datePoint: 1648463640,
      cdnDwSize: 84752445463.9,
      cdnUsersCount: 19727,
      p2pDwSize: 55474830016.5,
      p2pUsersCount: 22985,
    },
    1648463700: {
      datePoint: 1648463700,
      cdnDwSize: 84012918538.1,
      cdnUsersCount: 19710,
      p2pDwSize: 55687808573.9,
      p2pUsersCount: 22883,
    },
    1648463760: {
      datePoint: 1648463760,
      cdnDwSize: 82807839214.9,
      cdnUsersCount: 19765,
      p2pDwSize: 54680606169.1,
      p2pUsersCount: 24188,
    },
    1648463820: {
      datePoint: 1648463820,
      cdnDwSize: 83269519010.5,
      cdnUsersCount: 18159,
      p2pDwSize: 56583323387.2,
      p2pUsersCount: 25043,
    },
    1648463880: {
      datePoint: 1648463880,
      cdnDwSize: 82976699361.1,
      cdnUsersCount: 17521,
      p2pDwSize: 58130877973.9,
      p2pUsersCount: 25254,
    },
    1648463940: {
      datePoint: 1648463940,
      cdnDwSize: 77394166846.5,
      cdnUsersCount: 16541,
      p2pDwSize: 53143027313.6,
      p2pUsersCount: 24285,
    },
    1648464000: {
      datePoint: 1648464000,
      cdnDwSize: 83787540804.3,
      cdnUsersCount: 17236,
      p2pDwSize: 57834974672,
      p2pUsersCount: 24723,
    },
    1648464060: {
      datePoint: 1648464060,
      cdnDwSize: 82892506794.7,
      cdnUsersCount: 17454,
      p2pDwSize: 57673633798.9,
      p2pUsersCount: 24595,
    },
    1648464120: {
      datePoint: 1648464120,
      cdnDwSize: 83014349092.1,
      cdnUsersCount: 17694,
      p2pDwSize: 57735211143.5,
      p2pUsersCount: 24567,
    },
    1648464180: {
      datePoint: 1648464180,
      cdnDwSize: 83075409236.1,
      cdnUsersCount: 17872,
      p2pDwSize: 57443799261.9,
      p2pUsersCount: 24386,
    },
    1648464240: {
      datePoint: 1648464240,
      cdnDwSize: 82953045978.1,
      cdnUsersCount: 18048,
      p2pDwSize: 57715898404.8,
      p2pUsersCount: 24281,
    },
    1648464300: {
      datePoint: 1648464300,
      cdnDwSize: 82940898120,
      cdnUsersCount: 18139,
      p2pDwSize: 57066017473.1,
      p2pUsersCount: 24181,
    },
    1648464360: {
      datePoint: 1648464360,
      cdnDwSize: 84481768979.6,
      cdnUsersCount: 18209,
      p2pDwSize: 57120468665.1,
      p2pUsersCount: 24089,
    },
    1648464420: {
      datePoint: 1648464420,
      cdnDwSize: 83304668266.4,
      cdnUsersCount: 18110,
      p2pDwSize: 55936433811.2,
      p2pUsersCount: 23891,
    },
    1648464480: {
      datePoint: 1648464480,
      cdnDwSize: 78992658456.4,
      cdnUsersCount: 18071,
      p2pDwSize: 54432176501.9,
      p2pUsersCount: 23738,
    },
    1648464540: {
      datePoint: 1648464540,
      cdnDwSize: 82758248072.4,
      cdnUsersCount: 18644,
      p2pDwSize: 55031409230.9,
      p2pUsersCount: 23027,
    },
    1648464600: {
      datePoint: 1648464600,
      cdnDwSize: 79967348944.5,
      cdnUsersCount: 18096,
      p2pDwSize: 54425344832.5,
      p2pUsersCount: 23732,
    },
    1648464660: {
      datePoint: 1648464660,
      cdnDwSize: 83525839381.6,
      cdnUsersCount: 18864,
      p2pDwSize: 54518935067.7,
      p2pUsersCount: 23092,
    },
    1648464720: {
      datePoint: 1648464720,
      cdnDwSize: 83226376074.3,
      cdnUsersCount: 18949,
      p2pDwSize: 54866162444.8,
      p2pUsersCount: 23104,
    },
    1648464780: {
      datePoint: 1648464780,
      cdnDwSize: 83244388949.1,
      cdnUsersCount: 18928,
      p2pDwSize: 54973079097.6,
      p2pUsersCount: 23024,
    },
    1648464840: {
      datePoint: 1648464840,
      cdnDwSize: 83958722974.5,
      cdnUsersCount: 19033,
      p2pDwSize: 55044520350.9,
      p2pUsersCount: 22985,
    },
    1648464900: {
      datePoint: 1648464900,
      cdnDwSize: 83173141087.2,
      cdnUsersCount: 19040,
      p2pDwSize: 54778853991.5,
      p2pUsersCount: 22958,
    },
    1648464960: {
      datePoint: 1648464960,
      cdnDwSize: 84126940805.6,
      cdnUsersCount: 19057,
      p2pDwSize: 54472842205.3,
      p2pUsersCount: 23001,
    },
    1648465020: {
      datePoint: 1648465020,
      cdnDwSize: 80470669412.8,
      cdnUsersCount: 18819,
      p2pDwSize: 53035327528,
      p2pUsersCount: 22806,
    },
    1648465080: {
      datePoint: 1648465080,
      cdnDwSize: 83335682151.3,
      cdnUsersCount: 18717,
      p2pDwSize: 53650340049.6,
      p2pUsersCount: 22643,
    },
    1648465140: {
      datePoint: 1648465140,
      cdnDwSize: 80433100846.4,
      cdnUsersCount: 18702,
      p2pDwSize: 52766247765.3,
      p2pUsersCount: 22566,
    },
    1648465200: {
      datePoint: 1648465200,
      cdnDwSize: 81482734748.5,
      cdnUsersCount: 18998,
      p2pDwSize: 52697846722.7,
      p2pUsersCount: 22299,
    },
    1648465260: {
      datePoint: 1648465260,
      cdnDwSize: 80982726966.8,
      cdnUsersCount: 19315,
      p2pDwSize: 51439448894.9,
      p2pUsersCount: 23308,
    },
    1648465320: {
      datePoint: 1648465320,
      cdnDwSize: 80695141362.7,
      cdnUsersCount: 17929,
      p2pDwSize: 53418276108.3,
      p2pUsersCount: 23969,
    },
    1648465380: {
      datePoint: 1648465380,
      cdnDwSize: 79122195997.1,
      cdnUsersCount: 17015,
      p2pDwSize: 54567377153.1,
      p2pUsersCount: 24178,
    },
    1648465440: {
      datePoint: 1648465440,
      cdnDwSize: 80275923595.6,
      cdnUsersCount: 16945,
      p2pDwSize: 54785241830.4,
      p2pUsersCount: 24263,
    },
    1648465500: {
      datePoint: 1648465500,
      cdnDwSize: 79604779188.3,
      cdnUsersCount: 17174,
      p2pDwSize: 54676988071.5,
      p2pUsersCount: 24186,
    },
    1648465560: {
      datePoint: 1648465560,
      cdnDwSize: 79561428970.1,
      cdnUsersCount: 17199,
      p2pDwSize: 54757226621.9,
      p2pUsersCount: 24057,
    },
    1648465620: {
      datePoint: 1648465620,
      cdnDwSize: 80586378900.5,
      cdnUsersCount: 17452,
      p2pDwSize: 54647454800.5,
      p2pUsersCount: 23914,
    },
    1648465680: {
      datePoint: 1648465680,
      cdnDwSize: 80313493138.4,
      cdnUsersCount: 17509,
      p2pDwSize: 54499509905.1,
      p2pUsersCount: 23781,
    },
    1648465740: {
      datePoint: 1648465740,
      cdnDwSize: 79751614282.1,
      cdnUsersCount: 17657,
      p2pDwSize: 54301921052.8,
      p2pUsersCount: 23626,
    },
    1648465800: {
      datePoint: 1648465800,
      cdnDwSize: 81359166963.2,
      cdnUsersCount: 17985,
      p2pDwSize: 54441742643.7,
      p2pUsersCount: 23307,
    },
    1648465860: {
      datePoint: 1648465860,
      cdnDwSize: 80888065732,
      cdnUsersCount: 18167,
      p2pDwSize: 53542257897.1,
      p2pUsersCount: 23120,
    },
    1648465920: {
      datePoint: 1648465920,
      cdnDwSize: 80394271100.8,
      cdnUsersCount: 18072,
      p2pDwSize: 53673005454.9,
      p2pUsersCount: 23042,
    },
    1648465980: {
      datePoint: 1648465980,
      cdnDwSize: 80397877392,
      cdnUsersCount: 18114,
      p2pDwSize: 53812473981.9,
      p2pUsersCount: 23052,
    },
    1648466040: {
      datePoint: 1648466040,
      cdnDwSize: 80218220278.7,
      cdnUsersCount: 18227,
      p2pDwSize: 53594445343.5,
      p2pUsersCount: 22865,
    },
    1648466100: {
      datePoint: 1648466100,
      cdnDwSize: 80464592626.1,
      cdnUsersCount: 18376,
      p2pDwSize: 53091452346.1,
      p2pUsersCount: 22743,
    },
    1648466160: {
      datePoint: 1648466160,
      cdnDwSize: 80725815967.9,
      cdnUsersCount: 18438,
      p2pDwSize: 53159106051.2,
      p2pUsersCount: 22640,
    },
    1648466220: {
      datePoint: 1648466220,
      cdnDwSize: 80753546896.5,
      cdnUsersCount: 18629,
      p2pDwSize: 52701884762.1,
      p2pUsersCount: 22406,
    },
    1648466280: {
      datePoint: 1648466280,
      cdnDwSize: 80306311564.7,
      cdnUsersCount: 18592,
      p2pDwSize: 52614763155.7,
      p2pUsersCount: 22369,
    },
    1648466340: {
      datePoint: 1648466340,
      cdnDwSize: 81312531254.7,
      cdnUsersCount: 18365,
      p2pDwSize: 52463697460.3,
      p2pUsersCount: 22410,
    },
    1648466400: {
      datePoint: 1648466400,
      cdnDwSize: 76133971189.7,
      cdnUsersCount: 18182,
      p2pDwSize: 51025140711.5,
      p2pUsersCount: 22168,
    },
    1648466460: {
      datePoint: 1648466460,
      cdnDwSize: 78489925100.8,
      cdnUsersCount: 18661,
      p2pDwSize: 50348872325.3,
      p2pUsersCount: 21665,
    },
    1648466520: {
      datePoint: 1648466520,
      cdnDwSize: 80225216711.9,
      cdnUsersCount: 18238,
      p2pDwSize: 51127692555.7,
      p2pUsersCount: 22148,
    },
    1648466580: {
      datePoint: 1648466580,
      cdnDwSize: 75675892972.8,
      cdnUsersCount: 18096,
      p2pDwSize: 50425836492.3,
      p2pUsersCount: 22238,
    },
    1648466640: {
      datePoint: 1648466640,
      cdnDwSize: 75400211828.3,
      cdnUsersCount: 18258,
      p2pDwSize: 49979530332.8,
      p2pUsersCount: 22014,
    },
    1648466700: {
      datePoint: 1648466700,
      cdnDwSize: 77996537023.5,
      cdnUsersCount: 18625,
      p2pDwSize: 49785302544.5,
      p2pUsersCount: 21545,
    },
    1648466760: {
      datePoint: 1648466760,
      cdnDwSize: 77838283631.9,
      cdnUsersCount: 18687,
      p2pDwSize: 49488898786.7,
      p2pUsersCount: 21448,
    },
    1648466820: {
      datePoint: 1648466820,
      cdnDwSize: 78025076996.7,
      cdnUsersCount: 18639,
      p2pDwSize: 49535742245.3,
      p2pUsersCount: 21428,
    },
    1648466880: {
      datePoint: 1648466880,
      cdnDwSize: 77526991005.9,
      cdnUsersCount: 18651,
      p2pDwSize: 49419440231.5,
      p2pUsersCount: 21413,
    },
    1648466940: {
      datePoint: 1648466940,
      cdnDwSize: 77453466737.6,
      cdnUsersCount: 18604,
      p2pDwSize: 49199124811.2,
      p2pUsersCount: 21271,
    },
    1648467000: {
      datePoint: 1648467000,
      cdnDwSize: 75713467476.4,
      cdnUsersCount: 18531,
      p2pDwSize: 48164641300.3,
      p2pUsersCount: 21085,
    },
    1648467060: {
      datePoint: 1648467060,
      cdnDwSize: 75024696215.3,
      cdnUsersCount: 18401,
      p2pDwSize: 47766841747.7,
      p2pUsersCount: 20753,
    },
    1648467120: {
      datePoint: 1648467120,
      cdnDwSize: 74334014793.2,
      cdnUsersCount: 18157,
      p2pDwSize: 47464072786.1,
      p2pUsersCount: 20451,
    },
    1648467180: {
      datePoint: 1648467180,
      cdnDwSize: 73716082561.1,
      cdnUsersCount: 18242,
      p2pDwSize: 46967945699.2,
      p2pUsersCount: 20290,
    },
    1648467240: {
      datePoint: 1648467240,
      cdnDwSize: 74030768466.7,
      cdnUsersCount: 18093,
      p2pDwSize: 47218929258.7,
      p2pUsersCount: 20234,
    },
    1648467300: {
      datePoint: 1648467300,
      cdnDwSize: 73680029172,
      cdnUsersCount: 17924,
      p2pDwSize: 47089115409.1,
      p2pUsersCount: 20246,
    },
    1648467360: {
      datePoint: 1648467360,
      cdnDwSize: 73228086373.9,
      cdnUsersCount: 18000,
      p2pDwSize: 46726843269.3,
      p2pUsersCount: 20201,
    },
    1648467420: {
      datePoint: 1648467420,
      cdnDwSize: 70511003487.2,
      cdnUsersCount: 18048,
      p2pDwSize: 45521985560.5,
      p2pUsersCount: 21673,
    },
    1648467480: {
      datePoint: 1648467480,
      cdnDwSize: 72219528438.9,
      cdnUsersCount: 16418,
      p2pDwSize: 47798298910.4,
      p2pUsersCount: 21719,
    },
    1648467540: {
      datePoint: 1648467540,
      cdnDwSize: 71593328588.8,
      cdnUsersCount: 16054,
      p2pDwSize: 48147100649.6,
      p2pUsersCount: 21658,
    },
    1648467600: {
      datePoint: 1648467600,
      cdnDwSize: 71694796400,
      cdnUsersCount: 15935,
      p2pDwSize: 47876331518.9,
      p2pUsersCount: 21659,
    },
    1648467660: {
      datePoint: 1648467660,
      cdnDwSize: 70880904443.7,
      cdnUsersCount: 15985,
      p2pDwSize: 47424596696.5,
      p2pUsersCount: 21622,
    },
    1648467720: {
      datePoint: 1648467720,
      cdnDwSize: 70282165868.4,
      cdnUsersCount: 15882,
      p2pDwSize: 47303817550.4,
      p2pUsersCount: 21337,
    },
    1648467780: {
      datePoint: 1648467780,
      cdnDwSize: 69445585053.7,
      cdnUsersCount: 16036,
      p2pDwSize: 47113005020.3,
      p2pUsersCount: 21121,
    },
    1648467840: {
      datePoint: 1648467840,
      cdnDwSize: 69177945304.7,
      cdnUsersCount: 16165,
      p2pDwSize: 46723811380.8,
      p2pUsersCount: 20917,
    },
    1648467900: {
      datePoint: 1648467900,
      cdnDwSize: 69234056800,
      cdnUsersCount: 16276,
      p2pDwSize: 46157041238.4,
      p2pUsersCount: 20664,
    },
    1648467960: {
      datePoint: 1648467960,
      cdnDwSize: 68289684324.7,
      cdnUsersCount: 16331,
      p2pDwSize: 45625130432,
      p2pUsersCount: 20480,
    },
    1648468020: {
      datePoint: 1648468020,
      cdnDwSize: 68606665360.5,
      cdnUsersCount: 16350,
      p2pDwSize: 45515247991.5,
      p2pUsersCount: 20332,
    },
    1648468080: {
      datePoint: 1648468080,
      cdnDwSize: 68600556513.6,
      cdnUsersCount: 16322,
      p2pDwSize: 45389942707.7,
      p2pUsersCount: 20246,
    },
    1648468140: {
      datePoint: 1648468140,
      cdnDwSize: 67821530234.9,
      cdnUsersCount: 16503,
      p2pDwSize: 45652577429.3,
      p2pUsersCount: 20185,
    },
    1648468200: {
      datePoint: 1648468200,
      cdnDwSize: 67311550904.8,
      cdnUsersCount: 16381,
      p2pDwSize: 45652811978.1,
      p2pUsersCount: 20185,
    },
    1648468260: {
      datePoint: 1648468260,
      cdnDwSize: 67720556914.1,
      cdnUsersCount: 16593,
      p2pDwSize: 45149476169.1,
      p2pUsersCount: 20071,
    },
    1648468320: {
      datePoint: 1648468320,
      cdnDwSize: 67061049780.8,
      cdnUsersCount: 16484,
      p2pDwSize: 44583673875.7,
      p2pUsersCount: 20026,
    },
    1648468380: {
      datePoint: 1648468380,
      cdnDwSize: 67220070733.9,
      cdnUsersCount: 16452,
      p2pDwSize: 44612947907.2,
      p2pUsersCount: 20019,
    },
    1648468440: {
      datePoint: 1648468440,
      cdnDwSize: 67214361097.5,
      cdnUsersCount: 16571,
      p2pDwSize: 44391765857.1,
      p2pUsersCount: 20041,
    },
    1648468500: {
      datePoint: 1648468500,
      cdnDwSize: 66650938158.4,
      cdnUsersCount: 16591,
      p2pDwSize: 44215516182.9,
      p2pUsersCount: 19895,
    },
    1648468560: {
      datePoint: 1648468560,
      cdnDwSize: 67140468322.9,
      cdnUsersCount: 16641,
      p2pDwSize: 44573955253.3,
      p2pUsersCount: 19938,
    },
    1648468620: {
      datePoint: 1648468620,
      cdnDwSize: 67214268749.5,
      cdnUsersCount: 16679,
      p2pDwSize: 44166954253.9,
      p2pUsersCount: 19693,
    },
    1648468680: {
      datePoint: 1648468680,
      cdnDwSize: 67151281231.9,
      cdnUsersCount: 16697,
      p2pDwSize: 44699523010.1,
      p2pUsersCount: 19747,
    },
    1648468740: {
      datePoint: 1648468740,
      cdnDwSize: 67857947813.1,
      cdnUsersCount: 16780,
      p2pDwSize: 44825563123.2,
      p2pUsersCount: 19741,
    },
    1648468800: {
      datePoint: 1648468800,
      cdnDwSize: 68343440694.9,
      cdnUsersCount: 16946,
      p2pDwSize: 44214129319.5,
      p2pUsersCount: 19561,
    },
    1648468860: {
      datePoint: 1648468860,
      cdnDwSize: 68044632868.3,
      cdnUsersCount: 17369,
      p2pDwSize: 44229254069.9,
      p2pUsersCount: 19629,
    },
    1648468920: {
      datePoint: 1648468920,
      cdnDwSize: 66759952012.7,
      cdnUsersCount: 17012,
      p2pDwSize: 43356138980.3,
      p2pUsersCount: 21278,
    },
    1648468980: {
      datePoint: 1648468980,
      cdnDwSize: 67537779001.5,
      cdnUsersCount: 15463,
      p2pDwSize: 46135119987.2,
      p2pUsersCount: 21367,
    },
    1648469040: {
      datePoint: 1648469040,
      cdnDwSize: 67568563300.8,
      cdnUsersCount: 15204,
      p2pDwSize: 46419222930.1,
      p2pUsersCount: 21608,
    },
    1648469100: {
      datePoint: 1648469100,
      cdnDwSize: 67420761584.9,
      cdnUsersCount: 15244,
      p2pDwSize: 46033194759.5,
      p2pUsersCount: 21611,
    },
    1648469160: {
      datePoint: 1648469160,
      cdnDwSize: 67610817528.8,
      cdnUsersCount: 15315,
      p2pDwSize: 45990732202.1,
      p2pUsersCount: 21546,
    },
    1648469220: {
      datePoint: 1648469220,
      cdnDwSize: 67077009149.7,
      cdnUsersCount: 15466,
      p2pDwSize: 46193645740.8,
      p2pUsersCount: 21382,
    },
    1648469280: {
      datePoint: 1648469280,
      cdnDwSize: 67494012222.8,
      cdnUsersCount: 15723,
      p2pDwSize: 46243764635.7,
      p2pUsersCount: 21242,
    },
    1648469340: {
      datePoint: 1648469340,
      cdnDwSize: 67823842308,
      cdnUsersCount: 15823,
      p2pDwSize: 46076277290.7,
      p2pUsersCount: 21117,
    },
    1648469400: {
      datePoint: 1648469400,
      cdnDwSize: 68045186264.3,
      cdnUsersCount: 15922,
      p2pDwSize: 46069928355.2,
      p2pUsersCount: 21096,
    },
    1648469460: {
      datePoint: 1648469460,
      cdnDwSize: 68235554661.3,
      cdnUsersCount: 16074,
      p2pDwSize: 45790721734.4,
      p2pUsersCount: 20975,
    },
    1648469520: {
      datePoint: 1648469520,
      cdnDwSize: 68141260780,
      cdnUsersCount: 16231,
      p2pDwSize: 45543430595.2,
      p2pUsersCount: 20813,
    },
    1648469580: {
      datePoint: 1648469580,
      cdnDwSize: 68488708144.5,
      cdnUsersCount: 16157,
      p2pDwSize: 45336192830.9,
      p2pUsersCount: 20727,
    },
    1648469640: {
      datePoint: 1648469640,
      cdnDwSize: 67663752387.1,
      cdnUsersCount: 16316,
      p2pDwSize: 45248531714.7,
      p2pUsersCount: 20699,
    },
    1648469700: {
      datePoint: 1648469700,
      cdnDwSize: 68253698719.5,
      cdnUsersCount: 16402,
      p2pDwSize: 44972976179.2,
      p2pUsersCount: 20551,
    },
    1648469760: {
      datePoint: 1648469760,
      cdnDwSize: 68689872305.6,
      cdnUsersCount: 16434,
      p2pDwSize: 44976331051.7,
      p2pUsersCount: 20462,
    },
    1648469820: {
      datePoint: 1648469820,
      cdnDwSize: 68451862498.4,
      cdnUsersCount: 16590,
      p2pDwSize: 44769445073.1,
      p2pUsersCount: 20221,
    },
    1648469880: {
      datePoint: 1648469880,
      cdnDwSize: 68498880798,
      cdnUsersCount: 16594,
      p2pDwSize: 45120309523.2,
      p2pUsersCount: 20134,
    },
    1648469940: {
      datePoint: 1648469940,
      cdnDwSize: 68290389049.9,
      cdnUsersCount: 16681,
      p2pDwSize: 45191988283.2,
      p2pUsersCount: 20075,
    },
    1648470000: {
      datePoint: 1648470000,
      cdnDwSize: 68154676886.1,
      cdnUsersCount: 16694,
      p2pDwSize: 44852413157.9,
      p2pUsersCount: 19905,
    },
    1648470060: {
      datePoint: 1648470060,
      cdnDwSize: 68914407699.2,
      cdnUsersCount: 16702,
      p2pDwSize: 44599432010.7,
      p2pUsersCount: 19881,
    },
    1648470120: {
      datePoint: 1648470120,
      cdnDwSize: 69891541834,
      cdnUsersCount: 16566,
      p2pDwSize: 44791668578.1,
      p2pUsersCount: 19843,
    },
    1648470180: {
      datePoint: 1648470180,
      cdnDwSize: 68730909020.8,
      cdnUsersCount: 16476,
      p2pDwSize: 44809964011.2,
      p2pUsersCount: 19948,
    },
    1648470240: {
      datePoint: 1648470240,
      cdnDwSize: 65417475898.7,
      cdnUsersCount: 16475,
      p2pDwSize: 44007279687.5,
      p2pUsersCount: 19913,
    },
    1648470300: {
      datePoint: 1648470300,
      cdnDwSize: 69037102916.3,
      cdnUsersCount: 16685,
      p2pDwSize: 44470069787.2,
      p2pUsersCount: 19801,
    },
    1648470360: {
      datePoint: 1648470360,
      cdnDwSize: 67195043996.9,
      cdnUsersCount: 16510,
      p2pDwSize: 43819770066.7,
      p2pUsersCount: 20001,
    },
    1648470420: {
      datePoint: 1648470420,
      cdnDwSize: 68301724621.5,
      cdnUsersCount: 16963,
      p2pDwSize: 44415572723.2,
      p2pUsersCount: 19637,
    },
    1648470480: {
      datePoint: 1648470480,
      cdnDwSize: 69794240549.5,
      cdnUsersCount: 17036,
      p2pDwSize: 44364867920,
      p2pUsersCount: 19676,
    },
    1648470540: {
      datePoint: 1648470540,
      cdnDwSize: 70472080854.3,
      cdnUsersCount: 17234,
      p2pDwSize: 44216169871.5,
      p2pUsersCount: 19612,
    },
    1648470600: {
      datePoint: 1648470600,
      cdnDwSize: 69866465025.7,
      cdnUsersCount: 17069,
      p2pDwSize: 44025972076.3,
      p2pUsersCount: 19566,
    },
    1648470660: {
      datePoint: 1648470660,
      cdnDwSize: 69432790424.3,
      cdnUsersCount: 17176,
      p2pDwSize: 43977926269.9,
      p2pUsersCount: 19521,
    },
    1648470720: {
      datePoint: 1648470720,
      cdnDwSize: 69410009286.1,
      cdnUsersCount: 17070,
      p2pDwSize: 43887877779.2,
      p2pUsersCount: 19549,
    },
    1648470780: {
      datePoint: 1648470780,
      cdnDwSize: 69997990769.1,
      cdnUsersCount: 17150,
      p2pDwSize: 44113240498.1,
      p2pUsersCount: 19588,
    },
    1648470840: {
      datePoint: 1648470840,
      cdnDwSize: 70356723043.1,
      cdnUsersCount: 17154,
      p2pDwSize: 44389854373.3,
      p2pUsersCount: 19658,
    },
    1648470900: {
      datePoint: 1648470900,
      cdnDwSize: 69764124659.7,
      cdnUsersCount: 17227,
      p2pDwSize: 44464983610.1,
      p2pUsersCount: 19608,
    },
    1648470960: {
      datePoint: 1648470960,
      cdnDwSize: 70243732862.3,
      cdnUsersCount: 17310,
      p2pDwSize: 44599047914.1,
      p2pUsersCount: 19628,
    },
    1648471020: {
      datePoint: 1648471020,
      cdnDwSize: 70667855722.4,
      cdnUsersCount: 17391,
      p2pDwSize: 44879879632,
      p2pUsersCount: 19807,
    },
    1648471080: {
      datePoint: 1648471080,
      cdnDwSize: 71297953239.9,
      cdnUsersCount: 17450,
      p2pDwSize: 45227519681.6,
      p2pUsersCount: 19924,
    },
    1648471140: {
      datePoint: 1648471140,
      cdnDwSize: 71701319398.4,
      cdnUsersCount: 17565,
      p2pDwSize: 45236258272.5,
      p2pUsersCount: 19827,
    },
    1648471200: {
      datePoint: 1648471200,
      cdnDwSize: 71613048053.5,
      cdnUsersCount: 17547,
      p2pDwSize: 45546232472,
      p2pUsersCount: 19838,
    },
    1648471260: {
      datePoint: 1648471260,
      cdnDwSize: 71454344245.9,
      cdnUsersCount: 17538,
      p2pDwSize: 45783189953.1,
      p2pUsersCount: 19878,
    },
    1648471320: {
      datePoint: 1648471320,
      cdnDwSize: 71179805014.7,
      cdnUsersCount: 17477,
      p2pDwSize: 45859027849.6,
      p2pUsersCount: 19829,
    },
    1648471380: {
      datePoint: 1648471380,
      cdnDwSize: 71552873595.7,
      cdnUsersCount: 17537,
      p2pDwSize: 45660270238.9,
      p2pUsersCount: 19802,
    },
    1648471440: {
      datePoint: 1648471440,
      cdnDwSize: 71856606546.1,
      cdnUsersCount: 17603,
      p2pDwSize: 45517595810.7,
      p2pUsersCount: 19756,
    },
    1648471500: {
      datePoint: 1648471500,
      cdnDwSize: 72481805634.7,
      cdnUsersCount: 17761,
      p2pDwSize: 45638256516.8,
      p2pUsersCount: 19715,
    },
    1648471560: {
      datePoint: 1648471560,
      cdnDwSize: 72992167981.3,
      cdnUsersCount: 17767,
      p2pDwSize: 46099645841.1,
      p2pUsersCount: 19727,
    },
    1648471620: {
      datePoint: 1648471620,
      cdnDwSize: 72171939470.1,
      cdnUsersCount: 17739,
      p2pDwSize: 45979062540.8,
      p2pUsersCount: 19764,
    },
    1648471680: {
      datePoint: 1648471680,
      cdnDwSize: 72527017052.8,
      cdnUsersCount: 17758,
      p2pDwSize: 46231079799.5,
      p2pUsersCount: 19841,
    },
    1648471740: {
      datePoint: 1648471740,
      cdnDwSize: 72800091063.5,
      cdnUsersCount: 17772,
      p2pDwSize: 46079871850.7,
      p2pUsersCount: 19780,
    },
    1648471800: {
      datePoint: 1648471800,
      cdnDwSize: 73059500226.7,
      cdnUsersCount: 17813,
      p2pDwSize: 46075612873.6,
      p2pUsersCount: 19869,
    },
    1648471860: {
      datePoint: 1648471860,
      cdnDwSize: 73110788184.4,
      cdnUsersCount: 17828,
      p2pDwSize: 46732713606.9,
      p2pUsersCount: 19901,
    },
    1648471920: {
      datePoint: 1648471920,
      cdnDwSize: 70871772331.2,
      cdnUsersCount: 17436,
      p2pDwSize: 45567852171.2,
      p2pUsersCount: 20089,
    },
    1648471980: {
      datePoint: 1648471980,
      cdnDwSize: 73261453439.9,
      cdnUsersCount: 17681,
      p2pDwSize: 45632385477.3,
      p2pUsersCount: 19591,
    },
    1648472040: {
      datePoint: 1648472040,
      cdnDwSize: 69680996499.2,
      cdnUsersCount: 17188,
      p2pDwSize: 45034581473.1,
      p2pUsersCount: 19995,
    },
    1648472100: {
      datePoint: 1648472100,
      cdnDwSize: 70024807512.8,
      cdnUsersCount: 17114,
      p2pDwSize: 45393112137.1,
      p2pUsersCount: 19929,
    },
    1648472160: {
      datePoint: 1648472160,
      cdnDwSize: 71144099979.2,
      cdnUsersCount: 17642,
      p2pDwSize: 45164822985.1,
      p2pUsersCount: 19651,
    },
    1648472220: {
      datePoint: 1648472220,
      cdnDwSize: 71828765375.5,
      cdnUsersCount: 17580,
      p2pDwSize: 45445845460.3,
      p2pUsersCount: 19572,
    },
    1648472280: {
      datePoint: 1648472280,
      cdnDwSize: 71164126437.9,
      cdnUsersCount: 17494,
      p2pDwSize: 45898792757.3,
      p2pUsersCount: 19693,
    },
    1648472340: {
      datePoint: 1648472340,
      cdnDwSize: 71685152872.1,
      cdnUsersCount: 17397,
      p2pDwSize: 46153102362.7,
      p2pUsersCount: 19830,
    },
    1648472400: {
      datePoint: 1648472400,
      cdnDwSize: 72034818134.4,
      cdnUsersCount: 17570,
      p2pDwSize: 45389280045.3,
      p2pUsersCount: 19798,
    },
    1648472460: {
      datePoint: 1648472460,
      cdnDwSize: 71428628355.1,
      cdnUsersCount: 17518,
      p2pDwSize: 45733974285.3,
      p2pUsersCount: 19863,
    },
    1648472520: {
      datePoint: 1648472520,
      cdnDwSize: 71030918993.6,
      cdnUsersCount: 17686,
      p2pDwSize: 45214885680.5,
      p2pUsersCount: 20403,
    },
    1648472580: {
      datePoint: 1648472580,
      cdnDwSize: 70121102091.6,
      cdnUsersCount: 16598,
      p2pDwSize: 46420531309.9,
      p2pUsersCount: 22085,
    },
    1648472640: {
      datePoint: 1648472640,
      cdnDwSize: 69664667897.1,
      cdnUsersCount: 15369,
      p2pDwSize: 48311158323.2,
      p2pUsersCount: 22040,
    },
    1648472700: {
      datePoint: 1648472700,
      cdnDwSize: 69928370784.5,
      cdnUsersCount: 15218,
      p2pDwSize: 48544805149.3,
      p2pUsersCount: 22185,
    },
    1648472760: {
      datePoint: 1648472760,
      cdnDwSize: 70326307449.3,
      cdnUsersCount: 15433,
      p2pDwSize: 48422757429.3,
      p2pUsersCount: 21955,
    },
    1648472820: {
      datePoint: 1648472820,
      cdnDwSize: 69541358194.5,
      cdnUsersCount: 15358,
      p2pDwSize: 48466808110.4,
      p2pUsersCount: 22032,
    },
    1648472880: {
      datePoint: 1648472880,
      cdnDwSize: 70523776435.2,
      cdnUsersCount: 15535,
      p2pDwSize: 48537183403.7,
      p2pUsersCount: 21944,
    },
    1648472940: {
      datePoint: 1648472940,
      cdnDwSize: 70949520753.1,
      cdnUsersCount: 15719,
      p2pDwSize: 48396149037.3,
      p2pUsersCount: 21834,
    },
    1648473000: {
      datePoint: 1648473000,
      cdnDwSize: 71221581071.2,
      cdnUsersCount: 15948,
      p2pDwSize: 48455871548.8,
      p2pUsersCount: 21820,
    },
    1648473060: {
      datePoint: 1648473060,
      cdnDwSize: 71414469899.6,
      cdnUsersCount: 16057,
      p2pDwSize: 48443153348.8,
      p2pUsersCount: 21792,
    },
    1648473120: {
      datePoint: 1648473120,
      cdnDwSize: 72565581377.9,
      cdnUsersCount: 16049,
      p2pDwSize: 48198089257.6,
      p2pUsersCount: 22022,
    },
    1648473180: {
      datePoint: 1648473180,
      cdnDwSize: 70614546638.9,
      cdnUsersCount: 15835,
      p2pDwSize: 48217822464.5,
      p2pUsersCount: 21934,
    },
    1648473240: {
      datePoint: 1648473240,
      cdnDwSize: 67362121126.3,
      cdnUsersCount: 16065,
      p2pDwSize: 46605003402.1,
      p2pUsersCount: 21470,
    },
    1648473300: {
      datePoint: 1648473300,
      cdnDwSize: 70984600329.5,
      cdnUsersCount: 16160,
      p2pDwSize: 47015075168.5,
      p2pUsersCount: 21505,
    },
    1648473360: {
      datePoint: 1648473360,
      cdnDwSize: 69273704901.9,
      cdnUsersCount: 16366,
      p2pDwSize: 46677730801.1,
      p2pUsersCount: 21378,
    },
    1648473420: {
      datePoint: 1648473420,
      cdnDwSize: 70210114076.8,
      cdnUsersCount: 16562,
      p2pDwSize: 46927553648.5,
      p2pUsersCount: 21084,
    },
    1648473480: {
      datePoint: 1648473480,
      cdnDwSize: 71287701250.1,
      cdnUsersCount: 16784,
      p2pDwSize: 46768516101.3,
      p2pUsersCount: 20976,
    },
    1648473540: {
      datePoint: 1648473540,
      cdnDwSize: 70987239925.9,
      cdnUsersCount: 16841,
      p2pDwSize: 47100124137.6,
      p2pUsersCount: 20959,
    },
    1648473600: {
      datePoint: 1648473600,
      cdnDwSize: 71091253931.2,
      cdnUsersCount: 16966,
      p2pDwSize: 47101985036.8,
      p2pUsersCount: 20853,
    },
    1648473660: {
      datePoint: 1648473660,
      cdnDwSize: 71633655465.9,
      cdnUsersCount: 16958,
      p2pDwSize: 47063428242.1,
      p2pUsersCount: 20863,
    },
    1648473720: {
      datePoint: 1648473720,
      cdnDwSize: 71240338179.3,
      cdnUsersCount: 16980,
      p2pDwSize: 47006202220.3,
      p2pUsersCount: 20842,
    },
    1648473780: {
      datePoint: 1648473780,
      cdnDwSize: 71687012069.6,
      cdnUsersCount: 17087,
      p2pDwSize: 47061869747.2,
      p2pUsersCount: 20943,
    },
    1648473840: {
      datePoint: 1648473840,
      cdnDwSize: 71559921438.8,
      cdnUsersCount: 17164,
      p2pDwSize: 47241895664.5,
      p2pUsersCount: 20790,
    },
    1648473900: {
      datePoint: 1648473900,
      cdnDwSize: 71835435715.7,
      cdnUsersCount: 17104,
      p2pDwSize: 47098230175.5,
      p2pUsersCount: 20623,
    },
    1648473960: {
      datePoint: 1648473960,
      cdnDwSize: 71692798932.7,
      cdnUsersCount: 17129,
      p2pDwSize: 47212286993.1,
      p2pUsersCount: 20539,
    },
    1648474020: {
      datePoint: 1648474020,
      cdnDwSize: 72489435577.1,
      cdnUsersCount: 17180,
      p2pDwSize: 46914907941.3,
      p2pUsersCount: 20556,
    },
    1648474080: {
      datePoint: 1648474080,
      cdnDwSize: 72255566356.5,
      cdnUsersCount: 17212,
      p2pDwSize: 47187265546.7,
      p2pUsersCount: 20478,
    },
    1648474140: {
      datePoint: 1648474140,
      cdnDwSize: 71446952340.3,
      cdnUsersCount: 17067,
      p2pDwSize: 47163456600,
      p2pUsersCount: 20416,
    },
    1648474200: {
      datePoint: 1648474200,
      cdnDwSize: 71636732194.1,
      cdnUsersCount: 17225,
      p2pDwSize: 46853407100.3,
      p2pUsersCount: 20201,
    },
    1648474260: {
      datePoint: 1648474260,
      cdnDwSize: 72017258632.5,
      cdnUsersCount: 17232,
      p2pDwSize: 46997436858.1,
      p2pUsersCount: 20275,
    },
    1648474320: {
      datePoint: 1648474320,
      cdnDwSize: 71463605704.9,
      cdnUsersCount: 17147,
      p2pDwSize: 46775145733.3,
      p2pUsersCount: 20328,
    },
    1648474380: {
      datePoint: 1648474380,
      cdnDwSize: 71511047179.1,
      cdnUsersCount: 17245,
      p2pDwSize: 46373496717.9,
      p2pUsersCount: 20326,
    },
    1648474440: {
      datePoint: 1648474440,
      cdnDwSize: 72295528892.8,
      cdnUsersCount: 17283,
      p2pDwSize: 46245130092.3,
      p2pUsersCount: 20245,
    },
    1648474500: {
      datePoint: 1648474500,
      cdnDwSize: 70902395425.6,
      cdnUsersCount: 17123,
      p2pDwSize: 46611285359.5,
      p2pUsersCount: 20325,
    },
    1648474560: {
      datePoint: 1648474560,
      cdnDwSize: 71191189996.3,
      cdnUsersCount: 17214,
      p2pDwSize: 46312440509.3,
      p2pUsersCount: 20217,
    },
    1648474620: {
      datePoint: 1648474620,
      cdnDwSize: 72455565146.1,
      cdnUsersCount: 17153,
      p2pDwSize: 46835898835.7,
      p2pUsersCount: 20291,
    },
    1648474680: {
      datePoint: 1648474680,
      cdnDwSize: 70792631249.6,
      cdnUsersCount: 16874,
      p2pDwSize: 46754718605.9,
      p2pUsersCount: 20613,
    },
    1648474740: {
      datePoint: 1648474740,
      cdnDwSize: 68280676116.7,
      cdnUsersCount: 16861,
      p2pDwSize: 45312898929.1,
      p2pUsersCount: 20480,
    },
    1648474800: {
      datePoint: 1648474800,
      cdnDwSize: 71206697013.3,
      cdnUsersCount: 17136,
      p2pDwSize: 45671656596.8,
      p2pUsersCount: 20069,
    },
    1648474860: {
      datePoint: 1648474860,
      cdnDwSize: 68321954725.2,
      cdnUsersCount: 16994,
      p2pDwSize: 45045514349.9,
      p2pUsersCount: 20398,
    },
    1648474920: {
      datePoint: 1648474920,
      cdnDwSize: 70583109034.7,
      cdnUsersCount: 17240,
      p2pDwSize: 45263484858.7,
      p2pUsersCount: 19983,
    },
    1648474980: {
      datePoint: 1648474980,
      cdnDwSize: 71445946614.3,
      cdnUsersCount: 17180,
      p2pDwSize: 46244506408.5,
      p2pUsersCount: 20005,
    },
    1648475040: {
      datePoint: 1648475040,
      cdnDwSize: 70944506147.2,
      cdnUsersCount: 17244,
      p2pDwSize: 45830777490.7,
      p2pUsersCount: 19838,
    },
    1648475100: {
      datePoint: 1648475100,
      cdnDwSize: 71889492508.8,
      cdnUsersCount: 17207,
      p2pDwSize: 45643637402.7,
      p2pUsersCount: 19837,
    },
    1648475160: {
      datePoint: 1648475160,
      cdnDwSize: 71410934672.4,
      cdnUsersCount: 17193,
      p2pDwSize: 45582123301.3,
      p2pUsersCount: 19876,
    },
    1648475220: {
      datePoint: 1648475220,
      cdnDwSize: 70794994534.9,
      cdnUsersCount: 17184,
      p2pDwSize: 45795703985.1,
      p2pUsersCount: 19851,
    },
    1648475280: {
      datePoint: 1648475280,
      cdnDwSize: 71125035952.3,
      cdnUsersCount: 17144,
      p2pDwSize: 46010962105.1,
      p2pUsersCount: 19781,
    },
    1648475340: {
      datePoint: 1648475340,
      cdnDwSize: 70428598552.5,
      cdnUsersCount: 17184,
      p2pDwSize: 45668384243.7,
      p2pUsersCount: 19756,
    },
    1648475400: {
      datePoint: 1648475400,
      cdnDwSize: 70277545314,
      cdnUsersCount: 17108,
      p2pDwSize: 45430179420.3,
      p2pUsersCount: 19797,
    },
    1648475460: {
      datePoint: 1648475460,
      cdnDwSize: 70971730426.3,
      cdnUsersCount: 17155,
      p2pDwSize: 45125695823.5,
      p2pUsersCount: 19706,
    },
    1648475520: {
      datePoint: 1648475520,
      cdnDwSize: 70397734938.7,
      cdnUsersCount: 17107,
      p2pDwSize: 45347882570.7,
      p2pUsersCount: 19648,
    },
    1648475580: {
      datePoint: 1648475580,
      cdnDwSize: 70020805069.2,
      cdnUsersCount: 16938,
      p2pDwSize: 45501505793.1,
      p2pUsersCount: 19753,
    },
    1648475640: {
      datePoint: 1648475640,
      cdnDwSize: 69470123540.7,
      cdnUsersCount: 17072,
      p2pDwSize: 45412101841.6,
      p2pUsersCount: 19639,
    },
    1648475700: {
      datePoint: 1648475700,
      cdnDwSize: 61536700888.5,
      cdnUsersCount: 15435,
      p2pDwSize: 40051210376.5,
      p2pUsersCount: 18936,
    },
    1648475760: {
      datePoint: 1648475760,
      cdnDwSize: 65904754750.7,
      cdnUsersCount: 16673,
      p2pDwSize: 43236553508.8,
      p2pUsersCount: 19366,
    },
    1648475820: {
      datePoint: 1648475820,
      cdnDwSize: 69146147678.9,
      cdnUsersCount: 16863,
      p2pDwSize: 45293331312.5,
      p2pUsersCount: 19543,
    },
    1648475880: {
      datePoint: 1648475880,
      cdnDwSize: 68975864999.5,
      cdnUsersCount: 16783,
      p2pDwSize: 45114532909.9,
      p2pUsersCount: 19515,
    },
    1648475940: {
      datePoint: 1648475940,
      cdnDwSize: 68906048039.9,
      cdnUsersCount: 16684,
      p2pDwSize: 45565539570.7,
      p2pUsersCount: 19569,
    },
    1648476000: {
      datePoint: 1648476000,
      cdnDwSize: 68453190255.9,
      cdnUsersCount: 16874,
      p2pDwSize: 44908007214.4,
      p2pUsersCount: 19373,
    },
    1648476060: {
      datePoint: 1648476060,
      cdnDwSize: 66779572671.5,
      cdnUsersCount: 16666,
      p2pDwSize: 44331216997.9,
      p2pUsersCount: 19403,
    },
    1648476120: {
      datePoint: 1648476120,
      cdnDwSize: 67154621691.1,
      cdnUsersCount: 16483,
      p2pDwSize: 44570432233.6,
      p2pUsersCount: 19395,
    },
    1648476180: {
      datePoint: 1648476180,
      cdnDwSize: 67007522543.7,
      cdnUsersCount: 16334,
      p2pDwSize: 45669643994.1,
      p2pUsersCount: 19554,
    },
    1648476240: {
      datePoint: 1648476240,
      cdnDwSize: 67163292904,
      cdnUsersCount: 16307,
      p2pDwSize: 45615669620.3,
      p2pUsersCount: 19620,
    },
    1648476300: {
      datePoint: 1648476300,
      cdnDwSize: 66693725504.5,
      cdnUsersCount: 16352,
      p2pDwSize: 45058549818.7,
      p2pUsersCount: 19704,
    },
    1648476360: {
      datePoint: 1648476360,
      cdnDwSize: 67358799743.7,
      cdnUsersCount: 16328,
      p2pDwSize: 45155060445.9,
      p2pUsersCount: 19711,
    },
    1648476420: {
      datePoint: 1648476420,
      cdnDwSize: 66539146790.9,
      cdnUsersCount: 16021,
      p2pDwSize: 45906505043.7,
      p2pUsersCount: 19941,
    },
    1648476480: {
      datePoint: 1648476480,
      cdnDwSize: 66010433260.5,
      cdnUsersCount: 15975,
      p2pDwSize: 45685431908.3,
      p2pUsersCount: 19868,
    },
    1648476540: {
      datePoint: 1648476540,
      cdnDwSize: 66613425365.3,
      cdnUsersCount: 15927,
      p2pDwSize: 45683947685.9,
      p2pUsersCount: 19910,
    },
    1648476600: {
      datePoint: 1648476600,
      cdnDwSize: 66717542245.7,
      cdnUsersCount: 15958,
      p2pDwSize: 45673720536,
      p2pUsersCount: 19899,
    },
    1648476660: {
      datePoint: 1648476660,
      cdnDwSize: 66162487836,
      cdnUsersCount: 16032,
      p2pDwSize: 46181780709.9,
      p2pUsersCount: 19791,
    },
    1648476720: {
      datePoint: 1648476720,
      cdnDwSize: 67074332406.9,
      cdnUsersCount: 16079,
      p2pDwSize: 45961498352.5,
      p2pUsersCount: 19690,
    },
    1648476780: {
      datePoint: 1648476780,
      cdnDwSize: 67567909385.5,
      cdnUsersCount: 16195,
      p2pDwSize: 46281145528,
      p2pUsersCount: 19654,
    },
    1648476840: {
      datePoint: 1648476840,
      cdnDwSize: 67571590324,
      cdnUsersCount: 16404,
      p2pDwSize: 46422238099.2,
      p2pUsersCount: 19578,
    },
    1648476900: {
      datePoint: 1648476900,
      cdnDwSize: 67403774149.7,
      cdnUsersCount: 16287,
      p2pDwSize: 46415762627.2,
      p2pUsersCount: 19601,
    },
    1648476960: {
      datePoint: 1648476960,
      cdnDwSize: 67199348670.8,
      cdnUsersCount: 16225,
      p2pDwSize: 46444515923.7,
      p2pUsersCount: 19557,
    },
    1648477020: {
      datePoint: 1648477020,
      cdnDwSize: 67881225649.6,
      cdnUsersCount: 16129,
      p2pDwSize: 46905343128,
      p2pUsersCount: 19732,
    },
    1648477080: {
      datePoint: 1648477080,
      cdnDwSize: 65622534968.5,
      cdnUsersCount: 16160,
      p2pDwSize: 45745904038.4,
      p2pUsersCount: 19549,
    },
    1648477140: {
      datePoint: 1648477140,
      cdnDwSize: 66876613828.8,
      cdnUsersCount: 16294,
      p2pDwSize: 45560812273.1,
      p2pUsersCount: 19415,
    },
    1648477200: {
      datePoint: 1648477200,
      cdnDwSize: 67558424059.7,
      cdnUsersCount: 16114,
      p2pDwSize: 46087979764.3,
      p2pUsersCount: 19622,
    },
    1648477260: {
      datePoint: 1648477260,
      cdnDwSize: 65290142287.1,
      cdnUsersCount: 16126,
      p2pDwSize: 45643828912,
      p2pUsersCount: 19678,
    },
    1648477320: {
      datePoint: 1648477320,
      cdnDwSize: 65178437582.3,
      cdnUsersCount: 16154,
      p2pDwSize: 45557825880.5,
      p2pUsersCount: 19744,
    },
    1648477380: {
      datePoint: 1648477380,
      cdnDwSize: 66463526020.7,
      cdnUsersCount: 16175,
      p2pDwSize: 46410913430.4,
      p2pUsersCount: 19717,
    },
    1648477440: {
      datePoint: 1648477440,
      cdnDwSize: 66553255964.1,
      cdnUsersCount: 16192,
      p2pDwSize: 46808263437.3,
      p2pUsersCount: 19763,
    },
    1648477500: {
      datePoint: 1648477500,
      cdnDwSize: 66351056275.2,
      cdnUsersCount: 16238,
      p2pDwSize: 46405333690.7,
      p2pUsersCount: 19759,
    },
    1648477560: {
      datePoint: 1648477560,
      cdnDwSize: 66794636733.1,
      cdnUsersCount: 16264,
      p2pDwSize: 46574608489.6,
      p2pUsersCount: 19684,
    },
    1648477620: {
      datePoint: 1648477620,
      cdnDwSize: 66397509920.9,
      cdnUsersCount: 16168,
      p2pDwSize: 46328929162.1,
      p2pUsersCount: 19722,
    },
    1648477680: {
      datePoint: 1648477680,
      cdnDwSize: 66699904912.9,
      cdnUsersCount: 16160,
      p2pDwSize: 46697994268.3,
      p2pUsersCount: 19752,
    },
    1648477740: {
      datePoint: 1648477740,
      cdnDwSize: 66533301868.5,
      cdnUsersCount: 16210,
      p2pDwSize: 47155801340.3,
      p2pUsersCount: 19749,
    },
    1648477800: {
      datePoint: 1648477800,
      cdnDwSize: 66947539841.2,
      cdnUsersCount: 16309,
      p2pDwSize: 46940942582.4,
      p2pUsersCount: 19833,
    },
    1648477860: {
      datePoint: 1648477860,
      cdnDwSize: 67012294409.9,
      cdnUsersCount: 16310,
      p2pDwSize: 47401110708.8,
      p2pUsersCount: 19832,
    },
    1648477920: {
      datePoint: 1648477920,
      cdnDwSize: 66007654347.2,
      cdnUsersCount: 16644,
      p2pDwSize: 46806910664.5,
      p2pUsersCount: 20807,
    },
    1648477980: {
      datePoint: 1648477980,
      cdnDwSize: 66852122623.5,
      cdnUsersCount: 15107,
      p2pDwSize: 49224336613.9,
      p2pUsersCount: 21671,
    },
    1648478040: {
      datePoint: 1648478040,
      cdnDwSize: 67325044418,
      cdnUsersCount: 14669,
      p2pDwSize: 49864009045.3,
      p2pUsersCount: 21836,
    },
    1648478100: {
      datePoint: 1648478100,
      cdnDwSize: 67411736957.9,
      cdnUsersCount: 14718,
      p2pDwSize: 50131441702.4,
      p2pUsersCount: 21902,
    },
    1648478160: {
      datePoint: 1648478160,
      cdnDwSize: 68509064382.3,
      cdnUsersCount: 14782,
      p2pDwSize: 50125520529.6,
      p2pUsersCount: 21923,
    },
    1648478220: {
      datePoint: 1648478220,
      cdnDwSize: 67759740297.9,
      cdnUsersCount: 14929,
      p2pDwSize: 50673898907.7,
      p2pUsersCount: 21930,
    },
    1648478280: {
      datePoint: 1648478280,
      cdnDwSize: 63631260749.1,
      cdnUsersCount: 14757,
      p2pDwSize: 47629181906.1,
      p2pUsersCount: 21561,
    },
    1648478340: {
      datePoint: 1648478340,
      cdnDwSize: 61766325264.3,
      cdnUsersCount: 14290,
      p2pDwSize: 45905086245.3,
      p2pUsersCount: 21260,
    },
    1648478400: {
      datePoint: 1648478400,
      cdnDwSize: 68244208604.8,
      cdnUsersCount: 15327,
      p2pDwSize: 50324255730.7,
      p2pUsersCount: 21466,
    },
    1648478460: {
      datePoint: 1648478460,
      cdnDwSize: 68756927392.4,
      cdnUsersCount: 15517,
      p2pDwSize: 49849931931.2,
      p2pUsersCount: 21297,
    },
    1648478520: {
      datePoint: 1648478520,
      cdnDwSize: 69170319362.9,
      cdnUsersCount: 15661,
      p2pDwSize: 50153658364.3,
      p2pUsersCount: 21297,
    },
    1648478580: {
      datePoint: 1648478580,
      cdnDwSize: 69453632733.3,
      cdnUsersCount: 15702,
      p2pDwSize: 50273662323.7,
      p2pUsersCount: 21320,
    },
    1648478640: {
      datePoint: 1648478640,
      cdnDwSize: 69581210280.7,
      cdnUsersCount: 15747,
      p2pDwSize: 50438165532.3,
      p2pUsersCount: 21364,
    },
    1648478700: {
      datePoint: 1648478700,
      cdnDwSize: 69933825465.5,
      cdnUsersCount: 15975,
      p2pDwSize: 49991038289.1,
      p2pUsersCount: 21146,
    },
    1648478760: {
      datePoint: 1648478760,
      cdnDwSize: 70135967201.5,
      cdnUsersCount: 16011,
      p2pDwSize: 50118488076.3,
      p2pUsersCount: 21031,
    },
    1648478820: {
      datePoint: 1648478820,
      cdnDwSize: 70220235595.7,
      cdnUsersCount: 16068,
      p2pDwSize: 50473421798.9,
      p2pUsersCount: 21011,
    },
    1648478880: {
      datePoint: 1648478880,
      cdnDwSize: 70924567318.4,
      cdnUsersCount: 16083,
      p2pDwSize: 50195627258.7,
      p2pUsersCount: 21033,
    },
    1648478940: {
      datePoint: 1648478940,
      cdnDwSize: 70206127950.4,
      cdnUsersCount: 16145,
      p2pDwSize: 50613883442.1,
      p2pUsersCount: 21074,
    },
    1648479000: {
      datePoint: 1648479000,
      cdnDwSize: 70317021726,
      cdnUsersCount: 16134,
      p2pDwSize: 50566763974.4,
      p2pUsersCount: 21143,
    },
    1648479060: {
      datePoint: 1648479060,
      cdnDwSize: 70433840342.3,
      cdnUsersCount: 16174,
      p2pDwSize: 50942823908.8,
      p2pUsersCount: 21074,
    },
    1648479120: {
      datePoint: 1648479120,
      cdnDwSize: 70370078012.8,
      cdnUsersCount: 16224,
      p2pDwSize: 50535155409.1,
      p2pUsersCount: 21063,
    },
    1648479180: {
      datePoint: 1648479180,
      cdnDwSize: 70502601418.7,
      cdnUsersCount: 16163,
      p2pDwSize: 50528153161.1,
      p2pUsersCount: 20956,
    },
    1648479240: {
      datePoint: 1648479240,
      cdnDwSize: 71222350896,
      cdnUsersCount: 16111,
      p2pDwSize: 50511018665.6,
      p2pUsersCount: 20983,
    },
    1648479300: {
      datePoint: 1648479300,
      cdnDwSize: 67488730880.5,
      cdnUsersCount: 15969,
      p2pDwSize: 48824640341.9,
      p2pUsersCount: 20932,
    },
    1648479360: {
      datePoint: 1648479360,
      cdnDwSize: 70025621755.7,
      cdnUsersCount: 15876,
      p2pDwSize: 49560054029.3,
      p2pUsersCount: 20600,
    },
    1648479420: {
      datePoint: 1648479420,
      cdnDwSize: 65965616043.2,
      cdnUsersCount: 15552,
      p2pDwSize: 48713323411.2,
      p2pUsersCount: 20802,
    },
    1648479480: {
      datePoint: 1648479480,
      cdnDwSize: 65895019209.5,
      cdnUsersCount: 15595,
      p2pDwSize: 48243672264,
      p2pUsersCount: 20697,
    },
    1648479540: {
      datePoint: 1648479540,
      cdnDwSize: 67558682094.8,
      cdnUsersCount: 15934,
      p2pDwSize: 48084615616,
      p2pUsersCount: 20185,
    },
    1648479600: {
      datePoint: 1648479600,
      cdnDwSize: 66606855011.3,
      cdnUsersCount: 16457,
      p2pDwSize: 47186215102.9,
      p2pUsersCount: 21160,
    },
    1648479660: {
      datePoint: 1648479660,
      cdnDwSize: 67289579621.7,
      cdnUsersCount: 15304,
      p2pDwSize: 48317039113.6,
      p2pUsersCount: 21253,
    },
    1648479720: {
      datePoint: 1648479720,
      cdnDwSize: 67132447390.7,
      cdnUsersCount: 14567,
      p2pDwSize: 49067770589.9,
      p2pUsersCount: 21721,
    },
    1648479780: {
      datePoint: 1648479780,
      cdnDwSize: 66646091167.5,
      cdnUsersCount: 14293,
      p2pDwSize: 49713313684.8,
      p2pUsersCount: 21843,
    },
    1648479840: {
      datePoint: 1648479840,
      cdnDwSize: 67424707280,
      cdnUsersCount: 14550,
      p2pDwSize: 49542389524.3,
      p2pUsersCount: 21616,
    },
    1648479900: {
      datePoint: 1648479900,
      cdnDwSize: 66388366579.7,
      cdnUsersCount: 14505,
      p2pDwSize: 49872564499.2,
      p2pUsersCount: 21677,
    },
    1648479960: {
      datePoint: 1648479960,
      cdnDwSize: 66653250608.5,
      cdnUsersCount: 14602,
      p2pDwSize: 49756892836.8,
      p2pUsersCount: 21594,
    },
    1648480020: {
      datePoint: 1648480020,
      cdnDwSize: 66362518760.5,
      cdnUsersCount: 14678,
      p2pDwSize: 49664459378.1,
      p2pUsersCount: 21437,
    },
    1648480080: {
      datePoint: 1648480080,
      cdnDwSize: 67042772368.3,
      cdnUsersCount: 14823,
      p2pDwSize: 49844760402.1,
      p2pUsersCount: 21292,
    },
    1648480140: {
      datePoint: 1648480140,
      cdnDwSize: 66747635230,
      cdnUsersCount: 14793,
      p2pDwSize: 49763824196.3,
      p2pUsersCount: 21345,
    },
    1648480200: {
      datePoint: 1648480200,
      cdnDwSize: 67014418812.3,
      cdnUsersCount: 15005,
      p2pDwSize: 49312552731.2,
      p2pUsersCount: 21139,
    },
    1648480260: {
      datePoint: 1648480260,
      cdnDwSize: 66823910389.7,
      cdnUsersCount: 15148,
      p2pDwSize: 49333322720.5,
      p2pUsersCount: 20967,
    },
    1648480320: {
      datePoint: 1648480320,
      cdnDwSize: 67840036352.4,
      cdnUsersCount: 15174,
      p2pDwSize: 49141731444.3,
      p2pUsersCount: 20842,
    },
    1648480380: {
      datePoint: 1648480380,
      cdnDwSize: 68522177584,
      cdnUsersCount: 15273,
      p2pDwSize: 49352339221.3,
      p2pUsersCount: 20845,
    },
    1648480440: {
      datePoint: 1648480440,
      cdnDwSize: 67663542001.3,
      cdnUsersCount: 15365,
      p2pDwSize: 49345096859.7,
      p2pUsersCount: 20735,
    },
    1648480500: {
      datePoint: 1648480500,
      cdnDwSize: 68454190341.9,
      cdnUsersCount: 15414,
      p2pDwSize: 48890755229.3,
      p2pUsersCount: 20730,
    },
    1648480560: {
      datePoint: 1648480560,
      cdnDwSize: 68332659494.1,
      cdnUsersCount: 15425,
      p2pDwSize: 48847468304.5,
      p2pUsersCount: 20682,
    },
    1648480620: {
      datePoint: 1648480620,
      cdnDwSize: 68079155884.8,
      cdnUsersCount: 15473,
      p2pDwSize: 48556702290.7,
      p2pUsersCount: 20599,
    },
    1648480680: {
      datePoint: 1648480680,
      cdnDwSize: 68946872614.7,
      cdnUsersCount: 15691,
      p2pDwSize: 48435985034.1,
      p2pUsersCount: 20267,
    },
    1648480740: {
      datePoint: 1648480740,
      cdnDwSize: 68571159354.7,
      cdnUsersCount: 15649,
      p2pDwSize: 48703530817.1,
      p2pUsersCount: 20340,
    },
    1648480800: {
      datePoint: 1648480800,
      cdnDwSize: 68602487735.9,
      cdnUsersCount: 15652,
      p2pDwSize: 48692341533.3,
      p2pUsersCount: 20417,
    },
    1648480860: {
      datePoint: 1648480860,
      cdnDwSize: 68510962275.1,
      cdnUsersCount: 15689,
      p2pDwSize: 48965492373.3,
      p2pUsersCount: 20444,
    },
    1648480920: {
      datePoint: 1648480920,
      cdnDwSize: 68438211319.5,
      cdnUsersCount: 15636,
      p2pDwSize: 49007372832.5,
      p2pUsersCount: 20419,
    },
    1648480980: {
      datePoint: 1648480980,
      cdnDwSize: 68701101294.9,
      cdnUsersCount: 15669,
      p2pDwSize: 49041419281.6,
      p2pUsersCount: 20461,
    },
    1648481040: {
      datePoint: 1648481040,
      cdnDwSize: 68015878417.5,
      cdnUsersCount: 16248,
      p2pDwSize: 47967669163.2,
      p2pUsersCount: 21420,
    },
    1648481100: {
      datePoint: 1648481100,
      cdnDwSize: 69435937897.6,
      cdnUsersCount: 14865,
      p2pDwSize: 50158226964.8,
      p2pUsersCount: 21625,
    },
    1648481160: {
      datePoint: 1648481160,
      cdnDwSize: 68563925114.7,
      cdnUsersCount: 14607,
      p2pDwSize: 50511246972.8,
      p2pUsersCount: 21846,
    },
    1648481220: {
      datePoint: 1648481220,
      cdnDwSize: 68949191810.1,
      cdnUsersCount: 14826,
      p2pDwSize: 50018571540.8,
      p2pUsersCount: 21568,
    },
    1648481280: {
      datePoint: 1648481280,
      cdnDwSize: 68033225003.2,
      cdnUsersCount: 14842,
      p2pDwSize: 50422658214.4,
      p2pUsersCount: 21458,
    },
    1648481340: {
      datePoint: 1648481340,
      cdnDwSize: 68142974057.9,
      cdnUsersCount: 14836,
      p2pDwSize: 49946011537.1,
      p2pUsersCount: 21417,
    },
    1648481400: {
      datePoint: 1648481400,
      cdnDwSize: 68500478448.5,
      cdnUsersCount: 14962,
      p2pDwSize: 49882623326.4,
      p2pUsersCount: 21279,
    },
    1648481460: {
      datePoint: 1648481460,
      cdnDwSize: 68098342711.2,
      cdnUsersCount: 15028,
      p2pDwSize: 49669756215.5,
      p2pUsersCount: 21041,
    },
    1648481520: {
      datePoint: 1648481520,
      cdnDwSize: 67137677227.2,
      cdnUsersCount: 15045,
      p2pDwSize: 50163065157.3,
      p2pUsersCount: 21002,
    },
    1648481580: {
      datePoint: 1648481580,
      cdnDwSize: 67586677296.7,
      cdnUsersCount: 14964,
      p2pDwSize: 49856156310.4,
      p2pUsersCount: 21007,
    },
    1648481640: {
      datePoint: 1648481640,
      cdnDwSize: 68394962718,
      cdnUsersCount: 14965,
      p2pDwSize: 49866473499.7,
      p2pUsersCount: 21101,
    },
    1648481700: {
      datePoint: 1648481700,
      cdnDwSize: 68546882638.9,
      cdnUsersCount: 15141,
      p2pDwSize: 49413634064.5,
      p2pUsersCount: 21040,
    },
    1648481760: {
      datePoint: 1648481760,
      cdnDwSize: 68068650946.1,
      cdnUsersCount: 15218,
      p2pDwSize: 49999272513.6,
      p2pUsersCount: 20976,
    },
    1648481820: {
      datePoint: 1648481820,
      cdnDwSize: 67984964725.2,
      cdnUsersCount: 16036,
      p2pDwSize: 48641293066.1,
      p2pUsersCount: 21580,
    },
    1648481880: {
      datePoint: 1648481880,
      cdnDwSize: 68945712882.7,
      cdnUsersCount: 14797,
      p2pDwSize: 50062513056.5,
      p2pUsersCount: 21688,
    },
    1648481940: {
      datePoint: 1648481940,
      cdnDwSize: 69011912844,
      cdnUsersCount: 14761,
      p2pDwSize: 50725922618.7,
      p2pUsersCount: 21655,
    },
    1648482000: {
      datePoint: 1648482000,
      cdnDwSize: 68944699550.8,
      cdnUsersCount: 14868,
      p2pDwSize: 50868807431.5,
      p2pUsersCount: 21639,
    },
    1648482060: {
      datePoint: 1648482060,
      cdnDwSize: 69020289423.3,
      cdnUsersCount: 14831,
      p2pDwSize: 50725620991.5,
      p2pUsersCount: 21640,
    },
    1648482120: {
      datePoint: 1648482120,
      cdnDwSize: 69079070205.2,
      cdnUsersCount: 15064,
      p2pDwSize: 50857082899.2,
      p2pUsersCount: 21556,
    },
    1648482180: {
      datePoint: 1648482180,
      cdnDwSize: 64904964003.1,
      cdnUsersCount: 14876,
      p2pDwSize: 48365125427.7,
      p2pUsersCount: 21302,
    },
    1648482240: {
      datePoint: 1648482240,
      cdnDwSize: 62801520269.9,
      cdnUsersCount: 14246,
      p2pDwSize: 46216293499.2,
      p2pUsersCount: 20927,
    },
    1648482300: {
      datePoint: 1648482300,
      cdnDwSize: 69735894378,
      cdnUsersCount: 15290,
      p2pDwSize: 50975445167.5,
      p2pUsersCount: 21386,
    },
    1648482360: {
      datePoint: 1648482360,
      cdnDwSize: 70168639998.4,
      cdnUsersCount: 15329,
      p2pDwSize: 51389600669.9,
      p2pUsersCount: 21409,
    },
    1648482420: {
      datePoint: 1648482420,
      cdnDwSize: 69629703030.4,
      cdnUsersCount: 15409,
      p2pDwSize: 51235297840,
      p2pUsersCount: 21361,
    },
    1648482480: {
      datePoint: 1648482480,
      cdnDwSize: 70224715784.9,
      cdnUsersCount: 15491,
      p2pDwSize: 51125493331.7,
      p2pUsersCount: 21343,
    },
    1648482540: {
      datePoint: 1648482540,
      cdnDwSize: 70207600592,
      cdnUsersCount: 15556,
      p2pDwSize: 51155844076.8,
      p2pUsersCount: 21152,
    },
    1648482600: {
      datePoint: 1648482600,
      cdnDwSize: 70028810386.1,
      cdnUsersCount: 15699,
      p2pDwSize: 50878315920,
      p2pUsersCount: 20996,
    },
    1648482660: {
      datePoint: 1648482660,
      cdnDwSize: 70760024304,
      cdnUsersCount: 15824,
      p2pDwSize: 50749451796.3,
      p2pUsersCount: 20880,
    },
    1648482720: {
      datePoint: 1648482720,
      cdnDwSize: 70396501387.7,
      cdnUsersCount: 15763,
      p2pDwSize: 50574992909.9,
      p2pUsersCount: 20961,
    },
    1648482780: {
      datePoint: 1648482780,
      cdnDwSize: 70471937263.9,
      cdnUsersCount: 15822,
      p2pDwSize: 50653509856.5,
      p2pUsersCount: 20895,
    },
    1648482840: {
      datePoint: 1648482840,
      cdnDwSize: 70668502068.8,
      cdnUsersCount: 15537,
      p2pDwSize: 50198246224,
      p2pUsersCount: 21133,
    },
    1648482900: {
      datePoint: 1648482900,
      cdnDwSize: 69304442850.5,
      cdnUsersCount: 15161,
      p2pDwSize: 50088984860.3,
      p2pUsersCount: 21335,
    },
    1648482960: {
      datePoint: 1648482960,
      cdnDwSize: 66440590498.1,
      cdnUsersCount: 15515,
      p2pDwSize: 48679596737.6,
      p2pUsersCount: 20891,
    },
    1648483020: {
      datePoint: 1648483020,
      cdnDwSize: 70311595597.1,
      cdnUsersCount: 15557,
      p2pDwSize: 49277484213.9,
      p2pUsersCount: 20954,
    },
    1648483080: {
      datePoint: 1648483080,
      cdnDwSize: 67986793665.6,
      cdnUsersCount: 15600,
      p2pDwSize: 49544412805.3,
      p2pUsersCount: 20884,
    },
    1648483140: {
      datePoint: 1648483140,
      cdnDwSize: 69677887000.7,
      cdnUsersCount: 16020,
      p2pDwSize: 49043039139.7,
      p2pUsersCount: 20622,
    },
    1648483200: {
      datePoint: 1648483200,
      cdnDwSize: 70044811038.7,
      cdnUsersCount: 16054,
      p2pDwSize: 49214439792.5,
      p2pUsersCount: 20705,
    },
    1648483260: {
      datePoint: 1648483260,
      cdnDwSize: 70907006212.1,
      cdnUsersCount: 16348,
      p2pDwSize: 49652900937.6,
      p2pUsersCount: 20705,
    },
    1648483320: {
      datePoint: 1648483320,
      cdnDwSize: 70000108124.1,
      cdnUsersCount: 16657,
      p2pDwSize: 48909144963.2,
      p2pUsersCount: 21836,
    },
    1648483380: {
      datePoint: 1648483380,
      cdnDwSize: 71146762336.4,
      cdnUsersCount: 15284,
      p2pDwSize: 50349826086.9,
      p2pUsersCount: 21853,
    },
    1648483440: {
      datePoint: 1648483440,
      cdnDwSize: 71440875403.2,
      cdnUsersCount: 15154,
      p2pDwSize: 50471112858.1,
      p2pUsersCount: 21769,
    },
    1648483500: {
      datePoint: 1648483500,
      cdnDwSize: 70653252010.1,
      cdnUsersCount: 15176,
      p2pDwSize: 50890080734.4,
      p2pUsersCount: 21760,
    },
    1648483560: {
      datePoint: 1648483560,
      cdnDwSize: 70522535817.9,
      cdnUsersCount: 15159,
      p2pDwSize: 50653873373.3,
      p2pUsersCount: 21754,
    },
    1648483620: {
      datePoint: 1648483620,
      cdnDwSize: 70521176744.5,
      cdnUsersCount: 15175,
      p2pDwSize: 51505998664,
      p2pUsersCount: 21774,
    },
    1648483680: {
      datePoint: 1648483680,
      cdnDwSize: 70770080372.7,
      cdnUsersCount: 15367,
      p2pDwSize: 51491564249.6,
      p2pUsersCount: 21649,
    },
    1648483740: {
      datePoint: 1648483740,
      cdnDwSize: 71072003661.3,
      cdnUsersCount: 15416,
      p2pDwSize: 51188513588.8,
      p2pUsersCount: 21731,
    },
    1648483800: {
      datePoint: 1648483800,
      cdnDwSize: 70905255656.4,
      cdnUsersCount: 15522,
      p2pDwSize: 50897083283.2,
      p2pUsersCount: 21563,
    },
    1648483860: {
      datePoint: 1648483860,
      cdnDwSize: 70670049083.2,
      cdnUsersCount: 15704,
      p2pDwSize: 51235042736.5,
      p2pUsersCount: 21450,
    },
    1648483920: {
      datePoint: 1648483920,
      cdnDwSize: 71539239585.1,
      cdnUsersCount: 15691,
      p2pDwSize: 51250358870.9,
      p2pUsersCount: 21468,
    },
    1648483980: {
      datePoint: 1648483980,
      cdnDwSize: 71148555205.9,
      cdnUsersCount: 15664,
      p2pDwSize: 51133145708.8,
      p2pUsersCount: 21448,
    },
    1648484040: {
      datePoint: 1648484040,
      cdnDwSize: 71240893185.6,
      cdnUsersCount: 15757,
      p2pDwSize: 51068236853.9,
      p2pUsersCount: 21460,
    },
    1648484100: {
      datePoint: 1648484100,
      cdnDwSize: 71638020749.7,
      cdnUsersCount: 15902,
      p2pDwSize: 50918078321.1,
      p2pUsersCount: 21333,
    },
    1648484160: {
      datePoint: 1648484160,
      cdnDwSize: 71526314759.3,
      cdnUsersCount: 15944,
      p2pDwSize: 50938457972.3,
      p2pUsersCount: 21367,
    },
    1648484220: {
      datePoint: 1648484220,
      cdnDwSize: 71549520603.2,
      cdnUsersCount: 15994,
      p2pDwSize: 50523743207.5,
      p2pUsersCount: 21166,
    },
    1648484280: {
      datePoint: 1648484280,
      cdnDwSize: 72058100282.5,
      cdnUsersCount: 16032,
      p2pDwSize: 50307517915.2,
      p2pUsersCount: 21185,
    },
    1648484340: {
      datePoint: 1648484340,
      cdnDwSize: 72749887717.7,
      cdnUsersCount: 15975,
      p2pDwSize: 50045396007.5,
      p2pUsersCount: 21198,
    },
    1648484400: {
      datePoint: 1648484400,
      cdnDwSize: 71815031323.7,
      cdnUsersCount: 16044,
      p2pDwSize: 50070726826.7,
      p2pUsersCount: 21185,
    },
    1648484460: {
      datePoint: 1648484460,
      cdnDwSize: 72584898362.8,
      cdnUsersCount: 16169,
      p2pDwSize: 50334407906.1,
      p2pUsersCount: 21169,
    },
    1648484520: {
      datePoint: 1648484520,
      cdnDwSize: 73151054574.7,
      cdnUsersCount: 16232,
      p2pDwSize: 50270187983.5,
      p2pUsersCount: 21169,
    },
    1648484580: {
      datePoint: 1648484580,
      cdnDwSize: 73446845178,
      cdnUsersCount: 16332,
      p2pDwSize: 50551370684.8,
      p2pUsersCount: 21023,
    },
    1648484640: {
      datePoint: 1648484640,
      cdnDwSize: 73082889411.7,
      cdnUsersCount: 16414,
      p2pDwSize: 50753117320,
      p2pUsersCount: 21026,
    },
    1648484700: {
      datePoint: 1648484700,
      cdnDwSize: 73484679024.9,
      cdnUsersCount: 16361,
      p2pDwSize: 50983839091.7,
      p2pUsersCount: 21279,
    },
    1648484760: {
      datePoint: 1648484760,
      cdnDwSize: 73230428802.5,
      cdnUsersCount: 16442,
      p2pDwSize: 51030173321.1,
      p2pUsersCount: 21245,
    },
    1648484820: {
      datePoint: 1648484820,
      cdnDwSize: 73798638398.3,
      cdnUsersCount: 16634,
      p2pDwSize: 51062389452.3,
      p2pUsersCount: 21119,
    },
    1648484880: {
      datePoint: 1648484880,
      cdnDwSize: 74168063387.7,
      cdnUsersCount: 16650,
      p2pDwSize: 50873404557.9,
      p2pUsersCount: 21139,
    },
    1648484940: {
      datePoint: 1648484940,
      cdnDwSize: 74218698731.2,
      cdnUsersCount: 16727,
      p2pDwSize: 50774248068.8,
      p2pUsersCount: 21182,
    },
    1648485000: {
      datePoint: 1648485000,
      cdnDwSize: 74485320782.4,
      cdnUsersCount: 16858,
      p2pDwSize: 50881391725.3,
      p2pUsersCount: 21146,
    },
    1648485060: {
      datePoint: 1648485060,
      cdnDwSize: 75541479413.7,
      cdnUsersCount: 16928,
      p2pDwSize: 50840137882.7,
      p2pUsersCount: 21233,
    },
    1648485120: {
      datePoint: 1648485120,
      cdnDwSize: 75551725338.5,
      cdnUsersCount: 17031,
      p2pDwSize: 51134650160,
      p2pUsersCount: 21236,
    },
    1648485180: {
      datePoint: 1648485180,
      cdnDwSize: 75351467287.3,
      cdnUsersCount: 16898,
      p2pDwSize: 50779959358.4,
      p2pUsersCount: 21329,
    },
    1648485240: {
      datePoint: 1648485240,
      cdnDwSize: 75554753518.4,
      cdnUsersCount: 16870,
      p2pDwSize: 50590575402.7,
      p2pUsersCount: 21181,
    },
    1648485300: {
      datePoint: 1648485300,
      cdnDwSize: 74921787974.4,
      cdnUsersCount: 16883,
      p2pDwSize: 50806560882.1,
      p2pUsersCount: 21186,
    },
    1648485360: {
      datePoint: 1648485360,
      cdnDwSize: 75386462309.2,
      cdnUsersCount: 16908,
      p2pDwSize: 51270269249.1,
      p2pUsersCount: 21269,
    },
    1648485420: {
      datePoint: 1648485420,
      cdnDwSize: 75752175351.5,
      cdnUsersCount: 17054,
      p2pDwSize: 51299156401.6,
      p2pUsersCount: 21213,
    },
    1648485480: {
      datePoint: 1648485480,
      cdnDwSize: 76197139867.7,
      cdnUsersCount: 16967,
      p2pDwSize: 50921666589.3,
      p2pUsersCount: 21279,
    },
    1648485540: {
      datePoint: 1648485540,
      cdnDwSize: 76715841391.9,
      cdnUsersCount: 17018,
      p2pDwSize: 50705584528,
      p2pUsersCount: 21200,
    },
    1648485600: {
      datePoint: 1648485600,
      cdnDwSize: 76685499195.7,
      cdnUsersCount: 17147,
      p2pDwSize: 50716278895.5,
      p2pUsersCount: 21111,
    },
    1648485660: {
      datePoint: 1648485660,
      cdnDwSize: 76255290122.7,
      cdnUsersCount: 17011,
      p2pDwSize: 50550411558.9,
      p2pUsersCount: 21164,
    },
    1648485720: {
      datePoint: 1648485720,
      cdnDwSize: 76490893325.7,
      cdnUsersCount: 17010,
      p2pDwSize: 50270747070.4,
      p2pUsersCount: 21093,
    },
    1648485780: {
      datePoint: 1648485780,
      cdnDwSize: 76596190196.8,
      cdnUsersCount: 17052,
      p2pDwSize: 50588824646.4,
      p2pUsersCount: 21151,
    },
    1648485840: {
      datePoint: 1648485840,
      cdnDwSize: 76718102104.4,
      cdnUsersCount: 17088,
      p2pDwSize: 50681344033.6,
      p2pUsersCount: 21201,
    },
    1648485900: {
      datePoint: 1648485900,
      cdnDwSize: 76808875648.4,
      cdnUsersCount: 16728,
      p2pDwSize: 50380464096,
      p2pUsersCount: 21494,
    },
    1648485960: {
      datePoint: 1648485960,
      cdnDwSize: 74922396893.9,
      cdnUsersCount: 16377,
      p2pDwSize: 50567241444.3,
      p2pUsersCount: 21675,
    },
    1648486020: {
      datePoint: 1648486020,
      cdnDwSize: 73025489526.3,
      cdnUsersCount: 16809,
      p2pDwSize: 49625483142.9,
      p2pUsersCount: 21201,
    },
    1648486080: {
      datePoint: 1648486080,
      cdnDwSize: 75701844242.1,
      cdnUsersCount: 16693,
      p2pDwSize: 49791428035.7,
      p2pUsersCount: 21344,
    },
    1648486140: {
      datePoint: 1648486140,
      cdnDwSize: 74159838437.9,
      cdnUsersCount: 16836,
      p2pDwSize: 49283325524.3,
      p2pUsersCount: 21216,
    },
    1648486200: {
      datePoint: 1648486200,
      cdnDwSize: 75620527498,
      cdnUsersCount: 17110,
      p2pDwSize: 49196488524.8,
      p2pUsersCount: 20954,
    },
    1648486260: {
      datePoint: 1648486260,
      cdnDwSize: 75450534457.2,
      cdnUsersCount: 17203,
      p2pDwSize: 49219698603.7,
      p2pUsersCount: 21021,
    },
    1648486320: {
      datePoint: 1648486320,
      cdnDwSize: 75922029742.9,
      cdnUsersCount: 17122,
      p2pDwSize: 49509193837.9,
      p2pUsersCount: 21107,
    },
    1648486380: {
      datePoint: 1648486380,
      cdnDwSize: 75730940592.7,
      cdnUsersCount: 17107,
      p2pDwSize: 49761036381.9,
      p2pUsersCount: 21133,
    },
    1648486440: {
      datePoint: 1648486440,
      cdnDwSize: 75906217663.5,
      cdnUsersCount: 17131,
      p2pDwSize: 49581108625.6,
      p2pUsersCount: 20970,
    },
    1648486500: {
      datePoint: 1648486500,
      cdnDwSize: 76777515244.3,
      cdnUsersCount: 16859,
      p2pDwSize: 49552463241.1,
      p2pUsersCount: 21275,
    },
    1648486560: {
      datePoint: 1648486560,
      cdnDwSize: 75854176531.2,
      cdnUsersCount: 16829,
      p2pDwSize: 49854399137.1,
      p2pUsersCount: 21416,
    },
    1648486620: {
      datePoint: 1648486620,
      cdnDwSize: 73423664752.9,
      cdnUsersCount: 16981,
      p2pDwSize: 49123367654.4,
      p2pUsersCount: 21190,
    },
    1648486680: {
      datePoint: 1648486680,
      cdnDwSize: 77285124168,
      cdnUsersCount: 16839,
      p2pDwSize: 49988327253.9,
      p2pUsersCount: 21530,
    },
    1648486740: {
      datePoint: 1648486740,
      cdnDwSize: 74685707171.3,
      cdnUsersCount: 17009,
      p2pDwSize: 50189168380.8,
      p2pUsersCount: 21585,
    },
    1648486800: {
      datePoint: 1648486800,
      cdnDwSize: 75814058432.9,
      cdnUsersCount: 17354,
      p2pDwSize: 50695106134.9,
      p2pUsersCount: 21572,
    },
    1648486860: {
      datePoint: 1648486860,
      cdnDwSize: 77037852979.5,
      cdnUsersCount: 17468,
      p2pDwSize: 50676051733.3,
      p2pUsersCount: 21780,
    },
    1648486920: {
      datePoint: 1648486920,
      cdnDwSize: 76558275380.9,
      cdnUsersCount: 17170,
      p2pDwSize: 51699914948.8,
      p2pUsersCount: 22104,
    },
    1648486980: {
      datePoint: 1648486980,
      cdnDwSize: 77298316828.7,
      cdnUsersCount: 17246,
      p2pDwSize: 52601590096,
      p2pUsersCount: 22165,
    },
    1648487040: {
      datePoint: 1648487040,
      cdnDwSize: 77552321070.7,
      cdnUsersCount: 17127,
      p2pDwSize: 53222258158.9,
      p2pUsersCount: 22365,
    },
    1648487100: {
      datePoint: 1648487100,
      cdnDwSize: 77561184118.8,
      cdnUsersCount: 17209,
      p2pDwSize: 54403292557.9,
      p2pUsersCount: 22582,
    },
    1648487160: {
      datePoint: 1648487160,
      cdnDwSize: 77243078514.5,
      cdnUsersCount: 17195,
      p2pDwSize: 54340123956.3,
      p2pUsersCount: 22725,
    },
    1648487220: {
      datePoint: 1648487220,
      cdnDwSize: 77964786778.9,
      cdnUsersCount: 17297,
      p2pDwSize: 54387671612.8,
      p2pUsersCount: 22742,
    },
    1648487280: {
      datePoint: 1648487280,
      cdnDwSize: 77817909273.5,
      cdnUsersCount: 17398,
      p2pDwSize: 54779330358.4,
      p2pUsersCount: 22708,
    },
    1648487340: {
      datePoint: 1648487340,
      cdnDwSize: 78274829461.7,
      cdnUsersCount: 17469,
      p2pDwSize: 54669815370.1,
      p2pUsersCount: 22859,
    },
    1648487400: {
      datePoint: 1648487400,
      cdnDwSize: 78672480519.3,
      cdnUsersCount: 17403,
      p2pDwSize: 55393983624.5,
      p2pUsersCount: 23012,
    },
    1648487460: {
      datePoint: 1648487460,
      cdnDwSize: 79047857488.5,
      cdnUsersCount: 17465,
      p2pDwSize: 55430432788.8,
      p2pUsersCount: 23052,
    },
    1648487520: {
      datePoint: 1648487520,
      cdnDwSize: 79213966969.1,
      cdnUsersCount: 17667,
      p2pDwSize: 55585184362.1,
      p2pUsersCount: 23028,
    },
    1648487580: {
      datePoint: 1648487580,
      cdnDwSize: 79208260414.7,
      cdnUsersCount: 17699,
      p2pDwSize: 55668509195.7,
      p2pUsersCount: 22945,
    },
    1648487640: {
      datePoint: 1648487640,
      cdnDwSize: 79018224251.7,
      cdnUsersCount: 17681,
      p2pDwSize: 55234589303.5,
      p2pUsersCount: 23091,
    },
    1648487700: {
      datePoint: 1648487700,
      cdnDwSize: 78777561840.9,
      cdnUsersCount: 17837,
      p2pDwSize: 55674572646.9,
      p2pUsersCount: 23053,
    },
    1648487760: {
      datePoint: 1648487760,
      cdnDwSize: 79314972274,
      cdnUsersCount: 17762,
      p2pDwSize: 55553771993.6,
      p2pUsersCount: 23204,
    },
    1648487820: {
      datePoint: 1648487820,
      cdnDwSize: 78630039594.5,
      cdnUsersCount: 17841,
      p2pDwSize: 55713865198.4,
      p2pUsersCount: 23173,
    },
    1648487880: {
      datePoint: 1648487880,
      cdnDwSize: 79947344690.5,
      cdnUsersCount: 17779,
      p2pDwSize: 55449308790.9,
      p2pUsersCount: 23195,
    },
    1648487940: {
      datePoint: 1648487940,
      cdnDwSize: 79945888667.9,
      cdnUsersCount: 17738,
      p2pDwSize: 55705681257.6,
      p2pUsersCount: 23223,
    },
    1648488000: {
      datePoint: 1648488000,
      cdnDwSize: 79040796935.5,
      cdnUsersCount: 17735,
      p2pDwSize: 55921651797.3,
      p2pUsersCount: 23237,
    },
    1648488060: {
      datePoint: 1648488060,
      cdnDwSize: 79680069074.9,
      cdnUsersCount: 17945,
      p2pDwSize: 55568773716.8,
      p2pUsersCount: 23142,
    },
    1648488120: {
      datePoint: 1648488120,
      cdnDwSize: 79656443668.8,
      cdnUsersCount: 18048,
      p2pDwSize: 55780699424,
      p2pUsersCount: 23037,
    },
    1648488180: {
      datePoint: 1648488180,
      cdnDwSize: 79316989264.5,
      cdnUsersCount: 18012,
      p2pDwSize: 55488982706.7,
      p2pUsersCount: 23075,
    },
    1648488240: {
      datePoint: 1648488240,
      cdnDwSize: 79111885400.5,
      cdnUsersCount: 17906,
      p2pDwSize: 55352931744,
      p2pUsersCount: 22945,
    },
    1648488300: {
      datePoint: 1648488300,
      cdnDwSize: 79918413597.3,
      cdnUsersCount: 17987,
      p2pDwSize: 54869565971.7,
      p2pUsersCount: 22748,
    },
    1648488360: {
      datePoint: 1648488360,
      cdnDwSize: 79707262624.4,
      cdnUsersCount: 17981,
      p2pDwSize: 55000862640,
      p2pUsersCount: 22778,
    },
    1648488420: {
      datePoint: 1648488420,
      cdnDwSize: 80047164896,
      cdnUsersCount: 18072,
      p2pDwSize: 54877558804.3,
      p2pUsersCount: 22648,
    },
    1648488480: {
      datePoint: 1648488480,
      cdnDwSize: 80268102094.9,
      cdnUsersCount: 17990,
      p2pDwSize: 54997686292.3,
      p2pUsersCount: 22799,
    },
    1648488540: {
      datePoint: 1648488540,
      cdnDwSize: 80552149565.3,
      cdnUsersCount: 18086,
      p2pDwSize: 54711662390.4,
      p2pUsersCount: 22814,
    },
    1648488600: {
      datePoint: 1648488600,
      cdnDwSize: 80616822517.9,
      cdnUsersCount: 18640,
      p2pDwSize: 54166331918.4,
      p2pUsersCount: 23005,
    },
    1648488660: {
      datePoint: 1648488660,
      cdnDwSize: 80142960847.5,
      cdnUsersCount: 18130,
      p2pDwSize: 53541499078.9,
      p2pUsersCount: 23711,
    },
    1648488720: {
      datePoint: 1648488720,
      cdnDwSize: 79714347969.3,
      cdnUsersCount: 16886,
      p2pDwSize: 54924529100.3,
      p2pUsersCount: 23889,
    },
    1648488780: {
      datePoint: 1648488780,
      cdnDwSize: 79431273987.1,
      cdnUsersCount: 16818,
      p2pDwSize: 54911036515.7,
      p2pUsersCount: 23886,
    },
    1648488840: {
      datePoint: 1648488840,
      cdnDwSize: 79385244143.5,
      cdnUsersCount: 16859,
      p2pDwSize: 54631075363.2,
      p2pUsersCount: 23692,
    },
    1648488900: {
      datePoint: 1648488900,
      cdnDwSize: 79038467515.2,
      cdnUsersCount: 16849,
      p2pDwSize: 54227220932.3,
      p2pUsersCount: 23544,
    },
    1648488960: {
      datePoint: 1648488960,
      cdnDwSize: 78842397957.7,
      cdnUsersCount: 16800,
      p2pDwSize: 54497673245.3,
      p2pUsersCount: 23549,
    },
    1648489020: {
      datePoint: 1648489020,
      cdnDwSize: 78768946721.6,
      cdnUsersCount: 16873,
      p2pDwSize: 53785527590.9,
      p2pUsersCount: 23376,
    },
    1648489080: {
      datePoint: 1648489080,
      cdnDwSize: 78111087927.7,
      cdnUsersCount: 16968,
      p2pDwSize: 53484919275.7,
      p2pUsersCount: 23162,
    },
    1648489140: {
      datePoint: 1648489140,
      cdnDwSize: 78526277029.1,
      cdnUsersCount: 16720,
      p2pDwSize: 53988781685.3,
      p2pUsersCount: 23455,
    },
    1648489200: {
      datePoint: 1648489200,
      cdnDwSize: 77930582306.9,
      cdnUsersCount: 17067,
      p2pDwSize: 53965269152,
      p2pUsersCount: 23222,
    },
    1648489260: {
      datePoint: 1648489260,
      cdnDwSize: 79389971065.1,
      cdnUsersCount: 16997,
      p2pDwSize: 53946262151.5,
      p2pUsersCount: 23327,
    },
    1648489320: {
      datePoint: 1648489320,
      cdnDwSize: 76417701091.7,
      cdnUsersCount: 17011,
      p2pDwSize: 52748576191.5,
      p2pUsersCount: 23326,
    },
    1648489380: {
      datePoint: 1648489380,
      cdnDwSize: 77670900643.1,
      cdnUsersCount: 17291,
      p2pDwSize: 52595653607.5,
      p2pUsersCount: 22757,
    },
    1648489440: {
      datePoint: 1648489440,
      cdnDwSize: 79279031737.5,
      cdnUsersCount: 17360,
      p2pDwSize: 53353770350.4,
      p2pUsersCount: 22726,
    },
    1648489500: {
      datePoint: 1648489500,
      cdnDwSize: 76468883685.9,
      cdnUsersCount: 17040,
      p2pDwSize: 52730336005.3,
      p2pUsersCount: 23139,
    },
    1648489560: {
      datePoint: 1648489560,
      cdnDwSize: 75222302338.5,
      cdnUsersCount: 17061,
      p2pDwSize: 52202770605.9,
      p2pUsersCount: 23008,
    },
    1648489620: {
      datePoint: 1648489620,
      cdnDwSize: 77536788588.3,
      cdnUsersCount: 17444,
      p2pDwSize: 52284876297.1,
      p2pUsersCount: 22561,
    },
    1648489680: {
      datePoint: 1648489680,
      cdnDwSize: 77447730630.8,
      cdnUsersCount: 17706,
      p2pDwSize: 52123828052.3,
      p2pUsersCount: 22426,
    },
    1648489740: {
      datePoint: 1648489740,
      cdnDwSize: 77601632970.5,
      cdnUsersCount: 17760,
      p2pDwSize: 52564725401.6,
      p2pUsersCount: 22430,
    },
    1648489800: {
      datePoint: 1648489800,
      cdnDwSize: 78222786926.9,
      cdnUsersCount: 17856,
      p2pDwSize: 52725864061.9,
      p2pUsersCount: 22441,
    },
    1648489860: {
      datePoint: 1648489860,
      cdnDwSize: 77950348055.5,
      cdnUsersCount: 17818,
      p2pDwSize: 53114020893.9,
      p2pUsersCount: 22522,
    },
    1648489920: {
      datePoint: 1648489920,
      cdnDwSize: 77883845060.8,
      cdnUsersCount: 17728,
      p2pDwSize: 53189422530.1,
      p2pUsersCount: 22526,
    },
    1648489980: {
      datePoint: 1648489980,
      cdnDwSize: 77780089540.3,
      cdnUsersCount: 17746,
      p2pDwSize: 53375633045.9,
      p2pUsersCount: 22508,
    },
    1648490040: {
      datePoint: 1648490040,
      cdnDwSize: 77525090450,
      cdnUsersCount: 17856,
      p2pDwSize: 53437381320,
      p2pUsersCount: 22339,
    },
    1648490100: {
      datePoint: 1648490100,
      cdnDwSize: 77656118704.4,
      cdnUsersCount: 17900,
      p2pDwSize: 52979608664.5,
      p2pUsersCount: 22304,
    },
    1648490160: {
      datePoint: 1648490160,
      cdnDwSize: 77803305207.9,
      cdnUsersCount: 17747,
      p2pDwSize: 53193690430.9,
      p2pUsersCount: 22313,
    },
    1648490220: {
      datePoint: 1648490220,
      cdnDwSize: 77369324304.5,
      cdnUsersCount: 17743,
      p2pDwSize: 53121161459.7,
      p2pUsersCount: 22286,
    },
    1648490280: {
      datePoint: 1648490280,
      cdnDwSize: 76943685059.1,
      cdnUsersCount: 17670,
      p2pDwSize: 52499494816,
      p2pUsersCount: 22052,
    },
    1648490340: {
      datePoint: 1648490340,
      cdnDwSize: 76840437766.4,
      cdnUsersCount: 17580,
      p2pDwSize: 52458835755.2,
      p2pUsersCount: 22029,
    },
    1648490400: {
      datePoint: 1648490400,
      cdnDwSize: 74357359660.3,
      cdnUsersCount: 17904,
      p2pDwSize: 50791095952,
      p2pUsersCount: 22478,
    },
    1648490460: {
      datePoint: 1648490460,
      cdnDwSize: 74187564226.7,
      cdnUsersCount: 16705,
      p2pDwSize: 52130488491.2,
      p2pUsersCount: 22866,
    },
    1648490520: {
      datePoint: 1648490520,
      cdnDwSize: 74427435156.3,
      cdnUsersCount: 16061,
      p2pDwSize: 53460977450.7,
      p2pUsersCount: 23133,
    },
    1648490580: {
      datePoint: 1648490580,
      cdnDwSize: 74392151491.7,
      cdnUsersCount: 16036,
      p2pDwSize: 53738682929.1,
      p2pUsersCount: 23286,
    },
    1648490640: {
      datePoint: 1648490640,
      cdnDwSize: 75373496201.1,
      cdnUsersCount: 16197,
      p2pDwSize: 53701515504.5,
      p2pUsersCount: 23277,
    },
    1648490700: {
      datePoint: 1648490700,
      cdnDwSize: 75083635394.7,
      cdnUsersCount: 16325,
      p2pDwSize: 53379873273.1,
      p2pUsersCount: 23235,
    },
    1648490760: {
      datePoint: 1648490760,
      cdnDwSize: 75143996379.2,
      cdnUsersCount: 16275,
      p2pDwSize: 53536276890.1,
      p2pUsersCount: 23253,
    },
    1648490820: {
      datePoint: 1648490820,
      cdnDwSize: 75239392064.7,
      cdnUsersCount: 16300,
      p2pDwSize: 53592745071.5,
      p2pUsersCount: 23169,
    },
    1648490880: {
      datePoint: 1648490880,
      cdnDwSize: 76205679528.4,
      cdnUsersCount: 16427,
      p2pDwSize: 52877148890.1,
      p2pUsersCount: 22993,
    },
    1648490940: {
      datePoint: 1648490940,
      cdnDwSize: 75196053654.4,
      cdnUsersCount: 16372,
      p2pDwSize: 53072622667.2,
      p2pUsersCount: 22939,
    },
    1648491000: {
      datePoint: 1648491000,
      cdnDwSize: 74872342770,
      cdnUsersCount: 16496,
      p2pDwSize: 53376416705.1,
      p2pUsersCount: 22851,
    },
    1648491060: {
      datePoint: 1648491060,
      cdnDwSize: 75120106016,
      cdnUsersCount: 16642,
      p2pDwSize: 52886105937.1,
      p2pUsersCount: 22748,
    },
    1648491120: {
      datePoint: 1648491120,
      cdnDwSize: 75450147685.2,
      cdnUsersCount: 16659,
      p2pDwSize: 52736326562.7,
      p2pUsersCount: 22569,
    },
    1648491180: {
      datePoint: 1648491180,
      cdnDwSize: 75906748699.3,
      cdnUsersCount: 16855,
      p2pDwSize: 51974153732.3,
      p2pUsersCount: 22325,
    },
    1648491240: {
      datePoint: 1648491240,
      cdnDwSize: 74688989678.8,
      cdnUsersCount: 16756,
      p2pDwSize: 50653125183.5,
      p2pUsersCount: 22211,
    },
    1648491300: {
      datePoint: 1648491300,
      cdnDwSize: 74775113708.3,
      cdnUsersCount: 16872,
      p2pDwSize: 50013312228.3,
      p2pUsersCount: 21582,
    },
    1648491360: {
      datePoint: 1648491360,
      cdnDwSize: 74556082979.7,
      cdnUsersCount: 16920,
      p2pDwSize: 49977121601.6,
      p2pUsersCount: 21525,
    },
    1648491420: {
      datePoint: 1648491420,
      cdnDwSize: 74031606845.2,
      cdnUsersCount: 16708,
      p2pDwSize: 50058946746.7,
      p2pUsersCount: 21640,
    },
    1648491480: {
      datePoint: 1648491480,
      cdnDwSize: 73280037666,
      cdnUsersCount: 16693,
      p2pDwSize: 50100285490.1,
      p2pUsersCount: 21686,
    },
    1648491540: {
      datePoint: 1648491540,
      cdnDwSize: 74193124042.1,
      cdnUsersCount: 16677,
      p2pDwSize: 50154502333.9,
      p2pUsersCount: 21767,
    },
    1648491600: {
      datePoint: 1648491600,
      cdnDwSize: 74246169629.1,
      cdnUsersCount: 16796,
      p2pDwSize: 50034191859.2,
      p2pUsersCount: 21819,
    },
    1648491660: {
      datePoint: 1648491660,
      cdnDwSize: 73691374397.9,
      cdnUsersCount: 16871,
      p2pDwSize: 49888208154.7,
      p2pUsersCount: 21671,
    },
    1648491720: {
      datePoint: 1648491720,
      cdnDwSize: 73308525580.5,
      cdnUsersCount: 16701,
      p2pDwSize: 49563813828.8,
      p2pUsersCount: 21648,
    },
    1648491780: {
      datePoint: 1648491780,
      cdnDwSize: 73824013035.2,
      cdnUsersCount: 17264,
      p2pDwSize: 48890936661.9,
      p2pUsersCount: 22179,
    },
    1648491840: {
      datePoint: 1648491840,
      cdnDwSize: 74114506974.3,
      cdnUsersCount: 16516,
      p2pDwSize: 49523369915.2,
      p2pUsersCount: 22565,
    },
    1648491900: {
      datePoint: 1648491900,
      cdnDwSize: 74311326230.9,
      cdnUsersCount: 15937,
      p2pDwSize: 50478915158.9,
      p2pUsersCount: 22719,
    },
    1648491960: {
      datePoint: 1648491960,
      cdnDwSize: 74513592346.5,
      cdnUsersCount: 15662,
      p2pDwSize: 50622529085.9,
      p2pUsersCount: 22890,
    },
    1648492020: {
      datePoint: 1648492020,
      cdnDwSize: 74732465933.3,
      cdnUsersCount: 15878,
      p2pDwSize: 51127062906.1,
      p2pUsersCount: 22623,
    },
    1648492080: {
      datePoint: 1648492080,
      cdnDwSize: 74550488425.6,
      cdnUsersCount: 15942,
      p2pDwSize: 51683403610.7,
      p2pUsersCount: 22609,
    },
    1648492140: {
      datePoint: 1648492140,
      cdnDwSize: 73610981637.3,
      cdnUsersCount: 15995,
      p2pDwSize: 50671939672,
      p2pUsersCount: 22339,
    },
    1648492200: {
      datePoint: 1648492200,
      cdnDwSize: 73598181641.2,
      cdnUsersCount: 15953,
      p2pDwSize: 50365969020.3,
      p2pUsersCount: 22022,
    },
    1648492260: {
      datePoint: 1648492260,
      cdnDwSize: 74120289077.2,
      cdnUsersCount: 16095,
      p2pDwSize: 50570507956.8,
      p2pUsersCount: 22049,
    },
    1648492320: {
      datePoint: 1648492320,
      cdnDwSize: 73969947809.6,
      cdnUsersCount: 16134,
      p2pDwSize: 50250987994.7,
      p2pUsersCount: 21923,
    },
    1648492380: {
      datePoint: 1648492380,
      cdnDwSize: 73297763385.1,
      cdnUsersCount: 15774,
      p2pDwSize: 50926501021.9,
      p2pUsersCount: 21960,
    },
    1648492440: {
      datePoint: 1648492440,
      cdnDwSize: 73325506494.9,
      cdnUsersCount: 15874,
      p2pDwSize: 51544866636.3,
      p2pUsersCount: 21890,
    },
    1648492500: {
      datePoint: 1648492500,
      cdnDwSize: 73269080274,
      cdnUsersCount: 15904,
      p2pDwSize: 51967870546.7,
      p2pUsersCount: 21866,
    },
    1648492560: {
      datePoint: 1648492560,
      cdnDwSize: 73679421075.1,
      cdnUsersCount: 15939,
      p2pDwSize: 52610184729.1,
      p2pUsersCount: 21950,
    },
    1648492620: {
      datePoint: 1648492620,
      cdnDwSize: 73987493873.5,
      cdnUsersCount: 16048,
      p2pDwSize: 52253579962.1,
      p2pUsersCount: 21993,
    },
    1648492680: {
      datePoint: 1648492680,
      cdnDwSize: 73890924264.4,
      cdnUsersCount: 16082,
      p2pDwSize: 52356472487.5,
      p2pUsersCount: 21960,
    },
    1648492740: {
      datePoint: 1648492740,
      cdnDwSize: 74054112275.1,
      cdnUsersCount: 16092,
      p2pDwSize: 52544875108.3,
      p2pUsersCount: 21961,
    },
    1648492800: {
      datePoint: 1648492800,
      cdnDwSize: 75004104475.1,
      cdnUsersCount: 16269,
      p2pDwSize: 52114328863.5,
      p2pUsersCount: 21923,
    },
    1648492860: {
      datePoint: 1648492860,
      cdnDwSize: 75170483743.3,
      cdnUsersCount: 16318,
      p2pDwSize: 52135771566.9,
      p2pUsersCount: 21824,
    },
    1648492920: {
      datePoint: 1648492920,
      cdnDwSize: 74873760010.8,
      cdnUsersCount: 16460,
      p2pDwSize: 52492571152,
      p2pUsersCount: 21730,
    },
    1648492980: {
      datePoint: 1648492980,
      cdnDwSize: 75983907184.5,
      cdnUsersCount: 16508,
      p2pDwSize: 52251217453.9,
      p2pUsersCount: 21649,
    },
    1648493040: {
      datePoint: 1648493040,
      cdnDwSize: 75815892411.7,
      cdnUsersCount: 16433,
      p2pDwSize: 52170441925.3,
      p2pUsersCount: 21629,
    },
    1648493100: {
      datePoint: 1648493100,
      cdnDwSize: 75963769952.8,
      cdnUsersCount: 17018,
      p2pDwSize: 51899834850.7,
      p2pUsersCount: 22357,
    },
    1648493160: {
      datePoint: 1648493160,
      cdnDwSize: 76701816843.7,
      cdnUsersCount: 16259,
      p2pDwSize: 53191253825.6,
      p2pUsersCount: 22608,
    },
    1648493220: {
      datePoint: 1648493220,
      cdnDwSize: 76246865541.9,
      cdnUsersCount: 15782,
      p2pDwSize: 53812027945.6,
      p2pUsersCount: 22677,
    },
    1648493280: {
      datePoint: 1648493280,
      cdnDwSize: 76508788945.5,
      cdnUsersCount: 15640,
      p2pDwSize: 53853683907.7,
      p2pUsersCount: 22934,
    },
    1648493340: {
      datePoint: 1648493340,
      cdnDwSize: 76576979653.7,
      cdnUsersCount: 15899,
      p2pDwSize: 54145719247.5,
      p2pUsersCount: 22847,
    },
    1648493400: {
      datePoint: 1648493400,
      cdnDwSize: 77202826290.7,
      cdnUsersCount: 15871,
      p2pDwSize: 54344930715.2,
      p2pUsersCount: 22868,
    },
    1648493460: {
      datePoint: 1648493460,
      cdnDwSize: 77205750266.7,
      cdnUsersCount: 15872,
      p2pDwSize: 54462352958.4,
      p2pUsersCount: 22867,
    },
    1648493520: {
      datePoint: 1648493520,
      cdnDwSize: 76923335363.7,
      cdnUsersCount: 15855,
      p2pDwSize: 54166775949.3,
      p2pUsersCount: 22857,
    },
    1648493580: {
      datePoint: 1648493580,
      cdnDwSize: 78089775245.1,
      cdnUsersCount: 16067,
      p2pDwSize: 54286025753.1,
      p2pUsersCount: 22734,
    },
    1648493640: {
      datePoint: 1648493640,
      cdnDwSize: 77661503652.3,
      cdnUsersCount: 16181,
      p2pDwSize: 54359746970.1,
      p2pUsersCount: 22545,
    },
    1648493700: {
      datePoint: 1648493700,
      cdnDwSize: 77822223874.7,
      cdnUsersCount: 16425,
      p2pDwSize: 54329323030.4,
      p2pUsersCount: 22408,
    },
    1648493760: {
      datePoint: 1648493760,
      cdnDwSize: 78323948548.8,
      cdnUsersCount: 16518,
      p2pDwSize: 54460221289.1,
      p2pUsersCount: 22531,
    },
    1648493820: {
      datePoint: 1648493820,
      cdnDwSize: 79438162557.7,
      cdnUsersCount: 16653,
      p2pDwSize: 54694590336,
      p2pUsersCount: 22588,
    },
    1648493880: {
      datePoint: 1648493880,
      cdnDwSize: 79371397892.1,
      cdnUsersCount: 16730,
      p2pDwSize: 55492519788.8,
      p2pUsersCount: 22561,
    },
    1648493940: {
      datePoint: 1648493940,
      cdnDwSize: 79562208290.4,
      cdnUsersCount: 16717,
      p2pDwSize: 55411584836.3,
      p2pUsersCount: 22672,
    },
    1648494000: {
      datePoint: 1648494000,
      cdnDwSize: 79112823244.8,
      cdnUsersCount: 17473,
      p2pDwSize: 54715699953.6,
      p2pUsersCount: 23257,
    },
    1648494060: {
      datePoint: 1648494060,
      cdnDwSize: 79526565463.9,
      cdnUsersCount: 16557,
      p2pDwSize: 55199801031.5,
      p2pUsersCount: 23247,
    },
    1648494120: {
      datePoint: 1648494120,
      cdnDwSize: 79969914002.1,
      cdnUsersCount: 16047,
      p2pDwSize: 55993813792.5,
      p2pUsersCount: 23410,
    },
    1648494180: {
      datePoint: 1648494180,
      cdnDwSize: 79532144861.9,
      cdnUsersCount: 15791,
      p2pDwSize: 55750838632,
      p2pUsersCount: 23681,
    },
    1648494240: {
      datePoint: 1648494240,
      cdnDwSize: 78373137894.7,
      cdnUsersCount: 16149,
      p2pDwSize: 54290109814.9,
      p2pUsersCount: 22859,
    },
    1648494300: {
      datePoint: 1648494300,
      cdnDwSize: 78495516864.1,
      cdnUsersCount: 15449,
      p2pDwSize: 55042993740.8,
      p2pUsersCount: 23448,
    },
    1648494360: {
      datePoint: 1648494360,
      cdnDwSize: 78297986245.3,
      cdnUsersCount: 15726,
      p2pDwSize: 54674351083.2,
      p2pUsersCount: 23248,
    },
    1648494420: {
      datePoint: 1648494420,
      cdnDwSize: 77529090238.1,
      cdnUsersCount: 16092,
      p2pDwSize: 54158607249.1,
      p2pUsersCount: 22950,
    },
    1648494480: {
      datePoint: 1648494480,
      cdnDwSize: 79265008508.4,
      cdnUsersCount: 16315,
      p2pDwSize: 54541992415.5,
      p2pUsersCount: 22786,
    },
    1648494540: {
      datePoint: 1648494540,
      cdnDwSize: 79145666089.9,
      cdnUsersCount: 16339,
      p2pDwSize: 54462941348.3,
      p2pUsersCount: 22790,
    },
    1648494600: {
      datePoint: 1648494600,
      cdnDwSize: 80770295245.3,
      cdnUsersCount: 16514,
      p2pDwSize: 53944432560.5,
      p2pUsersCount: 22733,
    },
    1648494660: {
      datePoint: 1648494660,
      cdnDwSize: 81005088286.5,
      cdnUsersCount: 16625,
      p2pDwSize: 54258490746.7,
      p2pUsersCount: 22712,
    },
    1648494720: {
      datePoint: 1648494720,
      cdnDwSize: 81121524410.1,
      cdnUsersCount: 16738,
      p2pDwSize: 54259013161.1,
      p2pUsersCount: 22595,
    },
    1648494780: {
      datePoint: 1648494780,
      cdnDwSize: 81173620563.7,
      cdnUsersCount: 16600,
      p2pDwSize: 53937773475.2,
      p2pUsersCount: 22708,
    },
    1648494840: {
      datePoint: 1648494840,
      cdnDwSize: 81361794072.8,
      cdnUsersCount: 16852,
      p2pDwSize: 54233058557.9,
      p2pUsersCount: 22438,
    },
    1648494900: {
      datePoint: 1648494900,
      cdnDwSize: 81384943542.9,
      cdnUsersCount: 16836,
      p2pDwSize: 53918299633.1,
      p2pUsersCount: 22372,
    },
    1648494960: {
      datePoint: 1648494960,
      cdnDwSize: 81997031536.5,
      cdnUsersCount: 16773,
      p2pDwSize: 54025047436.8,
      p2pUsersCount: 22326,
    },
    1648495020: {
      datePoint: 1648495020,
      cdnDwSize: 81952608163.1,
      cdnUsersCount: 17045,
      p2pDwSize: 54271359196.3,
      p2pUsersCount: 22101,
    },
    1648495080: {
      datePoint: 1648495080,
      cdnDwSize: 82093837248.5,
      cdnUsersCount: 17129,
      p2pDwSize: 53880727958.4,
      p2pUsersCount: 21978,
    },
    1648495140: {
      datePoint: 1648495140,
      cdnDwSize: 82512628503.9,
      cdnUsersCount: 17032,
      p2pDwSize: 53709922438.4,
      p2pUsersCount: 21933,
    },
    1648495200: {
      datePoint: 1648495200,
      cdnDwSize: 82071252430.1,
      cdnUsersCount: 17076,
      p2pDwSize: 53390196177.6,
      p2pUsersCount: 21863,
    },
    1648495260: {
      datePoint: 1648495260,
      cdnDwSize: 81662050805.3,
      cdnUsersCount: 16936,
      p2pDwSize: 52979851209.6,
      p2pUsersCount: 21792,
    },
    1648495320: {
      datePoint: 1648495320,
      cdnDwSize: 81418576768,
      cdnUsersCount: 16662,
      p2pDwSize: 53698762758.4,
      p2pUsersCount: 21922,
    },
    1648495380: {
      datePoint: 1648495380,
      cdnDwSize: 81261155768.7,
      cdnUsersCount: 16754,
      p2pDwSize: 54196209956.3,
      p2pUsersCount: 21843,
    },
    1648495440: {
      datePoint: 1648495440,
      cdnDwSize: 81315437361.1,
      cdnUsersCount: 16658,
      p2pDwSize: 54213430555.7,
      p2pUsersCount: 21904,
    },
    1648495500: {
      datePoint: 1648495500,
      cdnDwSize: 81631079284,
      cdnUsersCount: 16743,
      p2pDwSize: 54050288818.1,
      p2pUsersCount: 21873,
    },
    1648495560: {
      datePoint: 1648495560,
      cdnDwSize: 81335513229.3,
      cdnUsersCount: 16823,
      p2pDwSize: 54443306302.4,
      p2pUsersCount: 21721,
    },
    1648495620: {
      datePoint: 1648495620,
      cdnDwSize: 81376643969.1,
      cdnUsersCount: 16741,
      p2pDwSize: 54480320770.1,
      p2pUsersCount: 21840,
    },
    1648495680: {
      datePoint: 1648495680,
      cdnDwSize: 81035363508.8,
      cdnUsersCount: 16675,
      p2pDwSize: 54554907764.8,
      p2pUsersCount: 21860,
    },
    1648495740: {
      datePoint: 1648495740,
      cdnDwSize: 81640354502.4,
      cdnUsersCount: 16759,
      p2pDwSize: 54666563321.1,
      p2pUsersCount: 21793,
    },
    1648495800: {
      datePoint: 1648495800,
      cdnDwSize: 81906657379.7,
      cdnUsersCount: 16915,
      p2pDwSize: 54578225705.6,
      p2pUsersCount: 21632,
    },
    1648495860: {
      datePoint: 1648495860,
      cdnDwSize: 81203865051.2,
      cdnUsersCount: 16794,
      p2pDwSize: 54489757718.4,
      p2pUsersCount: 21672,
    },
    1648495920: {
      datePoint: 1648495920,
      cdnDwSize: 80678807142.8,
      cdnUsersCount: 17079,
      p2pDwSize: 53488171324.8,
      p2pUsersCount: 22525,
    },
    1648495980: {
      datePoint: 1648495980,
      cdnDwSize: 81296273391.1,
      cdnUsersCount: 15993,
      p2pDwSize: 55229909532.3,
      p2pUsersCount: 22698,
    },
    1648496040: {
      datePoint: 1648496040,
      cdnDwSize: 81228597528.5,
      cdnUsersCount: 15774,
      p2pDwSize: 55445318252.8,
      p2pUsersCount: 22690,
    },
    1648496100: {
      datePoint: 1648496100,
      cdnDwSize: 81906105988,
      cdnUsersCount: 15700,
      p2pDwSize: 55749996617.6,
      p2pUsersCount: 22667,
    },
    1648496160: {
      datePoint: 1648496160,
      cdnDwSize: 80916719840.7,
      cdnUsersCount: 15679,
      p2pDwSize: 55532679220.8,
      p2pUsersCount: 22601,
    },
    1648496220: {
      datePoint: 1648496220,
      cdnDwSize: 81362815566.4,
      cdnUsersCount: 15690,
      p2pDwSize: 55843542284.3,
      p2pUsersCount: 22553,
    },
    1648496280: {
      datePoint: 1648496280,
      cdnDwSize: 80842476385.9,
      cdnUsersCount: 15544,
      p2pDwSize: 55205135268.3,
      p2pUsersCount: 22606,
    },
    1648496340: {
      datePoint: 1648496340,
      cdnDwSize: 81492432166.9,
      cdnUsersCount: 15623,
      p2pDwSize: 55587553187.2,
      p2pUsersCount: 22442,
    },
    1648496400: {
      datePoint: 1648496400,
      cdnDwSize: 80165875841.6,
      cdnUsersCount: 15583,
      p2pDwSize: 55410917912.5,
      p2pUsersCount: 22430,
    },
    1648496460: {
      datePoint: 1648496460,
      cdnDwSize: 80383691363.2,
      cdnUsersCount: 15737,
      p2pDwSize: 55312835730.7,
      p2pUsersCount: 22100,
    },
    1648496520: {
      datePoint: 1648496520,
      cdnDwSize: 79491829239.5,
      cdnUsersCount: 15425,
      p2pDwSize: 54787916293.3,
      p2pUsersCount: 22185,
    },
    1648496580: {
      datePoint: 1648496580,
      cdnDwSize: 78235630685.9,
      cdnUsersCount: 14959,
      p2pDwSize: 54852845552.5,
      p2pUsersCount: 22071,
    },
    1648496640: {
      datePoint: 1648496640,
      cdnDwSize: 75649189227.2,
      cdnUsersCount: 15375,
      p2pDwSize: 53436134704.5,
      p2pUsersCount: 21472,
    },
    1648496700: {
      datePoint: 1648496700,
      cdnDwSize: 78257890261.6,
      cdnUsersCount: 15102,
      p2pDwSize: 53940599491.2,
      p2pUsersCount: 21640,
    },
    1648496760: {
      datePoint: 1648496760,
      cdnDwSize: 75879372298.7,
      cdnUsersCount: 15487,
      p2pDwSize: 53208912765.9,
      p2pUsersCount: 21019,
    },
    1648496820: {
      datePoint: 1648496820,
      cdnDwSize: 77205879179.2,
      cdnUsersCount: 15597,
      p2pDwSize: 52931842827.7,
      p2pUsersCount: 20882,
    },
    1648496880: {
      datePoint: 1648496880,
      cdnDwSize: 77891483699.5,
      cdnUsersCount: 15654,
      p2pDwSize: 53331583242.1,
      p2pUsersCount: 20877,
    },
    1648496940: {
      datePoint: 1648496940,
      cdnDwSize: 71445951127.2,
      cdnUsersCount: 14932,
      p2pDwSize: 49203609288,
      p2pUsersCount: 20590,
    },
    1648497000: {
      datePoint: 1648497000,
      cdnDwSize: 66855568333.7,
      cdnUsersCount: 13555,
      p2pDwSize: 46394628068.3,
      p2pUsersCount: 18194,
    },
    1648497060: {
      datePoint: 1648497060,
      cdnDwSize: 67839094548.8,
      cdnUsersCount: 13498,
      p2pDwSize: 47017636856.5,
      p2pUsersCount: 18328,
    },
    1648497120: {
      datePoint: 1648497120,
      cdnDwSize: 66676421032,
      cdnUsersCount: 13610,
      p2pDwSize: 46412086525.3,
      p2pUsersCount: 18127,
    },
    1648497180: {
      datePoint: 1648497180,
      cdnDwSize: 67564161493.9,
      cdnUsersCount: 13497,
      p2pDwSize: 47042019930.1,
      p2pUsersCount: 18275,
    },
    1648497240: {
      datePoint: 1648497240,
      cdnDwSize: 67718910761.1,
      cdnUsersCount: 13514,
      p2pDwSize: 46973075191.5,
      p2pUsersCount: 18110,
    },
    1648497300: {
      datePoint: 1648497300,
      cdnDwSize: 67073899580.8,
      cdnUsersCount: 13562,
      p2pDwSize: 46914178376,
      p2pUsersCount: 17994,
    },
    1648497360: {
      datePoint: 1648497360,
      cdnDwSize: 67294179454.5,
      cdnUsersCount: 13474,
      p2pDwSize: 46782671473.6,
      p2pUsersCount: 17984,
    },
    1648497420: {
      datePoint: 1648497420,
      cdnDwSize: 67447097979.5,
      cdnUsersCount: 13566,
      p2pDwSize: 46540668624,
      p2pUsersCount: 17754,
    },
    1648497480: {
      datePoint: 1648497480,
      cdnDwSize: 66762467629.3,
      cdnUsersCount: 13368,
      p2pDwSize: 46718003709.9,
      p2pUsersCount: 17929,
    },
    1648497540: {
      datePoint: 1648497540,
      cdnDwSize: 67344235033.1,
      cdnUsersCount: 13420,
      p2pDwSize: 46277377205.9,
      p2pUsersCount: 17817,
    },
    1648497600: {
      datePoint: 1648497600,
      cdnDwSize: 59632886427.2,
      cdnUsersCount: 12059,
      p2pDwSize: 40676585959.5,
      p2pUsersCount: 17402,
    },
    1648497660: {
      datePoint: 1648497660,
      cdnDwSize: 78591089304.5,
      cdnUsersCount: 11957,
      p2pDwSize: 46851784083.7,
      p2pUsersCount: 20354,
    },
    1648497720: {
      datePoint: 1648497720,
      cdnDwSize: 66766543870.4,
      cdnUsersCount: 12406,
      p2pDwSize: 45291494778.1,
      p2pUsersCount: 18579,
    },
    1648497780: {
      datePoint: 1648497780,
      cdnDwSize: 64639584839.5,
      cdnUsersCount: 11990,
      p2pDwSize: 45677098845.9,
      p2pUsersCount: 18259,
    },
    1648497840: {
      datePoint: 1648497840,
      cdnDwSize: 64766276861.3,
      cdnUsersCount: 12036,
      p2pDwSize: 45812842014.9,
      p2pUsersCount: 18360,
    },
    1648497900: {
      datePoint: 1648497900,
      cdnDwSize: 64304896285.3,
      cdnUsersCount: 12278,
      p2pDwSize: 45964738369.6,
      p2pUsersCount: 18307,
    },
    1648497960: {
      datePoint: 1648497960,
      cdnDwSize: 64330680334.9,
      cdnUsersCount: 12110,
      p2pDwSize: 45766881730.1,
      p2pUsersCount: 18369,
    },
    1648498020: {
      datePoint: 1648498020,
      cdnDwSize: 63903340918.9,
      cdnUsersCount: 12247,
      p2pDwSize: 45480931749.9,
      p2pUsersCount: 18184,
    },
    1648498080: {
      datePoint: 1648498080,
      cdnDwSize: 63984632068.8,
      cdnUsersCount: 12356,
      p2pDwSize: 45145610563.2,
      p2pUsersCount: 17970,
    },
    1648498140: {
      datePoint: 1648498140,
      cdnDwSize: 63883187293.9,
      cdnUsersCount: 12465,
      p2pDwSize: 44670584496,
      p2pUsersCount: 17805,
    },
    1648498200: {
      datePoint: 1648498200,
      cdnDwSize: 64113026418.1,
      cdnUsersCount: 12385,
      p2pDwSize: 44350226329.6,
      p2pUsersCount: 17571,
    },
    1648498260: {
      datePoint: 1648498260,
      cdnDwSize: 63486257955.7,
      cdnUsersCount: 12426,
      p2pDwSize: 44060843594.7,
      p2pUsersCount: 17394,
    },
    1648498320: {
      datePoint: 1648498320,
      cdnDwSize: 62719155261.6,
      cdnUsersCount: 12277,
      p2pDwSize: 43848271643.7,
      p2pUsersCount: 17312,
    },
    1648498380: {
      datePoint: 1648498380,
      cdnDwSize: 62566943595.7,
      cdnUsersCount: 12177,
      p2pDwSize: 43526279606.9,
      p2pUsersCount: 17272,
    },
    1648498440: {
      datePoint: 1648498440,
      cdnDwSize: 63095697029.9,
      cdnUsersCount: 12358,
      p2pDwSize: 43742587343.5,
      p2pUsersCount: 17214,
    },
    1648498500: {
      datePoint: 1648498500,
      cdnDwSize: 61311739236.3,
      cdnUsersCount: 11958,
      p2pDwSize: 42404314231.5,
      p2pUsersCount: 17407,
    },
    1648498560: {
      datePoint: 1648498560,
      cdnDwSize: 60279483049.1,
      cdnUsersCount: 11720,
      p2pDwSize: 41901336800.5,
      p2pUsersCount: 16968,
    },
    1648498620: {
      datePoint: 1648498620,
      cdnDwSize: 60030548163.2,
      cdnUsersCount: 12163,
      p2pDwSize: 41219225374.4,
      p2pUsersCount: 16479,
    },
    1648498680: {
      datePoint: 1648498680,
      cdnDwSize: 59799850605.9,
      cdnUsersCount: 11636,
      p2pDwSize: 41298761005.3,
      p2pUsersCount: 16613,
    },
    1648498740: {
      datePoint: 1648498740,
      cdnDwSize: 58999233179.2,
      cdnUsersCount: 11794,
      p2pDwSize: 40704095372.8,
      p2pUsersCount: 16333,
    },
    1648498800: {
      datePoint: 1648498800,
      cdnDwSize: 59036189241.6,
      cdnUsersCount: 11747,
      p2pDwSize: 40788999506.7,
      p2pUsersCount: 16213,
    },
    1648498860: {
      datePoint: 1648498860,
      cdnDwSize: 58636445769.1,
      cdnUsersCount: 11758,
      p2pDwSize: 40598140202.1,
      p2pUsersCount: 16191,
    },
    1648498920: {
      datePoint: 1648498920,
      cdnDwSize: 58855851116.5,
      cdnUsersCount: 11773,
      p2pDwSize: 40692251824,
      p2pUsersCount: 15889,
    },
    1648498980: {
      datePoint: 1648498980,
      cdnDwSize: 59185766954.7,
      cdnUsersCount: 11797,
      p2pDwSize: 40527611751.5,
      p2pUsersCount: 15825,
    },
    1648499040: {
      datePoint: 1648499040,
      cdnDwSize: 58716665944.4,
      cdnUsersCount: 11894,
      p2pDwSize: 40392062397.9,
      p2pUsersCount: 15714,
    },
    1648499100: {
      datePoint: 1648499100,
      cdnDwSize: 58630764233.6,
      cdnUsersCount: 11847,
      p2pDwSize: 40051396195.7,
      p2pUsersCount: 15552,
    },
    1648499160: {
      datePoint: 1648499160,
      cdnDwSize: 58282321447.9,
      cdnUsersCount: 11753,
      p2pDwSize: 40070911297.6,
      p2pUsersCount: 15517,
    },
    1648499220: {
      datePoint: 1648499220,
      cdnDwSize: 58528284605.2,
      cdnUsersCount: 11928,
      p2pDwSize: 40175211867.7,
      p2pUsersCount: 15342,
    },
    1648499280: {
      datePoint: 1648499280,
      cdnDwSize: 58261966288,
      cdnUsersCount: 11781,
      p2pDwSize: 39984380261.9,
      p2pUsersCount: 15378,
    },
    1648499340: {
      datePoint: 1648499340,
      cdnDwSize: 58227492275.6,
      cdnUsersCount: 11707,
      p2pDwSize: 40134152843.2,
      p2pUsersCount: 15432,
    },
    1648499400: {
      datePoint: 1648499400,
      cdnDwSize: 58239545182.4,
      cdnUsersCount: 11790,
      p2pDwSize: 39808188667.2,
      p2pUsersCount: 15405,
    },
    1648499460: {
      datePoint: 1648499460,
      cdnDwSize: 57772190542.9,
      cdnUsersCount: 11657,
      p2pDwSize: 39714817439.5,
      p2pUsersCount: 15423,
    },
    1648499520: {
      datePoint: 1648499520,
      cdnDwSize: 58023431963.2,
      cdnUsersCount: 11696,
      p2pDwSize: 39713891652.3,
      p2pUsersCount: 15202,
    },
    1648499580: {
      datePoint: 1648499580,
      cdnDwSize: 57832250176,
      cdnUsersCount: 11908,
      p2pDwSize: 39199371447.5,
      p2pUsersCount: 15159,
    },
    1648499640: {
      datePoint: 1648499640,
      cdnDwSize: 58941214272.4,
      cdnUsersCount: 12390,
      p2pDwSize: 39051127331.2,
      p2pUsersCount: 16734,
    },
    1648499700: {
      datePoint: 1648499700,
      cdnDwSize: 61051953717.3,
      cdnUsersCount: 12755,
      p2pDwSize: 42751022452.8,
      p2pUsersCount: 18869,
    },
    1648499760: {
      datePoint: 1648499760,
      cdnDwSize: 69242214024.5,
      cdnUsersCount: 12731,
      p2pDwSize: 48719572581.3,
      p2pUsersCount: 19091,
    },
    1648499820: {
      datePoint: 1648499820,
      cdnDwSize: 68343148217.1,
      cdnUsersCount: 12593,
      p2pDwSize: 48975202648.5,
      p2pUsersCount: 19064,
    },
    1648499880: {
      datePoint: 1648499880,
      cdnDwSize: 68094689445.1,
      cdnUsersCount: 12692,
      p2pDwSize: 49144690388.8,
      p2pUsersCount: 18999,
    },
    1648499940: {
      datePoint: 1648499940,
      cdnDwSize: 68182446994,
      cdnUsersCount: 12831,
      p2pDwSize: 48848517319.5,
      p2pUsersCount: 18830,
    },
    1648500000: {
      datePoint: 1648500000,
      cdnDwSize: 68311979382.7,
      cdnUsersCount: 12838,
      p2pDwSize: 48383695716.8,
      p2pUsersCount: 18793,
    },
    1648500060: {
      datePoint: 1648500060,
      cdnDwSize: 68511813116.1,
      cdnUsersCount: 12885,
      p2pDwSize: 48288614290.7,
      p2pUsersCount: 18612,
    },
    1648500120: {
      datePoint: 1648500120,
      cdnDwSize: 67941609394.7,
      cdnUsersCount: 12873,
      p2pDwSize: 48080728001.6,
      p2pUsersCount: 18493,
    },
    1648500180: {
      datePoint: 1648500180,
      cdnDwSize: 67418817265.1,
      cdnUsersCount: 12920,
      p2pDwSize: 47758696133.9,
      p2pUsersCount: 18276,
    },
    1648500240: {
      datePoint: 1648500240,
      cdnDwSize: 67188282916.8,
      cdnUsersCount: 12944,
      p2pDwSize: 47568460964.3,
      p2pUsersCount: 18121,
    },
    1648500300: {
      datePoint: 1648500300,
      cdnDwSize: 67438165297.6,
      cdnUsersCount: 12903,
      p2pDwSize: 47307611565.9,
      p2pUsersCount: 18025,
    },
    1648500360: {
      datePoint: 1648500360,
      cdnDwSize: 66781621119.5,
      cdnUsersCount: 12914,
      p2pDwSize: 47346238446.9,
      p2pUsersCount: 17960,
    },
    1648500420: {
      datePoint: 1648500420,
      cdnDwSize: 67055750234.8,
      cdnUsersCount: 12914,
      p2pDwSize: 46766701976.5,
      p2pUsersCount: 17954,
    },
    1648500480: {
      datePoint: 1648500480,
      cdnDwSize: 66794028216.5,
      cdnUsersCount: 12765,
      p2pDwSize: 46789802840,
      p2pUsersCount: 17715,
    },
    1648500540: {
      datePoint: 1648500540,
      cdnDwSize: 66169317878.4,
      cdnUsersCount: 12852,
      p2pDwSize: 46768739119.5,
      p2pUsersCount: 17595,
    },
    1648500600: {
      datePoint: 1648500600,
      cdnDwSize: 66127995503.5,
      cdnUsersCount: 12725,
      p2pDwSize: 46825446587.2,
      p2pUsersCount: 17566,
    },
    1648500660: {
      datePoint: 1648500660,
      cdnDwSize: 64817981323.2,
      cdnUsersCount: 12623,
      p2pDwSize: 45166960194.1,
      p2pUsersCount: 17370,
    },
    1648500720: {
      datePoint: 1648500720,
      cdnDwSize: 63030063171.7,
      cdnUsersCount: 12431,
      p2pDwSize: 44453057692.3,
      p2pUsersCount: 16830,
    },
    1648500780: {
      datePoint: 1648500780,
      cdnDwSize: 63259968897.9,
      cdnUsersCount: 12435,
      p2pDwSize: 43977252452.8,
      p2pUsersCount: 16758,
    },
    1648500840: {
      datePoint: 1648500840,
      cdnDwSize: 63504259657.3,
      cdnUsersCount: 12441,
      p2pDwSize: 44231454973.3,
      p2pUsersCount: 16735,
    },
    1648500900: {
      datePoint: 1648500900,
      cdnDwSize: 62558486779.2,
      cdnUsersCount: 12395,
      p2pDwSize: 44033026964.3,
      p2pUsersCount: 16637,
    },
    1648500960: {
      datePoint: 1648500960,
      cdnDwSize: 63095441123.7,
      cdnUsersCount: 12402,
      p2pDwSize: 43751811300.3,
      p2pUsersCount: 16503,
    },
    1648501020: {
      datePoint: 1648501020,
      cdnDwSize: 63230581088.8,
      cdnUsersCount: 12484,
      p2pDwSize: 43938510315.2,
      p2pUsersCount: 16361,
    },
    1648501080: {
      datePoint: 1648501080,
      cdnDwSize: 62686921936.8,
      cdnUsersCount: 12435,
      p2pDwSize: 43850956358.9,
      p2pUsersCount: 16281,
    },
    1648501140: {
      datePoint: 1648501140,
      cdnDwSize: 62796218793.7,
      cdnUsersCount: 12455,
      p2pDwSize: 43948814169.1,
      p2pUsersCount: 16279,
    },
    1648501200: {
      datePoint: 1648501200,
      cdnDwSize: 62471461779.2,
      cdnUsersCount: 12419,
      p2pDwSize: 43436816197.3,
      p2pUsersCount: 16206,
    },
    1648501260: {
      datePoint: 1648501260,
      cdnDwSize: 62201697095.5,
      cdnUsersCount: 12782,
      p2pDwSize: 42977944368.5,
      p2pUsersCount: 16102,
    },
    1648501320: {
      datePoint: 1648501320,
      cdnDwSize: 62945886923.7,
      cdnUsersCount: 13364,
      p2pDwSize: 43264391521.1,
      p2pUsersCount: 17870,
    },
    1648501380: {
      datePoint: 1648501380,
      cdnDwSize: 61166397086,
      cdnUsersCount: 11412,
      p2pDwSize: 43772005157.3,
      p2pUsersCount: 16798,
    },
    1648501440: {
      datePoint: 1648501440,
      cdnDwSize: 60826292379.2,
      cdnUsersCount: 11221,
      p2pDwSize: 43696300841.1,
      p2pUsersCount: 16711,
    },
    1648501500: {
      datePoint: 1648501500,
      cdnDwSize: 60728294270.7,
      cdnUsersCount: 11077,
      p2pDwSize: 43729711624.5,
      p2pUsersCount: 16713,
    },
    1648501560: {
      datePoint: 1648501560,
      cdnDwSize: 59885382959.7,
      cdnUsersCount: 11179,
      p2pDwSize: 43433984265.6,
      p2pUsersCount: 16552,
    },
    1648501620: {
      datePoint: 1648501620,
      cdnDwSize: 60247677334.4,
      cdnUsersCount: 11124,
      p2pDwSize: 43080110286.4,
      p2pUsersCount: 16478,
    },
    1648501680: {
      datePoint: 1648501680,
      cdnDwSize: 59969754050.7,
      cdnUsersCount: 11180,
      p2pDwSize: 42651526360.5,
      p2pUsersCount: 16342,
    },
    1648501740: {
      datePoint: 1648501740,
      cdnDwSize: 60361905731.9,
      cdnUsersCount: 11285,
      p2pDwSize: 42116081354.7,
      p2pUsersCount: 16104,
    },
    1648501800: {
      datePoint: 1648501800,
      cdnDwSize: 59918150407.5,
      cdnUsersCount: 11229,
      p2pDwSize: 41942935109.3,
      p2pUsersCount: 16015,
    },
    1648501860: {
      datePoint: 1648501860,
      cdnDwSize: 59679353108.3,
      cdnUsersCount: 11420,
      p2pDwSize: 42210747225.6,
      p2pUsersCount: 15911,
    },
    1648501920: {
      datePoint: 1648501920,
      cdnDwSize: 59095620377.9,
      cdnUsersCount: 11283,
      p2pDwSize: 42000933961.6,
      p2pUsersCount: 15740,
    },
    1648501980: {
      datePoint: 1648501980,
      cdnDwSize: 58782327851.9,
      cdnUsersCount: 11248,
      p2pDwSize: 42098493227.7,
      p2pUsersCount: 15640,
    },
    1648502040: {
      datePoint: 1648502040,
      cdnDwSize: 58858662495.7,
      cdnUsersCount: 11303,
      p2pDwSize: 41701148835.7,
      p2pUsersCount: 15476,
    },
    1648502100: {
      datePoint: 1648502100,
      cdnDwSize: 58850200565.6,
      cdnUsersCount: 11298,
      p2pDwSize: 41479327560.5,
      p2pUsersCount: 15392,
    },
    1648502160: {
      datePoint: 1648502160,
      cdnDwSize: 58772247342.7,
      cdnUsersCount: 11336,
      p2pDwSize: 41226731562.7,
      p2pUsersCount: 15276,
    },
    1648502220: {
      datePoint: 1648502220,
      cdnDwSize: 58124949957.1,
      cdnUsersCount: 11206,
      p2pDwSize: 41056752691.2,
      p2pUsersCount: 15249,
    },
    1648502280: {
      datePoint: 1648502280,
      cdnDwSize: 57949826603.7,
      cdnUsersCount: 11176,
      p2pDwSize: 40908140897.1,
      p2pUsersCount: 15146,
    },
    1648502340: {
      datePoint: 1648502340,
      cdnDwSize: 57920041036.8,
      cdnUsersCount: 11065,
      p2pDwSize: 40780398807.5,
      p2pUsersCount: 15156,
    },
    1648502400: {
      datePoint: 1648502400,
      cdnDwSize: 57304613384.4,
      cdnUsersCount: 11124,
      p2pDwSize: 40627157977.1,
      p2pUsersCount: 15053,
    },
    1648502460: {
      datePoint: 1648502460,
      cdnDwSize: 57396354528.8,
      cdnUsersCount: 11158,
      p2pDwSize: 40356979392,
      p2pUsersCount: 14975,
    },
    1648502520: {
      datePoint: 1648502520,
      cdnDwSize: 50709897523.5,
      cdnUsersCount: 11105,
      p2pDwSize: 36219087811.2,
      p2pUsersCount: 14980,
    },
    1648502580: {
      datePoint: 1648502580,
      cdnDwSize: 42144638603.6,
      cdnUsersCount: 9657,
      p2pDwSize: 31111043161.1,
      p2pUsersCount: 12680,
    },
    1648502640: {
      datePoint: 1648502640,
      cdnDwSize: 40538819258.7,
      cdnUsersCount: 9207,
      p2pDwSize: 30025278012.8,
      p2pUsersCount: 12064,
    },
    1648502700: {
      datePoint: 1648502700,
      cdnDwSize: 39223412762.1,
      cdnUsersCount: 8961,
      p2pDwSize: 29482194416.5,
      p2pUsersCount: 11862,
    },
    1648502760: {
      datePoint: 1648502760,
      cdnDwSize: 38580237309.5,
      cdnUsersCount: 9084,
      p2pDwSize: 29282338585.1,
      p2pUsersCount: 11597,
    },
    1648502820: {
      datePoint: 1648502820,
      cdnDwSize: 37713788453.9,
      cdnUsersCount: 8760,
      p2pDwSize: 28997462210.1,
      p2pUsersCount: 11572,
    },
    1648502880: {
      datePoint: 1648502880,
      cdnDwSize: 37096710363.7,
      cdnUsersCount: 8578,
      p2pDwSize: 29039846684.8,
      p2pUsersCount: 11575,
    },
    1648502940: {
      datePoint: 1648502940,
      cdnDwSize: 36123227714.1,
      cdnUsersCount: 8401,
      p2pDwSize: 28915279714.7,
      p2pUsersCount: 11490,
    },
    1648503000: {
      datePoint: 1648503000,
      cdnDwSize: 35729162718.9,
      cdnUsersCount: 8369,
      p2pDwSize: 28642217610.7,
      p2pUsersCount: 11361,
    },
    1648503060: {
      datePoint: 1648503060,
      cdnDwSize: 35853028273.6,
      cdnUsersCount: 8668,
      p2pDwSize: 28468010694.4,
      p2pUsersCount: 11362,
    },
    1648503120: {
      datePoint: 1648503120,
      cdnDwSize: 36444909475.7,
      cdnUsersCount: 9259,
      p2pDwSize: 28176780571.2,
      p2pUsersCount: 12775,
    },
    1648503180: {
      datePoint: 1648503180,
      cdnDwSize: 34560594524.8,
      cdnUsersCount: 7615,
      p2pDwSize: 28544018317.3,
      p2pUsersCount: 11795,
    },
    1648503240: {
      datePoint: 1648503240,
      cdnDwSize: 34161683566.4,
      cdnUsersCount: 7509,
      p2pDwSize: 28969920962.1,
      p2pUsersCount: 11783,
    },
    1648503300: {
      datePoint: 1648503300,
      cdnDwSize: 33402714406.4,
      cdnUsersCount: 7456,
      p2pDwSize: 28574421652.3,
      p2pUsersCount: 11583,
    },
    1648503360: {
      datePoint: 1648503360,
      cdnDwSize: 33568468667.2,
      cdnUsersCount: 7424,
      p2pDwSize: 28037863134.9,
      p2pUsersCount: 11432,
    },
    1648503420: {
      datePoint: 1648503420,
      cdnDwSize: 33021793418.7,
      cdnUsersCount: 7494,
      p2pDwSize: 27834971629.9,
      p2pUsersCount: 11301,
    },
    1648503480: {
      datePoint: 1648503480,
      cdnDwSize: 32832818325.3,
      cdnUsersCount: 7383,
      p2pDwSize: 27739740731.2,
      p2pUsersCount: 11067,
    },
    1648503540: {
      datePoint: 1648503540,
      cdnDwSize: 32137056092.7,
      cdnUsersCount: 7109,
      p2pDwSize: 27520757804.8,
      p2pUsersCount: 11104,
    },
    1648503600: {
      datePoint: 1648503600,
      cdnDwSize: 31698469941.3,
      cdnUsersCount: 7195,
      p2pDwSize: 26799367513.6,
      p2pUsersCount: 10760,
    },
    1648503660: {
      datePoint: 1648503660,
      cdnDwSize: 32061664183.5,
      cdnUsersCount: 7337,
      p2pDwSize: 26842638897.1,
      p2pUsersCount: 10670,
    },
    1648503720: {
      datePoint: 1648503720,
      cdnDwSize: 31739112583.5,
      cdnUsersCount: 7042,
      p2pDwSize: 26622799417.6,
      p2pUsersCount: 10732,
    },
    1648503780: {
      datePoint: 1648503780,
      cdnDwSize: 30673157571.7,
      cdnUsersCount: 7070,
      p2pDwSize: 26545253553.6,
      p2pUsersCount: 10581,
    },
    1648503840: {
      datePoint: 1648503840,
      cdnDwSize: 30489532081.1,
      cdnUsersCount: 7118,
      p2pDwSize: 26157781718.4,
      p2pUsersCount: 10377,
    },
    1648503900: {
      datePoint: 1648503900,
      cdnDwSize: 30509178808,
      cdnUsersCount: 7150,
      p2pDwSize: 25948765248.5,
      p2pUsersCount: 10160,
    },
    1648503960: {
      datePoint: 1648503960,
      cdnDwSize: 30280213482.7,
      cdnUsersCount: 7083,
      p2pDwSize: 25486559528,
      p2pUsersCount: 10093,
    },
    1648504020: {
      datePoint: 1648504020,
      cdnDwSize: 30095809294.7,
      cdnUsersCount: 7101,
      p2pDwSize: 25193614894.9,
      p2pUsersCount: 9901,
    },
    1648504080: {
      datePoint: 1648504080,
      cdnDwSize: 29877938701.7,
      cdnUsersCount: 7041,
      p2pDwSize: 25096682947.2,
      p2pUsersCount: 9844,
    },
    1648504140: {
      datePoint: 1648504140,
      cdnDwSize: 29498901149.3,
      cdnUsersCount: 7075,
      p2pDwSize: 25172440630.4,
      p2pUsersCount: 9804,
    },
    1648504200: {
      datePoint: 1648504200,
      cdnDwSize: 29535385632.5,
      cdnUsersCount: 6938,
      p2pDwSize: 25016252411.2,
      p2pUsersCount: 9787,
    },
    1648504260: {
      datePoint: 1648504260,
      cdnDwSize: 28935719901.9,
      cdnUsersCount: 6911,
      p2pDwSize: 24884695826.7,
      p2pUsersCount: 9681,
    },
    1648504320: {
      datePoint: 1648504320,
      cdnDwSize: 28880548419.2,
      cdnUsersCount: 6893,
      p2pDwSize: 24637823200.5,
      p2pUsersCount: 9556,
    },
    1648504380: {
      datePoint: 1648504380,
      cdnDwSize: 29013879998.3,
      cdnUsersCount: 6884,
      p2pDwSize: 24645602314.7,
      p2pUsersCount: 9521,
    },
    1648504440: {
      datePoint: 1648504440,
      cdnDwSize: 28611376560.4,
      cdnUsersCount: 6778,
      p2pDwSize: 24586662710.4,
      p2pUsersCount: 9510,
    },
    1648504500: {
      datePoint: 1648504500,
      cdnDwSize: 28117705406.4,
      cdnUsersCount: 6818,
      p2pDwSize: 24459379616.5,
      p2pUsersCount: 9455,
    },
    1648504560: {
      datePoint: 1648504560,
      cdnDwSize: 28063209069.3,
      cdnUsersCount: 6734,
      p2pDwSize: 24428084534.9,
      p2pUsersCount: 9421,
    },
    1648504620: {
      datePoint: 1648504620,
      cdnDwSize: 27462582758.9,
      cdnUsersCount: 6633,
      p2pDwSize: 24323208734.9,
      p2pUsersCount: 9474,
    },
    1648504680: {
      datePoint: 1648504680,
      cdnDwSize: 27225829596.3,
      cdnUsersCount: 6665,
      p2pDwSize: 24236327793.6,
      p2pUsersCount: 9360,
    },
    1648504740: {
      datePoint: 1648504740,
      cdnDwSize: 27216143509.2,
      cdnUsersCount: 6661,
      p2pDwSize: 23954884850.1,
      p2pUsersCount: 9320,
    },
    1648504800: {
      datePoint: 1648504800,
      cdnDwSize: 28089383582.4,
      cdnUsersCount: 7721,
      p2pDwSize: 23589243023.5,
      p2pUsersCount: 9925,
    },
    1648504860: {
      datePoint: 1648504860,
      cdnDwSize: 27771757106.1,
      cdnUsersCount: 7003,
      p2pDwSize: 23995707470.9,
      p2pUsersCount: 10302,
    },
    1648504920: {
      datePoint: 1648504920,
      cdnDwSize: 26585813149.9,
      cdnUsersCount: 6009,
      p2pDwSize: 23871100168.5,
      p2pUsersCount: 9800,
    },
    1648504980: {
      datePoint: 1648504980,
      cdnDwSize: 26166709387.2,
      cdnUsersCount: 5949,
      p2pDwSize: 24211164745.6,
      p2pUsersCount: 9788,
    },
    1648505040: {
      datePoint: 1648505040,
      cdnDwSize: 26095282646.3,
      cdnUsersCount: 5952,
      p2pDwSize: 24097313274.1,
      p2pUsersCount: 9693,
    },
    1648505100: {
      datePoint: 1648505100,
      cdnDwSize: 25807949338.1,
      cdnUsersCount: 5945,
      p2pDwSize: 24232657381.9,
      p2pUsersCount: 9686,
    },
    1648505160: {
      datePoint: 1648505160,
      cdnDwSize: 26876879086.4,
      cdnUsersCount: 7106,
      p2pDwSize: 23623057079.5,
      p2pUsersCount: 10157,
    },
    1648505220: {
      datePoint: 1648505220,
      cdnDwSize: 26958933040,
      cdnUsersCount: 6799,
      p2pDwSize: 23635243064,
      p2pUsersCount: 10157,
    },
    1648505280: {
      datePoint: 1648505280,
      cdnDwSize: 25645900094.8,
      cdnUsersCount: 5775,
      p2pDwSize: 24002094658.1,
      p2pUsersCount: 9700,
    },
    1648505340: {
      datePoint: 1648505340,
      cdnDwSize: 25554367994.7,
      cdnUsersCount: 5716,
      p2pDwSize: 24015461082.1,
      p2pUsersCount: 9717,
    },
    1648505400: {
      datePoint: 1648505400,
      cdnDwSize: 25587072750.4,
      cdnUsersCount: 5846,
      p2pDwSize: 23789804055.5,
      p2pUsersCount: 9672,
    },
    1648505460: {
      datePoint: 1648505460,
      cdnDwSize: 25246799192.5,
      cdnUsersCount: 5873,
      p2pDwSize: 23764004489.6,
      p2pUsersCount: 9569,
    },
    1648505520: {
      datePoint: 1648505520,
      cdnDwSize: 24652438295.5,
      cdnUsersCount: 5839,
      p2pDwSize: 23742282067.2,
      p2pUsersCount: 9508,
    },
    1648505580: {
      datePoint: 1648505580,
      cdnDwSize: 24602732173.3,
      cdnUsersCount: 5831,
      p2pDwSize: 23439566998.9,
      p2pUsersCount: 9423,
    },
    1648505640: {
      datePoint: 1648505640,
      cdnDwSize: 24565333634.1,
      cdnUsersCount: 5839,
      p2pDwSize: 23425918625.1,
      p2pUsersCount: 9381,
    },
    1648505700: {
      datePoint: 1648505700,
      cdnDwSize: 24473976738.7,
      cdnUsersCount: 5895,
      p2pDwSize: 23448478976,
      p2pUsersCount: 9195,
    },
    1648505760: {
      datePoint: 1648505760,
      cdnDwSize: 24459406513.1,
      cdnUsersCount: 5879,
      p2pDwSize: 23224393428.8,
      p2pUsersCount: 9189,
    },
    1648505820: {
      datePoint: 1648505820,
      cdnDwSize: 24752068268.8,
      cdnUsersCount: 5993,
      p2pDwSize: 22990100133.3,
      p2pUsersCount: 9075,
    },
    1648505880: {
      datePoint: 1648505880,
      cdnDwSize: 24386575388.3,
      cdnUsersCount: 5967,
      p2pDwSize: 22881014236.3,
      p2pUsersCount: 8982,
    },
    1648505940: {
      datePoint: 1648505940,
      cdnDwSize: 24258121706.1,
      cdnUsersCount: 5959,
      p2pDwSize: 22634861449.6,
      p2pUsersCount: 8943,
    },
    1648506000: {
      datePoint: 1648506000,
      cdnDwSize: 24413727200.5,
      cdnUsersCount: 6011,
      p2pDwSize: 22483911411.7,
      p2pUsersCount: 8872,
    },
    1648506060: {
      datePoint: 1648506060,
      cdnDwSize: 24251104718.9,
      cdnUsersCount: 6005,
      p2pDwSize: 22748141752,
      p2pUsersCount: 8897,
    },
    1648506120: {
      datePoint: 1648506120,
      cdnDwSize: 24588112414.8,
      cdnUsersCount: 5976,
      p2pDwSize: 22632559978.7,
      p2pUsersCount: 8824,
    },
    1648506180: {
      datePoint: 1648506180,
      cdnDwSize: 24036521017.6,
      cdnUsersCount: 5908,
      p2pDwSize: 22453073145.1,
      p2pUsersCount: 8835,
    },
    1648506240: {
      datePoint: 1648506240,
      cdnDwSize: 23868098284.8,
      cdnUsersCount: 5936,
      p2pDwSize: 22199275827.2,
      p2pUsersCount: 8748,
    },
    1648506300: {
      datePoint: 1648506300,
      cdnDwSize: 23169315770.7,
      cdnUsersCount: 5873,
      p2pDwSize: 21982061329.1,
      p2pUsersCount: 8707,
    },
    1648506360: {
      datePoint: 1648506360,
      cdnDwSize: 22729166435.7,
      cdnUsersCount: 5811,
      p2pDwSize: 21716740187.7,
      p2pUsersCount: 8582,
    },
    1648506420: {
      datePoint: 1648506420,
      cdnDwSize: 22439929888.4,
      cdnUsersCount: 5769,
      p2pDwSize: 21400083841.6,
      p2pUsersCount: 8474,
    },
    1648506480: {
      datePoint: 1648506480,
      cdnDwSize: 22382599715.7,
      cdnUsersCount: 5785,
      p2pDwSize: 21202435205.3,
      p2pUsersCount: 8384,
    },
    1648506540: {
      datePoint: 1648506540,
      cdnDwSize: 22265662763.2,
      cdnUsersCount: 5781,
      p2pDwSize: 21157336737.6,
      p2pUsersCount: 8393,
    },
    1648506600: {
      datePoint: 1648506600,
      cdnDwSize: 21850184342.4,
      cdnUsersCount: 5759,
      p2pDwSize: 21100906406.9,
      p2pUsersCount: 8302,
    },
    1648506660: {
      datePoint: 1648506660,
      cdnDwSize: 21735519506.5,
      cdnUsersCount: 5737,
      p2pDwSize: 21158776115.7,
      p2pUsersCount: 8194,
    },
    1648506720: {
      datePoint: 1648506720,
      cdnDwSize: 21462456902.4,
      cdnUsersCount: 5708,
      p2pDwSize: 20849127958.4,
      p2pUsersCount: 8122,
    },
    1648506780: {
      datePoint: 1648506780,
      cdnDwSize: 21344356179.7,
      cdnUsersCount: 5681,
      p2pDwSize: 20782839885.3,
      p2pUsersCount: 8088,
    },
    1648506840: {
      datePoint: 1648506840,
      cdnDwSize: 21290027814.4,
      cdnUsersCount: 5659,
      p2pDwSize: 20596633781.3,
      p2pUsersCount: 8015,
    },
    1648506900: {
      datePoint: 1648506900,
      cdnDwSize: 21047428428.3,
      cdnUsersCount: 5627,
      p2pDwSize: 20574796378.1,
      p2pUsersCount: 7981,
    },
    1648506960: {
      datePoint: 1648506960,
      cdnDwSize: 21024607107.1,
      cdnUsersCount: 5593,
      p2pDwSize: 20426775455.5,
      p2pUsersCount: 7964,
    },
    1648507020: {
      datePoint: 1648507020,
      cdnDwSize: 21074780647.3,
      cdnUsersCount: 5683,
      p2pDwSize: 20381521449.1,
      p2pUsersCount: 7859,
    },
    1648507080: {
      datePoint: 1648507080,
      cdnDwSize: 21001588388.3,
      cdnUsersCount: 5727,
      p2pDwSize: 20144511954.7,
      p2pUsersCount: 7799,
    },
    1648507140: {
      datePoint: 1648507140,
      cdnDwSize: 20890619508.3,
      cdnUsersCount: 5638,
      p2pDwSize: 20033026024.5,
      p2pUsersCount: 7806,
    },
    1648507200: {
      datePoint: 1648507200,
      cdnDwSize: 20817653825.6,
      cdnUsersCount: 5531,
      p2pDwSize: 19939777849.1,
      p2pUsersCount: 7831,
    },
    1648507260: {
      datePoint: 1648507260,
      cdnDwSize: 20492051261.3,
      cdnUsersCount: 5480,
      p2pDwSize: 20040109037.3,
      p2pUsersCount: 7807,
    },
    1648507320: {
      datePoint: 1648507320,
      cdnDwSize: 20568052617.6,
      cdnUsersCount: 5559,
      p2pDwSize: 19716054740.8,
      p2pUsersCount: 7661,
    },
    1648507380: {
      datePoint: 1648507380,
      cdnDwSize: 20270154610.7,
      cdnUsersCount: 5547,
      p2pDwSize: 19569047113.1,
      p2pUsersCount: 7586,
    },
    1648507440: {
      datePoint: 1648507440,
      cdnDwSize: 20229973872,
      cdnUsersCount: 5518,
      p2pDwSize: 19368934423.5,
      p2pUsersCount: 7561,
    },
    1648507500: {
      datePoint: 1648507500,
      cdnDwSize: 20345323929.1,
      cdnUsersCount: 5536,
      p2pDwSize: 19297243004.8,
      p2pUsersCount: 7542,
    },
    1648507560: {
      datePoint: 1648507560,
      cdnDwSize: 20056433678.9,
      cdnUsersCount: 5553,
      p2pDwSize: 19326284016.5,
      p2pUsersCount: 7451,
    },
    1648507620: {
      datePoint: 1648507620,
      cdnDwSize: 20012466545.1,
      cdnUsersCount: 5453,
      p2pDwSize: 19289225657.1,
      p2pUsersCount: 7447,
    },
    1648507680: {
      datePoint: 1648507680,
      cdnDwSize: 19715819239.5,
      cdnUsersCount: 5417,
      p2pDwSize: 19225552087.5,
      p2pUsersCount: 7407,
    },
    1648507740: {
      datePoint: 1648507740,
      cdnDwSize: 19362037054.4,
      cdnUsersCount: 5326,
      p2pDwSize: 19059676378.7,
      p2pUsersCount: 7349,
    },
    1648507800: {
      datePoint: 1648507800,
      cdnDwSize: 19391722906.1,
      cdnUsersCount: 5312,
      p2pDwSize: 19067417592,
      p2pUsersCount: 7313,
    },
    1648507860: {
      datePoint: 1648507860,
      cdnDwSize: 19254864846.9,
      cdnUsersCount: 5356,
      p2pDwSize: 19203561476.8,
      p2pUsersCount: 7389,
    },
    1648507920: {
      datePoint: 1648507920,
      cdnDwSize: 19174075406.4,
      cdnUsersCount: 5225,
      p2pDwSize: 18933548131.2,
      p2pUsersCount: 7332,
    },
    1648507980: {
      datePoint: 1648507980,
      cdnDwSize: 19358952549.7,
      cdnUsersCount: 5241,
      p2pDwSize: 18849561286.9,
      p2pUsersCount: 7338,
    },
    1648508040: {
      datePoint: 1648508040,
      cdnDwSize: 19054985753.6,
      cdnUsersCount: 5213,
      p2pDwSize: 18975020981.3,
      p2pUsersCount: 7306,
    },
    1648508100: {
      datePoint: 1648508100,
      cdnDwSize: 19113184863.5,
      cdnUsersCount: 5226,
      p2pDwSize: 18723706165.9,
      p2pUsersCount: 7203,
    },
    1648508160: {
      datePoint: 1648508160,
      cdnDwSize: 19006115855.5,
      cdnUsersCount: 5223,
      p2pDwSize: 18857615282.1,
      p2pUsersCount: 7177,
    },
    1648508220: {
      datePoint: 1648508220,
      cdnDwSize: 18935824560.5,
      cdnUsersCount: 5193,
      p2pDwSize: 18843373104,
      p2pUsersCount: 7188,
    },
    1648508280: {
      datePoint: 1648508280,
      cdnDwSize: 18822055909.3,
      cdnUsersCount: 5157,
      p2pDwSize: 18877162218.7,
      p2pUsersCount: 7200,
    },
    1648508340: {
      datePoint: 1648508340,
      cdnDwSize: 18746077915.2,
      cdnUsersCount: 5154,
      p2pDwSize: 18954523015.5,
      p2pUsersCount: 7162,
    },
    1648508400: {
      datePoint: 1648508400,
      cdnDwSize: 18577879654.4,
      cdnUsersCount: 5122,
      p2pDwSize: 18725046706.1,
      p2pUsersCount: 7141,
    },
    1648508460: {
      datePoint: 1648508460,
      cdnDwSize: 18463089461.3,
      cdnUsersCount: 5318,
      p2pDwSize: 18684918231.5,
      p2pUsersCount: 7111,
    },
    1648508520: {
      datePoint: 1648508520,
      cdnDwSize: 18558977307.2,
      cdnUsersCount: 5204,
      p2pDwSize: 18563163089.6,
      p2pUsersCount: 7093,
    },
    1648508580: {
      datePoint: 1648508580,
      cdnDwSize: 18062210769.6,
      cdnUsersCount: 5054,
      p2pDwSize: 18653671528.5,
      p2pUsersCount: 7110,
    },
    1648508640: {
      datePoint: 1648508640,
      cdnDwSize: 18162891111.5,
      cdnUsersCount: 5041,
      p2pDwSize: 18484003107.7,
      p2pUsersCount: 7124,
    },
    1648508700: {
      datePoint: 1648508700,
      cdnDwSize: 18251967190.9,
      cdnUsersCount: 5058,
      p2pDwSize: 18463415127.5,
      p2pUsersCount: 7083,
    },
    1648508760: {
      datePoint: 1648508760,
      cdnDwSize: 18201334278.9,
      cdnUsersCount: 5004,
      p2pDwSize: 18498662545.6,
      p2pUsersCount: 7103,
    },
    1648508820: {
      datePoint: 1648508820,
      cdnDwSize: 18312203769.6,
      cdnUsersCount: 5071,
      p2pDwSize: 18421820052.3,
      p2pUsersCount: 7056,
    },
    1648508880: {
      datePoint: 1648508880,
      cdnDwSize: 18249019526.4,
      cdnUsersCount: 5056,
      p2pDwSize: 18418665688,
      p2pUsersCount: 7020,
    },
    1648508940: {
      datePoint: 1648508940,
      cdnDwSize: 16363146906.5,
      cdnUsersCount: 5017,
      p2pDwSize: 16523479574.4,
      p2pUsersCount: 6936,
    },
    1648509000: {
      datePoint: 1648509000,
      cdnDwSize: 17919473524.3,
      cdnUsersCount: 5026,
      p2pDwSize: 18410433092.8,
      p2pUsersCount: 6967,
    },
    1648509060: {
      datePoint: 1648509060,
      cdnDwSize: 17797279665.6,
      cdnUsersCount: 4975,
      p2pDwSize: 18496427927.5,
      p2pUsersCount: 7021,
    },
    1648509120: {
      datePoint: 1648509120,
      cdnDwSize: 17793353170.4,
      cdnUsersCount: 4974,
      p2pDwSize: 18452037442.7,
      p2pUsersCount: 7024,
    },
    1648509180: {
      datePoint: 1648509180,
      cdnDwSize: 17565244549.7,
      cdnUsersCount: 4891,
      p2pDwSize: 18321915022.4,
      p2pUsersCount: 7071,
    },
    1648509240: {
      datePoint: 1648509240,
      cdnDwSize: 17453768596.1,
      cdnUsersCount: 4898,
      p2pDwSize: 18372453809.1,
      p2pUsersCount: 6984,
    },
    1648509300: {
      datePoint: 1648509300,
      cdnDwSize: 17358344309.3,
      cdnUsersCount: 4945,
      p2pDwSize: 18450628495.5,
      p2pUsersCount: 6990,
    },
    1648509360: {
      datePoint: 1648509360,
      cdnDwSize: 17448961362.1,
      cdnUsersCount: 4943,
      p2pDwSize: 18575140268.8,
      p2pUsersCount: 7019,
    },
    1648509420: {
      datePoint: 1648509420,
      cdnDwSize: 17381265194.1,
      cdnUsersCount: 4973,
      p2pDwSize: 18328539064.5,
      p2pUsersCount: 7043,
    },
    1648509480: {
      datePoint: 1648509480,
      cdnDwSize: 15742464406.1,
      cdnUsersCount: 4823,
      p2pDwSize: 16542629605.3,
      p2pUsersCount: 6934,
    },
    1648509540: {
      datePoint: 1648509540,
      cdnDwSize: 7062769486.4,
      cdnUsersCount: 3001,
      p2pDwSize: 7451996063.5,
      p2pUsersCount: 5035,
    },
    1648509600: {
      datePoint: 1648509600,
      cdnDwSize: 5723129664.5,
      cdnUsersCount: 1723,
      p2pDwSize: 5955971800.5,
      p2pUsersCount: 2526,
    },
    1648509660: {
      datePoint: 1648509660,
      cdnDwSize: 5958128737.1,
      cdnUsersCount: 1814,
      p2pDwSize: 5791280817.6,
      p2pUsersCount: 2581,
    },
    1648509720: {
      datePoint: 1648509720,
      cdnDwSize: 5902944721.1,
      cdnUsersCount: 1847,
      p2pDwSize: 5891182964.8,
      p2pUsersCount: 2617,
    },
    1648509780: {
      datePoint: 1648509780,
      cdnDwSize: 5951711118.9,
      cdnUsersCount: 1814,
      p2pDwSize: 5771100496.5,
      p2pUsersCount: 2566,
    },
    1648509840: {
      datePoint: 1648509840,
      cdnDwSize: 5854624534.9,
      cdnUsersCount: 1857,
      p2pDwSize: 5704997666.1,
      p2pUsersCount: 2527,
    },
    1648509900: {
      datePoint: 1648509900,
      cdnDwSize: 5946850165.9,
      cdnUsersCount: 1867,
      p2pDwSize: 5500447925.9,
      p2pUsersCount: 2444,
    },
    1648509960: {
      datePoint: 1648509960,
      cdnDwSize: 6158286446.4,
      cdnUsersCount: 1852,
      p2pDwSize: 5465829054.4,
      p2pUsersCount: 2451,
    },
    1648510020: {
      datePoint: 1648510020,
      cdnDwSize: 6098074784,
      cdnUsersCount: 1870,
      p2pDwSize: 5457503386.7,
      p2pUsersCount: 2420,
    },
    1648510080: {
      datePoint: 1648510080,
      cdnDwSize: 6075254115.7,
      cdnUsersCount: 1875,
      p2pDwSize: 5559378481.1,
      p2pUsersCount: 2413,
    },
    1648510140: {
      datePoint: 1648510140,
      cdnDwSize: 6060093896,
      cdnUsersCount: 1875,
      p2pDwSize: 5420371932.8,
      p2pUsersCount: 2430,
    },
    1648510200: {
      datePoint: 1648510200,
      cdnDwSize: 6123262096.5,
      cdnUsersCount: 2127,
      p2pDwSize: 5309770505.1,
      p2pUsersCount: 2486,
    },
    1648510260: {
      datePoint: 1648510260,
      cdnDwSize: 6425730582.8,
      cdnUsersCount: 2180,
      p2pDwSize: 5056956903.5,
      p2pUsersCount: 2460,
    },
    1648510320: {
      datePoint: 1648510320,
      cdnDwSize: 6058530737.5,
      cdnUsersCount: 1660,
      p2pDwSize: 5313047119.5,
      p2pUsersCount: 2507,
    },
    1648510380: {
      datePoint: 1648510380,
      cdnDwSize: 5915859192.8,
      cdnUsersCount: 1602,
      p2pDwSize: 5447440448.5,
      p2pUsersCount: 2569,
    },
    1648510440: {
      datePoint: 1648510440,
      cdnDwSize: 6120240936.3,
      cdnUsersCount: 1934,
      p2pDwSize: 5343041241.1,
      p2pUsersCount: 2556,
    },
    1648510500: {
      datePoint: 1648510500,
      cdnDwSize: 6336148934.9,
      cdnUsersCount: 2105,
      p2pDwSize: 5076741747.7,
      p2pUsersCount: 2470,
    },
    1648510560: {
      datePoint: 1648510560,
      cdnDwSize: 5755616265.1,
      cdnUsersCount: 1592,
      p2pDwSize: 5297415821.9,
      p2pUsersCount: 2468,
    },
    1648510620: {
      datePoint: 1648510620,
      cdnDwSize: 7687659502.9,
      cdnUsersCount: 2294,
      p2pDwSize: 6914647720.5,
      p2pUsersCount: 3425,
    },
    1648510680: {
      datePoint: 1648510680,
      cdnDwSize: 25881105778.1,
      cdnUsersCount: 4276,
      p2pDwSize: 21144787186.1,
      p2pUsersCount: 6664,
    },
    1648510740: {
      datePoint: 1648510740,
      cdnDwSize: 17499279161.1,
      cdnUsersCount: 4255,
      p2pDwSize: 16824987858.1,
      p2pUsersCount: 6884,
    },
    1648510800: {
      datePoint: 1648510800,
      cdnDwSize: 16944517128.9,
      cdnUsersCount: 4247,
      p2pDwSize: 16873104302.4,
      p2pUsersCount: 6958,
    },
    1648510860: {
      datePoint: 1648510860,
      cdnDwSize: 16754704534.4,
      cdnUsersCount: 4214,
      p2pDwSize: 16917486690.7,
      p2pUsersCount: 6958,
    },
    1648510920: {
      datePoint: 1648510920,
      cdnDwSize: 16528953733.3,
      cdnUsersCount: 4237,
      p2pDwSize: 17002569850.7,
      p2pUsersCount: 6938,
    },
    1648510980: {
      datePoint: 1648510980,
      cdnDwSize: 16502129617.6,
      cdnUsersCount: 4220,
      p2pDwSize: 16933216525.3,
      p2pUsersCount: 6888,
    },
    1648511040: {
      datePoint: 1648511040,
      cdnDwSize: 16376310768,
      cdnUsersCount: 4285,
      p2pDwSize: 16938001601.6,
      p2pUsersCount: 6880,
    },
    1648511100: {
      datePoint: 1648511100,
      cdnDwSize: 16314214418.1,
      cdnUsersCount: 4293,
      p2pDwSize: 16891876804.3,
      p2pUsersCount: 6836,
    },
    1648511160: {
      datePoint: 1648511160,
      cdnDwSize: 16206251808.5,
      cdnUsersCount: 4300,
      p2pDwSize: 16785760580.3,
      p2pUsersCount: 6757,
    },
    1648511220: {
      datePoint: 1648511220,
      cdnDwSize: 16250796378.1,
      cdnUsersCount: 4347,
      p2pDwSize: 16957991365.9,
      p2pUsersCount: 6753,
    },
    1648511280: {
      datePoint: 1648511280,
      cdnDwSize: 16035151554.7,
      cdnUsersCount: 4333,
      p2pDwSize: 16987048294.9,
      p2pUsersCount: 6736,
    },
    1648511340: {
      datePoint: 1648511340,
      cdnDwSize: 15848986309.3,
      cdnUsersCount: 4359,
      p2pDwSize: 17056040710.4,
      p2pUsersCount: 6749,
    },
    1648511400: {
      datePoint: 1648511400,
      cdnDwSize: 15694128577.5,
      cdnUsersCount: 4317,
      p2pDwSize: 17196685616,
      p2pUsersCount: 6715,
    },
    1648511460: {
      datePoint: 1648511460,
      cdnDwSize: 15527227642,
      cdnUsersCount: 4318,
      p2pDwSize: 17054200867.2,
      p2pUsersCount: 6702,
    },
    1648511520: {
      datePoint: 1648511520,
      cdnDwSize: 15620879517.3,
      cdnUsersCount: 4320,
      p2pDwSize: 16993363390.4,
      p2pUsersCount: 6752,
    },
    1648511580: {
      datePoint: 1648511580,
      cdnDwSize: 15448054952.5,
      cdnUsersCount: 4306,
      p2pDwSize: 17165611020.8,
      p2pUsersCount: 6712,
    },
    1648511640: {
      datePoint: 1648511640,
      cdnDwSize: 15437454184,
      cdnUsersCount: 4346,
      p2pDwSize: 17071767411.7,
      p2pUsersCount: 6636,
    },
    1648511700: {
      datePoint: 1648511700,
      cdnDwSize: 15407551380.3,
      cdnUsersCount: 4304,
      p2pDwSize: 17153178280,
      p2pUsersCount: 6654,
    },
    1648511760: {
      datePoint: 1648511760,
      cdnDwSize: 15391779634.1,
      cdnUsersCount: 4301,
      p2pDwSize: 17068600313.6,
      p2pUsersCount: 6632,
    },
    1648511820: {
      datePoint: 1648511820,
      cdnDwSize: 15223467018,
      cdnUsersCount: 4292,
      p2pDwSize: 17222324780.3,
      p2pUsersCount: 6656,
    },
    1648511880: {
      datePoint: 1648511880,
      cdnDwSize: 15201810221.3,
      cdnUsersCount: 4306,
      p2pDwSize: 17118127184,
      p2pUsersCount: 6667,
    },
    1648511940: {
      datePoint: 1648511940,
      cdnDwSize: 15223031360.5,
      cdnUsersCount: 4283,
      p2pDwSize: 17186517498.1,
      p2pUsersCount: 6701,
    },
    1648512000: {
      datePoint: 1648512000,
      cdnDwSize: 15236780853.3,
      cdnUsersCount: 4367,
      p2pDwSize: 16925379983.5,
      p2pUsersCount: 6682,
    },
    1648512060: {
      datePoint: 1648512060,
      cdnDwSize: 15231903782.4,
      cdnUsersCount: 4487,
      p2pDwSize: 16908260778.7,
      p2pUsersCount: 6673,
    },
    1648512120: {
      datePoint: 1648512120,
      cdnDwSize: 15185450911.1,
      cdnUsersCount: 4384,
      p2pDwSize: 16873678103.5,
      p2pUsersCount: 6660,
    },
    1648512180: {
      datePoint: 1648512180,
      cdnDwSize: 15084010697.3,
      cdnUsersCount: 4284,
      p2pDwSize: 17005570380.8,
      p2pUsersCount: 6654,
    },
    1648512240: {
      datePoint: 1648512240,
      cdnDwSize: 15222535090.7,
      cdnUsersCount: 4296,
      p2pDwSize: 16943490825.6,
      p2pUsersCount: 6662,
    },
    1648512300: {
      datePoint: 1648512300,
      cdnDwSize: 15104420481.1,
      cdnUsersCount: 4330,
      p2pDwSize: 16863251924.3,
      p2pUsersCount: 6641,
    },
    1648512360: {
      datePoint: 1648512360,
      cdnDwSize: 15024697726.3,
      cdnUsersCount: 4312,
      p2pDwSize: 16804540476.8,
      p2pUsersCount: 6628,
    },
    1648512420: {
      datePoint: 1648512420,
      cdnDwSize: 15105337520,
      cdnUsersCount: 4360,
      p2pDwSize: 16686143867.2,
      p2pUsersCount: 6593,
    },
    1648512480: {
      datePoint: 1648512480,
      cdnDwSize: 15139752347.6,
      cdnUsersCount: 4375,
      p2pDwSize: 16634526211.2,
      p2pUsersCount: 6527,
    },
    1648512540: {
      datePoint: 1648512540,
      cdnDwSize: 14945079852.8,
      cdnUsersCount: 4349,
      p2pDwSize: 16805876404.8,
      p2pUsersCount: 6562,
    },
    1648512600: {
      datePoint: 1648512600,
      cdnDwSize: 15002584565.9,
      cdnUsersCount: 4364,
      p2pDwSize: 17312974595.2,
      p2pUsersCount: 6596,
    },
    1648512660: {
      datePoint: 1648512660,
      cdnDwSize: 15117961318.9,
      cdnUsersCount: 4351,
      p2pDwSize: 16856717746.1,
      p2pUsersCount: 6643,
    },
    1648512720: {
      datePoint: 1648512720,
      cdnDwSize: 14858872452.8,
      cdnUsersCount: 4284,
      p2pDwSize: 16932702708.8,
      p2pUsersCount: 6608,
    },
    1648512780: {
      datePoint: 1648512780,
      cdnDwSize: 14936033668.8,
      cdnUsersCount: 4285,
      p2pDwSize: 17028840494.4,
      p2pUsersCount: 6666,
    },
    1648512840: {
      datePoint: 1648512840,
      cdnDwSize: 14918039186.1,
      cdnUsersCount: 4309,
      p2pDwSize: 16836121970.1,
      p2pUsersCount: 6658,
    },
    1648512900: {
      datePoint: 1648512900,
      cdnDwSize: 14809172747.7,
      cdnUsersCount: 4329,
      p2pDwSize: 16651315162.7,
      p2pUsersCount: 6624,
    },
    1648512960: {
      datePoint: 1648512960,
      cdnDwSize: 14223324941.9,
      cdnUsersCount: 4223,
      p2pDwSize: 16323410525.9,
      p2pUsersCount: 6412,
    },
    1648513020: {
      datePoint: 1648513020,
      cdnDwSize: 14255740201.5,
      cdnUsersCount: 4208,
      p2pDwSize: 16571970289.1,
      p2pUsersCount: 6494,
    },
    1648513080: {
      datePoint: 1648513080,
      cdnDwSize: 14958774173.9,
      cdnUsersCount: 4858,
      p2pDwSize: 16315878894.9,
      p2pUsersCount: 6915,
    },
    1648513140: {
      datePoint: 1648513140,
      cdnDwSize: 15494844593.1,
      cdnUsersCount: 4712,
      p2pDwSize: 16840760080.5,
      p2pUsersCount: 7576,
    },
    1648513200: {
      datePoint: 1648513200,
      cdnDwSize: 14731914323.2,
      cdnUsersCount: 3731,
      p2pDwSize: 17299285237.3,
      p2pUsersCount: 7223,
    },
    1648513260: {
      datePoint: 1648513260,
      cdnDwSize: 15330521562.5,
      cdnUsersCount: 3756,
      p2pDwSize: 17564239553.1,
      p2pUsersCount: 7281,
    },
    1648513320: {
      datePoint: 1648513320,
      cdnDwSize: 14401893834.1,
      cdnUsersCount: 3816,
      p2pDwSize: 17285398454.4,
      p2pUsersCount: 7235,
    },
    1648513380: {
      datePoint: 1648513380,
      cdnDwSize: 14460875324.8,
      cdnUsersCount: 3791,
      p2pDwSize: 17228573624.5,
      p2pUsersCount: 7295,
    },
    1648513440: {
      datePoint: 1648513440,
      cdnDwSize: 14354844777.9,
      cdnUsersCount: 3779,
      p2pDwSize: 17272417881.6,
      p2pUsersCount: 7251,
    },
    1648513500: {
      datePoint: 1648513500,
      cdnDwSize: 14491186013.3,
      cdnUsersCount: 3820,
      p2pDwSize: 17319667395.2,
      p2pUsersCount: 7276,
    },
    1648513560: {
      datePoint: 1648513560,
      cdnDwSize: 14398920725.7,
      cdnUsersCount: 3876,
      p2pDwSize: 17183389829.9,
      p2pUsersCount: 7242,
    },
    1648513620: {
      datePoint: 1648513620,
      cdnDwSize: 14327866125.7,
      cdnUsersCount: 3991,
      p2pDwSize: 17129352914.7,
      p2pUsersCount: 7165,
    },
    1648513680: {
      datePoint: 1648513680,
      cdnDwSize: 14379562191.5,
      cdnUsersCount: 3948,
      p2pDwSize: 17135421278.9,
      p2pUsersCount: 7184,
    },
    1648513740: {
      datePoint: 1648513740,
      cdnDwSize: 14400441371.2,
      cdnUsersCount: 3949,
      p2pDwSize: 17219051725.3,
      p2pUsersCount: 7176,
    },
    1648513800: {
      datePoint: 1648513800,
      cdnDwSize: 14397777511.5,
      cdnUsersCount: 4055,
      p2pDwSize: 17020260324.8,
      p2pUsersCount: 7063,
    },
    1648513860: {
      datePoint: 1648513860,
      cdnDwSize: 14625517979.2,
      cdnUsersCount: 4139,
      p2pDwSize: 16894169978.1,
      p2pUsersCount: 7034,
    },
    1648513920: {
      datePoint: 1648513920,
      cdnDwSize: 15160070160.5,
      cdnUsersCount: 4762,
      p2pDwSize: 16764305168,
      p2pUsersCount: 7367,
    },
    1648513980: {
      datePoint: 1648513980,
      cdnDwSize: 15223437816,
      cdnUsersCount: 4913,
      p2pDwSize: 16912733524.3,
      p2pUsersCount: 7740,
    },
    1648514040: {
      datePoint: 1648514040,
      cdnDwSize: 14409770758.4,
      cdnUsersCount: 3839,
      p2pDwSize: 17026869427.2,
      p2pUsersCount: 7263,
    },
    1648514100: {
      datePoint: 1648514100,
      cdnDwSize: 14537784921.6,
      cdnUsersCount: 3858,
      p2pDwSize: 16981855083.2,
      p2pUsersCount: 7229,
    },
    1648514160: {
      datePoint: 1648514160,
      cdnDwSize: 14300780464.4,
      cdnUsersCount: 3852,
      p2pDwSize: 17029877577.6,
      p2pUsersCount: 7190,
    },
    1648514220: {
      datePoint: 1648514220,
      cdnDwSize: 14214187690.7,
      cdnUsersCount: 3919,
      p2pDwSize: 16945649642.1,
      p2pUsersCount: 7122,
    },
    1648514280: {
      datePoint: 1648514280,
      cdnDwSize: 14087479325.3,
      cdnUsersCount: 3873,
      p2pDwSize: 17011169196.3,
      p2pUsersCount: 7045,
    },
    1648514340: {
      datePoint: 1648514340,
      cdnDwSize: 14281292435.7,
      cdnUsersCount: 3894,
      p2pDwSize: 16984516185.6,
      p2pUsersCount: 7035,
    },
    1648514400: {
      datePoint: 1648514400,
      cdnDwSize: 14166716661.7,
      cdnUsersCount: 3879,
      p2pDwSize: 16735733780.3,
      p2pUsersCount: 6967,
    },
    1648514460: {
      datePoint: 1648514460,
      cdnDwSize: 14274556369.5,
      cdnUsersCount: 3994,
      p2pDwSize: 16825469890.1,
      p2pUsersCount: 6924,
    },
    1648514520: {
      datePoint: 1648514520,
      cdnDwSize: 13971357135.1,
      cdnUsersCount: 3907,
      p2pDwSize: 17027113376,
      p2pUsersCount: 6941,
    },
    1648514580: {
      datePoint: 1648514580,
      cdnDwSize: 14106312388.3,
      cdnUsersCount: 3989,
      p2pDwSize: 16933135234.1,
      p2pUsersCount: 6922,
    },
    1648514640: {
      datePoint: 1648514640,
      cdnDwSize: 14029651026.7,
      cdnUsersCount: 3955,
      p2pDwSize: 16923632786.7,
      p2pUsersCount: 6925,
    },
    1648514700: {
      datePoint: 1648514700,
      cdnDwSize: 14048368081.1,
      cdnUsersCount: 3972,
      p2pDwSize: 16985282373.3,
      p2pUsersCount: 6981,
    },
    1648514760: {
      datePoint: 1648514760,
      cdnDwSize: 14503015775.5,
      cdnUsersCount: 4525,
      p2pDwSize: 16875827520,
      p2pUsersCount: 7331,
    },
    1648514820: {
      datePoint: 1648514820,
      cdnDwSize: 14960443437.2,
      cdnUsersCount: 4932,
      p2pDwSize: 16880142646.4,
      p2pUsersCount: 7705,
    },
    1648514880: {
      datePoint: 1648514880,
      cdnDwSize: 13767533756.3,
      cdnUsersCount: 3611,
      p2pDwSize: 17237651192,
      p2pUsersCount: 7311,
    },
    1648514940: {
      datePoint: 1648514940,
      cdnDwSize: 13730711499.7,
      cdnUsersCount: 3568,
      p2pDwSize: 17341472988.8,
      p2pUsersCount: 7303,
    },
    1648515000: {
      datePoint: 1648515000,
      cdnDwSize: 13626149609.6,
      cdnUsersCount: 3627,
      p2pDwSize: 17325767343.5,
      p2pUsersCount: 7276,
    },
    1648515060: {
      datePoint: 1648515060,
      cdnDwSize: 13581035273.5,
      cdnUsersCount: 3645,
      p2pDwSize: 17249402620.8,
      p2pUsersCount: 7222,
    },
    1648515120: {
      datePoint: 1648515120,
      cdnDwSize: 14055552938.5,
      cdnUsersCount: 4371,
      p2pDwSize: 17166303412.3,
      p2pUsersCount: 7446,
    },
    1648515180: {
      datePoint: 1648515180,
      cdnDwSize: 14421498373.9,
      cdnUsersCount: 4742,
      p2pDwSize: 17230220228.8,
      p2pUsersCount: 7785,
    },
    1648515240: {
      datePoint: 1648515240,
      cdnDwSize: 13664004235.6,
      cdnUsersCount: 3584,
      p2pDwSize: 17456931434.1,
      p2pUsersCount: 7349,
    },
    1648515300: {
      datePoint: 1648515300,
      cdnDwSize: 13434992588.3,
      cdnUsersCount: 3612,
      p2pDwSize: 17641604285.3,
      p2pUsersCount: 7361,
    },
    1648515360: {
      datePoint: 1648515360,
      cdnDwSize: 13358774229.9,
      cdnUsersCount: 3618,
      p2pDwSize: 17589301958.4,
      p2pUsersCount: 7304,
    },
    1648515420: {
      datePoint: 1648515420,
      cdnDwSize: 13328024974.9,
      cdnUsersCount: 3634,
      p2pDwSize: 17543710028.3,
      p2pUsersCount: 7299,
    },
    1648515480: {
      datePoint: 1648515480,
      cdnDwSize: 13372751027.2,
      cdnUsersCount: 3639,
      p2pDwSize: 17557722545.6,
      p2pUsersCount: 7312,
    },
    1648515540: {
      datePoint: 1648515540,
      cdnDwSize: 13266483400.5,
      cdnUsersCount: 3690,
      p2pDwSize: 17568300754.1,
      p2pUsersCount: 7250,
    },
    1648515600: {
      datePoint: 1648515600,
      cdnDwSize: 13948688174.9,
      cdnUsersCount: 4504,
      p2pDwSize: 17199774205.3,
      p2pUsersCount: 7377,
    },
    1648515660: {
      datePoint: 1648515660,
      cdnDwSize: 14321929212.3,
      cdnUsersCount: 4731,
      p2pDwSize: 17390747713.6,
      p2pUsersCount: 7755,
    },
    1648515720: {
      datePoint: 1648515720,
      cdnDwSize: 13381595824.4,
      cdnUsersCount: 3581,
      p2pDwSize: 17636406285.9,
      p2pUsersCount: 7426,
    },
    1648515780: {
      datePoint: 1648515780,
      cdnDwSize: 13353301674,
      cdnUsersCount: 3580,
      p2pDwSize: 17590765475.7,
      p2pUsersCount: 7413,
    },
    1648515840: {
      datePoint: 1648515840,
      cdnDwSize: 13389218147.7,
      cdnUsersCount: 3529,
      p2pDwSize: 17640514161.1,
      p2pUsersCount: 7425,
    },
    1648515900: {
      datePoint: 1648515900,
      cdnDwSize: 13247211495.5,
      cdnUsersCount: 3574,
      p2pDwSize: 17690374819.2,
      p2pUsersCount: 7442,
    },
    1648515960: {
      datePoint: 1648515960,
      cdnDwSize: 13415640469.9,
      cdnUsersCount: 3620,
      p2pDwSize: 17682793029.9,
      p2pUsersCount: 7478,
    },
    1648516020: {
      datePoint: 1648516020,
      cdnDwSize: 13565307494.3,
      cdnUsersCount: 3713,
      p2pDwSize: 17572206617.1,
      p2pUsersCount: 7475,
    },
    1648516080: {
      datePoint: 1648516080,
      cdnDwSize: 13467048593.6,
      cdnUsersCount: 3745,
      p2pDwSize: 17629768532.3,
      p2pUsersCount: 7452,
    },
    1648516140: {
      datePoint: 1648516140,
      cdnDwSize: 13436607733.9,
      cdnUsersCount: 3744,
      p2pDwSize: 17843834055.5,
      p2pUsersCount: 7459,
    },
    1648516200: {
      datePoint: 1648516200,
      cdnDwSize: 13318119681.1,
      cdnUsersCount: 3787,
      p2pDwSize: 17883557327.5,
      p2pUsersCount: 7437,
    },
    1648516260: {
      datePoint: 1648516260,
      cdnDwSize: 13420090580.3,
      cdnUsersCount: 3786,
      p2pDwSize: 17897123307.2,
      p2pUsersCount: 7477,
    },
    1648516320: {
      datePoint: 1648516320,
      cdnDwSize: 13442903277.3,
      cdnUsersCount: 3798,
      p2pDwSize: 17783146477.3,
      p2pUsersCount: 7432,
    },
    1648516380: {
      datePoint: 1648516380,
      cdnDwSize: 13699484020.5,
      cdnUsersCount: 3914,
      p2pDwSize: 17560548461.3,
      p2pUsersCount: 7416,
    },
    1648516440: {
      datePoint: 1648516440,
      cdnDwSize: 13610830266.7,
      cdnUsersCount: 3952,
      p2pDwSize: 17679174656.5,
      p2pUsersCount: 7385,
    },
    1648516500: {
      datePoint: 1648516500,
      cdnDwSize: 13647533381.3,
      cdnUsersCount: 3958,
      p2pDwSize: 17746013118.4,
      p2pUsersCount: 7417,
    },
    1648516560: {
      datePoint: 1648516560,
      cdnDwSize: 13645653932.8,
      cdnUsersCount: 3988,
      p2pDwSize: 17810385722.1,
      p2pUsersCount: 7355,
    },
    1648516620: {
      datePoint: 1648516620,
      cdnDwSize: 13889050714.7,
      cdnUsersCount: 4057,
      p2pDwSize: 17754512272.5,
      p2pUsersCount: 7386,
    },
    1648516680: {
      datePoint: 1648516680,
      cdnDwSize: 13822194831.5,
      cdnUsersCount: 4073,
      p2pDwSize: 17925588863.5,
      p2pUsersCount: 7376,
    },
    1648516740: {
      datePoint: 1648516740,
      cdnDwSize: 13837869569.6,
      cdnUsersCount: 3995,
      p2pDwSize: 17952319681.1,
      p2pUsersCount: 7436,
    },
    1648516800: {
      datePoint: 1648516800,
      cdnDwSize: 13793593714.7,
      cdnUsersCount: 4046,
      p2pDwSize: 18077389363.2,
      p2pUsersCount: 7517,
    },
    1648516860: {
      datePoint: 1648516860,
      cdnDwSize: 13728913392.5,
      cdnUsersCount: 4028,
      p2pDwSize: 18009324514.7,
      p2pUsersCount: 7507,
    },
    1648516920: {
      datePoint: 1648516920,
      cdnDwSize: 13655297955.6,
      cdnUsersCount: 4045,
      p2pDwSize: 18231144962.7,
      p2pUsersCount: 7467,
    },
    1648516980: {
      datePoint: 1648516980,
      cdnDwSize: 13815428285.3,
      cdnUsersCount: 4067,
      p2pDwSize: 18103345721.1,
      p2pUsersCount: 7480,
    },
    1648517040: {
      datePoint: 1648517040,
      cdnDwSize: 14206514032,
      cdnUsersCount: 4734,
      p2pDwSize: 17967543194.1,
      p2pUsersCount: 7836,
    },
    1648517100: {
      datePoint: 1648517100,
      cdnDwSize: 14776410037.9,
      cdnUsersCount: 4855,
      p2pDwSize: 17934950762.1,
      p2pUsersCount: 8286,
    },
    1648517160: {
      datePoint: 1648517160,
      cdnDwSize: 13982289289.6,
      cdnUsersCount: 3719,
      p2pDwSize: 18119802138.1,
      p2pUsersCount: 7911,
    },
    1648517220: {
      datePoint: 1648517220,
      cdnDwSize: 13973605043.2,
      cdnUsersCount: 3807,
      p2pDwSize: 18130514027.2,
      p2pUsersCount: 7890,
    },
    1648517280: {
      datePoint: 1648517280,
      cdnDwSize: 13864207166.4,
      cdnUsersCount: 3756,
      p2pDwSize: 18210396330.1,
      p2pUsersCount: 7879,
    },
    1648517340: {
      datePoint: 1648517340,
      cdnDwSize: 13908160588.8,
      cdnUsersCount: 3819,
      p2pDwSize: 18191110738.7,
      p2pUsersCount: 7827,
    },
    1648517400: {
      datePoint: 1648517400,
      cdnDwSize: 13741932442.7,
      cdnUsersCount: 3817,
      p2pDwSize: 18285044813.3,
      p2pUsersCount: 7824,
    },
    1648517460: {
      datePoint: 1648517460,
      cdnDwSize: 13961055742.4,
      cdnUsersCount: 3910,
      p2pDwSize: 18285787338.1,
      p2pUsersCount: 7832,
    },
    1648517520: {
      datePoint: 1648517520,
      cdnDwSize: 15059372773.3,
      cdnUsersCount: 5011,
      p2pDwSize: 18044365308.3,
      p2pUsersCount: 8280,
    },
    1648517580: {
      datePoint: 1648517580,
      cdnDwSize: 14393727790.9,
      cdnUsersCount: 4480,
      p2pDwSize: 18251236948.8,
      p2pUsersCount: 8202,
    },
    1648517640: {
      datePoint: 1648517640,
      cdnDwSize: 14124820811.6,
      cdnUsersCount: 3740,
      p2pDwSize: 18485089271.5,
      p2pUsersCount: 8080,
    },
    1648517700: {
      datePoint: 1648517700,
      cdnDwSize: 13942832651.7,
      cdnUsersCount: 3760,
      p2pDwSize: 18700810097.1,
      p2pUsersCount: 8112,
    },
    1648517760: {
      datePoint: 1648517760,
      cdnDwSize: 13823199000.9,
      cdnUsersCount: 3769,
      p2pDwSize: 18664084397.3,
      p2pUsersCount: 8053,
    },
    1648517820: {
      datePoint: 1648517820,
      cdnDwSize: 13792078986.1,
      cdnUsersCount: 3846,
      p2pDwSize: 18875860606.9,
      p2pUsersCount: 8069,
    },
    1648517880: {
      datePoint: 1648517880,
      cdnDwSize: 13940161447.5,
      cdnUsersCount: 3888,
      p2pDwSize: 18861427897.1,
      p2pUsersCount: 8115,
    },
    1648517940: {
      datePoint: 1648517940,
      cdnDwSize: 13890112714.1,
      cdnUsersCount: 3973,
      p2pDwSize: 18866559896,
      p2pUsersCount: 8074,
    },
    1648518000: {
      datePoint: 1648518000,
      cdnDwSize: 14064457171.2,
      cdnUsersCount: 4041,
      p2pDwSize: 18744517038.9,
      p2pUsersCount: 8004,
    },
    1648518060: {
      datePoint: 1648518060,
      cdnDwSize: 14294977054.8,
      cdnUsersCount: 4106,
      p2pDwSize: 18603029141.3,
      p2pUsersCount: 7954,
    },
    1648518120: {
      datePoint: 1648518120,
      cdnDwSize: 14250264463.3,
      cdnUsersCount: 4184,
      p2pDwSize: 18548634574.9,
      p2pUsersCount: 7959,
    },
    1648518180: {
      datePoint: 1648518180,
      cdnDwSize: 14394641519.9,
      cdnUsersCount: 4201,
      p2pDwSize: 18487085956.8,
      p2pUsersCount: 7943,
    },
    1648518240: {
      datePoint: 1648518240,
      cdnDwSize: 14389279410.1,
      cdnUsersCount: 4219,
      p2pDwSize: 18710179916.8,
      p2pUsersCount: 7891,
    },
    1648518300: {
      datePoint: 1648518300,
      cdnDwSize: 14173540085.3,
      cdnUsersCount: 4258,
      p2pDwSize: 18764363547.2,
      p2pUsersCount: 7848,
    },
    1648518360: {
      datePoint: 1648518360,
      cdnDwSize: 14384912220.3,
      cdnUsersCount: 4322,
      p2pDwSize: 18666493053.3,
      p2pUsersCount: 7848,
    },
    1648518420: {
      datePoint: 1648518420,
      cdnDwSize: 14539113353.5,
      cdnUsersCount: 4372,
      p2pDwSize: 18820955031.5,
      p2pUsersCount: 7934,
    },
    1648518480: {
      datePoint: 1648518480,
      cdnDwSize: 14441854286.9,
      cdnUsersCount: 4281,
      p2pDwSize: 19102903444.3,
      p2pUsersCount: 7955,
    },
    1648518540: {
      datePoint: 1648518540,
      cdnDwSize: 14344396940.7,
      cdnUsersCount: 4292,
      p2pDwSize: 19211194828.3,
      p2pUsersCount: 7934,
    },
    1648518600: {
      datePoint: 1648518600,
      cdnDwSize: 14539799128.5,
      cdnUsersCount: 4401,
      p2pDwSize: 18696672868.8,
      p2pUsersCount: 7859,
    },
    1648518660: {
      datePoint: 1648518660,
      cdnDwSize: 14452674814.9,
      cdnUsersCount: 4380,
      p2pDwSize: 18691703753.1,
      p2pUsersCount: 7901,
    },
    1648518720: {
      datePoint: 1648518720,
      cdnDwSize: 14383499763.7,
      cdnUsersCount: 4362,
      p2pDwSize: 18587441760.5,
      p2pUsersCount: 7952,
    },
    1648518780: {
      datePoint: 1648518780,
      cdnDwSize: 14513873176.5,
      cdnUsersCount: 4438,
      p2pDwSize: 18811664322.1,
      p2pUsersCount: 7936,
    },
    1648518840: {
      datePoint: 1648518840,
      cdnDwSize: 15640686219.7,
      cdnUsersCount: 5337,
      p2pDwSize: 18749739202.7,
      p2pUsersCount: 8575,
    },
    1648518900: {
      datePoint: 1648518900,
      cdnDwSize: 15205819056.9,
      cdnUsersCount: 4704,
      p2pDwSize: 18941949700.8,
      p2pUsersCount: 8760,
    },
    1648518960: {
      datePoint: 1648518960,
      cdnDwSize: 14511591683.5,
      cdnUsersCount: 3947,
      p2pDwSize: 19225405572.8,
      p2pUsersCount: 8499,
    },
    1648519020: {
      datePoint: 1648519020,
      cdnDwSize: 14587577045.1,
      cdnUsersCount: 3936,
      p2pDwSize: 19193393509.3,
      p2pUsersCount: 8533,
    },
    1648519080: {
      datePoint: 1648519080,
      cdnDwSize: 14608695612.7,
      cdnUsersCount: 4026,
      p2pDwSize: 19168354014.9,
      p2pUsersCount: 8482,
    },
    1648519140: {
      datePoint: 1648519140,
      cdnDwSize: 14830351148.3,
      cdnUsersCount: 4098,
      p2pDwSize: 19123372608.5,
      p2pUsersCount: 8435,
    },
    1648519200: {
      datePoint: 1648519200,
      cdnDwSize: 15581428519.5,
      cdnUsersCount: 5237,
      p2pDwSize: 18657346477.3,
      p2pUsersCount: 8763,
    },
    1648519260: {
      datePoint: 1648519260,
      cdnDwSize: 15878383643.7,
      cdnUsersCount: 5090,
      p2pDwSize: 18754177531.7,
      p2pUsersCount: 8806,
    },
    1648519320: {
      datePoint: 1648519320,
      cdnDwSize: 15704659686.9,
      cdnUsersCount: 4163,
      p2pDwSize: 19125303493.9,
      p2pUsersCount: 8664,
    },
    1648519380: {
      datePoint: 1648519380,
      cdnDwSize: 15060842242.5,
      cdnUsersCount: 4056,
      p2pDwSize: 19351652736.5,
      p2pUsersCount: 8628,
    },
    1648519440: {
      datePoint: 1648519440,
      cdnDwSize: 15193892112.5,
      cdnUsersCount: 4104,
      p2pDwSize: 19483180043.2,
      p2pUsersCount: 8660,
    },
    1648519500: {
      datePoint: 1648519500,
      cdnDwSize: 15314122019.3,
      cdnUsersCount: 4099,
      p2pDwSize: 19419458295.5,
      p2pUsersCount: 8680,
    },
    1648519560: {
      datePoint: 1648519560,
      cdnDwSize: 15250350944,
      cdnUsersCount: 4171,
      p2pDwSize: 19593579684.3,
      p2pUsersCount: 8641,
    },
    1648519620: {
      datePoint: 1648519620,
      cdnDwSize: 15441478988.3,
      cdnUsersCount: 4289,
      p2pDwSize: 19585294950.4,
      p2pUsersCount: 8651,
    },
    1648519680: {
      datePoint: 1648519680,
      cdnDwSize: 15405666016,
      cdnUsersCount: 4295,
      p2pDwSize: 19504373784.5,
      p2pUsersCount: 8675,
    },
    1648519740: {
      datePoint: 1648519740,
      cdnDwSize: 15337932847.2,
      cdnUsersCount: 4396,
      p2pDwSize: 19505413249.1,
      p2pUsersCount: 8548,
    },
    1648519800: {
      datePoint: 1648519800,
      cdnDwSize: 15437833693.3,
      cdnUsersCount: 4411,
      p2pDwSize: 19514295296.5,
      p2pUsersCount: 8616,
    },
    1648519860: {
      datePoint: 1648519860,
      cdnDwSize: 15772449629.3,
      cdnUsersCount: 4565,
      p2pDwSize: 19525358595.2,
      p2pUsersCount: 8592,
    },
    1648519920: {
      datePoint: 1648519920,
      cdnDwSize: 15674977845.9,
      cdnUsersCount: 4562,
      p2pDwSize: 19654249490.1,
      p2pUsersCount: 8536,
    },
    1648519980: {
      datePoint: 1648519980,
      cdnDwSize: 15827318106.1,
      cdnUsersCount: 4600,
      p2pDwSize: 19598195184.5,
      p2pUsersCount: 8503,
    },
    1648520040: {
      datePoint: 1648520040,
      cdnDwSize: 15663442441.6,
      cdnUsersCount: 4666,
      p2pDwSize: 19666180070.4,
      p2pUsersCount: 8520,
    },
    1648520100: {
      datePoint: 1648520100,
      cdnDwSize: 15953994712,
      cdnUsersCount: 4712,
      p2pDwSize: 19533325308.3,
      p2pUsersCount: 8453,
    },
    1648520160: {
      datePoint: 1648520160,
      cdnDwSize: 16255065632.5,
      cdnUsersCount: 4815,
      p2pDwSize: 19539543295.5,
      p2pUsersCount: 8421,
    },
    1648520220: {
      datePoint: 1648520220,
      cdnDwSize: 16371723868.8,
      cdnUsersCount: 4856,
      p2pDwSize: 19491327060.8,
      p2pUsersCount: 8459,
    },
    1648520280: {
      datePoint: 1648520280,
      cdnDwSize: 16471573224.4,
      cdnUsersCount: 4965,
      p2pDwSize: 19469488078.4,
      p2pUsersCount: 8421,
    },
    1648520340: {
      datePoint: 1648520340,
      cdnDwSize: 16699006851.1,
      cdnUsersCount: 5037,
      p2pDwSize: 19543104516.8,
      p2pUsersCount: 8425,
    },
    1648520400: {
      datePoint: 1648520400,
      cdnDwSize: 16757020794.7,
      cdnUsersCount: 5064,
      p2pDwSize: 19507572291.2,
      p2pUsersCount: 8416,
    },
    1648520460: {
      datePoint: 1648520460,
      cdnDwSize: 16657812643.2,
      cdnUsersCount: 5122,
      p2pDwSize: 19726700453.9,
      p2pUsersCount: 8462,
    },
    1648520520: {
      datePoint: 1648520520,
      cdnDwSize: 16788307579.2,
      cdnUsersCount: 5076,
      p2pDwSize: 19966401506.7,
      p2pUsersCount: 8500,
    },
    1648520580: {
      datePoint: 1648520580,
      cdnDwSize: 16620577413.3,
      cdnUsersCount: 5098,
      p2pDwSize: 19938845218.7,
      p2pUsersCount: 8477,
    },
    1648520640: {
      datePoint: 1648520640,
      cdnDwSize: 16600229120.5,
      cdnUsersCount: 5096,
      p2pDwSize: 20008900886.9,
      p2pUsersCount: 8555,
    },
    1648520700: {
      datePoint: 1648520700,
      cdnDwSize: 16633139321.5,
      cdnUsersCount: 5084,
      p2pDwSize: 20160514389.3,
      p2pUsersCount: 8598,
    },
    1648520760: {
      datePoint: 1648520760,
      cdnDwSize: 16534548513.1,
      cdnUsersCount: 5109,
      p2pDwSize: 20278324714.7,
      p2pUsersCount: 8548,
    },
    1648520820: {
      datePoint: 1648520820,
      cdnDwSize: 16684296803.6,
      cdnUsersCount: 5102,
      p2pDwSize: 20233197670.9,
      p2pUsersCount: 8582,
    },
    1648520880: {
      datePoint: 1648520880,
      cdnDwSize: 16835782717.9,
      cdnUsersCount: 5113,
      p2pDwSize: 20401143936.5,
      p2pUsersCount: 8619,
    },
    1648520940: {
      datePoint: 1648520940,
      cdnDwSize: 16818551239.5,
      cdnUsersCount: 5112,
      p2pDwSize: 20469017225.6,
      p2pUsersCount: 8647,
    },
    1648521000: {
      datePoint: 1648521000,
      cdnDwSize: 17019423272.4,
      cdnUsersCount: 5207,
      p2pDwSize: 20417172590.9,
      p2pUsersCount: 8699,
    },
    1648521060: {
      datePoint: 1648521060,
      cdnDwSize: 16836963909.3,
      cdnUsersCount: 5207,
      p2pDwSize: 20581632960.5,
      p2pUsersCount: 8755,
    },
    1648521120: {
      datePoint: 1648521120,
      cdnDwSize: 17102973555.6,
      cdnUsersCount: 5207,
      p2pDwSize: 20690640298.7,
      p2pUsersCount: 8800,
    },
    1648521180: {
      datePoint: 1648521180,
      cdnDwSize: 16969815712.4,
      cdnUsersCount: 5112,
      p2pDwSize: 20789969597.3,
      p2pUsersCount: 8842,
    },
    1648521240: {
      datePoint: 1648521240,
      cdnDwSize: 17259102918.3,
      cdnUsersCount: 5226,
      p2pDwSize: 20900296720.5,
      p2pUsersCount: 8876,
    },
    1648521300: {
      datePoint: 1648521300,
      cdnDwSize: 17486627989.3,
      cdnUsersCount: 5841,
      p2pDwSize: 20927555392,
      p2pUsersCount: 9383,
    },
    1648521360: {
      datePoint: 1648521360,
      cdnDwSize: 17929773192,
      cdnUsersCount: 5686,
      p2pDwSize: 21363197815.5,
      p2pUsersCount: 9964,
    },
    1648521420: {
      datePoint: 1648521420,
      cdnDwSize: 17163031833.9,
      cdnUsersCount: 4687,
      p2pDwSize: 21570522937.1,
      p2pUsersCount: 9726,
    },
    1648521480: {
      datePoint: 1648521480,
      cdnDwSize: 17034121888.3,
      cdnUsersCount: 4676,
      p2pDwSize: 21942344950.4,
      p2pUsersCount: 9639,
    },
    1648521540: {
      datePoint: 1648521540,
      cdnDwSize: 17059884859.2,
      cdnUsersCount: 4626,
      p2pDwSize: 21788795524.3,
      p2pUsersCount: 9656,
    },
    1648521600: {
      datePoint: 1648521600,
      cdnDwSize: 17360840948.1,
      cdnUsersCount: 4765,
      p2pDwSize: 21638391137.6,
      p2pUsersCount: 9596,
    },
    1648521660: {
      datePoint: 1648521660,
      cdnDwSize: 17396132585.1,
      cdnUsersCount: 4870,
      p2pDwSize: 21823550908.8,
      p2pUsersCount: 9598,
    },
    1648521720: {
      datePoint: 1648521720,
      cdnDwSize: 17614825488.9,
      cdnUsersCount: 4855,
      p2pDwSize: 21755676566.9,
      p2pUsersCount: 9561,
    },
    1648521780: {
      datePoint: 1648521780,
      cdnDwSize: 17747601688.7,
      cdnUsersCount: 4991,
      p2pDwSize: 21760029067.7,
      p2pUsersCount: 9478,
    },
    1648521840: {
      datePoint: 1648521840,
      cdnDwSize: 17584791136.5,
      cdnUsersCount: 5000,
      p2pDwSize: 21754921182.9,
      p2pUsersCount: 9531,
    },
    1648521900: {
      datePoint: 1648521900,
      cdnDwSize: 18590452217.1,
      cdnUsersCount: 5743,
      p2pDwSize: 21587972445.3,
      p2pUsersCount: 10004,
    },
    1648521960: {
      datePoint: 1648521960,
      cdnDwSize: 19195692521.3,
      cdnUsersCount: 5970,
      p2pDwSize: 21376275747.2,
      p2pUsersCount: 10129,
    },
    1648522020: {
      datePoint: 1648522020,
      cdnDwSize: 18018046762.1,
      cdnUsersCount: 4895,
      p2pDwSize: 21852102443.7,
      p2pUsersCount: 9852,
    },
    1648522080: {
      datePoint: 1648522080,
      cdnDwSize: 17930406476.3,
      cdnUsersCount: 4825,
      p2pDwSize: 21981679889.6,
      p2pUsersCount: 9980,
    },
    1648522140: {
      datePoint: 1648522140,
      cdnDwSize: 18719058229.3,
      cdnUsersCount: 5667,
      p2pDwSize: 21817430330.7,
      p2pUsersCount: 10308,
    },
    1648522200: {
      datePoint: 1648522200,
      cdnDwSize: 19211718997.3,
      cdnUsersCount: 5942,
      p2pDwSize: 21488414889.6,
      p2pUsersCount: 10303,
    },
    1648522260: {
      datePoint: 1648522260,
      cdnDwSize: 18313918053.9,
      cdnUsersCount: 5006,
      p2pDwSize: 21941451048,
      p2pUsersCount: 9921,
    },
    1648522320: {
      datePoint: 1648522320,
      cdnDwSize: 18593586252.3,
      cdnUsersCount: 5047,
      p2pDwSize: 22199409181.9,
      p2pUsersCount: 9909,
    },
    1648522380: {
      datePoint: 1648522380,
      cdnDwSize: 18836986493.3,
      cdnUsersCount: 5212,
      p2pDwSize: 22117098645.9,
      p2pUsersCount: 9935,
    },
    1648522440: {
      datePoint: 1648522440,
      cdnDwSize: 18639987509.9,
      cdnUsersCount: 5364,
      p2pDwSize: 22125164848.5,
      p2pUsersCount: 9957,
    },
    1648522500: {
      datePoint: 1648522500,
      cdnDwSize: 18698137989.2,
      cdnUsersCount: 5320,
      p2pDwSize: 22277951496,
      p2pUsersCount: 10017,
    },
    1648522560: {
      datePoint: 1648522560,
      cdnDwSize: 19056128439.1,
      cdnUsersCount: 5485,
      p2pDwSize: 22335402741.9,
      p2pUsersCount: 10068,
    },
    1648522620: {
      datePoint: 1648522620,
      cdnDwSize: 19024041027.6,
      cdnUsersCount: 5460,
      p2pDwSize: 22642096541.9,
      p2pUsersCount: 10108,
    },
    1648522680: {
      datePoint: 1648522680,
      cdnDwSize: 19132310504.5,
      cdnUsersCount: 5415,
      p2pDwSize: 22574031091.7,
      p2pUsersCount: 10138,
    },
    1648522740: {
      datePoint: 1648522740,
      cdnDwSize: 19318024299.2,
      cdnUsersCount: 5516,
      p2pDwSize: 22722674720.5,
      p2pUsersCount: 10189,
    },
    1648522800: {
      datePoint: 1648522800,
      cdnDwSize: 19234822566.8,
      cdnUsersCount: 5630,
      p2pDwSize: 22758702288.5,
      p2pUsersCount: 10204,
    },
    1648522860: {
      datePoint: 1648522860,
      cdnDwSize: 20523879534.8,
      cdnUsersCount: 6502,
      p2pDwSize: 22550206227.7,
      p2pUsersCount: 10708,
    },
    1648522920: {
      datePoint: 1648522920,
      cdnDwSize: 20744981197.3,
      cdnUsersCount: 6477,
      p2pDwSize: 22937714986.1,
      p2pUsersCount: 10948,
    },
    1648522980: {
      datePoint: 1648522980,
      cdnDwSize: 19687006058.7,
      cdnUsersCount: 5355,
      p2pDwSize: 23633937491.7,
      p2pUsersCount: 10827,
    },
    1648523040: {
      datePoint: 1648523040,
      cdnDwSize: 19917467336.4,
      cdnUsersCount: 5318,
      p2pDwSize: 23822180864,
      p2pUsersCount: 10919,
    },
    1648523100: {
      datePoint: 1648523100,
      cdnDwSize: 19879048657.9,
      cdnUsersCount: 5373,
      p2pDwSize: 23830756897.6,
      p2pUsersCount: 10987,
    },
    1648523160: {
      datePoint: 1648523160,
      cdnDwSize: 20031947105.1,
      cdnUsersCount: 5542,
      p2pDwSize: 23879191287.5,
      p2pUsersCount: 10951,
    },
    1648523220: {
      datePoint: 1648523220,
      cdnDwSize: 20244492258.5,
      cdnUsersCount: 5533,
      p2pDwSize: 24175248799.5,
      p2pUsersCount: 11021,
    },
    1648523280: {
      datePoint: 1648523280,
      cdnDwSize: 20223031230.1,
      cdnUsersCount: 5652,
      p2pDwSize: 24230276449.6,
      p2pUsersCount: 10973,
    },
    1648523340: {
      datePoint: 1648523340,
      cdnDwSize: 20402096566.5,
      cdnUsersCount: 5738,
      p2pDwSize: 24394011991.5,
      p2pUsersCount: 10989,
    },
    1648523400: {
      datePoint: 1648523400,
      cdnDwSize: 20345237773.7,
      cdnUsersCount: 5743,
      p2pDwSize: 24166965494.4,
      p2pUsersCount: 11027,
    },
    1648523460: {
      datePoint: 1648523460,
      cdnDwSize: 20566428924.3,
      cdnUsersCount: 5922,
      p2pDwSize: 24206935347.2,
      p2pUsersCount: 11027,
    },
    1648523520: {
      datePoint: 1648523520,
      cdnDwSize: 20656551085.9,
      cdnUsersCount: 6019,
      p2pDwSize: 24109245208,
      p2pUsersCount: 10943,
    },
    1648523580: {
      datePoint: 1648523580,
      cdnDwSize: 20787490905.1,
      cdnUsersCount: 6175,
      p2pDwSize: 24085996877.3,
      p2pUsersCount: 10882,
    },
    1648523640: {
      datePoint: 1648523640,
      cdnDwSize: 20845349258.7,
      cdnUsersCount: 6165,
      p2pDwSize: 24294827878.9,
      p2pUsersCount: 11016,
    },
    1648523700: {
      datePoint: 1648523700,
      cdnDwSize: 21189397831.5,
      cdnUsersCount: 6346,
      p2pDwSize: 24252984418.1,
      p2pUsersCount: 11019,
    },
    1648523760: {
      datePoint: 1648523760,
      cdnDwSize: 21409543925.2,
      cdnUsersCount: 6336,
      p2pDwSize: 24114175370.1,
      p2pUsersCount: 11048,
    },
    1648523820: {
      datePoint: 1648523820,
      cdnDwSize: 21127394952.8,
      cdnUsersCount: 6378,
      p2pDwSize: 24421119586.1,
      p2pUsersCount: 11010,
    },
    1648523880: {
      datePoint: 1648523880,
      cdnDwSize: 21394167757.3,
      cdnUsersCount: 6466,
      p2pDwSize: 24600570724.8,
      p2pUsersCount: 11044,
    },
    1648523940: {
      datePoint: 1648523940,
      cdnDwSize: 21360084598.9,
      cdnUsersCount: 6571,
      p2pDwSize: 24633438739.7,
      p2pUsersCount: 11033,
    },
    1648524000: {
      datePoint: 1648524000,
      cdnDwSize: 21904834463.5,
      cdnUsersCount: 6709,
      p2pDwSize: 24668391373.9,
      p2pUsersCount: 11045,
    },
    1648524060: {
      datePoint: 1648524060,
      cdnDwSize: 21963629132.3,
      cdnUsersCount: 6761,
      p2pDwSize: 24920921747.2,
      p2pUsersCount: 11068,
    },
    1648524120: {
      datePoint: 1648524120,
      cdnDwSize: 21939227158.4,
      cdnUsersCount: 6847,
      p2pDwSize: 24883199497.1,
      p2pUsersCount: 11120,
    },
    1648524180: {
      datePoint: 1648524180,
      cdnDwSize: 21875355711.3,
      cdnUsersCount: 6708,
      p2pDwSize: 24701093798.4,
      p2pUsersCount: 11072,
    },
    1648524240: {
      datePoint: 1648524240,
      cdnDwSize: 22037609888.4,
      cdnUsersCount: 6818,
      p2pDwSize: 24850850838.4,
      p2pUsersCount: 11249,
    },
    1648524300: {
      datePoint: 1648524300,
      cdnDwSize: 22077999233.1,
      cdnUsersCount: 6817,
      p2pDwSize: 25216397587.2,
      p2pUsersCount: 11266,
    },
    1648524360: {
      datePoint: 1648524360,
      cdnDwSize: 22460960092.4,
      cdnUsersCount: 6886,
      p2pDwSize: 25376206661.3,
      p2pUsersCount: 11290,
    },
    1648524420: {
      datePoint: 1648524420,
      cdnDwSize: 22580952374.4,
      cdnUsersCount: 6883,
      p2pDwSize: 25745759840.5,
      p2pUsersCount: 11442,
    },
    1648524480: {
      datePoint: 1648524480,
      cdnDwSize: 22637589075.3,
      cdnUsersCount: 7029,
      p2pDwSize: 25669890986.7,
      p2pUsersCount: 11442,
    },
    1648524540: {
      datePoint: 1648524540,
      cdnDwSize: 22909672154.1,
      cdnUsersCount: 7056,
      p2pDwSize: 25852578457.6,
      p2pUsersCount: 11530,
    },
    1648524600: {
      datePoint: 1648524600,
      cdnDwSize: 23213659430.9,
      cdnUsersCount: 7114,
      p2pDwSize: 25999419617.6,
      p2pUsersCount: 11526,
    },
    1648524660: {
      datePoint: 1648524660,
      cdnDwSize: 23231317844.8,
      cdnUsersCount: 7082,
      p2pDwSize: 26068311014.4,
      p2pUsersCount: 11558,
    },
    1648524720: {
      datePoint: 1648524720,
      cdnDwSize: 23392759912,
      cdnUsersCount: 7138,
      p2pDwSize: 26071501148.8,
      p2pUsersCount: 11583,
    },
    1648524780: {
      datePoint: 1648524780,
      cdnDwSize: 23389948533.7,
      cdnUsersCount: 7181,
      p2pDwSize: 26386348408.5,
      p2pUsersCount: 11607,
    },
    1648524840: {
      datePoint: 1648524840,
      cdnDwSize: 23628883174.4,
      cdnUsersCount: 7209,
      p2pDwSize: 26492464808,
      p2pUsersCount: 11644,
    },
    1648524900: {
      datePoint: 1648524900,
      cdnDwSize: 23671154949.3,
      cdnUsersCount: 7157,
      p2pDwSize: 26497758862.9,
      p2pUsersCount: 11661,
    },
    1648524960: {
      datePoint: 1648524960,
      cdnDwSize: 23817311211.6,
      cdnUsersCount: 7285,
      p2pDwSize: 26378393577.1,
      p2pUsersCount: 11600,
    },
    1648525020: {
      datePoint: 1648525020,
      cdnDwSize: 23989791009.5,
      cdnUsersCount: 7300,
      p2pDwSize: 26212883941.9,
      p2pUsersCount: 11670,
    },
    1648525080: {
      datePoint: 1648525080,
      cdnDwSize: 23921846430.8,
      cdnUsersCount: 7294,
      p2pDwSize: 26491749505.6,
      p2pUsersCount: 11658,
    },
    1648525140: {
      datePoint: 1648525140,
      cdnDwSize: 24581300649.6,
      cdnUsersCount: 7894,
      p2pDwSize: 26551832074.7,
      p2pUsersCount: 12071,
    },
    1648525200: {
      datePoint: 1648525200,
      cdnDwSize: 26052909251.1,
      cdnUsersCount: 8312,
      p2pDwSize: 26398876126.9,
      p2pUsersCount: 13240,
    },
    1648525260: {
      datePoint: 1648525260,
      cdnDwSize: 24643136406.4,
      cdnUsersCount: 6758,
      p2pDwSize: 27077369129.6,
      p2pUsersCount: 12715,
    },
    1648525320: {
      datePoint: 1648525320,
      cdnDwSize: 24705768080,
      cdnUsersCount: 6751,
      p2pDwSize: 27241249657.1,
      p2pUsersCount: 12745,
    },
    1648525380: {
      datePoint: 1648525380,
      cdnDwSize: 24692316127.3,
      cdnUsersCount: 6853,
      p2pDwSize: 27681105912.5,
      p2pUsersCount: 12735,
    },
    1648525440: {
      datePoint: 1648525440,
      cdnDwSize: 24931046398.3,
      cdnUsersCount: 7016,
      p2pDwSize: 27818590112,
      p2pUsersCount: 12726,
    },
    1648525500: {
      datePoint: 1648525500,
      cdnDwSize: 25006777512,
      cdnUsersCount: 7050,
      p2pDwSize: 27549745625.1,
      p2pUsersCount: 12780,
    },
    1648525560: {
      datePoint: 1648525560,
      cdnDwSize: 25334584664.5,
      cdnUsersCount: 7235,
      p2pDwSize: 27781727573.3,
      p2pUsersCount: 12787,
    },
    1648525620: {
      datePoint: 1648525620,
      cdnDwSize: 25213912877.7,
      cdnUsersCount: 7227,
      p2pDwSize: 27823232584,
      p2pUsersCount: 12861,
    },
    1648525680: {
      datePoint: 1648525680,
      cdnDwSize: 25672134000.9,
      cdnUsersCount: 7100,
      p2pDwSize: 28130905212.3,
      p2pUsersCount: 13092,
    },
    1648525740: {
      datePoint: 1648525740,
      cdnDwSize: 25330605731.1,
      cdnUsersCount: 7335,
      p2pDwSize: 27921623060.8,
      p2pUsersCount: 13035,
    },
    1648525800: {
      datePoint: 1648525800,
      cdnDwSize: 23831146683.1,
      cdnUsersCount: 6985,
      p2pDwSize: 25254283422.4,
      p2pUsersCount: 11911,
    },
    1648525860: {
      datePoint: 1648525860,
      cdnDwSize: 24281001907.7,
      cdnUsersCount: 7121,
      p2pDwSize: 25068987313.6,
      p2pUsersCount: 11809,
    },
    1648525920: {
      datePoint: 1648525920,
      cdnDwSize: 24499148909.7,
      cdnUsersCount: 7156,
      p2pDwSize: 25236193009.6,
      p2pUsersCount: 11815,
    },
    1648525980: {
      datePoint: 1648525980,
      cdnDwSize: 24824325542.4,
      cdnUsersCount: 7648,
      p2pDwSize: 25000046811.2,
      p2pUsersCount: 12103,
    },
    1648526040: {
      datePoint: 1648526040,
      cdnDwSize: 26705132657.7,
      cdnUsersCount: 8226,
      p2pDwSize: 24419770046.9,
      p2pUsersCount: 13059,
    },
    1648526100: {
      datePoint: 1648526100,
      cdnDwSize: 25197866023.7,
      cdnUsersCount: 6854,
      p2pDwSize: 25540129551.5,
      p2pUsersCount: 12493,
    },
    1648526160: {
      datePoint: 1648526160,
      cdnDwSize: 25062361090.7,
      cdnUsersCount: 6894,
      p2pDwSize: 26039149156.3,
      p2pUsersCount: 12660,
    },
    1648526220: {
      datePoint: 1648526220,
      cdnDwSize: 25762140105.1,
      cdnUsersCount: 7076,
      p2pDwSize: 25932395862.9,
      p2pUsersCount: 12720,
    },
    1648526280: {
      datePoint: 1648526280,
      cdnDwSize: 26075940980.7,
      cdnUsersCount: 7225,
      p2pDwSize: 26056691937.6,
      p2pUsersCount: 12683,
    },
    1648526340: {
      datePoint: 1648526340,
      cdnDwSize: 25214733712,
      cdnUsersCount: 7269,
      p2pDwSize: 25324507789.3,
      p2pUsersCount: 12650,
    },
    1648526400: {
      datePoint: 1648526400,
      cdnDwSize: 19678795871.9,
      cdnUsersCount: 6247,
      p2pDwSize: 16787659029.3,
      p2pUsersCount: 11279,
    },
    1648526460: {
      datePoint: 1648526460,
      cdnDwSize: 13238329722.5,
      cdnUsersCount: 4660,
      p2pDwSize: 7285937643.7,
      p2pUsersCount: 5484,
    },
    1648526520: {
      datePoint: 1648526520,
      cdnDwSize: 3159613477.3,
      cdnUsersCount: 2199,
      p2pDwSize: 1436637469.3,
      p2pUsersCount: 2036,
    },
  },
}
