export const bitesToString = (value) => {
  switch (true) {
    case value >= Math.pow(1000, 5):
      return `${
        (((value) / Math.pow(1000, 5)) | 0)
      } P`
    case (value) >= Math.pow(1000, 4) &&
    (value) < Math.pow(1000, 5):
      return `${
        ((((value)) / Math.pow(1000, 4)) | 0)
      } T`
    case (value) >= Math.pow(1000, 3) &&
    (value) < Math.pow(1000, 4):
      return `${
        ((((value)) / Math.pow(1000, 3)) | 0)
      } G`
    case (value) >= Math.pow(1000, 2) &&
    (value) < Math.pow(1000, 3):
      return `${
        ((((value)) / Math.pow(1000, 2)) | 0)
      } M`
    case (value) >= Math.pow(1000, 1) &&
    (value) < Math.pow(1000, 2):
      return `${
        ((((value)) / Math.pow(1000, 1)) | 0)
      } K`
    default:
      return `${value} K`
  }
}
