<template>
  <div class="statistics">
    <nodr-loader v-if="isLoadingSearchNodrs" />
    <template v-else>
      <nodr-no-nodr-stat v-if="!nodrsStat.length > 0" />
      <template v-else>
        <nodr-statistics-menu
          @isActiveArrayLicenseKeyOptions="isActiveArrayLicenseKeyOp"
          v-if="!isLoadingSearchNodrs"
        />
        <nodr-statistics-chart
          :isActiveArrayLicenseKeyError="isActiveArrayLicenseKeyError"
          :stat="stat" :statTotal="statTotal"
        />
      </template>
    </template>
  </div>
</template>

<script>
import Vuex from 'vuex'

import NodrLoader from '@/components/Loaders/Loader'
import NodrNoNodrStat from '@/Plug/NoNodrStat'
import NodrStatisticsMenu from '@/components/Statistics/StatisticsMenu'
import NodrStatisticsChart from '@/components/Statistics/StatisticsChart'
import {
  actionTypes as actionTypesStatistics,
  getterTypes as getterTypesStatistics
} from "@/store/modules/statistics";
import { getterTypes as getterTypesUser } from "@/store/modules/user";
import { actionTypes as actionTypesNodrs, getterTypes as getterTypesNodrs } from "@/store/modules/nodrs";

export default {
  name: 'NodrStatistics',
  data() {
    return {
      isActiveArrayLicenseKeyError: false
    }
  },
  computed: {
    ...Vuex.mapGetters({
      stat: getterTypesStatistics.stat,
      statTotal: getterTypesStatistics.statTotal,
      isLoadedStat: getterTypesStatistics.isLoadedStat,
      currentUser: getterTypesUser.currentUser,
      isLoadingSearchNodrs: getterTypesNodrs.isLoadingSearchNodrs,
      nodrsStat: getterTypesNodrs.nodrsStat
    })
  },
  methods: {
    ...Vuex.mapActions({
      findStatConfList: actionTypesStatistics.findStatConfList,
      searchNodrs: actionTypesNodrs.searchNodrs
    }),
    isActiveArrayLicenseKeyOp(val){
      this.isActiveArrayLicenseKeyError = val
    },
    getStatConfList(){
      this.findStatConfList({
        user_id: this.currentUser.id,
        team_id: this.currentUser.TeamsRender[0].id,
      })
    },
    findNodrs() {
      this.searchNodrs({
        teamId: this.currentUser.TeamsRender[0].id,
      })
    },
  },
  components: {
    NodrStatisticsChart,
    NodrStatisticsMenu,
    NodrNoNodrStat,
    NodrLoader,
  },
  mounted() {
    // this.getStatConfList()
    this.findNodrs()

    // console.log('>> STAT >>', Object.keys(this.stat))
  }
}
</script>
