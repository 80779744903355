<template>
  <div class="profile-change-email">
    <div class="profile-change-email__title">
      <span class="font-h5 color_dark-gray">Change my e-mail address</span>
      <span class="font-small color_gray"
        >Your current e-mail address is
        <i class="font-small-bold color_dodger-blue">{{ currentUser.email }}</i>
      </span>
      <div class="title-down">
        <svg
          v-if="currentUser.statusChangeEmail === 0"
          @click="clearForm"
          :class="
            isOpenFormChangeEmail || currentUser.statusChangeEmail === 10
              ? 'title-down--active'
              : ''
          ">
          <use
            xlink:href="../../assets/img/sprite/sprite-domain-up.svg#arrow"></use>
        </svg>
      </div>
    </div>

    <div
      v-if="isOpenFormChangeEmail || currentUser.statusChangeEmail === 10"
      class="profile-change-email__block">
      <div
        v-if="currentUser.statusChangeEmail === 0"
        class="profile-change-email__form">
        <form @submit.prevent="changeEmail(currentUser)">
          <div class="form-new-email">
            <div class="input-wrapper">
              <div
                class="input-field"
                :class="{
                  'error-validation':
                    ($v.newEmail.$dirty && !$v.newEmail.required) ||
                    ($v.newEmail.$dirty && !$v.newEmail.email),
                }">
                <input
                  class="input"
                  type="text"
                  placeholder="Enter new e-mail"
                  v-model.trim="newEmail"
                  autocomplete="off" />
              </div>
              <div class="validation-errors">
                <template
                  v-if="
                    ($v.newEmail.$dirty &&
                      !$v.newEmail.required &&
                      $v.currentPasswordUser.$dirty &&
                      !$v.currentPasswordUser.required) ||
                    ($v.newEmail.$dirty && !$v.newEmail.required) ||
                    ($v.currentPasswordUser.$dirty &&
                      !$v.currentPasswordUser.required &&
                      $v.newEmail.$dirty &&
                      $v.newEmail.email)
                  ">
                  <span class="font-small-2 color_coral"
                    >All fields are required</span
                  >
                </template>
                <template v-if="$v.newEmail.$dirty && !$v.newEmail.email">
                  <span class="font-small-2 color_coral">Invalid e-mail</span>
                </template>
              </div>
            </div>
            <div class="input-wrapper">
              <div
                class="input-field"
                :class="{
                  'error-validation':
                    ((($v.currentPasswordUser.$dirty &&
                      !$v.currentPasswordUser.required) ||
                      newMessageErrorCodeChangeEmail) &&
                      $v.newEmail.$dirty &&
                      $v.newEmail.email) ||
                    newMessageErrorCodeChangeEmail,
                }">
                <input
                  class="input"
                  :type="typeViewPassword"
                  placeholder="Current password"
                  v-model.trim="currentPasswordUser"
                  autocomplete="off" />
                <div
                  v-if="currentPasswordUser.length > 0"
                  class="password-control"
                  @click="viewPassword()">
                  <template v-if="!isViewPassword">
                    <svg>
                      <use
                        xlink:href="../../assets/img/svg/sprite/sprite-form.svg#eye-off-gray"></use>
                    </svg>
                  </template>
                  <template v-else>
                    <svg>
                      <use
                        xlink:href="../../assets/img/svg/sprite/sprite-form.svg#eye-on-gray"></use>
                    </svg>
                  </template>
                </div>
              </div>
              <div class="validation-errors">
                <template v-if="newMessageErrorCodeChangeEmail">
                  <span class="font-small-2 color_coral"
                    >{{messageErrorCodeChangeEmail}}</span
                  >
                </template>
              </div>
            </div>
          </div>
          <div class="form-btn">
            <button class="button button-loader-white color_white">
              <span v-if="!isLoadingChangeEmail">Submit changes</span>
              <tlprt-loader-white v-if="isLoadingChangeEmail" />
            </button>
          </div>
        </form>
      </div>

      <div
        v-if="currentUser.statusChangeEmail === 10"
        class="profile-change-email__process">
        <div class="process-text">
          <div class="process-text__title">
            <span class="font-menu-bold color_dark-gray"
              >Request in progress</span
            >
          </div>
          <div class="process-text__description">
            <span class="font-small color_dark-gray"
              >To confirm the change of e-mail, please follow instructions sent
              to the {{ currentUser.newChangeEmail }}</span
            >
          </div>
        </div>
        <div class="process-btn">
          <button @click="resent(currentUser.id)" class="button button-resend">
            Resend
          </button>
          <button
            @click="revoke(currentUser.id)"
            class="button button-revoke color_white">
            Revoke
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import {
  actionTypes as actionTypesUser,
  getterTypes,
} from '../../store/modules/user'
import {email, required} from 'vuelidate/lib/validators'
import TlprtLoaderWhite from '../Loaders/Loader-white'

export default {
  name: 'TlprtProfileChangeEmail',
  components: {TlprtLoaderWhite},
  props: {
    currentUser: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      isOpenFormChangeEmail: false,
      isViewPassword: false,
      newMessageErrorCodeChangeEmail: false,

      newEmail: '',
      currentPasswordUser: '',

      typeViewPassword: 'password',
    }
  },
  validations: {
    newEmail: {email, required},
    currentPasswordUser: {required},
  },
  watch: {
    newEmail() {
      this.$v.$reset()
      if (this.messageErrorCodeChangeEmail)
        this.newMessageErrorCodeChangeEmail = false
    },
    currentPasswordUser() {
      this.$v.$reset()
      if (this.messageErrorCodeChangeEmail)
        this.newMessageErrorCodeChangeEmail = false
    },
    messageErrorCodeChangeEmail() {
      if (this.messageErrorCodeChangeEmail)
        this.newMessageErrorCodeChangeEmail = true
    },
  },
  computed: {
    ...Vuex.mapGetters({
      messageErrorCodeChangeEmail: getterTypes.messageErrorCodeChangeEmail,
      isLoadingChangeEmail: getterTypes.isLoadingChangeEmail,
    }),
  },
  methods: {
    ...Vuex.mapActions({
      resentChangeEmail: actionTypesUser.resentChangeEmail,
      revokeChangeEmail: actionTypesUser.revokeChangeEmail,
      check: actionTypesUser.check,
    }),
    changeEmail(currentUser) {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.$emit('optionChangeEmail', {
        user_id: currentUser.id,
        team_id: currentUser.TeamsRender[0].id,
        userId: currentUser.id,
        password: this.currentPasswordUser,
        email: this.newEmail,
        url: origin + '/changeEmail',
      })
    },
    resent(userId) {
      this.$emit('optionResent', {
        id: userId,
        userId: userId,
        url: origin + '/changeEmail',
      })
      // this.resentChangeEmail({
      //   id: userId,
      //   userId: userId,
      //   url: origin + '/changeEmail',
      // })
    },
    revoke(userId) {
      this.$emit('optionRevoke', {
        id: userId,
        user_id: userId,
      })
      // this.revokeChangeEmail({
      //   id: userId,
      //   userId: userId,
      // })
    },
    clearForm() {
      this.isOpenFormChangeEmail = !this.isOpenFormChangeEmail

      this.$v.$reset()

      this.newEmail = ''
      this.currentPasswordUser = ''
    },
    viewPassword() {
      this.isViewPassword = !this.isViewPassword
      this.isViewPassword
        ? (this.typeViewPassword = 'text')
        : (this.typeViewPassword = 'password')
    },
  },
}
</script>
