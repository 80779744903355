var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create-account"},[_c('div',{staticClass:"create-account-block"},[_c('div',{staticClass:"create-account__inner"},[_vm._m(0),_c('div',{staticClass:"create-account-block__form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.createAccount.apply(null, arguments)}}},[_c('div',{staticClass:"form-title font-h2 color_white"},[_vm._v("Create account")]),_c('div',{staticClass:"input-field",class:{
              'error-password': _vm.$v.userName.$dirty && !_vm.$v.userName.required,
            }},[_c('label',{staticClass:"visually-hidden",attrs:{"for":"create-account-name"}},[_vm._v("Name")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.userName),expression:"userName",modifiers:{"trim":true}}],attrs:{"type":"text","id":"create-account-name","placeholder":"Name"},domProps:{"value":(_vm.userName)},on:{"input":function($event){if($event.target.composing)return;_vm.userName=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"validation-errors"},[(
                (_vm.$v.workspaceName.$dirty &&
                  _vm.$v.workspaceName.required &&
                  _vm.$v.userName.$dirty &&
                  !_vm.$v.userName.required &&
                  _vm.$v.lastName.$dirty &&
                  !_vm.$v.lastName.required) ||
                (_vm.$v.workspaceName.$dirty &&
                  !_vm.$v.workspaceName.required &&
                  _vm.$v.userName.$dirty &&
                  !_vm.$v.userName.required &&
                  _vm.$v.lastName.$dirty &&
                  _vm.$v.lastName.required) ||
                (_vm.$v.workspaceName.$dirty &&
                  _vm.$v.workspaceName.required &&
                  _vm.$v.userName.$dirty &&
                  !_vm.$v.userName.required &&
                  _vm.$v.lastName.$dirty &&
                  _vm.$v.lastName.required)
              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Required field")])]:_vm._e()],2),_c('div',{staticClass:"input-field",class:{
              'error-password': _vm.$v.lastName.$dirty && !_vm.$v.lastName.required,
            }},[_c('label',{staticClass:"visually-hidden",attrs:{"for":"create-account-last-name"}},[_vm._v("Last name")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.lastName),expression:"lastName",modifiers:{"trim":true}}],attrs:{"type":"text","id":"create-account-last-name","placeholder":"Last name"},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing)return;_vm.lastName=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"validation-errors"},[(
                (_vm.$v.workspaceName.$dirty &&
                  _vm.$v.workspaceName.required &&
                  _vm.$v.userName.$dirty &&
                  !_vm.$v.userName.required &&
                  _vm.$v.lastName.$dirty &&
                  !_vm.$v.lastName.required) ||
                (_vm.$v.workspaceName.$dirty &&
                  !_vm.$v.workspaceName.required &&
                  _vm.$v.userName.$dirty &&
                  _vm.$v.userName.required &&
                  _vm.$v.lastName.$dirty &&
                  !_vm.$v.lastName.required) ||
                (_vm.$v.workspaceName.$dirty &&
                  _vm.$v.workspaceName.required &&
                  _vm.$v.userName.$dirty &&
                  _vm.$v.userName.required &&
                  _vm.$v.lastName.$dirty &&
                  !_vm.$v.lastName.required)
              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Required field")])]:_vm._e()],2),_c('div',{staticClass:"input-field",class:{
              'error-password':
                _vm.$v.workspaceName.$dirty && !_vm.$v.workspaceName.required,
            }},[_c('label',{staticClass:"visually-hidden",attrs:{"for":"create-account-workspace-name"}},[_vm._v("Workspace name")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.workspaceName),expression:"workspaceName",modifiers:{"trim":true}}],attrs:{"type":"text","id":"create-account-workspace-name","placeholder":"Workspace name"},domProps:{"value":(_vm.workspaceName)},on:{"input":function($event){if($event.target.composing)return;_vm.workspaceName=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"validation-errors"},[(
                _vm.$v.workspaceName.$dirty &&
                !_vm.$v.workspaceName.required &&
                _vm.$v.userName.$dirty &&
                !_vm.$v.userName.required &&
                _vm.$v.lastName.$dirty &&
                !_vm.$v.lastName.required
              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("All fields are required")])]:(
                _vm.$v.workspaceName.$dirty && !_vm.$v.workspaceName.required
              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Required field")])]:_vm._e()],2),_vm._m(1)])])])]),_c('tlprt-background-img')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create-account-block__logo"},[_c('img',{attrs:{"src":require("../assets/img/svg/logo-icon-bg-blue60.svg"),"alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-actions"},[_c('div',{staticClass:"block-actions-btn"},[_c('button',{staticClass:"button button-white-w font-button"},[_vm._v("Start")])])])
}]

export { render, staticRenderFns }