import axios from '../services/api.axios'
import Config from '../common/config'
import * as auth from '../store/modules/auth'
const qs = require('qs')

const searchNodrs = (parameters) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios.post(Config.apiURL + '/v1/nodres/team/' + parameters.teamId, {},{headers: 
    {
      'Authorization':'Bearer ' + token
    }
  })
}

const createNodr = (parameters) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  const data = {
    teamId: parameters.teamId,
    configId: parameters.configId,
    publicKey: parameters.publicKey,
  }

  return axios({
    method: 'post',
    url: `${Config.apiURL}/v1/nodres/team/${parameters.teamId}/set_public_key/config/${parameters.configId}`,
    data: qs.stringify(data),
    headers: {'content-Type': 'application/x-www-form-urlencoded','Authorization': 'Bearer '+ token},
  })
}

const downloadDockerComposeNodr = (parameters) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios({
    method: 'post',
    url:
      Config.apiURL +
      '/v1/nodres/team/' +
      parameters.teamId +
      '/docker_compose/' +
      parameters.id,
    headers: {'Content-Type': 'text/yml','Authorization': 'Bearer '+ token},
  })
}

const findNodr = (parameters) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios.get(
    Config.apiURL +
      '/v1/nodres/team/' +
      parameters.teamId +
      '/' +
      parameters.nodrId,
    {},{headers: 
      {
        'Authorization':'Bearer ' + token
      }
    }
  )
}

const upNodr = (parameters) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  const data = {
    teamId: parameters.teamId,
    config_id: parameters.configId,
    name: parameters.name,
    id: parameters.id
  }
  return axios({
    method: 'put',
    url: Config.apiURL + '/v1/nodres/team/' + parameters.teamId + '/nodr/' + parameters.id,
    data: qs.stringify(data),
    headers: {'content-Type': 'application/x-www-form-urlencoded','Authorization': 'Bearer '+ token},
  })
}

const upNoActiveNodr = (parameters) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  const data = {
    teamId: parameters.teamId,
    configId: parameters.configId,
    publicKey: parameters.publicKey,
    id: parameters.id
  }
  return axios({
    method: 'put',
    url: Config.apiURL + '/v1/nodres/team/' + parameters.teamId + '/nodr/' + parameters.id,
    data: qs.stringify(data),
    headers: {'content-Type': 'application/x-www-form-urlencoded','Authorization': 'Bearer '+ token},
  })
}

const reconfigureNodr = (parameters) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  const data = {
    teamId: parameters.teamId,
    configId: parameters.configId,
    publicKey: parameters.publicKey,
    licenseKey: parameters.licenseKey,
    id: parameters.id
  }
  return axios({
    method: 'put',
    url: Config.apiURL + '/v1/nodres/team/' + parameters.teamId + '/reset_settings_nodr/' + parameters.id,
    data: data,
    headers: {'Authorization': 'Bearer '+ token},
  })
}

const deleteNodr = (parameters) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios.delete(
    Config.apiURL +
      '/v1/nodres/team/' +
      parameters.teamId +
      '/' +
      parameters.nodrId,
    {},{headers: 
      {
        'Authorization':'Bearer ' + token
      }
    }
  )
}
const updateActiveNodr = (parameters) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios.put(
    `${Config.apiURL}/v1/nodres/team/${parameters.teamId}/update_active_nodres/${parameters.nodrId}`,
    {},{headers: 
      {
        'Authorization':'Bearer ' + token
      }
    }
  )
}

const findConfigurationNodr = () => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios.post(Config.apiURL + '/v1/configs', {},{headers: 
    {
      'Authorization':'Bearer ' + token
    }
  })
}

export default {
  searchNodrs,
  createNodr,
  downloadDockerComposeNodr,
  findNodr,
  upNodr,
  upNoActiveNodr,
  reconfigureNodr,
  deleteNodr,
  updateActiveNodr,
  findConfigurationNodr,
}
