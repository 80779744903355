<template>
  <div class="create-account">
    <div class="create-account-block">
      <div class="create-account__inner">
        <div class="create-account-block__logo">
          <img src="../assets/img/svg/logo-icon-bg-blue60.svg" alt="logo" />
        </div>
        <div class="create-account-block__form">
          <form @submit.prevent="createAccount">
            <div class="form-title font-h2 color_white">Create account</div>
            <div
              class="input-field"
              :class="{
                'error-password': $v.userName.$dirty && !$v.userName.required,
              }">
              <label class="visually-hidden" for="create-account-name"
                >Name</label
              >
              <input
                type="text"
                id="create-account-name"
                placeholder="Name"
                v-model.trim="userName" />
            </div>
            <div class="validation-errors">
              <template
                v-if="
                  ($v.workspaceName.$dirty &&
                    $v.workspaceName.required &&
                    $v.userName.$dirty &&
                    !$v.userName.required &&
                    $v.lastName.$dirty &&
                    !$v.lastName.required) ||
                  ($v.workspaceName.$dirty &&
                    !$v.workspaceName.required &&
                    $v.userName.$dirty &&
                    !$v.userName.required &&
                    $v.lastName.$dirty &&
                    $v.lastName.required) ||
                  ($v.workspaceName.$dirty &&
                    $v.workspaceName.required &&
                    $v.userName.$dirty &&
                    !$v.userName.required &&
                    $v.lastName.$dirty &&
                    $v.lastName.required)
                ">
                <span class="font-small-2 color_coral">Required field</span>
              </template>
            </div>
            <div
              class="input-field"
              :class="{
                'error-password': $v.lastName.$dirty && !$v.lastName.required,
              }">
              <label class="visually-hidden" for="create-account-last-name"
                >Last name</label
              >
              <input
                type="text"
                id="create-account-last-name"
                placeholder="Last name"
                v-model.trim="lastName" />
            </div>
            <div class="validation-errors">
              <template
                v-if="
                  ($v.workspaceName.$dirty &&
                    $v.workspaceName.required &&
                    $v.userName.$dirty &&
                    !$v.userName.required &&
                    $v.lastName.$dirty &&
                    !$v.lastName.required) ||
                  ($v.workspaceName.$dirty &&
                    !$v.workspaceName.required &&
                    $v.userName.$dirty &&
                    $v.userName.required &&
                    $v.lastName.$dirty &&
                    !$v.lastName.required) ||
                  ($v.workspaceName.$dirty &&
                    $v.workspaceName.required &&
                    $v.userName.$dirty &&
                    $v.userName.required &&
                    $v.lastName.$dirty &&
                    !$v.lastName.required)
                ">
                <span class="font-small-2 color_coral">Required field</span>
              </template>
            </div>
            <div
              class="input-field"
              :class="{
                'error-password':
                  $v.workspaceName.$dirty && !$v.workspaceName.required,
              }">
              <label class="visually-hidden" for="create-account-workspace-name"
                >Workspace name</label
              >
              <input
                type="text"
                id="create-account-workspace-name"
                placeholder="Workspace name"
                v-model.trim="workspaceName" />
            </div>
            <div class="validation-errors">
              <template
                v-if="
                  $v.workspaceName.$dirty &&
                  !$v.workspaceName.required &&
                  $v.userName.$dirty &&
                  !$v.userName.required &&
                  $v.lastName.$dirty &&
                  !$v.lastName.required
                ">
                <span class="font-small-2 color_coral"
                  >All fields are required</span
                >
              </template>
              <template
                v-else-if="
                  $v.workspaceName.$dirty && !$v.workspaceName.required
                ">
                <span class="font-small-2 color_coral">Required field</span>
              </template>
            </div>
            <div class="block-actions">
              <div class="block-actions-btn">
                <button class="button button-white-w font-button">Start</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <tlprt-background-img />
  </div>
</template>

<script>
import Vuex from 'vuex'
import TlprtBackgroundImg from '../components/BackgroundImg'
import {required} from 'vuelidate/lib/validators'
import {actionTypes as actionUserType, getterTypes} from '../store/modules/user'
import {actionTypes as actionTeamType} from '../store/modules/team'

export default {
  name: 'TlprtCreateAccount',
  components: {TlprtBackgroundImg},
  data() {
    return {
      userName: '',
      lastName: '',
      workspaceName: '',
    }
  },
  validations: {
    userName: {required},
    lastName: {required},
    workspaceName: {required},
  },
  computed: {
    ...Vuex.mapGetters({
      currentUser: getterTypes.currentUser,
    }),
  },
  watch: {
    userName() {
      this.$v.$reset()
    },
    lastName() {
      this.$v.$reset()
    },
    workspaceName() {
      this.$v.$reset()
    },
  },
  methods: {
    ...Vuex.mapActions({
      updateUser: actionUserType.updateUsers,
      updateTeam: actionTeamType.updateTeam,
    }),
    createAccount() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.updateUser({
        id: this.currentUser.id,
        name: this.userName,
        surname: this.lastName,
      })

      this.updateTeam({
        id: this.currentUser.TeamsRender[0].id,
        name: this.workspaceName,
      }).then(() => {
        this.userName = ''
        this.lastName = ''
        this.workspaceName = ''
        this.$router.push('/myNodr')
      })
    },
  },
}
</script>
