<template>
  <div class="loader-block">
    <div class="lds-spinner-blue">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodrLoader',
}
</script>

<style lang="scss">
.loader-block {
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.lds-spinner-blue {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px;
}
.lds-spinner-blue div {
  transform-origin: 13px 13px;
  animation: lds-spinner-blue 0.8s linear infinite;
}
.lds-spinner-blue div:after {
  content: ' ';
  display: block;
  position: absolute;
  top: 2px;
  left: 12px;
  width: 2px;
  height: 6px;
  border-radius: 20%;
  background: #316bcb;
}
.lds-spinner-blue div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -0.7s;
}
.lds-spinner-blue div:nth-child(2) {
  transform: rotate(045deg);
  animation-delay: -0.6s;
}
.lds-spinner-blue div:nth-child(3) {
  transform: rotate(090deg);
  animation-delay: -0.5s;
}
.lds-spinner-blue div:nth-child(4) {
  transform: rotate(135deg);
  animation-delay: -0.4s;
}
.lds-spinner-blue div:nth-child(5) {
  transform: rotate(180deg);
  animation-delay: -0.3s;
}
.lds-spinner-blue div:nth-child(6) {
  transform: rotate(225deg);
  animation-delay: -0.2s;
}
.lds-spinner-blue div:nth-child(7) {
  transform: rotate(270deg);
  animation-delay: -0.1s;
}
.lds-spinner-blue div:nth-child(8) {
  transform: rotate(315deg);
  animation-delay: 0s;
}
@keyframes lds-spinner-blue {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>
