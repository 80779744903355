<template>
  <div class="profile-image">
    <div @click="openModalImage" class="profile-image__circle">
      <div
        v-if="
          Number(currentUser.image) === null || Number(currentUser.image) === 0
        "
        class="profile-image__circle-plug">
        <svg>
          <use
            xlink:href="../../assets/img/sprite/sprite-settings.svg#image-user-plug"></use>
        </svg>
      </div>
      <div
        v-else-if="Number(currentUser.image)"
        class="profile-image__circle-img">
        <img :src="currentImageUser" alt="" />
      </div>
      <div class="profile-image__circle-edit">
        <span class="font-small-bold color_white">Edit</span>
      </div>
    </div>

    <div class="profile-image__name">
      <span class="font-text color_dark-gray">{{ currentUser.name }}</span>
    </div>
  </div>
</template>

<script>
import {DEFAULT_USER_IMAGES} from '../../common/enum/defaultUserImage'

export default {
  name: 'TlprtProfileImage',
  props: {
    currentUser: {
      type: Object,
      require: true,
    },
  },
  computed: {
    currentImageUser() {
      const index = Number(this.currentUser.image)
      let imageUrl = DEFAULT_USER_IMAGES[index]

      return imageUrl.url
    },
  },
  methods: {
    openModalImage() {
      this.$emit('optionOpenModalImage', true)
    },
  },
}
</script>
