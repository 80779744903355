var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile-change-password"},[_c('div',{staticClass:"profile-change-password__title"},[_c('span',{staticClass:"font-h5 color_dark-gray"},[_vm._v("Change my password")]),_c('span',{staticClass:"font-small color_gray"},[_vm._v("Your password last modified on "+_vm._s(_vm.createDateChangePassword(_vm.currentUser.changePasswordAt))+" (UTC)")]),_c('div',{staticClass:"title-down"},[_c('svg',{class:_vm.isFormChangePassword ? 'title-down--active' : '',on:{"click":_vm.resetValueFormPassword}},[_c('use',{attrs:{"xlink:href":require("../../assets/img/sprite/sprite-domain-up.svg") + "#arrow"}})])])]),(_vm.isFormChangePassword && !_vm.isFormChangePasswordSuccess)?_c('div',{staticClass:"profile-change-password__form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.changePassword(_vm.currentUser.id)}}},[_c('div',{staticClass:"form-current-password"},[_c('div',{staticClass:"input-field",class:{
            'error-password':
              (_vm.$v.currentPasswordUser.$error &&
                !_vm.$v.currentPasswordUser.required) ||
              _vm.isViewErrorMessageCode,
          }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.currentPasswordUser),expression:"currentPasswordUser",modifiers:{"trim":true}}],staticClass:"input",attrs:{"type":_vm.typeViewCurrentPassword,"placeholder":"Confirm your current password","autocomplete":"off"},domProps:{"value":(_vm.currentPasswordUser)},on:{"input":function($event){if($event.target.composing)return;_vm.currentPasswordUser=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.currentPasswordUser.length > 0)?_c('div',{staticClass:"password-control",on:{"click":function($event){return _vm.viewCurrentPassword()}}},[(!_vm.isViewCurrentPassword)?[_c('svg',[_c('use',{attrs:{"xlink:href":require("../../assets/img/svg/sprite/sprite-form.svg") + "#eye-off-gray"}})])]:[_c('svg',[_c('use',{attrs:{"xlink:href":require("../../assets/img/svg/sprite/sprite-form.svg") + "#eye-on-gray"}})])]],2):_vm._e()]),_c('div',{staticClass:"validation-errors"},[(_vm.isViewErrorMessageCode)?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Incorrect password")])]:_vm._e()],2)]),_c('div',{staticClass:"form-new-password"},[_c('div',{staticClass:"input-wrapper"},[_c('div',{staticClass:"input-field",class:{
              'error-password':
                (_vm.$v.newPasswordUser.$error && !_vm.$v.newPasswordUser.required) ||
                (!(
                  (_vm.$v.newPasswordUser.$error &&
                    !_vm.$v.newPasswordUser.required) ||
                  (_vm.$v.currentPasswordUser.$error &&
                    !_vm.$v.currentPasswordUser.required) ||
                  (_vm.$v.retypeNewPasswordUser.$error &&
                    !_vm.$v.retypeNewPasswordUser.required)
                ) &&
                  _vm.$v.newPasswordUser.$error &&
                  !_vm.$v.newPasswordUser.minLength),
            }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.newPasswordUser),expression:"newPasswordUser",modifiers:{"trim":true}}],staticClass:"input",attrs:{"type":_vm.typeViewPassword,"placeholder":"New password","autocomplete":"off"},domProps:{"value":(_vm.newPasswordUser)},on:{"input":function($event){if($event.target.composing)return;_vm.newPasswordUser=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.newPasswordUser.length > 0)?_c('div',{staticClass:"password-control",on:{"click":function($event){return _vm.viewPassword()}}},[(!_vm.isViewPassword)?[_c('svg',[_c('use',{attrs:{"xlink:href":require("../../assets/img/svg/sprite/sprite-form.svg") + "#eye-off-gray"}})])]:[_c('svg',[_c('use',{attrs:{"xlink:href":require("../../assets/img/svg/sprite/sprite-form.svg") + "#eye-on-gray"}})])]],2):_vm._e()]),_c('div',{staticClass:"validation-errors"},[(
                (_vm.$v.newPasswordUser.$error && !_vm.$v.newPasswordUser.required) ||
                (_vm.$v.currentPasswordUser.$error &&
                  !_vm.$v.currentPasswordUser.required) ||
                (_vm.$v.retypeNewPasswordUser.$error &&
                  !_vm.$v.retypeNewPasswordUser.required)
              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("All fields are required")])]:_vm._e(),(
                !(
                  (_vm.$v.newPasswordUser.$error &&
                    !_vm.$v.newPasswordUser.required) ||
                  (_vm.$v.currentPasswordUser.$error &&
                    !_vm.$v.currentPasswordUser.required) ||
                  (_vm.$v.retypeNewPasswordUser.$error &&
                    !_vm.$v.retypeNewPasswordUser.required)
                ) &&
                _vm.$v.newPasswordUser.$error &&
                !_vm.$v.newPasswordUser.minLength
              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Minimum 6 symbols are required")])]:_vm._e()],2)]),_c('div',{staticClass:"input-wrapper"},[_c('div',{staticClass:"input-field",class:{
              'error-password':
                (_vm.$v.retypeNewPasswordUser.$error &&
                  !_vm.$v.retypeNewPasswordUser.required) ||
                (!(
                  !(
                    _vm.$v.newPasswordUser.$error &&
                    !_vm.$v.newPasswordUser.required &&
                    _vm.$v.currentPasswordUser.$error &&
                    !_vm.$v.currentPasswordUser.required &&
                    _vm.$v.retypeNewPasswordUser.$error &&
                    !_vm.$v.retypeNewPasswordUser.required
                  ) &&
                  _vm.$v.newPasswordUser.$error &&
                  !_vm.$v.newPasswordUser.minLength
                ) &&
                  !(
                    _vm.$v.newPasswordUser.$error && !_vm.$v.newPasswordUser.minLength
                  ) &&
                  _vm.$v.retypeNewPasswordUser.$error &&
                  !_vm.$v.retypeNewPasswordUser.sameAsPassword &&
                  !(
                    _vm.$v.newPasswordUser.$error && !_vm.$v.newPasswordUser.required
                  ) &&
                  !(
                    !(
                      _vm.$v.newPasswordUser.$error &&
                      !_vm.$v.newPasswordUser.required &&
                      _vm.$v.currentPasswordUser.$error &&
                      !_vm.$v.currentPasswordUser.required &&
                      _vm.$v.retypeNewPasswordUser.$error &&
                      !_vm.$v.retypeNewPasswordUser.required
                    ) &&
                    _vm.$v.newPasswordUser.$error &&
                    !_vm.$v.newPasswordUser.minLength
                  ) &&
                  !(
                    _vm.$v.newPasswordUser.$error && !_vm.$v.newPasswordUser.minLength
                  ) &&
                  _vm.$v.retypeNewPasswordUser.$error &&
                  !_vm.$v.retypeNewPasswordUser.sameAsPassword),
            }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.retypeNewPasswordUser),expression:"retypeNewPasswordUser",modifiers:{"trim":true}}],staticClass:"input",attrs:{"type":_vm.typeViewConfirmPassword,"placeholder":"Retype new password","autocomplete":"off"},domProps:{"value":(_vm.retypeNewPasswordUser)},on:{"input":function($event){if($event.target.composing)return;_vm.retypeNewPasswordUser=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.retypeNewPasswordUser.length > 0)?_c('div',{staticClass:"password-control",on:{"click":function($event){return _vm.viewConfirmPassword()}}},[(!_vm.isViewConfirmPassword)?[_c('svg',[_c('use',{attrs:{"xlink:href":require("../../assets/img/svg/sprite/sprite-form.svg") + "#eye-off-gray"}})])]:[_c('svg',[_c('use',{attrs:{"xlink:href":require("../../assets/img/svg/sprite/sprite-form.svg") + "#eye-on-gray"}})])]],2):_vm._e()]),_c('div',{staticClass:"validation-errors"},[(
                          !((_vm.$v.newPasswordUser.$error && !_vm.$v.newPasswordUser.required) ||
                          (_vm.$v.currentPasswordUser.$error &&
                          !_vm.$v.currentPasswordUser.required) ||
                          (_vm.$v.retypeNewPasswordUser.$error &&
                          !_vm.$v.retypeNewPasswordUser.required))

                          &&

                          (!(
                  !(
                    _vm.$v.newPasswordUser.$error &&
                    !_vm.$v.newPasswordUser.required &&
                    _vm.$v.currentPasswordUser.$error &&
                    !_vm.$v.currentPasswordUser.required &&
                    _vm.$v.retypeNewPasswordUser.$error &&
                    !_vm.$v.retypeNewPasswordUser.required
                  ) &&
                  _vm.$v.newPasswordUser.$error &&
                  !_vm.$v.newPasswordUser.minLength
                ) &&
                !(
                  _vm.$v.newPasswordUser.$error && !_vm.$v.newPasswordUser.minLength
                ) &&
                _vm.$v.retypeNewPasswordUser.$error &&
                !_vm.$v.retypeNewPasswordUser.sameAsPassword &&
                !(
                  _vm.$v.newPasswordUser.$error && !_vm.$v.newPasswordUser.required
                ) &&
                !(
                  !(
                    _vm.$v.newPasswordUser.$error &&
                    !_vm.$v.newPasswordUser.required &&
                    _vm.$v.currentPasswordUser.$error &&
                    !_vm.$v.currentPasswordUser.required &&
                    _vm.$v.retypeNewPasswordUser.$error &&
                    !_vm.$v.retypeNewPasswordUser.required
                  ) &&
                  _vm.$v.newPasswordUser.$error &&
                  !_vm.$v.newPasswordUser.minLength
                ) &&
                !(
                  _vm.$v.newPasswordUser.$error && !_vm.$v.newPasswordUser.minLength
                ) &&
                _vm.$v.retypeNewPasswordUser.$error &&
                !_vm.$v.retypeNewPasswordUser.sameAsPassword)

              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Passwords do not match")])]:_vm._e()],2)])]),_c('div',{staticClass:"form-btn"},[_c('button',{staticClass:"button button-loader-white color_white"},[(!_vm.isLoadingChangePassword)?_c('span',[_vm._v("Submit changes")]):_vm._e(),(_vm.isLoadingChangePassword)?_c('nodr-loader-white'):_vm._e()],1)])])]):_vm._e(),(_vm.isFormChangePasswordSuccess)?_c('div',{staticClass:"profile-change-password__success font-small color_dodger-blue"},[_c('svg',[_c('use',{attrs:{"xlink:href":require("../../assets/img/svg/sprite/sprite-domains.svg") + "#verification-check-success"}})]),_c('span',{staticClass:"color_dodger-blue font-small-bold"},[_vm._v("Password changed successfully")])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }