<template>
  <div class="modal-deleted-nodr">
    <div class="modal-deleted-nodr">
      <div class="modal-deleted-nodr__inner">
        <div class="popup">
          <button class="close">
            <svg @click="closeModal">
              <use
                xlink:href="../../../assets/img/sprite/sprite-header.svg#delete-draft-domain"></use>
            </svg>
          </button>
          <div class="popup__title font-h5 color_dark-gray">
            Are you sure you want to deleted <br />
            <span class="color_coral">{{
              currentDeletedNodr.name !== null || ''
                ? currentDeletedNodr.name
                : 'NODR name'
            }}</span>
            NODR?
          </div>
          <div class="popup__description font-text color_gray">
            You will no longer be receiving service <br />
            for this NODR until you will re-activate it
          </div>
          <div class="popup__btn">
            <button
              class="button button-red color_white"
              @click="deletedNodr()">
              Confirm
            </button>
            <button class="button button-border-w" @click="closeModal">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodrModalDeletedNodr',
  props: {
    currentDeletedNodr: {
      type: Object,
      require: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('deletedNodrParamsClose', false)
    },
    deletedNodr() {
      this.$emit('deletedNodrParams', this.currentDeletedNodr)
    },
  },
}
</script>
