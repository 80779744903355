<template>
  <div class="header">
    <div class="header-wrapper">
      <div
        v-if="
          $route.name === 'addNodr' ||
          $route.name === 'settings' ||
          $route.name === 'statistics' ||
          $route.name === 'integration' ||
          $route.name === 'myNodr'
        "></div>

      <nodr-header-menu :currentUser="currentUser" />
    </div>
  </div>
</template>

<script>
import NodrHeaderMenu from '@/components/HeaderMenu'

export default {
  name: 'NodrHeader',
  props: {
    currentUser: {
      type: Object,
      require: true,
    },
  },
  components: {NodrHeaderMenu},
}
</script>
