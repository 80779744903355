<template>
  <div class="no-pages">
    <div class="no-pages__image"></div>
    <div class="no-pages__text">
      <span class="font-h2 color_dark-blue"
        >We're sorry, it looks like we don't have</span
      >
      <span class="font-h2 color_dark-blue"> the page you are looking for</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nodr404',
}
</script>

<style lang="scss">
.no-pages {
  width: 100%;

  height: 100vh;

  .no-pages__image {
    width: 100%;

    height: calc(100vh - 194px);

    background: #2a5192;

    background-image: url('../assets/img/png/404-page.png');
    background-position: bottom;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .no-pages__text {
    width: 100%;

    height: 194px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
