import axios from '../services/api.axios'
import Config from '../common/config'

const login = (parameters) => {
  let formData = new FormData()
  formData.append('login', parameters.login)
  formData.append('password', parameters.password)

  return axios({
    method: 'post',
    url: Config.apiAuth +'/'+ Config.scope + '/guest/auth',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data'},
  })
}

const changePassword = (parameters) => {
  let formData = new FormData()
  formData.append('hash', parameters.hash)
  formData.append('password', parameters.password)
  formData.append('confirm', parameters.confirm)

  return axios({
    method: 'post',
    url: Config.apiAuth +'/'+ Config.scope + '/guest/set-new-password',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data'},
  })
}

const register = (parameters) => {
  let formData = new FormData()
  formData.append('login', parameters.login)
  formData.append('createPath', parameters.createPath)
  formData.append('resetPath', parameters.resetPath)
  formData.append('loginPath', parameters.loginPath)
  formData.append('offer', parameters.offer)
  formData.append('subscription', parameters.subscription)

  return axios({
    method: 'post',
    url: Config.apiAuth +'/'+ Config.scope + '/guest/register',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data'},
  })
}

const forgotPassword = (parameters) => {
  let formData = new FormData()
  formData.append('login', parameters.login)
  formData.append('url', parameters.url)

  return axios({
    method: 'post',
    url: Config.apiAuth +'/'+ Config.scope + '/guest/recovery-password',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data'},
  })
}

const logout = () => {
  return axios.get(Config.apiAuth +'/'+ Config.scope + '/v1/logout', {withCredentials: true})
}

export default {
  register,
  login,
  changePassword,
  forgotPassword,
  logout,
}
