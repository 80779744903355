<template>
  <div class="change-email-result">
    <div class="change-email-result__wrapper">
      <div class="change-email-result__inner">
        <nodr-loader-white v-if="loaderResultAcceptChangeEmail" />

        <div
          v-if="messageResultAcceptChangeEmail === 'success'"
          class="change-email-result__text">
          <div class="font-h2 color_white">
            You have successfully <br />
            changed your account <br> e-mail
          </div>
          <p class="font-h6 color_white">
            Please contact
            <a class="font-h6 color_white" href="mailto:support@nodr.app"
              >support@nodr.app</a
            >
            <br />
            if you need any assistance
          </p>
        </div>

        <div
          v-else-if="messageResultAcceptChangeEmail === 'error'"
          class="change-email-result__text">
          <div class="font-h2 color_white">This link is expired</div>
          <p class="font-h6 color_white">
            Unfortunately, this link is no longer valid. Please contact <br />
            <a class="font-h6 color_white" href="mailto:support@nodr.app"
              >support@nodr.app</a
            >
            if you need an assistance
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import {actionTypes, getterTypes} from '../../store/modules/user'
import NodrLoaderWhite from '../Loaders/Loader-white'

export default {
  name: 'NodrChangeEmailResult',
  components: {NodrLoaderWhite},
  computed: {
    ...Vuex.mapGetters({
      messageResultAcceptChangeEmail:
        getterTypes.messageResultAcceptChangeEmail,
      loaderResultAcceptChangeEmail: getterTypes.loaderResultAcceptChangeEmail,
    }),
  },
  methods: {
    ...Vuex.mapActions({
      acceptChangeEmail: actionTypes.acceptChangeEmail,
    }),
    accept() {
      this.acceptChangeEmail({
        hash: this.$route.params.hash,
      })
    },
  },
  mounted() {
    this.accept()
  },
}
</script>
