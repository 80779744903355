<template>
  <div class="statistics-menu">
    <div class="statistics-menu_filter">
      <div
        @click="getStatisticsDay(1)"
        class="menu_filter-item-time"
        :class="{active: isActiveBtnDate === 1}">
        <span class="font-small-bold">Day</span>
      </div>
      <div
        @click="getStatisticsWeek(2)"
        class="menu_filter-item-time"
        :class="{active: isActiveBtnDate === 2}">
        <span class="font-small-bold">Week</span>
      </div>
      <div
        @click="getStatisticsMonth(3)"
        class="menu_filter-item-time"
        :class="{active: isActiveBtnDate === 3}">
        <span class="font-small-bold">Month</span>
      </div>
    </div>

    <div class="statistics-menu_list">
      <div
        @click="isActiveMenuNodrList = !isActiveMenuNodrList"
        class="statistics-menu_list-title"
        :class="{'active': isActiveMenuNodrList}"
      >
        <div class="icon__block">
          <svg>
            <use xlink:href="../../assets/img/sprite/sprite-stat.svg#star-list"></use>
          </svg>
          <span class="font-small-bold color_dark-gray">All traffic</span>
        </div>
        <svg :class="{'icon-active': isActiveMenuNodrList}">
          <use xlink:href="../../assets/img/sprite/sprite-domain-up.svg#arrow"></use>
        </svg>
      </div>

      <div v-if="isActiveMenuNodrList" class="statistics-menu_list-nodrs">
        <div class="nodr-active">
          <div class="nodr-active__title">
            <span class="color_dark-gray font-submenu-bold">Active</span>
          </div>
          <div class="nodr-active__list">
            <div v-if="activeNodr.length === 0" class="">
              <span class="font-small color_gray domain-deleted_no-key">No key</span>
            </div>
            <div v-else class="nodr-active__list-item checkbox-list-blue" v-for="active in activeNodr">
              <input
                type="checkbox"
                :value="active.licenseKey"
                v-model="arrayLicenseKey"
                :id="`nodr-active__list-item--${active.licenseKey}`"
              >
              <label class="font-small color_gray" :for="`nodr-active__list-item--${active.licenseKey}`">
                <span>
                  {{active.name === null || active.name === '' ? active.licenseKey : active.name}}
                </span>
              </label>
            </div>
          </div>
        </div>
        <div class="nodr-inactive">
          <div class="nodr-inactive__title">
            <span class="color_dark-gray font-submenu-bold">Inactive</span>
          </div>
          <div class="nodr-inactive__list">
            <div v-if="inActiveNodr.length === 0" class="">
             <span class="font-small color_gray domain-deleted_no-key">No key</span>
            </div>
            <div v-else class="nodr-inactive__list-item checkbox-list-blue" v-for="inActive in inActiveNodr">
              <input
                type="checkbox"
                :value="inActive.licenseKey"
                v-model="arrayLicenseKey"
                :id="`nodr-inactive__list-item--${inActive.licenseKey}`"
              >
              <label class="font-small color_gray" :for="`nodr-inactive__list-item--${inActive.licenseKey}`">
                <span>
                  {{inActive.name === null || inActive.name === '' ? inActive.licenseKey : inActive.name}}
                 </span>
              </label>
            </div>
          </div>
        </div>
        <div class="nodr-delete">
          <div class="nodr-delete__title">
            <span class="color_dark-gray font-submenu-bold">Deleted</span>
          </div>
          <div class="nodr-delete__list">
            <div v-if="deletedNodr.length === 0" class="">
              <span class="font-small color_gray domain-deleted_no-key">No key</span>
            </div>
            <div v-else class="nodr-delete__list-item checkbox-list-blue" v-for="deleted in deletedNodr">
              <input
                type="checkbox"
                :value="deleted.licenseKey"
                v-model="arrayLicenseKey"
                :id="`nodr-delete__list-item--${deleted.licenseKey}`"
              >
              <label  class="font-small color_gray" :for="`nodr-delete__list-item--${deleted.licenseKey}`">
                <span>
                 {{deleted.name === null || deleted.name === '' ? deleted.licenseKey : deleted.name}}
              </span>
              </label>
            </div>
          </div>
        </div>
      </div>


    </div>

  </div>
</template>

<script>
import Vuex from 'vuex'
import {actionTypes as actionTypesStatistics} from "@/store/modules/statistics";
import { getterTypes as getterTypesNodrs } from "@/store/modules/nodrs";
import { getterTypes as getterTypesUser } from "@/store/modules/user";

export default {
  name: 'NodrStatisticsMenu',
  data() {
    return {
      isActiveBtnDate: 1,
      isActiveMenuNodrList: false,
      arrayLicenseKey: [],
    }
  },
  computed: {
    ...Vuex.mapGetters({
      currentUser: getterTypesUser.currentUser,
      activeNodr: getterTypesNodrs.activeNodr,
      inActiveNodr: getterTypesNodrs.inActiveNodr,
      deletedNodr: getterTypesNodrs.deletedNodr
    })
  },
  watch: {
    arrayLicenseKey(value){
      if (this.isActiveBtnDate === 1) {
        this.getStatisticsDay(this.isActiveBtnDate)
      }
      if (this.isActiveBtnDate === 2) {
        this.getStatisticsWeek(this.isActiveBtnDate)
      }
      if (this.isActiveBtnDate === 3) {
        this.getStatisticsMonth(this.isActiveBtnDate)
      }
      this.arrayLicenseKey.length === 0 ? this.isActiveArrayLicenseKey(true) : this.isActiveArrayLicenseKey(false)
    }
  },
  methods: {
    ...Vuex.mapActions({
      getStatDay: actionTypesStatistics.getStatDay,
      getStatWeek: actionTypesStatistics.getStatWeek,
      getStatMonth: actionTypesStatistics.getStatMonth,
      getMokStat: actionTypesStatistics.getMokStat
    }),
    isActiveArrayLicenseKey(bol) {
      this.$emit('isActiveArrayLicenseKeyOptions', bol)
    },
    getStatisticsDay(number) {
      this.isActiveBtnDate = number
      let dateEnd = (Date.now() / 1000) | 0
      let dateStart = dateEnd - 60 * 1440
      if (this.arrayLicenseKey.length !== 0) {
        this.getStatDay({
          endTimestamp: dateEnd,
          startTimestamp: dateStart,
          clientId: this.currentUser.TeamsRender[0].id,
          licenceKey: this.arrayLicenseKey,
        })
      } else {
        this.getMokStat()
      }

    },
    getStatisticsWeek(number) {
      this.isActiveBtnDate = number
      let dateEnd = (Date.now() / 1000) | 0
      let dateStart = dateEnd - 20 * 60 * 504
      if (this.arrayLicenseKey.length !== 0) {
        this.getStatWeek({
          endTimestamp: dateEnd,
          startTimestamp: dateStart,
          clientId: this.currentUser.TeamsRender[0].id,
          licenceKey: this.arrayLicenseKey,
        })
      } else {
        this.getMokStat()
      }

    },
    getStatisticsMonth(number) {
      this.isActiveBtnDate = number
      let dateEnd = (Date.now() / 1000) | 0
      let dateStart = dateEnd - 60 * 60 * 720
      if(this.arrayLicenseKey.length !== 0) {
        this.getStatMonth({
          endTimestamp: dateEnd,
          startTimestamp: dateStart,
          clientId: this.currentUser.TeamsRender[0].id,
          licenceKey: this.arrayLicenseKey,
        })
      } else {
        this.getMokStat()
      }

    },
  },
  mounted() {
    this.arrayLicenseKey = this.activeNodr.map(n => n.licenseKey)
    // if (this.arrayLicenseKey.length === 0) this.getStatisticsDay(this.isActiveBtnDate, true)
  }
}
</script>
