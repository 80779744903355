var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forgot-password"},[_c('div',{staticClass:"forgot-password-block"},[_c('div',{staticClass:"forgot-password__inner"},[_vm._m(0),_c('div',{staticClass:"forgot-password-block__form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.forgotPassword.apply(null, arguments)}}},[_c('div',{staticClass:"font-h2 color_white"},[_vm._v("Forgot password?")]),_c('div',{staticClass:"form-title font-text color_white"},[_vm._v(" Enter your e-mail address below and we will send you a link to reset your password ")]),_c('div',{staticClass:"input-field",class:{
              'error-email':
                (_vm.$v.email.$dirty && !_vm.$v.email.required) ||
                (_vm.$v.email.$dirty && !_vm.$v.email.email),
            }},[_c('label',{staticClass:"visually-hidden",attrs:{"for":"login-email"}},[_vm._v("Enter your e-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],attrs:{"type":"text","id":"login-email","placeholder":"Enter your e-mail"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"validation-errors"},[(_vm.$v.email.$dirty && !_vm.$v.email.required)?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Required field")])]:_vm._e(),(_vm.$v.email.$dirty && !_vm.$v.email.email)?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("invalid email")])]:_vm._e()],2),_vm._m(1)])])]),_c('div',{staticClass:"forgot-password-block__link-auth"},[_c('router-link',{staticClass:"links-white",attrs:{"to":{name: 'login'}}},[_vm._v(" Return to login page "),_c('svg',[_c('use',{attrs:{"xlink:href":require("../assets/img/svg/sprite/sprite-domains.svg") + "#arrow-right"}})])])],1)]),_c('tlprt-background-img')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"forgot-password__logo"},[_c('img',{attrs:{"src":require("../assets/img/svg/logo-icon-bg-blue60.svg"),"alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-actions"},[_c('div',{staticClass:"block-actions-btn"},[_c('button',{staticClass:"button button-white font-button"},[_vm._v("Submit")])])])
}]

export { render, staticRenderFns }