import axios from '../services/api.axios'
import Config from '../common/config'

const apiUrlStat = 'https://apistat.nodr.app/api'

const findStatConfList = (parameters) => {
  return axios.post(
    Config.apiURL +
    '/v1/statistic/settings/get/user/' +
    parameters.user_id +
    '/team/' +
    parameters.team_id,
    parameters
  )
}

const saveStatConfList = (parameters) => {
  return axios.post(
    Config.apiURL +
    '/v1/statistic/settings/put/user/' +
    parameters.user_id +
    '/team/' +
    parameters.team_id,
    {json: parameters.save_json}
  )
}

const getStatDay = (parameters) => {
  return axios.post(
    apiUrlStat + '/v1/nodr/day',
    parameters
  )
}

const getStatWeek = (parameters) => {
  return axios.post(
    apiUrlStat + '/v1/nodr/week',
    parameters
  )
}

const getStatMonth = (parameters) => {
  return axios.post(
    apiUrlStat + '/v1/nodr/month',
    parameters
  )
}

export default {
  getStatDay,
  getStatWeek,
  getStatMonth,
  findStatConfList,
  saveStatConfList
}
