import axios from 'axios'

axios.defaults.withCredentials = true

const Http = axios.create({
  withCredentials: true,
})

const errorCheck = (status) => {
  if (status === 403) {
    return (window.location.href = location.origin)
  }
}

/** Добавление перехватчиков ответа */
Http.interceptors.response.use(
  (response) => {
    // console.log('!!!!!!! API AXIOS onFulfilled')

    /** Добавить перехватчики ответа */
    /** Любой код состояния, который находится в диапазоне 2xx, вызывает срабатывание этой функции. */
    if ([200, 201].includes(response.status)) {
      // console.log('response.data', response.data)
      // console.log('response.status', response.status)

      return Promise.resolve(response.data)
    } else if ([401].includes(response.status)) {
      // console.log(' 401 response.status', response.status)
    } else {
      // console.log('reject(response.data)', response.data)
      return Promise.reject(response.data)
    }
    return response
  },

  (error) => {
    // console.log('!!!!!!! API AXIOS onRejected')

    /** Обработать ошибку в ответе */
    /** Любые коды состояния, выходящие за пределы диапазона 2xx, вызывают срабатывание этой функции */
    if (error.response) {
      // console.log('error.response.data', error.response.data)
      // console.log('error.response.status', error.response.status)

      errorCheck(error.response.status)
    } else if (error.request) {
      // console.log('error.request', error.request)
    } else {
      // console.log('error.message', error.message)
    }
    return Promise.reject(error)
  }
)

export default Http
