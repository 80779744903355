var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-delete-user"},[_c('div',{staticClass:"modal-delete-user__inner"},[_c('div',{staticClass:"popup"},[_c('button',{staticClass:"close",on:{"click":_vm.closeModal}},[_c('svg',[_c('use',{attrs:{"xlink:href":require("../../../assets/img/sprite/sprite-header.svg") + "#delete-draft-domain"}})])]),_vm._m(0),(
          _vm.findInfoDeleteUser.statusMessage ===
          _vm.statusTearmsDeletingUser.CAN_DELETE_USER_SOLO_TEAM
        )?_c('div',{staticClass:"modal-delete-user__text"},[_vm._m(1),_vm._m(2)]):_vm._e(),(
          _vm.findInfoDeleteUser.statusMessage ===
          _vm.statusTearmsDeletingUser.CAN_DELETE_USER_NO_SOLO_ADMIN
        )?_c('div',{staticClass:"modal-delete-user__text"},[_vm._m(3),_vm._m(4)]):_vm._e(),(
          _vm.findInfoDeleteUser.statusMessage ===
          _vm.statusTearmsDeletingUser.CAN_DELETE_USER_NO_ADMIN
        )?_c('div',{staticClass:"modal-delete-user__text-wrapper"},[_vm._m(5),_c('div',{staticClass:"modal-delete-user__list-teams"},_vm._l((_vm.teams),function(team,index){return _c('div',{key:index,staticClass:"modal-delete-user__list-team"},[_c('span',{staticClass:"font-text color_gray d-block"},[_vm._v(_vm._s(team.name))]),_c('span',{staticClass:"font-text color_gray d-block"},[_vm._v(_vm._s(team.role === 10 ? 'Admin' : 'Viewer'))])])}),0),_vm._m(6)]):_vm._e(),_c('form',{staticClass:"modal-delete-user__control",on:{"submit":function($event){$event.preventDefault();return _vm.deleteUser(_vm.currentUser)}}},[_c('div',{staticClass:"modal-delete-user__control-input",class:{
            'error-validation':
              (_vm.$v.currentPasswordUser.$dirty &&
                !_vm.$v.currentPasswordUser.required) ||
              _vm.isIncorrectPassword,
          }},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.currentPasswordUser),expression:"currentPasswordUser",modifiers:{"trim":true}}],staticClass:"input",attrs:{"type":_vm.typeViewPassword,"placeholder":"Enter your password","autocomplete":"off"},domProps:{"value":(_vm.currentPasswordUser)},on:{"input":function($event){if($event.target.composing)return;_vm.currentPasswordUser=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"validation-errors"},[(_vm.isIncorrectPassword)?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v(_vm._s(_vm.messageErrorCodeDeleteAccountUser))])]:_vm._e(),(
              _vm.$v.currentPasswordUser.$dirty &&
              !_vm.$v.currentPasswordUser.required
            )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Required field")])]:_vm._e()],2),_c('div',{staticClass:"modal-delete-user__control-btn"},[_c('button',{staticClass:"button button-red"},[_vm._v("Delete account")]),_c('button',{staticClass:"button button-border-w",on:{"click":_vm.closeModal}},[_vm._v(" Cancel ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-delete-user__title"},[_c('span',{staticClass:"font-h5 color_dark-gray"},[_vm._v("Are you sure you want to delete your account?")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"font-text color_gray d-block"},[_vm._v(" By deleting your account you will also delete this team. "),_c('br'),_vm._v(" You will no longer be able to login in the dashboard ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"font-text color_gray d-block"},[_vm._v("Please confirm your decision by entering your password. "),_c('br'),_vm._v(" We will e-mail you the link. Following that link, you'll delete "),_c('br'),_vm._v(" this account. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"font-text color_gray d-block"},[_vm._v(" Upon deletion, you'll be automatically signed out from "),_c('br'),_vm._v(" the current team and will no longer be able to sign "),_c('br'),_vm._v(" in the dashboard. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"font-text color_gray d-block"},[_vm._v(" Please confirm your decision by entering your password. "),_c('br'),_vm._v(" We will e-mail you the link. Following that link, you'll delete "),_c('br'),_vm._v(" this account. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-delete-user__text"},[_c('span',{staticClass:"font-text color_gray d-block"},[_vm._v(" Upon deletion, you'll be automatically signed out from "),_c('br'),_vm._v(" all teams and will no longer be able to sign into any of them "),_c('br'),_vm._v(" and into this dashboard. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-delete-user__text"},[_c('span',{staticClass:"font-text color_gray d-block"},[_vm._v(" Please confirm your decision by entering your password. "),_c('br'),_vm._v(" We will e-mail you the link. Following that link, you'll delete "),_c('br'),_vm._v(" this account. ")])])
}]

export { render, staticRenderFns }