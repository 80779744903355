import Vue from 'vue'
import Vuex from 'vuex'
import auth from '../store/modules/auth'
import user from '../store/modules/user'
import team from '../store/modules/team'
import nodrs from '../store/modules/nodrs'
import dictionaries from '../store/modules/dictionaries'
import statistics from '../store/modules/statistics'

Vue.use(Vuex)

let store = new Vuex.Store({
  modules: {
    auth,
    user,
    team,
    nodrs,
    statistics,
    dictionaries,
  },
})

export default store
