<template>
  <div class="modal-soon-add-nodr">
    <div class="modal-soon-add-nodr">
      <div class="modal-soon-add-nodr__inner">
        <div class="popup">
          <button class="close">
            <svg @click="closeModal">
              <use
                xlink:href="../../../assets/img/sprite/sprite-header.svg#delete-draft-domain"></use>
            </svg>
          </button>

          <div class="description">
            <svg>
              <use
                xlink:href="../../../assets/img/sprite/sprite-nodr.svg#key-circle"></use>
            </svg>

            <span class="font-h5 color_dark-gray"
              >Soon it will be possible <br />
              to add a new NODR</span
            >
            <span class="font_text color_gray"
              >Every day we improve the functionality <br />
              and capabilities of the dashboard</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodrModalSoonAddNodr',
  methods: {
    closeModal() {
      this.$emit('closeModalParams', false)
    },
  },
}
</script>
