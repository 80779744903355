<template>
    <div
      class="configuration-block__item"
      @click="currentConfigId(item_data)"
         :class="{
        'configuration-block__item-soon': item_data.status === 1,
        // 'configuration-block__item--active': isActiveConfig || startIsActiveConfig,
      }">
      <div
        class="config-items"
        :class="{'config-item__soon--background': item_data.status !== 10}">
        <div class="config-item__title">
          <span class="font-small color_dodger-blue">{{ item_data.name }}</span>
          <span class="title_current-config" v-if="currentActiveConfig">Current config</span>
        </div>
        <div class="config-item">
          <svg>
            <use
              xlink:href="@/assets/img/sprite/sprite-nodr.svg#volume-storage"></use>
          </svg>
          <span class="font-small color_gray">HDD {{ nodrConfigStorage }}B</span>
        </div>
        <div class="config-item">
          <svg>
            <use xlink:href="@/assets/img/sprite/sprite-nodr.svg#ram"></use>
          </svg>
          <span class="font-small color_gray">RAM {{ nodrConfigRam }}B</span>
        </div>
        <div class="config-item">
          <svg>
            <use
              xlink:href="@/assets/img/sprite/sprite-nodr.svg#speed"></use>
          </svg>
          <span class="font-small color_gray">{{nodrConfigBandwidth}}bps</span>
        </div>
        <div class="config-item">
          <svg>
            <use
              xlink:href="@/assets/img/sprite/sprite-nodr.svg#static-ip"></use>
          </svg>
          <span class="font-small color_gray">{{ item_data.ip_type }}</span>
        </div>
      </div>
    </div>
</template>

<script>
import {bitesToString} from "@/common/helpers/bitesToString";

export default {
  name: 'NodrCarouselItem',
  props: {
    item_data: {
      type: Object,
      default: () => {}
    },
    currentNodr: {
      type: Object,
      require: true
    },
    idx_item_data: {
      require: true
    },
    active: {
      type: Boolean,
    },
  },
  data() {
    return {
      currentActiveConfig: false,
      isActiveConfig: false,
    }
  },
  watch: {
    active(val) {
      // console.log('watch', val)
      this.isActiveConfig = this.active
    }
  },
  computed: {
    nodrConfigBandwidth() {
      return bitesToString(this. item_data.bandwidth)
    },
    nodrConfigRam() {
      return bitesToString(this. item_data.ram)
    },
    nodrConfigStorage() {
      return bitesToString(this. item_data.storage)
    },

  },
  methods: {
    currentConfigId(cfg) {

      if (cfg.status === 10) {
        this.configId = cfg.id

        this.$emit('activate');
        this.$emit('currentConfigIdOptions', this.configId)
      }
    },
  },
  mounted() {
    if (this.currentNodr.configId === this.item_data.id) {
      this.currentActiveConfig = true
    }
  }
}
</script>
