<template>
  <div class="profile-settings">
    <div class="profile-settings__block">
      <tlprt-profile-details
        :currentUser="currentUser"
        @optionEditProfileDetails="
          $emit('upOptionEditProfileDetails', $event)
        " />
      <tlprt-profile-change-password
        :currentUser="currentUser"
        @optionChangePassword="$emit('upOptionChangePassword', $event)" />
      <tlprt-profile-change-email
        :currentUser="currentUser"
        @optionChangeEmail="$emit('upOptionChangeEmail', $event)"
        @optionResent="$emit('upOptionResent', $event)"
        @optionRevoke="$emit('upOptionRevoke', $event)"
      />
    </div>
    <div class="profile-settings__block">
      <tlprt-profile-image
        :currentUser="currentUser"
        @optionOpenModalImage="$emit('upOptionOpenModalImage', $event)" />
      <tlprt-profile-account-deleting
        :currentUser="currentUser"
        @optionGetInfoDeleteUser="$emit('upOptionGetInfoDeleteUser', $event)" />
    </div>
  </div>
</template>

<script>
import TlprtProfileDetails from './ProfileDetails'
import TlprtProfileImage from './ProfileImage'
import TlprtProfileChangePassword from './ProfileChangePassword'
import TlprtProfileChangeEmail from './ProfileChangeEmail'
import TlprtProfileAccountDeleting from './ProfileAccountDeleting'

export default {
  name: 'TlprtProfileSettings',
  props: {
    currentUser: {
      type: Object,
      require: true,
    },
  },
  components: {
    TlprtProfileAccountDeleting,
    TlprtProfileChangeEmail,
    TlprtProfileChangePassword,
    TlprtProfileImage,
    TlprtProfileDetails,
  },
}
</script>
