import authApi from '../../services/api.auth'

export const state = {
  ifAuthenticated: false,
  isLoading: false,
  isAuthLoading: false,
  currentUser: null,
  messageError: null,
  accessToken:null,
}

export const mutationTypes = {
  registerStart: '[auth] Register start',
  registerSuccess: '[auth] Register success',
  registerFailure: '[auth] Register failure',

  loginStart: '[auth] Login start',
  loginSuccess: '[auth] Login success',
  loginFailure: '[auth] Login failure',

  forgotPasswordStart: '[auth] Forgot Password start',
  forgotPasswordSuccess: '[auth] Forgot Password success',
  forgotPasswordFailure: '[auth] Forgot Password failure',

  changePasswordStart: '[auth] Change Password start',
  changePasswordSuccess: '[auth] Change Password success',
  changePasswordFailure: '[auth] Change Password failure',

  logout: '[auth] logout',

  setAccessToken: '[auth] access token',
  cleanAccessToken:'[auth] clean access token',
}

export const actionTypes = {
  register: '[auth] Register',
  login: '[auth] Login',
  changePassword: '[auth] Change Password',
  forgotPassword: '[auth] Forgot Password',
  logout: '[auth] logout',
}

export const getterTypes = {
  messageError: '[auth] messageError',
  isLoading: '[auth] isLoading',
  accessToken: '[auth] access token',
}

export const getters = {
  [getterTypes.messageError]: (state) => {
    return state.messageError
  },
  [getterTypes.isLoading]: (state) => {
    return state.isLoading
  },
  [getterTypes.accessToken]: (state) => {
    if(state.accessToken != null){
      return  state.accessToken
    }

    return localStorage.getItem('bearer_access_token') 
  },
}

const mutations = {
  [mutationTypes.registerStart](state) {
    state.isLoading = true
  },
  [mutationTypes.registerSuccess](state) {
    state.isLoading = false
  },
  [mutationTypes.registerFailure](state) {
    state.isLoading = true
  },
  [mutationTypes.loginStart](state) {
    state.isLoading = true
    state.ifAuthenticated = false
    state.isAuthLoading = true
    state.messageError = null
  },
  [mutationTypes.loginSuccess](state, payload) {
    state.ifAuthenticated = true
    state.currentUser = payload
    state.isAuthLoading = false
    state.messageError = null
    state.isLoading = false
  },
  [mutationTypes.loginFailure](state, error) {
    state.ifAuthenticated = false
    state.isAuthLoading = true
    state.messageError = error.code
    state.isLoading = false
  },
  [mutationTypes.changePasswordStart](state) {
    state.isLoading = true
  },
  [mutationTypes.changePasswordSuccess](state) {
    state.isLoading = false
  },
  [mutationTypes.changePasswordFailure](state) {
    state.isLoading = true
  },
  [mutationTypes.forgotPasswordStart](state) {
    state.isLoading = true
  },
  [mutationTypes.forgotPasswordSuccess](state) {
    state.isLoading = false
  },
  [mutationTypes.forgotPasswordFailure](state) {
    state.isLoading = true
  },
  [mutationTypes.logout]() {
    state.isLoading = false
  },
  [mutationTypes.setAccessToken](state, payload) {
    state.accessToken = payload
    localStorage.removeItem('bearer_access_token')
    localStorage.setItem('bearer_access_token', payload)
    
  },
  [mutationTypes.cleanAccessToken](state) {
    localStorage.removeItem('bearer_access_token')
    state.accessToken = null
    
  },
}

export const actions = {
  [actionTypes.register](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.registerStart)
      authApi
        .register(credentials)
        .then((response) => {
          context.commit(mutationTypes.registerSuccess)
          resolve(response)
        })
        .catch((result) => {
          context.commit(mutationTypes.registerFailure, result.response.data)
        })
    })
  },
  [actionTypes.login](context, parameters) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.loginStart)
      authApi
        .login(parameters)
        .then((response) => {
          context.commit(mutationTypes.loginSuccess, response.data)
          context.commit(mutationTypes.setAccessToken, response.data.access_token)
          resolve(response.data)
        })
        .catch((result) => {
          context.commit(mutationTypes.cleanAccessToken)
          context.commit(mutationTypes.loginFailure, result.response.data.error)
        })
    })
  },
  [actionTypes.logout](context, parameters) {
    return new Promise((resolve) => {
      authApi.logout(parameters).then((response) => {
        context.commit(mutationTypes.logout)
        context.commit(mutationTypes.cleanAccessToken)
        resolve(response)
      })
    })
  },
  [actionTypes.changePassword](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.changePasswordStart)
      authApi
        .changePassword(credentials)
        .then((response) => {
          context.commit(mutationTypes.changePasswordSuccess)
          context.commit(mutationTypes.setAccessToken,response.data)
          resolve(response)
        })
        .catch((result) => {
          context.commit(
            mutationTypes.changePasswordFailure,
            result.response.data.error
          )
        })
    })
  },
  [actionTypes.forgotPassword](context, credentials) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.forgotPasswordStart)
      authApi
        .forgotPassword(credentials)
        .then((response) => {
          context.commit(mutationTypes.forgotPasswordSuccess)
          resolve(response)
        })
        .catch((result) => {
          context.commit(
            mutationTypes.forgotPasswordFailure,
            result.response.data.error
          )
        })
    })
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
