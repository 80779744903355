<template>
  <div class="main-index">
    <nodr-loader-logo v-if="isLoad" @isLoading="isLoads" />

    <div v-else class="app-main-layout">
      <nodr-sidebar />

      <nodr-header :currentUser="currentUser" />

      <router-view class="main-content" />
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import NodrSidebar from '@/components/Sidebar'
import NodrHeader from '@/components/Header'
import {getterTypes} from '@/store/modules/user'
import NodrLoaderLogo from '@/components/Loaders/LoaderLogo'
export default {
  name: 'NodrMainIndex',
  data() {
    return {
      isLoad: true,
    }
  },
  components: {NodrLoaderLogo, NodrHeader, NodrSidebar},
  computed: {
    ...Vuex.mapGetters({
      currentUser: getterTypes.currentUser,
    }),
  },
  methods: {
    isLoads(val) {
      this.isLoad = val.isL
    },
  },
}
</script>
