<template>
  <div class="modal-no-delete-user">
    <div class="modal-no-delete-user__inner">
      <div class="popup">
        <button @click="closeModal" class="close">
          <svg>
            <use
              xlink:href="../../../assets/img/sprite/sprite-header.svg#delete-draft-domain"></use>
          </svg>
        </button>
        <div class="modal-no-delete-user__title">
          <span class="font-h5 color_dark-gray">Unable to delete account</span>
        </div>

        <div
          v-if="
            findInfoDeleteUser.statusMessage ===
            statusTearmsDeletingUser.CANT_DELETE_USER_MUCH_TEAMS_SOLO_ADMIN_MUCH_USERS
          "
          class="modal-no-delete-user__text"
        >
          <span class="font-text color_gray"
          >It looks like you're the sole Administrator in the following teams.
            To be able to delete your account please pass your Administrative
            role in this team to another team member, or delete that team.</span
          >
        </div>
        <div
          v-if="
            findInfoDeleteUser.statusMessage ===
            statusTearmsDeletingUser.CANT_DELETE_USER_SOLO_ADMIN_MUCH_USERS
          "
          class="modal-no-delete-user__list-teams"
        >
          <div
            v-for="(team, index) in teams"
            :key="index"
            class="modal-no-delete-user-solo-admin__list-team"
          >
            <span class="font-text color_gray d-block">{{ team.name }}</span>
            <span class="font-text color_gray d-block">{{
                team.role === 10 ? 'Admin' : 'Viewer'
              }}</span>
          </div>
        </div>

        <div v-if="true" class="modal-no-delete-user__text">
          <span class="font-text color_gray">
            You are the sole Administrator in this team having a few users.
            Before you can delete your account please assign an Administrative
            role to at least one team member.
          </span>
        </div>

        <div class="modal-no-delete-user__btn">
          <button @click="closeModal" class="button button-blue-w color_white">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {STATUS_TEARMS_DELETING_USER} from '../../../common/enum/statusTearmsDeletingUser'
export default {
  name: 'NodrModalNoDeleteUser',
  props: {
    findInfoDeleteUser: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      teams: this.findInfoDeleteUser.teams,
      statusTearmsDeletingUser: STATUS_TEARMS_DELETING_USER,
    }
  },
  methods: {
    closeModal() {
      this.$emit('isCloseModalNoDeleteUser', false)
    },
  },
}
</script>
