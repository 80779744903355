<template>
  <div class="created-nodr">
    <div class="created-nodr__configuration">
      <div class="configuration-block__title">
        <span class="font-h5 color_dark-gray">
          Please choose the configuration for your NODR
        </span>
      </div>
      <div class="configuration-block__list">

<!--        <nodr-carousel :carousel_data="configNodr" @currentConfigIdOption="currentConfigId"/>-->

        <div
          class="configuration-block__item"
          @click="currentConfigId(cfg, index)"
          :class="{
            'configuration-block__item-soon': cfg.status !== 10,
            'configuration-block__item--active': currentActiveConfig === index,
          }"
          v-for="(cfg, index) in configNodr"
          :key="index">
          <div
            class="config-items"
            :class="{'config-item__soon--background': cfg.status !== 10}">
            <div class="config-item__title">
              <span class="font-small color_dodger-blue"> {{ cfg.name }} </span>
            </div>
            <div class="config-item">
              <svg>
                <use
                  xlink:href="@/assets/img/sprite/sprite-nodr.svg#volume-storage"></use>
              </svg>
              <span class="font-small color_gray">HDD {{ nodrConfigStorage(cfg.storage) }}B</span>
            </div>
            <div class="config-item">
              <svg>
                <use xlink:href="@/assets/img/sprite/sprite-nodr.svg#ram"></use>
              </svg>
              <span class="font-small color_gray">{{ nodrConfigRam(cfg.ram) }}B</span>
            </div>
            <div class="config-item">
              <svg>
                <use
                  xlink:href="@/assets/img/sprite/sprite-nodr.svg#speed"></use>
              </svg>
              <span class="font-small color_gray">{{ nodrConfigBandwidth(cfg.bandwidth) }}bit/s</span>
            </div>
            <div class="config-item">
              <svg>
                <use
                  xlink:href="@/assets/img/sprite/sprite-nodr.svg#static-ip"></use>
              </svg>
              <span class="font-small color_gray">{{ cfg.ip_type }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="created-nodr__install">
      <div class="install-title">
        <span class="font-h5 color_dark-gray">
          Download and run your NODR installation script
        </span>
      </div>
      <div class="install-description">
        <div class="install-description__text">
          <span class="font-small color_gray">
            The installation script helps you configure your server for NODR app. It will generate <span class="color_dodger-blue">public key</span> to register below, <br> check your OS, hardware and network environment. In case of successful check the NODR app will install and activate automatically
          </span>
          <span class="title_text-code font-small color_gray">To run the script you can download it by clicking the button on the right, or run a command on your server:</span>
          <div class="wrapper">
            <div class="text-code_block" @click="copyWget">
              <div class="text-code">
                <svg viewBox="0 0 12.32 9.33"><g><line class="st1" x1="7.6" y1="8.9" x2="7.6" y2="6.9"></line><rect width="1.9" height="1.9"></rect><rect x="1.9" y="1.9" width="1.9" height="1.9"></rect><rect x="3.7" y="3.7" width="1.9" height="1.9"></rect><rect x="1.9" y="5.6" width="1.9" height="1.9"></rect><rect y="7.5" width="1.9" height="1.9"></rect></g></svg>
                <span>wget -N https://gitlab.com/nodr/nodr-installer/-/raw/main/nodr_installer_linux_current.sh</span>
              </div>
              <div class="text-code">
                <svg viewBox="0 0 12.32 9.33"><g><line class="st1" x1="7.6" y1="8.9" x2="7.6" y2="6.9"></line><rect width="1.9" height="1.9"></rect><rect x="1.9" y="1.9" width="1.9" height="1.9"></rect><rect x="3.7" y="3.7" width="1.9" height="1.9"></rect><rect x="1.9" y="5.6" width="1.9" height="1.9"></rect><rect y="7.5" width="1.9" height="1.9"></rect></g></svg>
                <span>chmod +x nodr_installer_linux_current.sh</span>
              </div>
              <div class="text-code">
                <svg viewBox="0 0 12.32 9.33"><g><line class="st1" x1="7.6" y1="8.9" x2="7.6" y2="6.9"></line><rect width="1.9" height="1.9"></rect><rect x="1.9" y="1.9" width="1.9" height="1.9"></rect><rect x="3.7" y="3.7" width="1.9" height="1.9"></rect><rect x="1.9" y="5.6" width="1.9" height="1.9"></rect><rect y="7.5" width="1.9" height="1.9"></rect></g></svg>
                <span>./nodr_installer_linux_current.sh</span>
              </div>

              <svg class="copy">
                <use
                  xlink:href="../../assets/img/sprite/sprite-domains.svg#copy"></use>
              </svg>
            </div>
            <div class="install-description__block-download">
              <a class="button button-blue-w color_white" :href="links[0].url">
                <svg  width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <use xlink:href="@/assets/img/sprite/sprite-nodr.svg#os-linux"></use>
                </svg>
<!--                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                  <path fill-rule="evenodd" clip-rule="evenodd" d="M17.7734 15.001C17.5458 15 17.3312 15.1067 17.1947 15.2887C16.8295 15.7756 16.0662 16.7935 15.4193 17.656C14.7947 18.4889 14.8776 19.6545 15.6138 20.3906V20.3906C16.35 21.1269 17.5155 21.2097 18.3485 20.5851L20.7131 18.8119C20.8959 18.6748 21.0035 18.4597 21.0035 18.2311V17.6298C21.0035 16.9326 20.7265 16.264 20.2335 15.771V15.771C19.7406 15.278 19.0719 15.001 18.3748 15.001H17.7734Z" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path fill-rule="evenodd" clip-rule="evenodd" d="M6.22618 15.001C6.45373 15 6.66834 15.1067 6.80491 15.2887C7.17006 15.7756 7.93343 16.7935 8.58022 17.656C9.20485 18.4889 9.12196 19.6545 8.38573 20.3906V20.3906C7.64955 21.1269 6.48406 21.2097 5.65111 20.5851L3.2865 18.8119C3.10369 18.6748 2.99609 18.4597 2.99609 18.2311V17.6298C2.99609 16.9326 3.27305 16.264 3.76603 15.771V15.771C4.25901 15.278 4.92763 15.001 5.62482 15.001H6.22618Z" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path fill-rule="evenodd" clip-rule="evenodd" d="M10.052 11.4316L11.164 11.9875C11.69 12.2506 12.3092 12.2506 12.8353 11.9875L13.9472 11.4316C14.2861 11.2621 14.5002 10.9157 14.5002 10.5368C14.5002 10.1578 14.2861 9.81143 13.9472 9.64197L13.2531 9.29492C12.464 8.90039 11.5352 8.90039 10.7461 9.29492L10.052 9.64197C9.71312 9.81143 9.49902 10.1578 9.49902 10.5368C9.49902 10.9157 9.71312 11.2621 10.052 11.4316Z" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M10.4992 7.49805V9.41834" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M13.5012 7.49805V9.41834" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M15.0715 18.3742C15.6436 15.9799 15.3161 13.4588 14.1514 11.29" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M9.84818 11.29C8.68355 13.4587 8.35617 15.9796 8.92824 18.3737" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M5.12599 15.0505C5.10639 14.609 5.23406 14.1735 5.48894 13.8124L5.63475 13.6082C6.84668 11.9115 7.49818 9.87854 7.49819 7.79344V7.49797C7.49819 5.01165 9.51375 2.99609 12.0001 2.99609C14.4864 2.99609 16.5019 5.01165 16.5019 7.49797V7.79344C16.502 9.87854 17.1535 11.9115 18.3654 13.6082L18.5112 13.8124C18.7661 14.1735 18.8938 14.609 18.8742 15.0505" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                  <path d="M15.3128 19.9969C14.7525 19.9813 14.2065 19.8166 13.7311 19.5197L13.6798 19.4876C12.653 18.8421 11.3472 18.8421 10.3204 19.4876L10.2692 19.5197C9.79378 19.8166 9.24779 19.9813 8.6875 19.9969" stroke="#0062FF" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>-->
<!--                </svg>-->
                Download for Linux </a>
              <a href="#" id="js-nodr-install"></a>
            </div>
          </div>
        </div>

      </div>
    </div>

    <div class="created-nodr__key-verification">
      <div class="key-verification__title">
        <span class="font-h5 color_dark-gray">
          Register your NODR public key
        </span>
      </div>
      <div class="key-verification__input">
        <label
          class="visually-hidden"
          for="key-verification__validations-input-template">
          public key
        </label>
        <textarea
          style="resize: none"
          rows="4"
          class="input"
          :class="formatPublicKeyError || codeErrorPublicKey === 409 || codeErrorPublicKey === 500 ? 'error' : ''"
          type="text"
          id="key-verification__validations-input-template"
          placeholder="-----BEGIN PUBLIC KEY-----
MHYwEAYHKoZIzj0CAQYFK4EEACIDYgAERPmNC4pxRUWvF81xQstabck2nguQkfoT
5QxGQcSyaJyHam29ZVBNHzvbjBTC/G44+bwQxnbJyMCSVFJr4H2PboGxuzXU
-----END PUBLIC KEY-----"
          v-model="publicKey"
          required />
        <span class="input--close" type="submit" v-if="isPublicKeyValueClose && !isLoadingCreateNodrs">
          <svg @click="publicKeyValueClose">
            <use
              xlink:href="../../assets/img/sprite/sprite-header.svg#delete-draft-domain"></use>
          </svg>
          <nodr-loader v-if="isLoadingCreateNodrs" />
        </span>
        <div class="input-message-error">
          <template v-if="formatPublicKeyError || codeErrorPublicKey === 500">
            <span class="input--invalid-message font-small-2 color_coral"
              >Incorrect format public key. Please check your key and try
              again</span
            >
          </template>
          <template v-if="codeErrorPublicKey === 409">
            <span class="input--invalid-message font-small-2 color_coral">
              The key is already taken by another NODR. Please check that you enter your key and try again
            </span>
          </template>
        </div>
      </div>
      <div class="key-verification__confirm">
        <button
          @click="confirm()"
          class="button button-blue-w color_white"
          :class="publicKey.length === 0 ? 'button-disabled-basic' : ''"
          :disabled="this.publicKey.length === 0">
          Confirm
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import {bitesToString} from "@/common/helpers/bitesToString";
import {validatorPublicKey} from '@/plugins/validator'
import NodrLoader from '@/components/Loaders/Loader'
import {
  actionTypes as actionsTypeNodr,
  getterTypes as getterTypesNodr,
} from '@/store/modules/nodrs'
export default {
  name: 'NodrCreatedNodr',
  props: {
    configNodr: {
      type: Array,
      require: true,
    },
    currentUser: {
      type: Object,
      require: true,
    },
  },
  components: {NodrLoader},
  data() {
    return {
      formatPublicKeyError: false,
      codeErrorPublicKey: 0,
      publicKey: '',
      currentActiveConfig: 0,
      // configId: this.configNodr[0].id,
      isPublicKeyValueClose: false,
      links: [
        {
          id: 1,
          name: 'Linux',
          url: 'https://gitlab.com/nodr/nodr-installer/-/raw/main/nodr_installer_linux_current.sh?inline=false'
        },
        {
          id: 2,
          name: 'Windows',
          url: 'https://gitlab.com/nodr/nodr-installer/-/raw/main/nodr_installer_linux_current.sh?inline=false'
        },
        {
          id: 3,
          name: 'MacOs',
          url: 'https://gitlab.com/nodr/nodr-installer/-/raw/main/nodr_installer_linux_current.sh?inline=false'
        },
      ],
    }
  },
  watch: {
    publicKey() {
      this.publicKey.length > 0
        ? (this.isPublicKeyValueClose = true)
        : (this.isPublicKeyValueClose = false)
      this.formatPublicKeyError = false
      this.codeErrorPublicKey = 0
    },
  },
  computed: {
    ...Vuex.mapGetters({
      isLoadingCreateNodrs: getterTypesNodr.isLoadingCreateNodrs,
      errorCodeCreatedNodr: getterTypesNodr.errorCodeCreatedNodr,
    }),
  },
  methods: {
    ...Vuex.mapActions({
      createNodr: actionsTypeNodr.createNodr,
    }),
    copyWget() {
      this.$clipboard('wget -N https://gitlab.com/nodr/nodr-installer/-/raw/main/nodr_installer_linux_current.sh ; chmod +x nodr_installer_linux_current.sh ; ./nodr_installer_linux_current.sh')
    },
    nodrConfigBandwidth(config) {
      return bitesToString(config)
    },
    nodrConfigRam(config) {
      return bitesToString(config)
    },
    nodrConfigStorage(config) {
      return bitesToString(config)
    },
    publicKeyValueClose() {
      this.isPublicKeyValueClose = false
      this.publicKey = ''
    },
    currentConfigId(cfg, index) {
      if (cfg.status === 10) {
        this.configId = cfg.id
        this.currentActiveConfig = index
      }
    },
    confirm() {
      if (validatorPublicKey(this.publicKey)) {
        this.createNodr({
          configId: this.configId ? this.configId : this.configNodr[0].id,
          publicKey: this.publicKey,
          teamId: this.currentUser.TeamsRender[0].id,
        })
          .then(() => this.$emit('searchNodrsParams', true))
          .catch(() => this.codeErrorPublicKey = this.errorCodeCreatedNodr.code)
      } else {
        this.formatPublicKeyError = true
      }
    },
  },

}
</script>
