import one from '../../assets/img/jpg/defaut-image-user-daschboard-1.jpeg'
import two from '../../assets/img/jpg/defaut-image-user-daschboard-2.jpeg'
import three from '../../assets/img/jpg/defaut-image-user-daschboard-3.jpeg'
import four from '../../assets/img/jpg/defaut-image-user-daschboard-4.jpeg'
import five from '../../assets/img/jpg/defaut-image-user-daschboard-5.jpeg'
import six from '../../assets/img/jpg/defaut-image-user-daschboard-6.jpeg'
import seven from '../../assets/img/jpg/defaut-image-user-daschboard-7.jpeg'
import eight from '../../assets/img/jpg/defaut-image-user-daschboard-8.jpeg'
import nine from '../../assets/img/jpg/defaut-image-user-daschboard-9.jpeg'
import ten from '../../assets/img/jpg/defaut-image-user-daschboard-10.jpeg'
import eleven from '../../assets/img/jpg/defaut-image-user-daschboard-11.jpeg'
import twelve from '../../assets/img/jpg/defaut-image-user-daschboard-12.jpeg'

export const DEFAULT_USER_IMAGES = [
  {
    url: one,
  },
  {
    url: two,
  },
  {
    url: three,
  },
  {
    url: four,
  },
  {
    url: five,
  },
  {
    url: six,
  },
  {
    url: seven,
  },
  {
    url: eight,
  },
  {
    url: nine,
  },
  {
    url: ten,
  },
  {
    url: eleven,
  },
  {
    url: twelve,
  },
]
