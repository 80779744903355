<template>
  <div class="delete-account-user">
    <div class="delete-account-user__wrapper">
      <div class="delete-account-user__inner">
        <nodr-loader-white v-if="loaderResultAcceptChangeEmail" />

        <div
          v-if="messageResultDeleteAccountUser === 'success'"
          class="delete-account-user__text">
          <div class="font-h2 color_white">
            You have successfully <br />
            deleted your account
          </div>
          <p class="font-h6 color_white">
            We are sorry you choose to delete your account. If you have
            unresolved issues, please feel free to contact our CEO directly via
            <a class="font-h6 color_white" href="mailto:support@nodr.app"
              >support@nodr.app</a
            >.
          </p>
        </div>

        <div
          v-else-if="messageResultDeleteAccountUser === 'error'"
          class="delete-account-user__text">
          <div class="font-h2 color_white">
            This link is expired
          </div>
          <p class="font-h6 color_white">
            Unfortunately, this link is no longer valid. Please contact <br />
            <a class="font-h6 color_white" href="mailto:support@nodr.app"
              >support@nodr.app</a
            >
            if you need an assistance
          </p>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import NodrLoaderWhite from '../Loaders/Loader-white'
import Vuex from 'vuex'
import {actionTypes, getterTypes} from '../../store/modules/user'

export default {
  name: 'NodrDeleteAccountUserResult',
  components: {NodrLoaderWhite},
  computed: {
    ...Vuex.mapGetters({
      messageResultDeleteAccountUser:
        getterTypes.messageResultDeleteAccountUser,
      loaderResultAcceptChangeEmail: getterTypes.loaderResultAcceptChangeEmail,
    }),
  },
  methods: {
    ...Vuex.mapActions({
      acceptDeleteAccountUser: actionTypes.acceptDeleteAccountUser,
    }),
    accept() {
      this.acceptDeleteAccountUser({
        hash: this.$route.params.hash,
      })
    },
  },
  mounted() {
    this.accept()
  },
}
</script>
