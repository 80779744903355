var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-no-delete-user"},[_c('div',{staticClass:"modal-no-delete-user__inner"},[_c('div',{staticClass:"popup"},[_c('button',{staticClass:"close",on:{"click":_vm.closeModal}},[_c('svg',[_c('use',{attrs:{"xlink:href":require("../../../assets/img/sprite/sprite-header.svg") + "#delete-draft-domain"}})])]),_vm._m(0),(
          _vm.findInfoDeleteUser.statusMessage ===
          _vm.statusTearmsDeletingUser.CANT_DELETE_USER_MUCH_TEAMS_SOLO_ADMIN_MUCH_USERS
        )?_c('div',{staticClass:"modal-no-delete-user__text"},[_c('span',{staticClass:"font-text color_gray"},[_vm._v("It looks like you're the sole Administrator in the following teams. To be able to delete your account please pass your Administrative role in this team to another team member, or delete that team.")])]):_vm._e(),(
          _vm.findInfoDeleteUser.statusMessage ===
          _vm.statusTearmsDeletingUser.CANT_DELETE_USER_SOLO_ADMIN_MUCH_USERS
        )?_c('div',{staticClass:"modal-no-delete-user__list-teams"},_vm._l((_vm.teams),function(team,index){return _c('div',{key:index,staticClass:"modal-no-delete-user-solo-admin__list-team"},[_c('span',{staticClass:"font-text color_gray d-block"},[_vm._v(_vm._s(team.name))]),_c('span',{staticClass:"font-text color_gray d-block"},[_vm._v(_vm._s(team.role === 10 ? 'Admin' : 'Viewer'))])])}),0):_vm._e(),(true)?_c('div',{staticClass:"modal-no-delete-user__text"},[_c('span',{staticClass:"font-text color_gray"},[_vm._v(" You are the sole Administrator in this team having a few users. Before you can delete your account please assign an Administrative role to at least one team member. ")])]):_vm._e(),_c('div',{staticClass:"modal-no-delete-user__btn"},[_c('button',{staticClass:"button button-blue-w color_white",on:{"click":_vm.closeModal}},[_vm._v(" Ok ")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"modal-no-delete-user__title"},[_c('span',{staticClass:"font-h5 color_dark-gray"},[_vm._v("Unable to delete account")])])
}]

export { render, staticRenderFns }