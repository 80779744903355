import axios from '../services/api.axios'
import Config from '../common/config'
import * as auth from '../store/modules/auth'

const check = () => {
  
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios({
    method: 'get',
    url: Config.apiAuth  +'/'+ Config.scope + '/v1/check',
    headers: {'Authorization': 'Bearer '+ token}
  })
}

const searchUsers = () => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios.post(Config.apiAuth  +'/'+ Config.scope + '/v1/user', {},{headers: 
    {
      'Authorization':'Bearer ' + token
    }
  })
}

const getUsers = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios.get(Config.apiAuth  +'/'+ Config.scope + '/v1/user/' + params.id, params,{headers: 
    {
      'Authorization':'Bearer ' + token
    }
  })
}

const updateUser = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios.put(Config.apiAuth  +'/'+ Config.scope + '/v1/user/' + params.id, params,{headers: 
    {
      'Authorization':'Bearer ' + token
    }
  })
}

const changePasswordUser = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  let formData = new FormData()
  formData.append('user_id', params.user_id)
  formData.append('password', params.password)
  formData.append('new_password', params.new_password)
  formData.append('confirm', params.confirm)

  return axios({
    method: 'post',
    url:
      Config.apiAuth  +'/'+ Config.scope +
      '/v1/profile_settings/user/' +
      params.user_id +
      '/team/' +
      params.team_id +
      '/change_password',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+ token},
  })
}

const changeEmail = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  let formData = new FormData()
  formData.append('userId', params.userId)
  formData.append('password', params.password)
  formData.append('email', params.email)
  formData.append('url', params.url)

  return axios({
    method: 'post',
    url:
      Config.apiAuth  +'/'+ Config.scope +
      '/v1/profile_settings/user/' +
      params.user_id +
      '/team/' +
      params.team_id +
      '/change_email',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+ token},
  })
}

const acceptChangeEmail = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  let formData = new FormData()
  formData.append('hash', params.hash)
  return axios({
    method: 'post',
    url: Config.apiAuth  +'/'+ Config.scope + '/v1/profile_settings/change_email/accept',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+ token},
  })
}

const resentChangeEmail = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  let formData = new FormData()
  formData.append('userId', params.userId)
  formData.append('url', params.url)

  return axios({
    method: 'post',
    url:
      Config.apiAuth  +'/'+ Config.scope +
      '/v1/profile_settings/user/' +
      params.id +
      '/change_email/resend',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+ token},
  })
}

const revokeChangeEmail = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  let formData = new FormData()
  formData.append('user_id', params.user_id)

  return axios({
    method: 'post',
    url:
      Config.apiAuth  +'/'+ Config.scope +
      '/v1/profile_settings/user/' +
      params.id +
      '/change_email/revoke',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+ token},
  })

  // return axios.post(
  //   Config.apiAuth  +'/'+ Config.scope +
  //     '/v1/profile_settings/user/' +
  //     params.id +
  //     '/change_email/revoke',
  //   params
  // )
}

const infoDeleteUser = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios.post(
    Config.apiAuth  +'/'+ Config.scope + '/v1/user/' + params.userId + '/info_delete',{headers: 
      {
        'Authorization':'Bearer ' + token
      }
    }
  )
}

const deleteAccountUser = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  let formData = new FormData()
  formData.append('user_id', params.user_id)
  formData.append('password', params.password)
  formData.append('url', params.url)

  return axios({
    method: 'delete',
    url: Config.apiAuth  +'/'+ Config.scope + '/v1/user/' + params.user_id,
    data: formData,
    headers: {'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+ token},
  })
}

const resendDeleteAccountUser = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  let formData = new FormData()
  formData.append('user_id', params.id)
  formData.append('url', params.url)

  return axios({
    method: 'post',
    url: Config.apiAuth  +'/'+ Config.scope + '/v1/user/' + params.id + '/resend',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+ token},
  })
}

const revokeDeleteAccountUser = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios.post(Config.apiAuth  +'/'+ Config.scope + '/v1/user/' + params.id + '/revoke', params,{headers: 
    {
      'Authorization':'Bearer ' + token
    }
  })
}

const acceptDeleteAccountUser = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  let formData = new FormData()
  formData.append('hash', params.hash)
  return axios({
    method: 'delete',
    url: Config.apiAuth  +'/'+ Config.scope + '/v1/user/accept',
    data: formData,
    headers: {'Content-Type': 'multipart/form-data','Authorization': 'Bearer '+ token},
  })
}

export default {
  check,
  searchUsers,
  getUsers,
  updateUser,
  changePasswordUser,
  changeEmail,
  acceptChangeEmail,
  resentChangeEmail,
  revokeChangeEmail,
  infoDeleteUser,
  deleteAccountUser,
  resendDeleteAccountUser,
  revokeDeleteAccountUser,
  acceptDeleteAccountUser,
}
