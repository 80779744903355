<template>
  <div class="modal-deactivete-nodr">
    <div class="modal-deactiveted-nodr__inner">
      <div class="popup">
        <button class="close">
          <svg @click="closeModal">
            <use
              xlink:href="../../../assets/img/sprite/sprite-header.svg#delete-draft-domain"></use>
          </svg>
        </button>
        <div class="popup__title font-h5 color_dark-gray">
          Are you sure you want to deactivate <br />
          <span class="color_dodger-blue">{{
            currentDeacteveteNodr.name !== null || ''
              ? currentDeacteveteNodr.name
              : 'NODR name'
          }}</span>
          NODR?
        </div>
        <div class="popup__description font-text color_gray">
          You will no longer be receiving service <br />
          for this NODR until you will re-activate it
        </div>
        <div class="popup__btn">
          <button
            class="button button-blue-w color_white"
            @click="deactivetedNodr()">
            Confirm
          </button>
          <button class="button button-border-w" @click="closeModal">
            Cancel
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodrModalDeactiveteNodr',
  props: {
    currentDeacteveteNodr: {
      type: Object,
      require: true,
    },
  },
  methods: {
    closeModal() {
      this.$emit('deactivetedNodrParamsClose', false)
    },
    deactivetedNodr() {
      this.$emit('deactivetedNodrParams', this.currentDeacteveteNodr)
    },
  },
}
</script>
