<template>
  <div class="password-recovery">
    <div class="password-recovery__wrapper">
      <div class="password-recovery__inner">
        <div class="password-recovery__text">
          <div class="font-h2 color_white">Password recovery</div>
          <p class="font-h6 color_white">
            We will send the recovery instruction on the e-mail in case we'll
            find an account associated with it. <br>
            Please check
            <br />
            the mailbox
          </p>
          <p class="font-h6 color_white">
            Still having trouble logging in? Contact support
            <a class="font-h6 color_white" href="mailto:support@nodr.app"
              >support@nodr.app</a
            >.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TlprtPasswordRecovery',
}
</script>
