<template>
  <div class="wrapper">
    <div class="v-carousel">
      <nodr-carousel-item
        v-for="(item, idx) in carousel_data"
        :key="item.id"
        :item_data="item"
        :idx_item_data="idx"
        :currentNodr="currentNodr"
        :active="activeElem === item"
        @activate="activeElem = item"
        @currentConfigIdOptions="$emit('currentConfigIdOption', $event)"
        :class="{'configuration-block__item--active': item.status === 10 && activeElem === item}"
      />
    </div>
  </div>
</template>

<script>
import NodrCarouselItem from "@/components/Carousel/v-carousel-item";
export default {
  name: 'NodrCarousel',
  components: {NodrCarouselItem},
  props: {
    carousel_data: {
      type: Array,
      default: () => []
    },
    currentNodr: {
      type: Object,
      require: true
    },
  },
  data(){
    return {
      activeElem: null,
    }
  },
  methods: {

  }
}
</script>
