import userApi from '../../services/api.user'
import {termsDeletingUser} from '../../common/helpers/termsDeletingUser'

const state = {
  ifAuthenticated: false,
  isLoading: false,
  currentUser: null,
  users: null,
  checkUser: {},

  statusUserChangeEmail: null,
  statusDeleteAccountUser: false,

  inProcessNewChangeEmail: '',
  isChangePasswordSuccess: false,

  codeErrorMessageUserChangePassword: null,

  messageErrorCodeChangeEmail: null,
  messageErrorCodeDeleteAccountUser: null,
  messageResultAcceptChangeEmail: '',
  messageResultDeleteAccountUser: '',

  loaderResultAcceptChangeEmail: false,
  loaderResultDeleteAccountUser: false,

  isLoadingChangePassword: false,
  isLoadingChangeEmail: false,
  isLoadingUpdateUser: false,
  isLoadingPermissionToDelete: false,

  permissionToDelete: null,

  findInfoDeleteUser: {},
  statusDeletingAccountUser: null,
}

export const mutationTypes = {
  checkUserStart: '[user] Check user start',
  checkUserSuccess: '[user] Check user success',
  checkUserFailure: '[user] Check user failure',

  getUserStart: '[user] Get user start',
  getUserSuccess: '[user] Get user success',
  getUserFailure: '[user] Get user failure',

  searchUsersStart: '[user] Search users start',
  searchUsersSuccess: '[user] Search users success',
  searchUsersFailure: '[user] Search users failure',

  updateUsersStart: '[user] Update users start',
  updateUsersSuccess: '[user] Update users success',
  updateUsersFailure: '[user] Update users failure',

  changeEmailStart: '[user] Change E-mail start',
  changeEmailSuccess: '[user] Change E-mail success',
  changeEmailFailure: '[user] Change E-mail failure',

  changePasswordStart: '[user] Change password start',
  changePasswordSuccess: '[user] Change password success',
  changePasswordFailure: '[user] Change password failure',

  resentChangeEmailStart: '[user] Resent change e-mail start',
  resentChangeEmailSuccess: '[user] Resent change e-mail success',
  resentChangeEmailFailure: '[user] Resent change e-mail failure',

  revokeChangeEmailStart: '[user] Revoke change e-mail start',
  revokeChangeEmailSuccess: '[user] Revoke change e-mail success',
  revokeChangeEmailFailure: '[user] Revoke change e-mail failure',

  acceptResultChangeEmailStart: '[user] Accept result change e-mail start',
  acceptResultChangeEmailSuccess: '[user] Accept result change e-mail success',
  acceptResultChangeEmailFailure: '[user] Accept result change e-mail failure',

  acceptChangeEmailSuccess: '[user] Accept change e-mail success',

  infoDeleteUserStart: '[user] Info delete user start',
  infoDeleteUserSuccess: '[user] Info delete user success',
  infoDeleteUserFailure: '[user] Info delete user failure',

  deletingAccountUserStart: '[user] Deleting account delete user start',
  deletingAccountUserSuccess: '[user] Deleting account delete user success',
  deletingAccountUserFailure: '[user] Deleting account delete user failure',

  resendDeleteAccountUserStart: '[user] Resend delete account user start',
  resendDeleteAccountUserSuccess: '[user] Resend delete account user success',
  resendDeleteAccountUserFailure: '[user] Resend delete account user failure',

  revokeDeleteAccountUserStart: '[user] Revoke delete account user start',
  revokeDeleteAccountUserSuccess: '[user] Revoke delete account user success',
  revokeDeleteAccountUserFailure: '[user] Revoke delete account user failure',

  acceptDeleteAccountUserStart: '[user] Accept delete account user start',
  acceptDeleteAccountUserSuccess: '[user] Accept delete account user success',
  acceptDeleteAccountUserFailure: '[user] Accept delete account user failure',

  acceptResultDeleteAccountUser: '[user] Accept result account user success',
}

export const actionTypes = {
  check: '[user] Check user',
  getUser: '[user] Get user',
  searchUsers: '[user] Search users',
  updateUsers: '[user] Update users',
  changeEmail: '[user] Change E-mail',
  changePassword: '[user] Change password',
  resentChangeEmail: '[user] Resent change e-mail',
  revokeChangeEmail: '[user] Revoke change e-mail',
  acceptChangeEmail: '[user] Accept change e-mail',
  infoDeleteUser: '[user] Info delete user',
  deletingAccountUser: '[user] Deleting account user',
  resendDeleteAccountUser: '[user] Resend delete account user',
  revokeDeleteAccountUser: '[user] Revoke delete account user',
  acceptDeleteAccountUser: '[user] Accept delete account user',
}

export const getterTypes = {
  checkUser: '[user] Check user',
  currentUser: '[user] Current user',
  isLoading: '[user] isLoading',
  statusUserChangeEmail: '[user] Status user change e-mail',
  inProcessNewChangeEmail: '[user] In process new change e-mail',
  isChangePasswordSuccess: '[user] Is change password message success',
  codeErrorMessageUserChangePassword:
    '[user] Code error message user change password',
  messageErrorCodeChangeEmail: '[user] Message error code change e-mail',
  messageErrorCodeDeleteAccountUser:
    '[user] Message error code delete account user',
  messageResultAcceptChangeEmail: '[user] Message result accept change e-mail',
  messageResultDeleteAccountUser: '[user] Message result delete account user',
  loaderResultAcceptChangeEmail: '[user] Loader result accept change e-mail',
  loaderResultDeleteAccountUser:
    '[user] Loader result accept delete account user',
  isLoadingChangePassword: '[user] Loader change password',
  isLoadingChangeEmail: '[user] Loader change email',
  isLoadingUpdateUser: '[user] Loading update user',
  permissionToDelete: '[user] Permission number to delete user',
  isLoadingPermissionToDelete:
    '[user] Is loading permission number to delete user',
  findInfoDeleteUser: '[user] Find info delete user',
}

const getters = {
  [getterTypes.checkUser]: (state) => {
    return state.checkUser
  },
  [getterTypes.currentUser]: (state) => {
    return state.currentUser
  },
  [getterTypes.isLoading]: (state) => {
    return state.isLoading
  },
  [getterTypes.statusUserChangeEmail]: (state) => {
    return state.statusUserChangeEmail
  },
  [getterTypes.inProcessNewChangeEmail]: (state) => {
    return state.inProcessNewChangeEmail
  },
  [getterTypes.isChangePasswordSuccess]: (state) => {
    return state.isChangePasswordSuccess
  },
  [getterTypes.codeErrorMessageUserChangePassword]: (state) => {
    return state.codeErrorMessageUserChangePassword
  },
  [getterTypes.messageErrorCodeChangeEmail]: (state) => {
    return state.messageErrorCodeChangeEmail
  },
  [getterTypes.messageErrorCodeDeleteAccountUser]: (state) => {
    return state.messageErrorCodeDeleteAccountUser
  },
  [getterTypes.messageResultAcceptChangeEmail]: (state) => {
    return state.messageResultAcceptChangeEmail
  },
  [getterTypes.messageResultDeleteAccountUser]: (state) => {
    return state.messageResultDeleteAccountUser
  },
  [getterTypes.loaderResultAcceptChangeEmail]: (state) => {
    return state.loaderResultAcceptChangeEmail
  },
  [getterTypes.loaderResultDeleteAccountUser]: (state) => {
    return state.loaderResultDeleteAccountUser
  },
  [getterTypes.isLoadingChangePassword]: (state) => {
    return state.isLoadingChangePassword
  },
  [getterTypes.isLoadingChangeEmail]: (state) => {
    return state.isLoadingChangeEmail
  },
  [getterTypes.isLoadingUpdateUser]: (state) => {
    return state.isLoadingUpdateUser
  },
  [getterTypes.permissionToDelete]: (state) => {
    return state.permissionToDelete
  },
  [getterTypes.isLoadingPermissionToDelete]: (state) => {
    return state.isLoadingPermissionToDelete
  },
  [getterTypes.findInfoDeleteUser]: (state) => {
    return state.findInfoDeleteUser
  },
}

const mutations = {
  [mutationTypes.checkUserStart](state) {
    state.ifAuthenticated = false
    state.isLoading = true
  },
  [mutationTypes.checkUserSuccess](state, payload) {
    state.ifAuthenticated = true
    state.isLoading = false
    state.currentUser = payload
    // console.log('>> check currentUser',state.currentUser)
  },
  [mutationTypes.checkUserFailure](state) {
    state.isLoading = false
    state.currentUser = null
    state.ifAuthenticated = false
  },
  [mutationTypes.getUserStart](state) {
    state.isLoading = true
  },
  [mutationTypes.getUserSuccess](state) {
    state.isLoading = false
  },
  [mutationTypes.getUserFailure](state) {
    state.isLoading = false
  },
  [mutationTypes.updateUsersStart](state) {
    state.isLoadingUpdateUser = true
    state.ifAuthenticated = false
    state.isLoading = true
  },
  [mutationTypes.updateUsersSuccess](state, payload) {
    state.isLoadingUpdateUser = false
    state.ifAuthenticated = true
    state.isLoading = false
    state.currentUser = { ...state.currentUser, payload }
  },
  [mutationTypes.updateUsersFailure](state) {
    state.isLoadingUpdateUser = false
    state.isLoading = false
    state.currentUser = null
    state.ifAuthenticated = false
  },
  [mutationTypes.searchUsersStart](state) {
    state.ifAuthenticated = false
    state.isLoading = true
  },
  [mutationTypes.searchUsersSuccess](state, payload) {
    state.ifAuthenticated = true
    state.isLoading = false
    state.currentUser = payload
  },
  [mutationTypes.searchUsersFailure](state) {
    state.isLoading = false
    state.currentUser = null
    state.ifAuthenticated = false
  },
  [mutationTypes.changeEmailStart](state) {
    state.isLoadingChangeEmail = true
    state.statusUserChangeEmail = null
    state.messageErrorCodeChangeEmail = null
  },
  [mutationTypes.changeEmailSuccess](state, payload) {
    state.isLoadingChangeEmail = false
    // state.currentUser = payload
    state.statusUserChangeEmail = payload.statusChangeEmail
    state.inProcessNewChangeEmail = payload.newChangeEmail
    state.messageErrorCodeChangeEmail = null
  },
  [mutationTypes.changeEmailFailure](state, error) {
    state.isLoadingChangeEmail = false
    state.statusUserChangeEmail = null
    state.messageErrorCodeChangeEmail = error.message
  },
  [mutationTypes.resentChangeEmailStart](state) {
    state.statusUserChangeEmail = null
  },
  [mutationTypes.resentChangeEmailSuccess](state) {
    state.statusUserChangeEmail = null
  },
  [mutationTypes.resentChangeEmailFailure](state) {
    state.statusUserChangeEmail = null
  },
  [mutationTypes.revokeChangeEmailStart](state) {
    state.statusUserChangeEmail = null
  },
  [mutationTypes.revokeChangeEmailSuccess](state, payload) {
    // state.currentUser = payload
    state.statusUserChangeEmail = null
  },
  [mutationTypes.revokeChangeEmailFailure](state) {
    state.statusUserChangeEmail = null
  },
  [mutationTypes.acceptResultChangeEmailStart](state) {
    state.messageResultAcceptChangeEmail = null
    state.loaderResultAcceptChangeEmail = true
  },
  [mutationTypes.acceptResultChangeEmailSuccess](state, payload) {
    state.messageResultAcceptChangeEmail = payload
    state.loaderResultAcceptChangeEmail = false
  },
  [mutationTypes.acceptResultChangeEmailFailure](state, payload) {
    state.messageResultAcceptChangeEmail = payload
    state.loaderResultAcceptChangeEmail = false
  },
  [mutationTypes.acceptChangeEmailSuccess](state, payload) {
    // state.currentUser = payload
  },
  [mutationTypes.changePasswordStart](state) {
    state.isLoadingChangePassword = true
    state.isChangePasswordSuccess = false
    state.codeErrorMessageUserChangePassword = null
  },
  [mutationTypes.changePasswordSuccess](state, payload) {
    state.isLoadingChangePassword = false
    // state.currentUser = payload
    state.isChangePasswordSuccess = true
    state.codeErrorMessageUserChangePassword = null
  },
  [mutationTypes.changePasswordFailure](state, error) {
    state.isLoadingChangePassword = false
    state.codeErrorMessageUserChangePassword = error.code
    state.isChangePasswordSuccess = false
  },
  [mutationTypes.infoDeleteUserStart](state) {
    state.isLoadingPermissionToDelete = true
    state.permissionToDelete = null
  },
  [mutationTypes.infoDeleteUserSuccess](state, payload) {
    state.isLoadingPermissionToDelete = false
    state.findInfoDeleteUser = termsDeletingUser(payload)
  },
  [mutationTypes.infoDeleteUserFailure](state) {
    state.isLoadingPermissionToDelete = false
  },
  [mutationTypes.deletingAccountUserStart](state) {
    state.statusDeletingAccountUser = null
    state.messageErrorCodeDeleteAccountUser = null
  },
  [mutationTypes.deletingAccountUserSuccess](state, payload) {
    state.statusDeletingAccountUser = null
    state.messageErrorCodeDeleteAccountUser = null
    // state.currentUser = payload
  },
  [mutationTypes.deletingAccountUserFailure](state, payload) {
    state.statusDeletingAccountUser = null
    state.messageErrorCodeDeleteAccountUser = payload.message
  },
  [mutationTypes.resendDeleteAccountUserStart](state) {
    state.statusDeleteAccountUser = false
  },
  [mutationTypes.resendDeleteAccountUserSuccess](state) {
    state.statusDeleteAccountUser = false
  },
  [mutationTypes.resendDeleteAccountUserFailure](state) {
    state.statusDeleteAccountUser = false
  },
  [mutationTypes.revokeDeleteAccountUserStart](state) {
    state.statusDeleteAccountUser = false
  },
  [mutationTypes.revokeDeleteAccountUserSuccess](state, payload) {
    // state.currentUser = payload
    state.statusDeleteAccountUser = false
  },
  [mutationTypes.revokeDeleteAccountUserFailure](state) {
    state.statusDeleteAccountUser = false
  },
  [mutationTypes.acceptDeleteAccountUserStart](state) {
    state.currentUser = null
    state.loaderResultDeleteAccountUser = true
  },
  [mutationTypes.acceptDeleteAccountUserSuccess](state, payload) {
    // state.currentUser = payload
    state.loaderResultDeleteAccountUser = false
  },
  [mutationTypes.acceptDeleteAccountUserFailure](state, payload) {
    // state.currentUser = payload
    state.loaderResultDeleteAccountUser = false
  },
  [mutationTypes.acceptResultDeleteAccountUser](state, payload) {
    state.messageResultDeleteAccountUser = payload
  },
}

const actions = {
  [actionTypes.check](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.checkUserStart)
      userApi
        .check()
        .then((response) => {
          localStorage.getItem('bearer_access_token')
          context.commit(mutationTypes.checkUserSuccess, response.data)
          resolve(response.data)
        })
        .catch((error) => {
          if (error.response.data) {
            context.commit(mutationTypes.checkUserFailure, {root: true})
            reject('Error', error)
          }
        })
    })
  },
  [actionTypes.getUser](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.getUserStart)
      userApi
        .getUsers(params)
        .then((response) => {
          context.commit(mutationTypes.getUserSuccess, response.data)
          resolve(response.data)
        })
        .catch((error) => {
          context.commit(mutationTypes.getUserFailure)
          reject(error)
        })
    })
  },
  [actionTypes.searchUsers](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.searchUsersStart)
      userApi
        .searchUsers(params)
        .then((response) => {
          context.commit(mutationTypes.searchUsersSuccess, response.data)
          resolve(response.data)
        })
        .catch((result) => {
          context.commit(mutationTypes.searchUsersFailure)
          result.response.data.errors
        })
    })
  },
  [actionTypes.updateUsers](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.updateUsersStart)
      userApi
        .updateUser(params)
        .then((response) => {
          context.commit(mutationTypes.updateUsersSuccess, response.data)
          resolve(response.data)
        })
        .catch((result) => {
          context.commit(mutationTypes.updateUsersFailure)
          result.response.data.errors
        })
    })
  },
  [actionTypes.changeEmail](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changeEmailStart)
      userApi
        .changeEmail(params)
        .then((response) => {
          context.commit(mutationTypes.changeEmailSuccess, response.data)
          resolve(response)
        })
        .catch((error) => {
          context.commit(
            mutationTypes.changeEmailFailure,
            error.response.data.error
          )
          reject(error)
        })
    })
  },
  [actionTypes.changePassword](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.changePasswordStart)
      userApi
        .changePasswordUser(params)
        .then((response) => {
          context.commit(mutationTypes.changePasswordSuccess, response.data)
          resolve(response)
        })
        .catch((error) => {
          context.commit(
            mutationTypes.changePasswordFailure,
            error.response.data.error
          )
          console.error(error)
          reject(error)
        })
    })
  },
  [actionTypes.resentChangeEmail](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.resentChangeEmailStart)
      userApi
        .resentChangeEmail(params)
        .then((response) => {
          context.commit(mutationTypes.resentChangeEmailSuccess, response.data)
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.resentChangeEmailFailure)
          reject(error)
        })
    })
  },
  [actionTypes.revokeChangeEmail](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.revokeChangeEmailStart)
      userApi
        .revokeChangeEmail(params)
        .then((response) => {
          context.commit(mutationTypes.revokeChangeEmailSuccess, response.data)
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.revokeChangeEmailFailure)
          reject(error)
        })
    })
  },
  [actionTypes.acceptChangeEmail](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.acceptResultChangeEmailStart)
      userApi
        .acceptChangeEmail(params)
        .then((response) => {
          context.commit(mutationTypes.acceptChangeEmailSuccess, response.data)
          context.commit(
            mutationTypes.acceptResultChangeEmailSuccess,
            'success'
          )
        })
        .catch((error) => {
          context.commit(mutationTypes.acceptResultChangeEmailFailure, 'error')
          console.error(error.response.data.error)
          reject(error)
        })
    })
  },
  [actionTypes.infoDeleteUser](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.infoDeleteUserStart)
      userApi
        .infoDeleteUser(params)
        .then((response) => {
          context.commit(mutationTypes.infoDeleteUserSuccess, response.data)
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.infoDeleteUserFailure)
          reject(error)
        })
    })
  },
  [actionTypes.deletingAccountUser](context, params) {
    context.commit(mutationTypes.deletingAccountUserStart)
    return new Promise((resolve, reject) => {
      userApi
        .deleteAccountUser(params)
        .then((response) => {
          context.commit(
            mutationTypes.deletingAccountUserSuccess,
            response.data
          )
          resolve(response)
        })
        .catch((error) => {
          context.commit(
            mutationTypes.deletingAccountUserFailure,
            error.response.data.error
          )
          reject(error)
        })
    })
  },
  [actionTypes.resendDeleteAccountUser](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.resendDeleteAccountUserStart)
      userApi
        .resendDeleteAccountUser(params)
        .then((response) => {
          context.commit(
            mutationTypes.resendDeleteAccountUserSuccess,
            response.data
          )
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.resendDeleteAccountUserFailure)
          reject(error)
        })
    })
  },
  [actionTypes.revokeDeleteAccountUser](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.revokeDeleteAccountUserStart)
      userApi
        .revokeDeleteAccountUser(params)
        .then((response) => {
          context.commit(
            mutationTypes.revokeDeleteAccountUserSuccess,
            response.data
          )
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.revokeDeleteAccountUserFailure)
          reject(error)
        })
    })
  },
  [actionTypes.acceptDeleteAccountUser](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.acceptDeleteAccountUserStart)
      userApi
        .acceptDeleteAccountUser(params)
        .then((response) => {
          context.commit(
            mutationTypes.acceptDeleteAccountUserSuccess,
            response.data
          )
          context.commit(mutationTypes.acceptResultDeleteAccountUser, 'success')
        })
        .catch((error) => {
          context.commit(mutationTypes.acceptDeleteAccountUserFailure, error.data)
          context.commit(mutationTypes.acceptResultDeleteAccountUser, 'error')
          console.error(error.response.data.error)
          reject(error)
        })
    })
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
