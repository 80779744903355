import nodrApi from '../../services/api.nodrs'

const state = {
  nodrs: [],
  nodrsStat: [],
  configurationsNodr: [],
  checkConfigurationsNodr: null,
  errorCodeCreatedNodr: '',
  activeNodr: [],
  inActiveNodr: [],
  deletedNodr: [],
  allNodrs: [],
  reconfigureCurrentNodr: {},
  isLoadingSearchNodrs: false,
  isLoadingCreateNodrs: false,
  isLoadingUpNodrs: false,
  isLoadingDownloadDockerNodrs: false,
  isLoadingConfigurationsNodr: false,
  isLoadingDeleteNodr: false,
  isLoadingUpdateActiveNodr: false,
  isUpConfigNodr: false,
  isReconfigureNodr: false
}

export const mutationTypes = {
  searchNodrStart: '[nodr] Search NODR start',
  searchNodrSuccess: '[nodr] Search NODR success',
  searchNodrFailure: '[nodr] Search NODR failure',

  createNodrStart: '[nodr] Create NODR start',
  createNodrSuccess: '[nodr] Create NODR success',
  createNodrFailure: '[nodr] Create NODR failure',

  deleteNodrStart: '[nodr] Delete NODR start',
  deleteNodrSuccess: '[nodr] Delete NODR success',
  deleteNodrFailure: '[nodr] Delete NODR failure',

  upNodrStart: '[nodr] Update NODR start',
  upNodrSuccess: '[nodr] Update NODR success',
  upNodrFailure: '[nodr] Update NODR failure',

  downloadDockerNodrsStart: '[nodr] Download docker compose yaml NODR start',
  downloadDockerNodrsSuccess:
    '[nodr] Download docker compose yaml NODR success',
  downloadDockerNodrsFailure:
    '[nodr] Download docker compose yaml NODR failure',

  searchConfigurationsNodrStart: '[nodr] Search configurations NODR start',
  searchConfigurationsNodrSuccess: '[nodr] Search configurations NODR success',
  searchConfigurationsNodrFailure: '[nodr] Search configurations NODR failure',

  updateActiveNodrStart: '[nodr] Update active NODR start',
  updateActiveNodrSuccess: '[nodr] Update active NODR success',
  updateActiveNodrFailure: '[nodr] Update active NODR failure',

  upNoActiveNodrStart: '[nodr] Up no active NODR start',
  upNoActiveNodrSuccess: '[nodr] Up no active NODR success',
  upNoActiveNodrFailure: '[nodr] Up no active NODR failure',

  reconfigureNodrStart: '[nodr] Reconfigure NODR start',
  reconfigureNodrSuccess: '[nodr] Reconfigure NODR success',
  reconfigureNodrFailure: '[nodr] Reconfigure NODR failure',
}

export const actionTypes = {
  searchNodrs: '[nodr] Search NODRs',
  createNodr: '[nodr] Create nodr',
  deleteNodr: '[nodr] Delete NODR',
  upNodr: '[nodr] Update NODR',
  upNoActiveNodr: '[nodr] Up no active NODR',
  reconfigureNodr: '[nodr] Up active NODR',
  downloadDockerNodrs: '[nodr] Download docker compose yaml NODR',
  searchConfigurationsNodr: '[nodr] Search configurations NODR',
  updateActiveNodr: '[nodr] Update active NODR',
}

export const getterTypes = {
  nodrs: '[nodr] NODRs',
  nodrsStat: '[nodr] NODRs statistics',
  activeNodr: '[nodr] Active NODR',
  inActiveNodr: '[nodr] In active NODR',
  deletedNodr: '[nodr] Deleted NODRs',
  allNodrs: '[nodr] All NODRs',
  reconfigureCurrentNodr: '[nodr] Reconfigure current NODR',
  errorCodeCreatedNodr: '[nodr] Error code created NODR',
  isLoadingCreateNodrs: '[nodr] Is loading create NODR',
  isLoadingSearchNodrs: '[nodr] Is loading search NODRs',
  isLoadingUpNodrs: '[nodr] Is loading update NODRs',
  isLoadingDownloadDockerNodrs:
    '[nodr] Is loading download docker compose yaml NODR',
  configurationsNodr: '[nodr] Configurations NODR',
  checkConfigurationsNodr: '[nodr] Check configuration NODR',
  isLoadingConfigurationsNodr: '[nodr] Is loading configurations NODR',
  isLoadingUpdateActiveNodr: '[nodr] Is loading update active NODR',
  isReconfigureNodr: '[nodr] Is reconfigure NODR'
}

const getters = {
  [getterTypes.nodrs]: (state) => {
    return state.nodrs
  },
  [getterTypes.nodrsStat]: (state) => {
    return state.nodrsStat
  },
  [getterTypes.configurationsNodr]: (state) => {
    return state.configurationsNodr
  },
  [getterTypes.checkConfigurationsNodr]: (state) => {
    return state.checkConfigurationsNodr
  },
  [getterTypes.activeNodr]: (state) => {
    return state.activeNodr
  },
  [getterTypes.inActiveNodr]: (state) => {
    return state.inActiveNodr
  },
  [getterTypes.deletedNodr]: (state) => {
    return state.deletedNodr
  },
  [getterTypes.errorCodeCreatedNodr]: (state) => {
    return state.errorCodeCreatedNodr
  },
  [getterTypes.allNodrs]: (state) => {
    return state.allNodrs
  },
  [getterTypes.reconfigureCurrentNodr]: (state) => {
    return state.reconfigureCurrentNodr
  },
  [getterTypes.isLoadingCreateNodrs]: (state) => {
    return state.isLoadingCreateNodrs
  },
  [getterTypes.isLoadingSearchNodrs]: (state) => {
    return state.isLoadingSearchNodrs
  },
  [getterTypes.isLoadingUpNodrs]: (state) => {
    return state.isLoadingUpNodrs
  },
  [getterTypes.isLoadingDownloadDockerNodrs]: (state) => {
    return state.isLoadingDownloadDockerNodrs
  },
  [getterTypes.isLoadingConfigurationsNodr]: (state) => {
    return state.isLoadingConfigurationsNodr
  },
  [getterTypes.isLoadingUpdateActiveNodr]: (state) => {
    return state.isLoadingUpdateActiveNodr
  },
  [getterTypes.isReconfigureNodr]: (state) => {
    return state.isReconfigureNodr
  }
}

const mutations = {
  [mutationTypes.searchNodrStart](state) {
    state.isLoadingSearchNodrs = true
  },
  [mutationTypes.searchNodrSuccess](state, allNodrs) {
    state.isLoadingSearchNodrs = false
    state.allNodrs = allNodrs
    state.nodrs = allNodrs.filter(
      (n) => n.status === 10 || n.status === 1 || n.status === 20 || n.status === 40
    ).map(n => ({
      ...n, isShowBody: false
    }))
    state.nodrsStat = allNodrs.filter(
      (n) => n.status === 10 || n.status === 20 || n.status === 30 || n.status === 40 || n.status === 50
    )
    // console.log('>> NODR >> ', state.nodrs)
    state.activeNodr = allNodrs.filter(
      (n) => n.status === 10
    )
    state.inActiveNodr = allNodrs.filter(
      (n) => n.status === 20 || n.status === 30 || n.status === 40
    )
    state.deletedNodr = allNodrs.filter((n) => n.status === 50)

    // console.log('>> ACTIVE NODR >> ', state.activeNodr)
    // console.log('>> IN ACTIVE NODR >> ', state.inActiveNodr)
    // console.log('>> DELETE NODR >> ', state.deletedNodr)

    // console.log(state.nodrs.length)
    if (state.nodrs.length > 0) {
      // if (state.nodrs[0].check === null) console.log('check = null')
      // if (state.nodrs[0].check)
      //   state.checkConfigurationsNodr = JSON.parse(state.nodrs[0].check)

      let newNodrs = state.nodrs.map(n => {
        if (n.check) {
          // console.log('>> 1')
          return {...n, checkConfigurationsNodr: JSON.parse(n.check)}
        } else {
          // console.log('>> 2')
          return {...n, checkConfigurationsNodr: null}
        }
      })
      state.nodrs = newNodrs
      // console.log('>> state.nodrs', state.nodrs)
    } else {
      // console.log('no NODR: ', state.nodrs.length)
    }

    // console.log('state.checkConfigurationsNodr', state.checkConfigurationsNodr)

    // console.log('All Nodrs', state.allNodrs)
    // console.log('Nodrs', state.nodrs)
    // console.log('Deleted Nodrs', state.deletedNodr)
  },
  [mutationTypes.searchNodrFailure](state) {
    state.isLoadingSearchNodrs = false
  },
  [mutationTypes.createNodrStart](state) {
    state.isLoadingCreateNodrs = true
  },
  [mutationTypes.createNodrSuccess](state, payload) {
    state.isLoadingCreateNodrs = false
    state.nodrs.push(payload)
  },
  [mutationTypes.createNodrFailure](state, error) {
    state.isLoadingCreateNodrs = false
    state.errorCodeCreatedNodr = error
  },
  [mutationTypes.deleteNodrStart](state) {
    state.isLoadingDeleteNodr = true
  },
  [mutationTypes.deleteNodrSuccess](state) {
    state.isLoadingDeleteNodr = false
  },
  [mutationTypes.deleteNodrFailure](state) {
    state.isLoadingDeleteNodr = false
  },
  [mutationTypes.upNodrStart](state) {
    state.isLoadingUpNodrs = true
  },
  [mutationTypes.upNodrSuccess](state, payload) {
    state.isLoadingUpNodrs = false
    // console.log('upNodrSuccess', payload)
  },
  [mutationTypes.upNodrFailure](state) {
    state.isLoadingUpNodrs = false
  },
  [mutationTypes.downloadDockerNodrsStart](state) {
    state.isLoadingDownloadDockerNodrs = true
  },
  [mutationTypes.downloadDockerNodrsSuccess](state, payload) {
    state.isLoadingDownloadDockerNodrs = false
    let link = document.getElementById('js-docker-compose')
    link.download = 'docker-compose.yml'
    let blob = new Blob([payload], {type: 'text/plain'})
    link.href = URL.createObjectURL(blob)
    link.click()
    URL.revokeObjectURL(link.href)
  },
  [mutationTypes.downloadDockerNodrsFailure](state) {
    state.isLoadingDownloadDockerNodrs = false
  },
  [mutationTypes.searchConfigurationsNodrStart](state) {
    state.isLoadingConfigurationsNodr = true
  },
  [mutationTypes.searchConfigurationsNodrSuccess](state, payload) {
    state.isLoadingConfigurationsNodr = false

    function configurations(arr) {
      const active = arr.filter((a) => a.status === 10)
      const suspended = arr.filter((a) => a.status === 1)
      return active.concat(suspended)
    }

    state.configurationsNodr = configurations(payload)
  },
  [mutationTypes.searchConfigurationsNodrFailure](state) {
    state.isLoadingConfigurationsNodr = false
  },
  [mutationTypes.updateActiveNodrStart](state) {
    state.isLoadingUpdateActiveNodr = true
  },
  [mutationTypes.updateActiveNodrSuccess](state, payload) {
    state.isLoadingUpdateActiveNodr = false
    // console.log('update Active Nodr Success', payload)
    // console.log('state.nodrs', state.nodrs)

    state.nodrs.find((n) => {
      if (n.id === payload.id) {
        n.status = payload.status
      }
    })
  },
  [mutationTypes.updateActiveNodrFailure](state) {
    state.isLoadingUpdateActiveNodr = false
  },
  [mutationTypes.upNoActiveNodrStart](state) {
    state.isUpConfigNodr = true
  },
  [mutationTypes.upNoActiveNodrSuccess](state, payload) {
    state.isUpConfigNodr = false

    // console.log('mutationTypes.upNoActiveNodrSuccess,payload', payload)
  },
  [mutationTypes.upNoActiveNodrFailure](state, error) {
    state.isUpConfigNodr = false
    state.errorCodeCreatedNodr = error
  },
  [mutationTypes.reconfigureNodrStart](state) {
    state.isReconfigureNodr = true
  },
  [mutationTypes.reconfigureNodrSuccess](state, payload) {
    state.isReconfigureNodr = false
    state.reconfigureCurrentNodr = payload
    // console.log('mutationTypes.reconfigureNodrSuccess',payload)
  },
  [mutationTypes.reconfigureNodrFailure](state) {
    state.isReconfigureNodr = false
  },
}

const actions = {
  [actionTypes.createNodr](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.createNodrStart)
      nodrApi
        .createNodr(params)
        .then((response) => {
          context.commit(mutationTypes.createNodrSuccess, response.data)
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.createNodrFailure, error.response.data.error)
          reject(error)
        })
    })
  },
  [actionTypes.searchNodrs](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.searchNodrStart)
      nodrApi
        .searchNodrs(params)
        .then((response) => {
          context.commit(mutationTypes.searchNodrSuccess, response.data)
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.searchNodrFailure)
          reject(error)
        })
    })
  },
  [actionTypes.searchConfigurationsNodr](context) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.searchConfigurationsNodrStart)
      nodrApi
        .findConfigurationNodr()
        .then((response) => {
          context.commit(
            mutationTypes.searchConfigurationsNodrSuccess,
            response.data
          )
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.searchConfigurationsNodrFailure)
          reject(error)
        })
    })
  },
  [actionTypes.upNodr](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.upNodrStart)
      nodrApi
        .upNodr(params)
        .then((response) => {
          context.commit(mutationTypes.upNodrSuccess, response.data)
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.upNodrFailure)
          reject(error)
        })
    })
  },
  [actionTypes.upNoActiveNodr](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.upNoActiveNodrStart)
      nodrApi
        .upNoActiveNodr(params)
        .then((response) => {
          context.commit(mutationTypes.upNoActiveNodrSuccess, response.data)
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.upNoActiveNodrFailure, error.response.data.error)
          reject(error)
        })
    })
  },
  [actionTypes.reconfigureNodr](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.reconfigureNodrStart)
      nodrApi
        .reconfigureNodr(params)
        .then((response) => {
          context.commit(mutationTypes.reconfigureNodrSuccess, response.data)
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.reconfigureNodrFailure)
          reject(error)
        })
    })
  },
  [actionTypes.downloadDockerNodrs](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.downloadDockerNodrsStart)
      nodrApi
        .downloadDockerComposeNodr(params)
        .then((response) => {
          context.commit(mutationTypes.downloadDockerNodrsSuccess, response)
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.downloadDockerNodrsFailure)
          reject(error)
        })
    })
  },
  [actionTypes.deleteNodr](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.deleteNodrStart)
      nodrApi
        .deleteNodr(params)
        .then((response) => {
          context.commit(mutationTypes.deleteNodrSuccess)
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.deleteNodrFailure)
          reject(error)
        })
    })
  },
  [actionTypes.updateActiveNodr](context, params) {
    return new Promise((resolve, reject) => {
      context.commit(mutationTypes.updateActiveNodrStart)
      nodrApi
        .updateActiveNodr(params)
        .then((response) => {
          context.commit(mutationTypes.updateActiveNodrSuccess, response.data)
          resolve(response)
        })
        .catch((error) => {
          context.commit(mutationTypes.updateActiveNodrFailure)
          reject(error)
        })
    })
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
