<template>
  <div class="my-nodr">
    <nodr-loader v-if="isLoadingSearchNodrs" />
    <template v-else>
      <template v-if="nodrs.length === 0">
        <nodr-no-nodr v-if="!isAddNodr" @startNodrOptions="startNodr" />
        <nodr-created-nodr
          :currentUser="currentUser"
          :configNodr="configurationsNodr"
          @searchNodrsParams="findNodr"
          v-else />
      </template>
      <template v-if="nodrs.length > 0">
        <div v-for="(nodr, index) in nodrs" :key="nodr.id" class="nodrs">
          <nodr-nodrs
            :nodr="nodr"
            :isNodrIndex="nodrs.length === index + 1"
            @deleteNodrParams="deleteNodr"
            @activeNodrParams="activeNodr"
            @addNodrParams="addNodr"
            @optionEditNodrName="editNodrName"
            @openModalPublicKeyParams="pubKeyNodr"
            @settingsNodrParams="settingsNodr"
          />
        </div>
      </template>
      <template v-if="isNewAddNodr">
        <nodr-created-nodr
          :currentUser="currentUser"
          :configNodr="configurationsNodr"
          @searchNodrsParams="findNodr"
        />
      </template>
    </template>
    <nodr-modal-deactivete-nodr
      v-if="isModalDeactiveteNodr"
      :currentDeacteveteNodr="currentDeacteveteNodr"
      @deactivetedNodrParams="deactivetedNodr"
      @deactivetedNodrParamsClose="closeDeactiveteModalNodr" />
    <nodr-modal-deleted-nodr
      v-if="isModalDeletedNodr"
      :currentDeletedNodr="currentDeletedNodr"
      @deletedNodrParams="delNodr"
      @deletedNodrParamsClose="closeDeletedModalNodr" />
    <nodr-modal-soon-add-nodr
      v-if="isModalSoonAddNodr"
      @closeModalParams="isModalSoonAddNodr = false" />
    <nodr-modal-public-key
      v-if="isModalPublicKey"
      @closeModalParams="isModalPublicKey = false"
      :currentNodr="currentNodr" />
    <nodr-modal-config-update-nodr
      v-if="isModalConfigUpdateNodr"
      :currentNodr="currentNodr"
      @searchNodrsParams="searchNodrsUp"
      @closeModalConfigUpdateParams="closeModalConfigUpdate"
      @currentConfigIdOption="currentConfigId"
    />
    <nodr-modal-reconfigure-nodr
      v-if="isModalReconfigureNodr"
      :currentNodr="currentNodr"
      @closeModalReconfigureParams="closeModalReconfigure"
      @reconfigureNodrParams="reconfigureCurrentNodr"
    />
  </div>
</template>

<script>
import Vuex from 'vuex'
import NodrLoader from '@/components/Loaders/Loader'
import {
  actionTypes as actionTypesNodr,
  getterTypes as getterTypesNodr,
} from '@/store/modules/nodrs'
import {getterTypes as getterTypesUser} from '@/store/modules/user'
import NodrNoNodr from '@/Plug/NoNodr'
import NodrNodrs from '@/components/Integration/Nodrs'
import NodrCreatedNodr from '@/components/Integration/CreatedNodr'
import NodrModalDeactiveteNodr from '@/components/Integration/Modals/ModalDeactiveteNodr'
import NodrModalDeletedNodr from '@/components/Integration/Modals/ModalDeletedNodr'
import NodrModalSoonAddNodr from '@/components/Integration/Modals/ModalSoonAddNodr'
import {actionTypes as actionTypesDictionaries} from '@/store/modules/dictionaries'
import NodrModalPublicKey from '@/components/Integration/Modals/ModalPublicKey'
import NodrModalConfigUpdateNodr from "@/components/Integration/Modals/ModalConfigInfoNodr";
import NodrModalReconfigureNodr from "@/components/Integration/Modals/ModalReconfigureNodr";

export default {
  name: 'NodrMyNodr',
  data() {
    return {
      isAddNodr: false,
      isNewAddNodr: false,
      isModalDeactiveteNodr: false,
      isModalDeletedNodr: false,
      isModalSoonAddNodr: false,
      isModalPublicKey: false,
      isModalConfigUpdateNodr: false,
      isModalReconfigureNodr: false,
      currentDeacteveteNodr: {},
      currentDeletedNodr: {},
      currentNodr: {},
    }
  },
  components: {
    NodrModalReconfigureNodr,
    NodrModalConfigUpdateNodr,
    NodrModalPublicKey,
    NodrCreatedNodr,
    NodrNodrs,
    NodrNoNodr,
    NodrLoader,
    NodrModalDeactiveteNodr,
    NodrModalDeletedNodr,
    NodrModalSoonAddNodr,
  },
  computed: {
    ...Vuex.mapGetters({
      currentUser: getterTypesUser.currentUser,
      isLoadingSearchNodrs: getterTypesNodr.isLoadingSearchNodrs,
      nodrs: getterTypesNodr.nodrs,
      configurationsNodr: getterTypesNodr.configurationsNodr,
      findReconfigureCurrentNodr: getterTypesNodr.reconfigureCurrentNodr
    }),
  },
  methods: {
    ...Vuex.mapActions({
      searchNodrs: actionTypesNodr.searchNodrs,
      searchConfigurationsNodr: actionTypesNodr.searchConfigurationsNodr,
      createNodr: actionTypesNodr.createNodr,
      deletedNodr: actionTypesNodr.deleteNodr,
      dictionaries: actionTypesDictionaries.dictionaries,
      updateActiveNodr: actionTypesNodr.updateActiveNodr,
      upNodr: actionTypesNodr.upNodr,
      reconfigureNodr: actionTypesNodr.reconfigureNodr
    }),
    pubKeyNodr(val) {
      this.currentNodr = val
      this.isModalPublicKey = true
    },
    findNodrs() {
      this.searchNodrs({
        teamId: this.currentUser.TeamsRender[0].id,
      })
    },
    findNodr(val) {
      this.isNewAddNodr = false
      if (val) this.findNodrs()
    },
    findDictionaries() {
      this.dictionaries({
        locale: '45',
      })
    },
    findConfigurationNodr() {
      this.searchConfigurationsNodr()
    },
    startNodr(val) {
      this.isAddNodr = val
    },
    activeNodr(val) {
      if (val.status === 10) {
        this.currentDeacteveteNodr = val
        this.isModalDeactiveteNodr = true
      }

      if (val.status === 20) {
        this.updateActiveNodr({
          teamId: this.currentUser.TeamsRender[0].id,
          nodrId: val.id,
        })
      }

    },
    deactivetedNodr(val) {
      this.updateActiveNodr({
        teamId: this.currentUser.TeamsRender[0].id,
        nodrId: val.id,
      }).then(() => {
        this.closeDeactiveteModalNodr()
      })
    },
    closeDeactiveteModalNodr(val) {
      this.isModalDeactiveteNodr = val
    },
    closeDeletedModalNodr(val) {
      this.isModalDeletedNodr = val
    },
    closeModalConfigUpdate(val) {
      this.isModalConfigUpdateNodr = val
    },
    closeModalReconfigure(val) {
      this.isModalReconfigureNodr = val
    },
    deleteNodr(val) {
      this.currentDeletedNodr = val
      this.isModalDeletedNodr = true
    },
    delNodr(val) {
      this.deletedNodr({
        teamId: this.currentUser.TeamsRender[0].id,
        nodrId: val.id,
      }).then(() => {
        this.closeDeletedModalNodr()
        this.findNodrs()
        this.searchConfigurationsNodr()
      })
    },
    reconfigureCurrentNodr(nodr) {
      this.isModalReconfigureNodr = false
      this.reconfigureNodr({
        teamId: nodr.teamId,
        id: nodr.id,
        licenseKey: nodr.licenseKey
      }).then(() => {
        this.findConfigurationNodr()
        this.isModalConfigUpdateNodr = true
        this.currentNodr = this.findReconfigureCurrentNodr
      })
    },
    currentConfigId(id) {
    },
    addNodr(val) {
      if (this.nodrs.length >= 3) {
        this.isModalSoonAddNodr = val
      } else {
        this.isNewAddNodr = !this.isNewAddNodr
        if (this.isNewAddNodr) this.findConfigurationNodr()
      }
    },
    settingsNodr(nodr) {
      if (nodr.status === 1) {
        this.findConfigurationNodr()
        this.isModalConfigUpdateNodr = true
        this.currentNodr = nodr
      }

      if (nodr.status === 10) {
        this.findConfigurationNodr()
        this.isModalReconfigureNodr = true
        this.currentNodr = nodr
      }
    },
    editNodrName(val) {
      this.upNodr(val.newNameNodr)
    },
    searchNodrsUp() {
      this.findNodrs()
    }
  },
  mounted() {
    this.findNodrs()
    this.findConfigurationNodr()
    this.findDictionaries()
  },
}
</script>
