<template>
  <div id="app">
    <tlprt-maintenance-work v-if="isMaintenanceWork" />
    <router-view v-else />
  </div>
</template>

<script>
import {
  STR_BODY_NODR,
  STR_BODY_STYLE,
  STR_FOOTER_NODR,
  STR_FOOTER_STYLE,
  STR_HEADER_NODR,
  STR_HEADER_STYLE,
} from '@/static/logFile'
import devtools from 'devtools-detect'
import TlprtMaintenanceWork from "@/components/MaintenanceWork";

export default {
  name: 'NodrApp',
  data() {
    return {
      isMaintenanceWork: false
    }
  },
  components: { TlprtMaintenanceWork },
  mounted() {
    if (devtools.isOpen) {
      console.clear()
      console.log(STR_HEADER_NODR, STR_HEADER_STYLE)
      console.log(STR_BODY_NODR, STR_BODY_STYLE)
      console.log(STR_FOOTER_NODR, STR_FOOTER_STYLE)
    }

    window.addEventListener('devtoolschange', (event) => {
      if (event.detail.isOpen) {
        console.clear()
        console.log(STR_HEADER_NODR, STR_HEADER_STYLE)
        console.log(STR_BODY_NODR, STR_BODY_STYLE)
        console.log(STR_FOOTER_NODR, STR_FOOTER_STYLE)
      }
    })
  },
}
</script>

<style lang="scss">
@import 'assets/scss/main.scss';
</style>
