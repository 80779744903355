<template>
  <div class="main-item">
    <div class="animated-background">
      <div class="background-masker"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TlprtLoaderAnimated',
}
</script>

<style lang="scss">
.main-item {
  padding: 0;
  background-color: #fff;
  width: 300px;
}

.background-masker {
  background-color: #fff;
  position: absolute;
}

@keyframes placeHolderShimmer {
  0% {
    background-position: -800px 0;
  }
  100% {
    background-position: 800px 0;
  }
}

.animated-background {
  animation-duration: 2s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: placeHolderShimmer;
  animation-timing-function: linear;

  background-color: #f6f7f8;
  background: linear-gradient(to right, #eeeeee 8%, #bbbbbb 18%, #eeeeee 33%);
  //background: linear-gradient(90deg, #F3F7FE 0%, #EFF2F9 100%);
  background-size: 800px 104px;
  height: 50px;

  position: relative;
}
</style>
