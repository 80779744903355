<template>
  <div class="register">
    <div class="register-block">
      <div class="register-block__inner">
        <div class="register-block__logo">
          <img src="../assets/img/svg/logo-icon-bg-blue60.svg" alt="logo" />
        </div>
        <div class="register-block__form">
          <form @submit.prevent="singUp">
            <div class="form-title font-h2 color_white">Join Nodr Network</div>
            <div
              class="input-field"
              :class="{
                'error-email':
                  ($v.email.$dirty && !$v.email.required) ||
                  ($v.email.$dirty && !$v.email.email),
              }">
              <label class="visually-hidden" for="login-email"
                >Enter your e-mail</label
              >
              <input
                type="text"
                id="login-email"
                placeholder="Enter your e-mail"
                v-model.trim="email" />
            </div>
            <div class="validation-errors">
              <template v-if="$v.email.$dirty && !$v.email.required">
                <span class="font-small-2 color_coral">Required field</span>
              </template>
              <template v-if="$v.email.$dirty && !$v.email.email">
                <span class="font-small-2 color_coral">invalid email</span>
              </template>
            </div>
            <div class="block-actions">
              <div class="block-actions-btn">
                <button
                  class="button font-button"
                  :class="
                    !isCheckedAgree
                      ? 'button-disabled-reg color_white'
                      : 'button-border-reg'
                  "
                  :disabled="!isCheckedAgree">
                  Create account
                </button>
              </div>
            </div>
            <div class="block-checkbox">
              <div class="checkbox">
                <input
                  class="custom-checkbox"
                  type="checkbox"
                  id="checkbox__terns-of-service"
                  name="checkbox"
                  value="checkbox"
                  v-model="isCheckedAgree" />
                <label
                  class="font-small-2 color_white"
                  for="checkbox__terns-of-service"
                  >I agree to Teleport Media
                  <a class="font-small-2 color_white" href="#" target="_blank">
                    Terms of Service
                  </a>
                </label>
              </div>
              <div class="checkbox">
                <input
                  class="custom-checkbox"
                  type="checkbox"
                  id="checkbox__offers-and-news"
                  name="checkbox"
                  value="checkbox"
                  v-model="isCheckedOffers" />
                <label
                  class="font-small-2 color_white"
                  for="checkbox__offers-and-news">
                  I agree to receive updates, offers and news
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="register-block__link-login">
        <span>Already have an account?</span>
        <router-link class="links-white" :to="{name: 'login'}">
          Log in
        </router-link>
      </div>
    </div>

    <tlprt-background-img />
  </div>
</template>

<script>
import Vuex from 'vuex'
import TlprtBackgroundImg from '../components/BackgroundImg'
import {email, required} from 'vuelidate/lib/validators'
import {actionTypes, getterTypes} from '../store/modules/auth'

export default {
  name: 'NodrRegister',
  components: {
    TlprtBackgroundImg,
  },
  data() {
    return {
      email: '',
      isCheckedAgree: true,
      isCheckedOffers: true,
    }
  },
  validations: {
    email: {email, required},
  },
  watch: {
    email() {
      this.$v.$reset()
    },
  },
  computed: {
    ...Vuex.mapGetters({
      isLoading: getterTypes.isLoading,
    }),
  },
  methods: {
    ...Vuex.mapActions({
      register: actionTypes.register,
    }),
    singUp() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.register({
        login: this.email,
        createPath: origin + '/createPassword',
        resetPath: origin + '/resPassword',
        loginPath: origin,
        offer: this.isCheckedOffers,
        subscription: this.isCheckedAgree,
      }).then(() => {
        this.$router.push({
          name: 'pendingRegistration',
        })
      })
    },
  },
}
</script>
