var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header-wrapper"},[(
        _vm.$route.name === 'addNodr' ||
        _vm.$route.name === 'settings' ||
        _vm.$route.name === 'statistics' ||
        _vm.$route.name === 'integration' ||
        _vm.$route.name === 'myNodr'
      )?_c('div'):_vm._e(),_c('nodr-header-menu',{attrs:{"currentUser":_vm.currentUser}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }