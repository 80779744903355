<template>
  <div class="pending-registration">
    <div class="pending-registration__wrapper">
      <div class="pending-registration__inner">
        <div class="pending-registration__text">
          <div class="font-h2 color_white">Pending registration</div>
          <p class="font-h6 color_white">
            Complete the registration by confirming
          </p>
          <p class="font-h6 color_white">
            your e-mail. Please check the mailbox.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TlprtPendingRegistration',
}
</script>
