<template>
  <div class="modal-delete-user">
    <div class="modal-delete-user__inner">
      <div class="popup">
        <button @click="closeModal" class="close">
          <svg>
            <use
              xlink:href="../../../assets/img/sprite/sprite-header.svg#delete-draft-domain"></use>
          </svg>
        </button>

        <div class="modal-delete-user__title">
          <span class="font-h5 color_dark-gray"
          >Are you sure you want to delete your account?</span
          >
        </div>

        <div
          v-if="
            findInfoDeleteUser.statusMessage ===
            statusTearmsDeletingUser.CAN_DELETE_USER_SOLO_TEAM
          "
          class="modal-delete-user__text"
        >
          <span class="font-text color_gray d-block">
            By deleting your account you will also delete this team. <br />
            You will no longer be able to login in the dashboard
          </span>

          <span class="font-text color_gray d-block"
          >Please confirm your decision by entering your password. <br />
            We will e-mail you the link. Following that link, you'll delete
            <br />
            this account.
          </span>
        </div>

        <div
          v-if="
            findInfoDeleteUser.statusMessage ===
            statusTearmsDeletingUser.CAN_DELETE_USER_NO_SOLO_ADMIN
          "
          class="modal-delete-user__text"
        >
          <span class="font-text color_gray d-block">
            Upon deletion, you'll be automatically signed out from <br />
            the current team and will no longer be able to sign <br />
            in the dashboard.
          </span>

          <span class="font-text color_gray d-block">
            Please confirm your decision by entering your password. <br />
            We will e-mail you the link. Following that link, you'll delete
            <br />
            this account.
          </span>
        </div>

        <div
          v-if="
            findInfoDeleteUser.statusMessage ===
            statusTearmsDeletingUser.CAN_DELETE_USER_NO_ADMIN
          "
          class="modal-delete-user__text-wrapper"
        >
          <div class="modal-delete-user__text">
            <span class="font-text color_gray d-block">
              Upon deletion, you'll be automatically signed out from <br />
              all teams and will no longer be able to sign into any of them
              <br />
              and into this dashboard.
            </span>
          </div>
          <div class="modal-delete-user__list-teams">
            <div
              v-for="(team, index) in teams"
              :key="index"
              class="modal-delete-user__list-team"
            >
              <span class="font-text color_gray d-block">{{ team.name }}</span>
              <span class="font-text color_gray d-block">{{
                  team.role === 10 ? 'Admin' : 'Viewer'
                }}</span>
            </div>
          </div>
          <div class="modal-delete-user__text">
            <span class="font-text color_gray d-block">
              Please confirm your decision by entering your password. <br />
              We will e-mail you the link. Following that link, you'll delete
              <br />
              this account.
            </span>
          </div>
        </div>

        <form
          @submit.prevent="deleteUser(currentUser)"
          class="modal-delete-user__control"
        >
          <div
            class="modal-delete-user__control-input"
            :class="{
              'error-validation':
                ($v.currentPasswordUser.$dirty &&
                  !$v.currentPasswordUser.required) ||
                isIncorrectPassword,
            }"
          >
            <input
              class="input"
              :type="typeViewPassword"
              placeholder="Enter your password"
              autocomplete="off"
              v-model.trim="currentPasswordUser"
            />
          </div>
          <div class="validation-errors">
            <template v-if="isIncorrectPassword">
              <span class="font-small-2 color_coral">{{
                  messageErrorCodeDeleteAccountUser
                }}</span>
            </template>
            <template
              v-if="
                $v.currentPasswordUser.$dirty &&
                !$v.currentPasswordUser.required
              "
            >
              <span class="font-small-2 color_coral">Required field</span>
            </template>
          </div>
          <div class="modal-delete-user__control-btn">
            <button class="button button-red">Delete account</button>
            <button @click="closeModal" class="button button-border-w">
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import {STATUS_TEARMS_DELETING_USER} from '../../../common/enum/statusTearmsDeletingUser'
import {actionTypes, getterTypes} from '../../../store/modules/user'
import {required} from 'vuelidate/lib/validators'

export default {
  name: 'NodrModalDeleteUser',
  props: {
    findInfoDeleteUser: {
      type: Object,
      require: true,
    },
    currentUser: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      typeViewPassword: 'password',
      currentPasswordUser: '',
      isIncorrectPassword: false,
      teams: this.findInfoDeleteUser.teams,
      statusTearmsDeletingUser: STATUS_TEARMS_DELETING_USER,
    }
  },
  watch: {
    currentPasswordUser() {
      this.$v.$reset()
      if (this.messageErrorCodeDeleteAccountUser)
        this.isIncorrectPassword = false
    },
    messageErrorCodeDeleteAccountUser() {
      this.isIncorrectPassword = true
    },
  },
  validations: {
    currentPasswordUser: {required},
  },
  computed: {
    ...Vuex.mapGetters({
      messageErrorCodeDeleteAccountUser:
        getterTypes.messageErrorCodeDeleteAccountUser,
    }),
  },
  methods: {
    ...Vuex.mapActions({
      deletingAccountUser: actionTypes.deletingAccountUser,
      check: actionTypes.check
    }),
    closeModal() {
      this.$emit('isCloseModalDeleteUser', false)
    },
    deleteUser(currentUser) {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.deletingAccountUser({
        user_id: currentUser.id,
        password: this.currentPasswordUser,
        url: origin + '/deleteUserAccount',
      }).then(() => {
        this.check()
        this.closeModal();
      })
    },
  },
}
</script>
