<template>
  <div class="no-nodr-stat">
    <div class="no-nodr__image">
      <img src="../assets/img/png/no-NODR1.png" alt="no NODR" />
    </div>
    <div class="no-nodr__title">
      <span class="color_dark-gray font-h5"
      >You have added an active node</span
      >
      <span class="color_gray font-text"
      >You can make any settings and changes on it in the section My NODR</span
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodrNoNodrStat',
}
</script>

<style lang="scss">
.no-nodr-stat {
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;

  .no-nodr__image {
     //margin-top: 132px;
     margin-bottom: 25px;

     width: 444px;
     height: 290px;
   }

  .no-nodr__title {
    span {
      display: block;

    &:first-child {
       margin-bottom: 12px;
     }

    &:last-child {
       margin-bottom: 30px;
     }
    }
  }

}
</style>
