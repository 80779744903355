<template>
  <div class="modal-reconfigure-nodr">
    <div class="modal-reconfigure-nodr__inner">
      <div class="popup">
        <button class="close">
          <svg @click="closeModal">
            <use
              xlink:href="../../../assets/img/sprite/sprite-header.svg#delete-draft-domain"></use>
          </svg>
        </button>
        <div class="popup__title-header">
          <span>You may change your NODR configuration <br>
            and public key only through reconfiguration</span>
        </div>
        <div class="popup__title">
          <span class="font-h5 color_dark-gray" >Are you sure you want to reconfigure NODR?</span >
        </div>
        <div class="popup__text">
          <span class="font-text color_gray">
            This can’t be undone. Reconfiguration only possible with running installation pack on your device once again. Until installation process will be successfully completed the NODR will be disconnected from the network and will stop providing service.
          </span>
          <span class="font-text color_gray">
             Please type the license key for your NODR below <br> to confirm this action:
          </span>
          <span class="font-small-bold color_gray">
            {{currentNodr.licenseKey}}
            <svg class="copy" @click="copyLicenseKeyNodr(currentNodr.licenseKey)">
              <use
                xlink:href="../../../assets/img/sprite/sprite-domains.svg#copy"
              ></use>
            </svg>
          </span>
        </div>
        <div class="popup__form">
          <input
            class="input"
            type="text"
            name="text"
            placeholder="Enter your key"
            v-model="reconfigureLicenseKeyNodr"
          />
          <div class="popup__form-btn">
            <button
              class="button font-button"
              @click="
                reconfigureNodr(currentNodr)
              "
              :class="
                isDisabledBtn
                  ? 'button-disabled-del-key'
                  : 'button-active-del-key color_white'
              "
              :disabled="isDisabledBtn"
            >
              Reconfigure
            </button>
            <button
              @click="closeModal"
              class="button button-border-w font-button"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NodrModalReconfigureNodr',
  props: {
    currentNodr: {
      type: Object,
      require: true
    }
  },
  data(){
    return {
      isDisabledBtn: true,
      reconfigureLicenseKeyNodr: ''
    }
  },
  watch: {
    reconfigureLicenseKeyNodr(value) {
      if (value == this.currentNodr.licenseKey) {
        this.isDisabledBtn = false
      } else {
        this.isDisabledBtn = true
      }
    },
  },
  methods: {
    closeModal() {
      // console.log('closeModal')
      this.$emit('closeModalReconfigureParams', false)
    },
    reconfigureNodr(nodr) {
      // console.log('reconfigureNodr')
      if (nodr.licenseKey === this.reconfigureLicenseKeyNodr) {
        this.$emit('reconfigureNodrParams', nodr)
      }
    },
    copyLicenseKeyNodr(licenseKey) {
      this.$clipboard(licenseKey)
    }
  }
}
</script>
