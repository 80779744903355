var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create-password"},[_c('div',{staticClass:"create-password-block"},[_c('div',{staticClass:"create-password__inner"},[_vm._m(0),_c('div',{staticClass:"create-password-block__form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.createPassword.apply(null, arguments)}}},[_c('div',{staticClass:"form-title font-h2 color_white"},[_vm._v("Create password")]),_c('div',{staticClass:"input-field",class:{
              'error-email':
                (_vm.$v.password.$error && !_vm.$v.password.required) ||
                (_vm.$v.password.$error && !_vm.$v.password.minLength),
            }},[_c('label',{staticClass:"visually-hidden",attrs:{"for":"create-password"}},[_vm._v("New password")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password),expression:"password",modifiers:{"trim":true}}],attrs:{"type":_vm.typeViewPassword,"id":"create-password","placeholder":"New password","autocomplete":"off"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.password.length > 0)?_c('div',{staticClass:"password-control",on:{"click":function($event){return _vm.viewPassword()}}},[(!_vm.isViewPassword)?[_c('svg',[_c('use',{attrs:{"xlink:href":require("../assets/img/svg/sprite/sprite-form.svg") + "#eye-off"}})])]:[_c('svg',[_c('use',{attrs:{"xlink:href":require("../assets/img/svg/sprite/sprite-form.svg") + "#eye-on"}})])]],2):_vm._e()]),_c('div',{staticClass:"validation-errors"},[(
                _vm.$v.confirmPassword.$dirty &&
                _vm.$v.confirmPassword.required &&
                _vm.$v.password.$error &&
                !_vm.$v.password.required
              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Required field")])]:(_vm.$v.password.$error && !_vm.$v.password.minLength)?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Minimum 6 symbols are required")])]:_vm._e()],2),_c('div',{staticClass:"input-field",class:{
              'error-email':
                (_vm.$v.confirmPassword.$dirty &&
                  !_vm.$v.confirmPassword.required &&
                  _vm.$v.password.minLength) ||
                (_vm.$v.confirmPassword.$dirty &&
                  !_vm.$v.confirmPassword.sameAsPassword &&
                  _vm.$v.password.minLength &&
                  !(_vm.$v.password.$error && !_vm.$v.password.required)),
            }},[_c('label',{staticClass:"visually-hidden",attrs:{"for":"create-confirm-password"}},[_vm._v("Confirm new password")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.confirmPassword),expression:"confirmPassword",modifiers:{"trim":true}}],attrs:{"type":_vm.typeViewConfirmPassword,"id":"create-confirm-password","placeholder":"Confirm new password","autocomplete":"off"},domProps:{"value":(_vm.confirmPassword)},on:{"input":function($event){if($event.target.composing)return;_vm.confirmPassword=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.confirmPassword.length > 0)?_c('div',{staticClass:"password-control",on:{"click":function($event){return _vm.viewConfirmPassword()}}},[(!_vm.isViewConfirmPassword)?[_c('svg',[_c('use',{attrs:{"xlink:href":require("../assets/img/svg/sprite/sprite-form.svg") + "#eye-off"}})])]:[_c('svg',[_c('use',{attrs:{"xlink:href":require("../assets/img/svg/sprite/sprite-form.svg") + "#eye-on"}})])]],2):_vm._e()]),_c('div',{staticClass:"validation-errors"},[(
                _vm.$v.confirmPassword.$dirty &&
                !_vm.$v.confirmPassword.required &&
                _vm.$v.password.$error &&
                !_vm.$v.password.required
              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("All fields are required")])]:(
                _vm.$v.confirmPassword.$dirty &&
                !_vm.$v.confirmPassword.required &&
                _vm.$v.password.minLength
              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Required field")])]:(
                _vm.$v.confirmPassword.$dirty &&
                !_vm.$v.confirmPassword.sameAsPassword &&
                _vm.$v.password.minLength &&
                !(_vm.$v.password.$error && !_vm.$v.password.required)
              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Passwords do not match")])]:_vm._e()],2),_vm._m(1)])])])]),_c('tlprt-background-img')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"create-password__logo"},[_c('img',{attrs:{"src":require("../assets/img/svg/logo-icon-bg-blue60.svg"),"alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-actions"},[_c('div',{staticClass:"block-actions-btn"},[_c('button',{staticClass:"button button-white-w font-button"},[_vm._v(" Submit ")])])])
}]

export { render, staticRenderFns }