<template>
      <div class="modal-config-update-nodr">
        <div class="modal-config-update-nodr__inner">
          <div class="popup">
            <button class="close">
              <svg @click="closeModal">
                <use
                  xlink:href="../../../assets/img/sprite/sprite-header.svg#delete-draft-domain"></use>
              </svg>
            </button>

            <div class="popup-title">
              <span>You may change your NODR configuration and public key</span>
              <span class="nodr-name">{{currentNodr.name === '' || currentNodr.name === null ? 'NODR name' : currentNodr.name}}</span>
            </div>

            <div class="popup-nodr__configuration">
              <div class="popup-block__title">
                <span class="font-h5 color_dark-gray">
                   Please choose the configuration for your NODR
                 </span>
              </div>
              <div class="configuration-block__list">

                <nodr-carousel
                  :carousel_data="configurationsNodr"
                  :currentNodr="currentNodr"
                  @currentConfigIdOption="currentConfigId"
                />

              </div>
            </div>

            <div class="popup-nodr__key-verification">
              <div class="key-verification__title">
        <span class="font-h5 color_dark-gray">
          Register your NODR public key
        </span>
              </div>
              <div class="key-verification__input">
                <label
                  class="visually-hidden"
                  for="key-verification__validations-input-template">
                  public key
                </label>
                <textarea
                  style="resize: none"
                  rows="4"
                  class="input"
                  :class="formatPublicKeyError || codeErrorPublicKey === 409 || codeErrorPublicKey === 500 ? 'error' : ''"
                  type="text"
                  id="key-verification__validations-input-template"
                  placeholder="-----BEGIN PUBLIC KEY-----
MHYwEAYHKoZIzj0CAQYFK4EEACIDYgAERPmNC4pxRUWvF81xQstabck2nguQkfoT
5QxGQcSyaJyHam29ZVBNHzvbjBTC/G44+bwQxnbJyMCSVFJr4H2PboGxuzXU
-----END PUBLIC KEY-----"
                  v-model="publicKey"
                  required />
                <span class="input--close" type="submit">
          <svg
            v-if="isPublicKeyValueClose && !isLoadingCreateNodrs"
            @click="publicKeyValueClose">
            <use
              xlink:href="../../../assets/img/sprite/sprite-header.svg#delete-draft-domain"></use>
          </svg>
        </span>
                <div class="input-message-error">
                  <template v-if="formatPublicKeyError || codeErrorPublicKey === 500">
            <span class="input--invalid-message font-small-2 color_coral"
            >Incorrect format public key. Please check your key and try
              again</span
            >
                  </template>
                  <template v-if="codeErrorPublicKey === 409">
            <span class="input--invalid-message font-small-2 color_coral">
              The key is already taken by another NODR. Please check that you enter your key and try again
            </span>
                  </template>
                </div>
              </div>
              <div class="key-verification__confirm">
                <button
                  @click="confirm()"
                  class="button button-blue-w color_white">
                  Confirm
                </button>
                <button
                  @click="closeModal()"
                  class="button button-border-w font-button"
                  >
                  Cancel
                </button>
              </div>
            </div>

          </div>
        </div>
      </div>
</template>

<script>
import Vuex from "vuex";
import {actionTypes as actionsTypeNodr, getterTypes as getterTypesNodr} from "@/store/modules/nodrs";
import NodrCarousel from "@/components/Carousel/v-carousel";
import {validatorPublicKey} from "@/plugins/validator";

export default {
  name: 'NodrModalConfigUpdateNodr',
  data() {
    return {
      formatPublicKeyError: false,
      codeErrorPublicKey: 0,
      publicKey: '',
      isPublicKeyValueClose: false,
    }
  },
  components: {NodrCarousel},
  props: {
    currentNodr: {
      type: Object,
      require: true
    },
    stateNodr: {
      type: Object,
      require: true
    }
  },
  computed: {
    ...Vuex.mapGetters({
      configurationsNodr: getterTypesNodr.configurationsNodr,
      errorCodeCreatedNodr: getterTypesNodr.errorCodeCreatedNodr,
      isLoadingCreateNodrs: getterTypesNodr.isLoadingCreateNodrs
    })
  },
  watch: {
    publicKey() {
      this.publicKey.length > 0
        ? (this.isPublicKeyValueClose = true)
        : (this.isPublicKeyValueClose = false)
      this.formatPublicKeyError = false
      this.codeErrorPublicKey = 0
    },
  },
  methods: {
    ...Vuex.mapActions({
      upNoActiveNodr: actionsTypeNodr.upNoActiveNodr,
    }),
    closeModal() {
      // console.log('closeModal')
      this.$emit('closeModalConfigUpdateParams', false)
    },
    publicKeyValueClose() {
      this.isPublicKeyValueClose = false
      this.publicKey = ''
    },
    currentConfigId(id) {
      this.configId = id

      // console.log('MODAL this.configId', this.configId)
    },
    confirm() {
      // console.log('confirm popup updated')
      if (this.currentNodr.status === 1) {
        // console.log('upNoActiveNodr')

        if (this.publicKey.length > 0) {
          if (validatorPublicKey(this.publicKey)) {
            this.upNoActiveNodr({
              configId: this.configId ? this.configId : this.currentNodr.configId,
              publicKey: this.publicKey,
              teamId: this.currentNodr.teamId,
              id: this.currentNodr.id
            })
              .then(() => {
                this.$emit('searchNodrsParams', true)
                this.closeModal()
              })
              .catch(() => this.codeErrorPublicKey = this.errorCodeCreatedNodr.code)
          } else {
            this.formatPublicKeyError = true
          }
        }

        if (this.publicKey.length === 0) {
          this.upNoActiveNodr({
            configId: this.configId ? this.configId : this.currentNodr.configId,
            teamId: this.currentNodr.teamId,
            id: this.currentNodr.id
          })
            .then(() => {
              this.$emit('searchNodrsParams', true)
              this.closeModal()

            })
            .catch(() => this.codeErrorPublicKey = this.errorCodeCreatedNodr.code)
        }
      }

      // if (this.currentNodr.status === 10) {
      //   console.log('upActiveNodr')
      //   if (!validatorPublicKey(this.publicKey)) {
      //     this.upActiveNodr({
      //       configId: this.configId ? this.configId : this.currentNodr.configId,
      //       publicKey: this.publicKey,
      //       teamId: this.currentNodr.teamId,
      //       id: this.currentNodr.id
      //     })
      //       .then(() => {
      //         this.$emit('searchNodrsParams', true)
      //         this.closeModal()
      //       })
      //       .catch(() => this.codeErrorPublicKey = this.errorCodeCreatedNodr.code)
      //
      //     console.log('confirm succsess')
      //   } else {
      //     this.formatPublicKeyError = true
      //   }
      // }

    },
  },
  mounted() {
    this.publicKey = this.currentNodr.publicKey
  }
}
</script>
