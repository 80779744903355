var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login"},[_c('div',{staticClass:"login-block"},[_c('div',{staticClass:"login-block__inner"},[_vm._m(0),_c('div',{staticClass:"login-block__form"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.singIn.apply(null, arguments)}}},[_c('div',{staticClass:"form-title font-h2 color_white"},[_vm._v("Welcome")]),_c('div',{staticClass:"input-field",class:{
              'error-email':
                (_vm.$v.email.$dirty && !_vm.$v.email.required) ||
                (_vm.$v.email.$dirty && !_vm.$v.email.email) ||
                _vm.newMessageErrorCode,
            }},[_c('label',{staticClass:"visually-hidden",attrs:{"for":"login-email"}},[_vm._v("Enter your e-mail")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.email),expression:"email",modifiers:{"trim":true}}],attrs:{"type":"text","id":"login-email","placeholder":"Enter your e-mail"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing)return;_vm.email=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}})]),_c('div',{staticClass:"validation-errors"},[(
                _vm.$v.email.$dirty &&
                !_vm.$v.email.required &&
                _vm.$v.password.$dirty &&
                _vm.$v.password.required
              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Required field")])]:_vm._e(),(_vm.$v.email.$dirty && !_vm.$v.email.email)?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Invalid e-mail")])]:_vm._e()],2),_c('div',{staticClass:"input-field",class:{
              'error-password':
                (_vm.$v.password.$dirty &&
                  !_vm.$v.password.required &&
                  _vm.$v.email.$dirty &&
                  _vm.$v.email.email) ||
                _vm.newMessageErrorCode,
            }},[_c('label',{staticClass:"visually-hidden",attrs:{"for":"login-password"}},[_vm._v("Your password")]),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.password),expression:"password",modifiers:{"trim":true}}],attrs:{"type":_vm.typeViewPassword,"id":"login-password","placeholder":"Your password","autocomplete":"on"},domProps:{"value":(_vm.password)},on:{"input":function($event){if($event.target.composing)return;_vm.password=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),(_vm.password.length > 0)?_c('div',{staticClass:"password-control",on:{"click":function($event){return _vm.viewPassword()}}},[(!_vm.isViewPassword)?[_c('svg',[_c('use',{attrs:{"xlink:href":require("../assets/img/svg/sprite/sprite-form.svg") + "#eye-off"}})])]:_vm._e(),(_vm.isViewPassword)?[_c('svg',[_c('use',{attrs:{"xlink:href":require("../assets/img/svg/sprite/sprite-form.svg") + "#eye-on"}})])]:_vm._e()],2):_vm._e()]),_c('div',{staticClass:"validation-errors"},[(
                _vm.$v.password.$dirty &&
                !_vm.$v.password.required &&
                _vm.$v.email.$dirty &&
                !_vm.$v.email.required
              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("All fields are required")])]:(
                _vm.$v.password.$dirty &&
                !_vm.$v.password.required &&
                _vm.$v.email.$dirty &&
                _vm.$v.email.email
              )?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Required field")])]:(_vm.newMessageErrorCode)?[_c('span',{staticClass:"font-small-2 color_coral"},[_vm._v("Incorrect e-mail or password")])]:_vm._e()],2),_c('div',{staticClass:"block-actions"},[_vm._m(1),_c('div',{staticClass:"block-actions-reset"},[_c('router-link',{staticClass:"font-button",attrs:{"to":{name: 'forgotPassword'}}},[_vm._v("Forgot password? ")])],1)])])])]),_c('div',{staticClass:"login-block__link-register"},[_c('span',[_vm._v("Don't have an account?")]),_c('router-link',{staticClass:"links-white",attrs:{"to":{name: 'register'}}},[_vm._v("Sign up for free ")])],1)]),_c('nodr-background-img')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"login-block__logo"},[_c('img',{attrs:{"src":require("../assets/img/svg/logo-icon-bg-blue60.svg"),"alt":"logo"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"block-actions-btn"},[_c('button',{staticClass:"button button-white-w font-button"},[_c('span',[_vm._v("Login")])])])
}]

export { render, staticRenderFns }