<template>
  <div class="settings">
    <nodr-modal-profile-image
      :isModalProfileImage="isModalProfileImage"
      :defaultImageUser="defaultImageUser"
      :currentUser="currentUser"
      @isCloseModalProfileImage="isModalProfileImage = false" />

    <nodr-modal-no-delete-user
      :findInfoDeleteUser="findInfoDeleteUser"
      @isCloseModalNoDeleteUser="isViewModalNoDeleteUser"
      v-if="statusViewNoDeleteUser" />

    <nodr-modal-delete-user
      :currentUser="currentUser"
      :findInfoDeleteUser="findInfoDeleteUser"
      @isCloseModalDeleteUser="isViewModalDeleteUser"
      v-if="statusViewDeleteUser" />

    <nodr-invitation v-if="false" />

    <nodr-profile-settings
      :currentUser="currentUser"
      @upOptionEditProfileDetails="upEditProfileDetails"
      @upOptionOpenModalImage="upOpenModalImage"
      @upOptionChangeEmail="upChangeEmail"
      @upOptionResent="upResent"
      @upOptionRevoke="upRevoke"
      @upOptionChangePassword="upChangePassword"
      @upOptionGetInfoDeleteUser="upGetInfoDeleteUser" />
  </div>
</template>

<script>
import Vuex from 'vuex'
import {STATUS_TEARMS_DELETING_USER} from '../../common/enum/statusTearmsDeletingUser'
import NodrProfileSettings from './ProfileSettings'
import {actionTypes, getterTypes} from '../../store/modules/user'
import NodrInvitation from './Invitation'
import NodrModalProfileImage from './Modals/ModalProfileImage'
import {DEFAULT_USER_IMAGES} from '../../common/enum/defaultUserImage'
import NodrModalNoDeleteUser from './Modals/ModalNoDeleteUser'
import NodrModalDeleteUser from './Modals/ModalDeleteUser'

export default {
  name: 'NodrSettings',
  data() {
    return {
      isModalProfileImage: false,

      statusViewDeleteUser: false,
      statusViewNoDeleteUser: false,

      defaultImageUser: DEFAULT_USER_IMAGES,
      statusTearmsDeletingUser: STATUS_TEARMS_DELETING_USER,
    }
  },
  components: {
    NodrModalDeleteUser,
    NodrModalNoDeleteUser,
    NodrModalProfileImage,
    NodrInvitation,
    NodrProfileSettings,
  },
  computed: {
    ...Vuex.mapGetters({
      currentUser: getterTypes.currentUser,
      permissionToDelete: getterTypes.permissionToDelete,
      findInfoDeleteUser: getterTypes.findInfoDeleteUser,
    }),
  },
  methods: {
    ...Vuex.mapActions({
      updateUser: actionTypes.updateUsers,
      changeEmail: actionTypes.changeEmail,
      changePassword: actionTypes.changePassword,
      infoDeleteUser: actionTypes.infoDeleteUser,
      resentChangeEmail: actionTypes.resentChangeEmail,
      revokeChangeEmail: actionTypes.revokeChangeEmail,
      check: actionTypes.check
    }),
    upResent(obj) {
      this.resentChangeEmail({
          id: obj.id,
          userId: obj.userId,
          url: obj.url
        }).then(() => this.check())
    },
    upRevoke(obj) {
      this.revokeChangeEmail({
        id: obj.id,
        user_id: obj.user_id,
      }).then(() => this.check())
    },
    upEditProfileDetails(obj) {
      this.updateUser(obj.newCurrentUser)
    },
    upOpenModalImage(openModal) {
      this.isModalProfileImage = openModal
    },
    upChangeEmail(obj) {
      this.changeEmail({
        user_id: obj.user_id,
        team_id: obj.team_id,
        userId: obj.userId,
        password: obj.password,
        email: obj.email,
        url: obj.url,
      }).then(() => this.check())
    },
    upChangePassword(obj) {
      this.changePassword({
        user_id: obj.user_id,
        team_id: obj.team_id,
        password: obj.password,
        new_password: obj.new_password,
        confirm: obj.confirm,
      })
    },
    upGetInfoDeleteUser(obj) {
      this.infoDeleteUser({
        userId: obj.userId,
      }).then(() => {
        if (
          this.findInfoDeleteUser.status === this.statusTearmsDeletingUser.CANT_DELETE_USER
        ) {
          return (this.statusViewNoDeleteUser = true)
        }

        if (
          this.findInfoDeleteUser.status === this.statusTearmsDeletingUser.CAN_DELETE_USER
        ) {
          return (this.statusViewDeleteUser = true)
        }
      })
    },
    isViewModalDeleteUser(obj) {
      this.statusViewDeleteUser = obj
    },
    isViewModalNoDeleteUser(obj) {
      this.statusViewNoDeleteUser = obj
    },
  },
}
</script>
