<template>
  <div class="login">
    <div class="login-block">
      <div class="login-block__inner">
        <div class="login-block__logo">
          <img src="../assets/img/svg/logo-icon-bg-blue60.svg" alt="logo" />
        </div>
        <div class="login-block__form">
          <form @submit.prevent="singIn">
            <div class="form-title font-h2 color_white">Welcome</div>
            <div
              class="input-field"
              :class="{
                'error-email':
                  ($v.email.$dirty && !$v.email.required) ||
                  ($v.email.$dirty && !$v.email.email) ||
                  newMessageErrorCode,
              }">
              <label class="visually-hidden" for="login-email"
                >Enter your e-mail</label
              >
              <input
                type="text"
                id="login-email"
                placeholder="Enter your e-mail"
                v-model.trim="email" />
            </div>
            <div class="validation-errors">
              <template
                v-if="
                  $v.email.$dirty &&
                  !$v.email.required &&
                  $v.password.$dirty &&
                  $v.password.required
                ">
                <span class="font-small-2 color_coral">Required field</span>
              </template>
              <template v-if="$v.email.$dirty && !$v.email.email">
                <span class="font-small-2 color_coral">Invalid e-mail</span>
              </template>
            </div>
            <div
              class="input-field"
              :class="{
                'error-password':
                  ($v.password.$dirty &&
                    !$v.password.required &&
                    $v.email.$dirty &&
                    $v.email.email) ||
                  newMessageErrorCode,
              }">
              <label class="visually-hidden" for="login-password"
                >Your password</label
              >
              <input
                :type="typeViewPassword"
                id="login-password"
                placeholder="Your password"
                autocomplete="on"
                v-model.trim="password" />
              <div
                v-if="password.length > 0"
                class="password-control"
                @click="viewPassword()">
                <template v-if="!isViewPassword">
                  <svg>
                    <use
                      xlink:href="../assets/img/svg/sprite/sprite-form.svg#eye-off"></use>
                  </svg>
                </template>
                <template v-if="isViewPassword">
                  <svg>
                    <use
                      xlink:href="../assets/img/svg/sprite/sprite-form.svg#eye-on"></use>
                  </svg>
                </template>
              </div>
            </div>
            <div class="validation-errors">
              <template
                v-if="
                  $v.password.$dirty &&
                  !$v.password.required &&
                  $v.email.$dirty &&
                  !$v.email.required
                ">
                <span class="font-small-2 color_coral"
                  >All fields are required</span
                >
              </template>
              <template
                v-else-if="
                  $v.password.$dirty &&
                  !$v.password.required &&
                  $v.email.$dirty &&
                  $v.email.email
                ">
                <span class="font-small-2 color_coral">Required field</span>
              </template>
              <template v-else-if="newMessageErrorCode">
                <span class="font-small-2 color_coral"
                  >Incorrect e-mail or password</span
                >
              </template>
            </div>
            <div class="block-actions">
              <div class="block-actions-btn">
                <button class="button button-white-w font-button">
                  <span>Login</span>
                </button>
              </div>
              <div class="block-actions-reset">
                <router-link :to="{name: 'forgotPassword'}" class="font-button"
                  >Forgot password?
                </router-link>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="login-block__link-register">
        <span>Don't have an account?</span>
        <router-link class="links-white" :to="{name: 'register'}"
          >Sign up for free
        </router-link>
      </div>
    </div>

    <nodr-background-img />
  </div>
</template>

<script>
import Vuex from 'vuex'
import {email, required} from 'vuelidate/lib/validators'
import {actionTypes, getterTypes} from '../store/modules/auth'
import NodrBackgroundImg from '@/components/BackgroundImg'

export default {
  name: 'NodrLogin',
  components: {NodrBackgroundImg},
  data() {
    return {
      email: '',
      password: '',

      isViewPassword: false,
      isMessageError: false,

      typeViewPassword: 'password',

      newMessageErrorCode: false,
    }
  },
  computed: {
    ...Vuex.mapGetters({
      messageErrorCode: getterTypes.messageError,
      isLoading: getterTypes.isLoading,
    }),
  },
  validations: {
    email: {email, required},
    password: {required},
  },
  watch: {
    email() {
      this.$v.$reset()
      if (this.messageErrorCode) {
        this.newMessageErrorCode = false
      }
    },
    password() {
      this.$v.$reset()
      if (this.messageErrorCode) {
        this.newMessageErrorCode = false
      }
    },
    messageErrorCode() {
      if (this.messageErrorCode) {
        this.newMessageErrorCode = true
      }
    },
  },
  methods: {
    ...Vuex.mapActions({
      loginUser: actionTypes.login,
    }),
    viewPassword() {
      this.isViewPassword = !this.isViewPassword
      this.isViewPassword
        ? (this.typeViewPassword = 'text')
        : (this.typeViewPassword = 'password')
    },
    singIn() {
      if (this.$v.$invalid) {
        this.$v.$touch()
        return
      }

      this.loginUser({
        login: this.email,
        password: this.password,
      }).then(() => {
        this.email = ''
        this.password = ''
        this.$router.push('/myNodr')
      })
    },
  },
}
</script>
