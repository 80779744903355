import axios from '../services/api.axios'
import * as auth from '../store/modules/auth'
import Config from '../common/config'

const searchTeams = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios.post(Config.apiAuth  +'/'+ Config.scope + '/v1/teams', params,{headers: 
    {
      'Authorization':'Bearer ' + token
    }
  })
}

const getTeams = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios.get(Config.apiAuth  +'/'+ Config.scope + '/v1/teams/' + params.id,{headers: 
    {
      'Authorization':'Bearer ' + token
    }
  })
}

const updateTeams = (params) => {
  var token = ''
  if(auth.state.accessToken != null){
    token = auth.state.accessToken
  }else{
    token = localStorage.getItem('bearer_access_token')
  }
  return axios.put(Config.apiAuth  +'/'+ Config.scope + '/v1/teams/' + params.id, params,{headers: 
    {
      'Authorization':'Bearer ' + token
    }
  })
}

export default {
  getTeams,
  updateTeams,
  searchTeams,
}
