<template>
  <div class="menu">
    <div class="docs menu-item">
      <a v-if="false" href="https://docs.teleport.media/" target="_blank">
        <svg>
          <use xlink:href="../assets/img/sprite/sprite-header.svg#docs"></use>
        </svg>
      </a>
    </div>
    <div class="user menu-item">
      <router-link to="/settings">
        <svg
          v-if="
            Number(currentUser.image) === null ||
            Number(currentUser.image) === 0
          ">
          <use
            xlink:href="../assets/img/sprite/sprite-header.svg#user-images-default"></use>
        </svg>
        <img
          v-else-if="Number(currentUser.image)"
          :src="currentImageUser"
          alt="" />
      </router-link>
    </div>
  </div>
</template>

<script>
import {DEFAULT_USER_IMAGES} from '../common/enum/defaultUserImage'

export default {
  name: 'NodrHeaderMenu',
  props: {
    currentUser: {
      type: Object,
      require: true,
    },
  },
  computed: {
    currentImageUser() {
      const index = Number(this.currentUser.image)
      let imageUrl = DEFAULT_USER_IMAGES[index]

      return imageUrl.url
    },
  },
}
</script>
