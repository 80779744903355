import dictionariesApi from '../../services/api.dictionaries'

// Прибраться после нового api

const state = {
  userStatus: [],
  userRolesInTeam: [],
  teamStatuses: [],
  spyUsers: [],
  keyTypes: [],
  keySubdomains: [],
  keyStatuses: [],
  keySetting13: [],
  keySetting12: [],
  keySetting101: [],
  invitationStatuses: [],
  domainVerificationMethods: [],
  domainVerification: [],
  domainStatuses: [],
  currencyISO: [],
  countriesISO: [],
  availableKeySettings: [],
  nodrStatus: [],
}

export const mutationTypes = {
  dictionariesStart: '[dictionaries] Dictionaries start',
  dictionariesSuccess: '[dictionaries] Dictionaries success',
  dictionariesFailure: '[dictionaries] Dictionaries failure',
}

export const actionTypes = {
  dictionaries: '[dictionaries] Dictionaries user',
}

export const getterTypes = {
  dictionariesUserStatus: '[dictionaries] Dictionaries user status',
  dictionariesUserRolesInTeam: '[dictionaries] Dictionaries user roles in team',
  dictionariesTeamStatuses: '[dictionaries] Dictionaries team statuses',
  dictionariesSpyUsers: '[dictionaries] Dictionaries spy users',
  dictionariesKeyTypes: '[dictionaries] Dictionaries key types',
  dictionariesKeySubdomains: '[dictionaries] Dictionaries key subdomains',
  dictionariesKeyStatuses: '[dictionaries] Dictionaries key statuses',
  dictionariesKeySetting13: '[dictionaries] Dictionaries key setting 13',
  dictionariesKeySetting12: '[dictionaries] Dictionaries key setting 12',
  dictionariesKeySetting101: '[dictionaries] Dictionaries key setting 101',
  dictionariesInvitationStatuses:
    '[dictionaries] Dictionaries invitation statuses',
  dictionariesDomainVerificationMethods:
    '[dictionaries] Dictionaries domain verification methods',
  dictionariesDomainVerification:
    '[dictionaries] Dictionaries domain verification',
  dictionariesDomainStatuses: '[dictionaries] Dictionaries domain statuses',
  dictionariesCurrencyISO: '[dictionaries] Dictionaries currency ISO',
  dictionariesCountriesISO: '[dictionaries] Dictionaries countries ISO',
  dictionariesAvailableKeySettings:
    '[dictionaries] Dictionaries available key settings',
  dictionariesNodrStatus: '[dictionaries] Dictionaries NODR status',
}

const getters = {
  [getterTypes.dictionariesUserStatus]: (state) => {
    return state.userStatus
  },
  [getterTypes.dictionariesUserRolesInTeam]: (state) => {
    return state.userRolesInTeam
  },
  [getterTypes.dictionariesTeamStatuses]: (state) => {
    return state.teamStatuses
  },
  [getterTypes.dictionariesSpyUsers]: (state) => {
    return state.spyUsers
  },
  [getterTypes.dictionariesKeyTypes]: (state) => {
    return state.keyTypes
  },
  [getterTypes.dictionariesKeySubdomains]: (state) => {
    return state.keySubdomains
  },
  [getterTypes.dictionariesKeyStatuses]: (state) => {
    return state.keyStatuses
  },
  [getterTypes.dictionariesKeySetting13]: (state) => {
    return state.keySetting13
  },
  [getterTypes.dictionariesKeySetting12]: (state) => {
    return state.keySetting12
  },
  [getterTypes.dictionariesKeySetting101]: (state) => {
    return state.keySetting101
  },
  [getterTypes.dictionariesInvitationStatuses]: (state) => {
    return state.invitationStatuses
  },
  [getterTypes.dictionariesDomainVerificationMethods]: (state) => {
    return state.domainVerificationMethods
  },
  [getterTypes.dictionariesDomainVerification]: (state) => {
    return state.domainVerification
  },
  [getterTypes.dictionariesDomainStatuses]: (state) => {
    return state.domainStatuses
  },
  [getterTypes.dictionariesCurrencyISO]: (state) => {
    return state.currencyISO
  },
  [getterTypes.dictionariesCountriesISO]: (state) => {
    return state.countriesISO
  },
  [getterTypes.dictionariesAvailableKeySettings]: (state) => {
    return state.availableKeySettings
  },
  [getterTypes.dictionariesNodrStatus]: (state) => {
    return state.nodrStatus
  },
}

const mutations = {
  [mutationTypes.dictionariesStart](state) {
    state.userStatus = []
    state.userRolesInTeam = []
    state.teamStatuses = []
    state.spyUsers = []
    state.keyTypes = []
    state.keySubdomains = []
    state.keyStatuses = []
    state.keySetting13 = []
    state.keySetting12 = []
    state.keySetting101 = []
    state.invitationStatuses = []
    state.domainVerificationMethods = []
    state.domainVerification = []
    state.domainStatuses = []
    state.currencyISO = []
    state.countriesISO = []
    state.availableKeySettings = []
    state.nodrStatus = []
  },
  [mutationTypes.dictionariesSuccess](state, payload) {
    state.userStatus = payload.user_status
    state.userRolesInTeam = payload.user_roles_in_team
    state.teamStatuses = payload.team_statuses
    state.spyUsers = payload.spy_users
    state.keyTypes = payload.key_types
    state.keySubdomains = payload.key_subdomains
    state.keyStatuses = payload.key_statuses
    state.keySetting13 = payload.key_setting_13
    state.keySetting12 = payload.key_setting_12
    state.keySetting101 = payload.key_setting_101
    state.invitationStatuses = payload.invitation_statuses
    state.domainVerificationMethods = payload.domain_verification_methods
    state.domainVerification = payload.domain_verification
    state.domainStatuses = payload.domain_statuses
    state.currencyISO = payload.currency_ISO
    state.countriesISO = payload.countries_ISO
    state.availableKeySettings = payload.available_key_settings
    state.nodrStatus = payload.nodr_status
  },
  [mutationTypes.dictionariesFailure](state) {
    state.userStatus = []
    state.userRolesInTeam = []
    state.teamStatuses = []
    state.spyUsers = []
    state.keyTypes = []
    state.keySubdomains = []
    state.keyStatuses = []
    state.keySetting13 = []
    state.keySetting12 = []
    state.keySetting101 = []
    state.invitationStatuses = []
    state.domainVerificationMethods = []
    state.domainVerification = []
    state.domainStatuses = []
    state.currencyISO = []
    state.countriesISO = []
    state.availableKeySettings = []
    state.nodrStatus = []
  },
}

const actions = {
  [actionTypes.dictionaries](context, locales) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.dictionariesStart)
      dictionariesApi
        .dictionaries(locales)
        .then((response) => {
          context.commit(mutationTypes.dictionariesSuccess, response.data)
          resolve(response.data)
        })
        .catch((result) => {
          context.commit(mutationTypes.dictionariesFailure)
          result.response.data.errors
        })
    })
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
