<template>
  <div class="sidebar">
    <div class="sidebar-top">
      <div class="logo">
        <img
          src="../assets/img/svg/logo-icon-bg-blue60.svg"
          alt="logo company" />
      </div>
      <div class="name-workspace">
        <span class="font-h5 color_white">
          {{ firstTeam }}
        </span>
      </div>
      <div class="sidebar-links">
        <div class="sidebar-link">
          <router-link
            to="/statistics"
            class="sidebar-link__item"
            :class="{
              'link-active': isActive(
                $router.options.routes,
                'statistics',
                $route.name
              ),
            }">
            <svg>
              <use
                xlink:href="../assets/img/sprite/sprite-navbar.svg#n-statistic"></use>
            </svg>
            <span class="font-menu">Statistics</span>
          </router-link>
        </div>
        <div class="sidebar-link">
          <router-link
            to="/myNodr"
            class="sidebar-link__item"
            :class="{
              'link-active': isActive(
                $router.options.routes,
                'myNodr',
                $route.name
              ),
            }">
            <svg>
              <use
                xlink:href="../assets/img/sprite/sprite-navbar.svg#n-integration"></use>
            </svg>
            <span class="font-menu">My NODR</span>
          </router-link>
        </div>
        <!--        <div class="sidebar-link">-->
        <!--          <router-link-->
        <!--            to="/integration"-->
        <!--            class="sidebar-link__item"-->
        <!--            :class="{-->
        <!--              'link-active':-->
        <!--                $route.name === 'integration' || $route.name === 'addNodr',-->
        <!--            }"-->
        <!--          >-->
        <!--            <svg>-->
        <!--              <use-->
        <!--                xlink:href="../assets/img/sprite/sprite-navbar.svg#n-integration"-->
        <!--              ></use>-->
        <!--            </svg>-->
        <!--            <span class="font-menu">Integration</span>-->
        <!--            <span class="sidebar-link__icon">-->
        <!--              <svg>-->
        <!--                <use-->
        <!--                  xlink:href="../assets/img/sprite/sprite-navbar.svg#arrow-icon-menu"-->
        <!--                ></use>-->
        <!--              </svg>-->
        <!--            </span>-->
        <!--          </router-link>-->
        <!--          <transition name="fade">-->
        <!--            <div-->
        <!--              v-if="-->
        <!--                $route.name === 'integration' || $route.name === 'addNodr'-->
        <!--              "-->
        <!--              class="sidebar-link__sub-list"-->
        <!--            >-->
        <!--              <ul>-->
        <!--                <li>-->
        <!--                  <router-link-->
        <!--                    to="/integration"-->
        <!--                    class="font-submenu color_white"-->
        <!--                  >-->
        <!--                    My NODR-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--                <li>-->
        <!--                  <router-link-->
        <!--                    to="/addNodr"-->
        <!--                    class="font-submenu color_white"-->
        <!--                  >-->
        <!--                    Add NODR-->
        <!--                  </router-link>-->
        <!--                </li>-->
        <!--              </ul>-->
        <!--            </div>-->
        <!--          </transition>-->
        <!--        </div>-->
        <div class="sidebar-link">
          <router-link
            to="/settings"
            class="sidebar-link__item"
            :class="{'link-active': $route.name === 'settings'}">
            <svg>
              <use
                xlink:href="../assets/img/sprite/sprite-navbar.svg#n-account"></use>
            </svg>
            <span class="font-menu">Settings</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="sidebar-bottom">
      <div class="logout">
        <a @click="logoutUser">
          <svg class="navbar-icon">
            <use
              xlink:href="../assets/img/sprite/sprite-navbar.svg#n-logout"></use>
          </svg>
          <span class="font-menu">Logout</span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import isActive from '../common/helpers/activeLinks'
import {getterTypes as getterTypesUser} from '../store/modules/user'
import {actionTypes as actionTypesAuth} from '../store/modules/auth'
import {actionTypes as actionTypesTeam} from '../store/modules/team'
import Config from "@/common/config";

export default {
  name: 'NodrSidebar',
  mixins: [isActive],
  data() {
    return {
      firstTeam: null,
    }
  },
  computed: {
    ...Vuex.mapGetters({
      currentUser: getterTypesUser.currentUser,
    }),
  },
  methods: {
    ...Vuex.mapActions({
      logout: actionTypesAuth.logout,
      searchTeams: actionTypesTeam.searchTeams,
    }),
    logoutUser() {
      this.logout().then(() => {
        location.reload()
      })
    },
    findTeamsUser() {
      this.searchTeams({}).then((teams) => {
        this.firstTeam = teams[0].name
      })
    },
  },
  mounted() {
    this.findTeamsUser()

    function isFunction(functionToCheck) {
      return (
        functionToCheck &&
        {}.toString.call(functionToCheck) === '[object Function]'
      )
    }

    function debounce(func, wait) {
      let timeout
      let waitFunc

      return function () {
        if (isFunction(wait)) {
          waitFunc = wait
        } else {
          waitFunc = function () {
            return wait
          }
        }

        let context = this,
          args = arguments
        let later = function () {
          timeout = null
          func.apply(context, args)
        }
        clearTimeout(timeout)
        timeout = setTimeout(later, waitFunc())
      }
    }

    let reconnectFrequencySeconds = 1
    let evtSource

    let reconnectFunc = debounce(
      function () {
        setupEventSource()
        reconnectFrequencySeconds *= 2
        if (reconnectFrequencySeconds >= 64) {
          reconnectFrequencySeconds = 64
        }
      },
      function () {
        return reconnectFrequencySeconds * 1000
      }
    )
    function setupEventSource() {
      const urlSSE = `${Config.apiURL}/v1/sse/handle`
      evtSource = new EventSource(urlSSE, {withCredentials: true})
      // console.log('>> evtSource.onmessage >>', evtSource.onmessage)
      evtSource.onmessage = function (e) {
        // console.log('>> evtSource.onmessage e', e)
        if (+e.data === 10) {
          // console.log('>> e 10', e)
          evtSource.close()
          location.reload()
        }
        if (+e.data === 20) {
          // console.log('>> e 20', e)
          evtSource.close()
          location.reload()
        }
        if (+e.data === 30) {
          // console.log('>> e 30', e)
          evtSource.close()
          location.reload()
        }
      }
      evtSource.onopen = function () {
        reconnectFrequencySeconds = 1
      }
      evtSource.onerror = function () {
        evtSource.close()
        reconnectFunc()
      }
    }

    setupEventSource()
  },
}
</script>
