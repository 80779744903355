<template>
  <div v-if="isModalProfileImage" class="modal-profile-image">
    <div class="modal-profile-image__inner">
      <div class="popup">
        <button @click="closeModal" class="close">
          <svg>
            <use
              xlink:href="../../../assets/img/sprite/sprite-header.svg#delete-draft-domain"></use>
          </svg>
        </button>
        <div class="tabs">
          <span
            @click="tabActivate('default-tab')"
            class="font-small-bold color_gray tab"
            :class="{
              'tab-active': tabActive === 'default-tab',
            }">
            Default images
          </span>
        </div>
        <div class="tab-contents">
          <div v-if="tabActive === 'default-tab'" class="tab-content">
            <div
              v-for="(imageUser, index) in defaultImageUser"
              :key="index"
              class="tab-content__image"
              :class="{
                active: indexImageUser === index,
              }"
              @click="currentImageUser(index)">
              <img :src="imageUser.url" alt="" />
            </div>
          </div>
          <div class="tab-btn">
            <button @click="closeModal" class="button button-border-w">
              Cancel
            </button>
            <button
              @click="saveImageUser(currentUser)"
              class="button button-loader-white color_white">
              <span v-if="!isLoadingUpdateUser">Save</span>
              <nodr-loader-white v-if="isLoadingUpdateUser" />
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import {actionTypes, getterTypes} from '../../../store/modules/user'
import NodrLoaderWhite from '../../Loaders/Loader-white'

export default {
  name: 'NodrModalProfileImage',
  components: {NodrLoaderWhite},
  props: {
    isModalProfileImage: {
      type: Boolean,
      require: true,
    },
    defaultImageUser: {
      type: Array,
      require: true,
    },
    currentUser: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      tabActive: 'default-tab',
      indexImageUser: '',
    }
  },
  computed: {
    ...Vuex.mapGetters({
      isLoadingUpdateUser: getterTypes.isLoadingUpdateUser,
    }),
  },
  methods: {
    ...Vuex.mapActions({
      updateUsers: actionTypes.updateUsers,
    }),
    closeModal() {
      this.$emit('isCloseModalProfileImage', false)
      this.tabActive = 'default-tab'

      if (Number(this.currentUser.image) === null) {
        this.indexImageUser = 0
      } else if (Number(this.currentUser.image)) {
        this.indexImageUser = Number(this.currentUser.image)
      }
    },
    tabActivate(tag) {
      this.tabActive = tag
    },
    currentImageUser(index) {
      this.indexImageUser = index
    },
    saveImageUser(currentUser) {
      const newCurrentUser = Object.assign({}, currentUser)
      newCurrentUser.image = String(this.indexImageUser)

      this.updateUsers(newCurrentUser).then(() => this.closeModal())
    },
  },
  mounted() {
    if (
      Number(this.currentUser.image) === null ||
      Number(this.currentUser.image) === 0
    ) {
      this.indexImageUser = 0
    } else if (Number(this.currentUser.image)) {
      this.indexImageUser = Number(this.currentUser.image)
    }
  },
}
</script>
