<template>
  <div class="nodr">
    <div class="nodr-header" :class="{'nodr-header--show': !nodr.isShowBody}">
      <div class="nodr-header__name">
        <div
          class="nodr-header__name-block"
          :class="
            nodr.status !== 1 ? 'nodr-header__name-block--active-input' : ''
          ">
          <input
            :id="`js-nodr-header__name-block-${nodr.id}`"
            type="text"
            :value="nodr.name"
            placeholder="Untitled"
            :maxlength="maxLengthInput"
            @focus="onFocus(nodr)"
            @blur="onBlur(nodr)"
            autocomplete="off"
          />

          <svg :class="isActiveInputName ? 'active' : ''">
            <use
              xlink:href="../../assets/img/sprite/sprite-domain-up.svg#edit"></use>
          </svg>
        </div>
      </div>

      <div class="nodr-header__controls">

        <div v-if="nodr.status === 1 || nodr.status === 10 || nodr.status === 20" class="nodr__item">
          <div v-if="(nodr.status === 1 && !nodr.check && !checkConfigurationsNodrResult)" class="nodr__item-status-current inProcess">
            <span>Configuring hardware</span>
          </div>
          <div v-if="(nodr.status === 1 && nodr.check && !checkConfigurationsNodrResult)" class="nodr__item-status-current inProcess">
            <span>Misconfigured</span>
          </div>
          <div v-if="(nodr.status === 1 && nodr.check && checkConfigurationsNodrResult)" class="nodr__item-status-current inProcess">
            <span>Launching NODR app</span>
          </div>

          <div v-if="nodr.status === 10" class="nodr__item-status-current active">
            <span>Active</span>
          </div>

          <div v-if="nodr.status === 20" class="nodr__item-status-current inactive">
            <span>Inactive</span>
          </div>
          <div
            v-if="nodr.status === 10 || nodr.status === 20"
            class="nodr__item-status nodr__item-status--active"
            :class="{'nodr__item-status--blocked': nodr.status === 20}">
            <svg @click="activeNodr(nodr)">
              <use
                xlink:href="../../assets/img/sprite/sprite-domain-up.svg#power"></use>
            </svg>
          </div>
          <div class="nodr__item-settings">
            <svg @click="settingsNodr(nodr)">
              <use
                xlink:href="../../assets/img/sprite/sprite-domain-up.svg#nodr-update"></use>
            </svg>
          </div>
          <div class="nodr__item-deleted">
            <svg @click="deleteNodr(nodr)">
              <use
                xlink:href="../../assets/img/sprite/sprite-domain-up.svg#basket"></use>
            </svg>
          </div>
        </div>
        <div v-else class="nodr__item">
          <div class="nodr__item-status-current suspended">
            <span>Suspended</span>
          </div>
          <div class="keys-item-hash__important">
            <svg>
              <use
                xlink:href="../../assets/img/sprite/sprite-domain-up.svg#important"></use>
            </svg>
            <div class="keys-item-hash__important-info">
              <span class="important-info__title font-small-2-bold color_coral">
                The service for this key is restricted. <br />
                Please contact support for details
              </span>
              <span
                class="important-info__description font-small-2 color_coral">
                This key is disabled due to subscription limitations. <br />
                Please change your plan or deactivate other keys <br />
                to activate this one.
              </span>
            </div>
          </div>
        </div>

        <div
          class="nodr-header__controls-down"
          :class="{'nodr-header__controls-down--active': nodr.isShowBody}"
        >
          <svg @click="activeDownCurrentNodr">
            <use xlink:href="../../assets/img/sprite/sprite-domain-up.svg#arrow"></use>
          </svg>
        </div>
      </div>
    </div>

    <div v-if="nodr.isShowBody" class="nodr-body">
      <div class="left-block">
        <div class="left-block__item-config">
          <div
            class="config-items"
            :class="{'config-item__soon--background': true}">
            <div class="config-item__title">
              <span class="font-small color_dodger-blue">{{
                nodr.config.name
              }}</span>
            </div>
            <div class="config-item">
              <svg>
                <use
                  xlink:href="@/assets/img/sprite/sprite-nodr.svg#volume-storage"></use>
              </svg>
              <span class="font-small color_gray">HDD {{
                  nodrConfigStorage
              }}B</span>
            </div>
            <div class="config-item">
              <svg>
                <use xlink:href="@/assets/img/sprite/sprite-nodr.svg#ram"></use>
              </svg>
              <span class="font-small color_gray">RAM {{ nodrConfigRam }}B</span>
            </div>
            <div class="config-item">
              <svg>
                <use
                  xlink:href="@/assets/img/sprite/sprite-nodr.svg#speed"></use>
              </svg>
              <span class="font-small color_gray">{{ nodrConfigBandwidth }}bit/s</span>
            </div>
            <div class="config-item">
              <svg>
                <use
                  xlink:href="@/assets/img/sprite/sprite-nodr.svg#static-ip"></use>
              </svg>
              <span class="font-small color_gray">{{ nodr.config.ip_type }}</span>
            </div>
            <div class="config-item_img">

            </div>
          </div>
        </div>
      </div>

      <div class="right-block">
        <div class="description-block__item">
          <span class="d-block font-small-bold color_dark-gray">
            Public key
          </span>
          <div class="description-block__item-wrapper">
            <span class="d-block font-text-bold color_dodger-blue">
              ***********
            </span>
            <span @click="openModalPublicKey(nodr)">
              <svg>
                <use
                  xlink:href="../../assets/img/svg/sprite/sprite-form.svg#eye-off-public-key"></use>
              </svg>
            </span>
          </div>
        </div>
        <div class="description-block__item">
          <span class="d-block font-small-bold color_dark-gray"
            >Creation date</span
          >
          <span class="d-block font-text-bold color_dodger-blue">
            {{ createDate(nodr.createdAt) }}
          </span>
        </div>
        <div class="description-block__item">
          <span class="d-block font-small-bold color_dark-gray">
            Domain name
          </span>
          <span class="d-block font-text-bold color_dodger-blue">
            {{ `${nodr.licenseKey}.nodr.app` }}
          </span>
        </div>

        <div class="right-block__item-verification">
          <div class="item-verification__text">
            <span class="d-block font-small-bold color_dark-gray">
              Total storage
            </span>
            <span
              v-if="nodr.storage === null"
              class="d-block font-small-bold color_middle-sunny">
              awaiting...
            </span>
            <span
              v-else
              class="d-block font-text-bold"
              :class="
                nodr.checkConfigurationsNodr.check_config_nodr.storage.result ===
                true
                  ? 'color_dodger-blue'
                  : 'color_coral'
              ">
              {{ nodr.checkConfigurationsNodr.check_config_nodr.storage.used ? `${findCheckCurrentNodrStorage}B` : 'undefined' }}
            </span>
          </div>
          <div v-if="nodr.storage !== null" class="item-verification__icon">
            <svg
              v-if="
                nodr.checkConfigurationsNodr.check_config_nodr.storage.result ===
                  true && nodr.storage !== null
              ">
              <use
                xlink:href="@/assets/img/sprite/sprite-nodr.svg#verification-success"></use>
            </svg>
            <div class="item-verification__icon--important">
              <svg
                v-if="
                  nodr.checkConfigurationsNodr.check_config_nodr.storage.result ===
                    false && nodr.storage !== null
                ">
                <use
                  xlink:href="@/assets/img/sprite/sprite-nodr.svg#verification-important"></use>
              </svg>
              <div class="item-verification__icon-message">
                <span class="font-small-bold color_coral">
                  This parameter is lower than required minimum.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="right-block__item-verification">
          <div class="item-verification__text">
            <span class="d-block font-small-bold color_dark-gray">RAM</span>
            <span
              v-if="nodr.ram === null"
              class="d-block font-small-bold color_middle-sunny"
              >awaiting...</span
            >
            <span
              v-else
              class="d-block font-text-bold"
              :class="
                nodr.checkConfigurationsNodr.check_config_nodr.ram.result === true
                  ? 'color_dodger-blue'
                  : 'color_coral'
              ">
              {{ nodr.checkConfigurationsNodr.check_config_nodr.ram.used ? `${findCheckCurrentNodrRam}B` : 'undefined' }}
            </span>
          </div>
          <div v-if="nodr.ram !== null" class="item-verification__icon">
            <svg
              v-if="
                nodr.checkConfigurationsNodr.check_config_nodr.ram.result === true &&
                nodr.ram !== null
              ">
              <use
                xlink:href="@/assets/img/sprite/sprite-nodr.svg#verification-success"></use>
            </svg>
            <div class="item-verification__icon--important">
              <svg
                v-if="
                  nodr.checkConfigurationsNodr.check_config_nodr.ram.result ===
                    false && nodr.ram !== null
                ">
                <use
                  xlink:href="@/assets/img/sprite/sprite-nodr.svg#verification-important"></use>
              </svg>
              <div class="item-verification__icon-message">
                <span class="font-small-bold color_coral">
                  This parameter is lower than required minimum.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="right-block__item-verification">
          <div class="item-verification__text">
            <span class="d-block font-small-bold color_dark-gray">IP</span>
            <span
              v-if="nodr.ipAddress === null"
              class="d-block font-small-bold color_middle-sunny"
              >awaiting...</span
            >
            <span
              v-else
              class="d-block font-text-bold"
              :class="
                nodr.checkConfigurationsNodr.check_config_nodr.ip.result === true
                  ? 'color_dodger-blue'
                  : 'color_coral'
              ">
              {{ nodr.checkConfigurationsNodr.check_config_nodr.ip.used ?  nodr.checkConfigurationsNodr.check_config_nodr.ip.used : 'undefined'}}
            </span>
          </div>
          <div v-if="nodr.ipAddress !== null" class="item-verification__icon">
            <svg
              v-if="
                nodr.checkConfigurationsNodr.check_config_nodr.ip.result === true &&
                nodr.ip !== null
              ">
              <use
                xlink:href="@/assets/img/sprite/sprite-nodr.svg#verification-success"></use>
            </svg>
            <div class="item-verification__icon--important">
              <svg
                v-if="
                  nodr.checkConfigurationsNodr.check_config_nodr.ip.result ===
                    false && nodr.ip !== null
                ">
                <use
                  xlink:href="@/assets/img/sprite/sprite-nodr.svg#verification-important"></use>
              </svg>
              <div class="item-verification__icon-message--right">
                <span class="font-small-bold color_coral">
                  This parameter is lower than required minimum.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="right-block__item-verification">
          <div class="item-verification__text">
            <span class="d-block font-small-bold color_dark-gray">OS</span>
            <span
              v-if="nodr.os === null"
              class="d-block font-small-bold color_middle-sunny"
              >awaiting...</span
            >
            <span
              v-else
              class="d-block font-text-bold"
              :class="
                nodr.checkConfigurationsNodr.check_config_nodr.os.result === true
                  ? 'color_dodger-blue'
                  : 'color_coral'
              ">
              {{ nodr.checkConfigurationsNodr.check_config_nodr.os.used ? `${findCheckCurrentNodrOs}` : 'undefined' }}
            </span>
          </div>
          <div v-if="nodr.os !== null" class="item-verification__icon">
            <svg
              v-if="
                nodr.checkConfigurationsNodr.check_config_nodr.os.result === true &&
                nodr.os !== null
              ">
              <use
                xlink:href="@/assets/img/sprite/sprite-nodr.svg#verification-success"></use>
            </svg>
            <div class="item-verification__icon--important">
              <svg
                v-if="
                  nodr.checkConfigurationsNodr.check_config_nodr.os.result ===
                    false && nodr.os !== null
                ">
                <use
                  xlink:href="@/assets/img/sprite/sprite-nodr.svg#verification-important"></use>
              </svg>
              <div class="item-verification__icon-message">
                <span class="font-small-bold color_coral">
                  TThis parameter is lower than required minimum.
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="right-block__item-verification">
          <div class="item-verification__text">
            <span class="d-block font-small-bold color_dark-gray"
              >Bandwidth</span
            >
            <span
              v-if="nodr.bandWidth === null"
              class="d-block font-small-bold color_middle-sunny"
              >awaiting...</span
            >
            <span
              v-else
              class="d-block font-text-bold"
              :class="
                nodr.checkConfigurationsNodr.check_config_nodr.bandwidth.result ===
                true
                  ? 'color_dodger-blue'
                  : 'color_coral'
              ">
              {{ nodr.checkConfigurationsNodr.check_config_nodr.bandwidth.used ?  `${findCheckCurrentNodrBandwidth}bit/s` : 'undefined'}}
            </span>
          </div>
          <div v-if="nodr.bandWidth !== null" class="item-verification__icon">
            <svg
              v-if="
                nodr.checkConfigurationsNodr.check_config_nodr.bandwidth.result ===
                  true && nodr.bandwidth !== null
              ">
              <use
                xlink:href="@/assets/img/sprite/sprite-nodr.svg#verification-success"></use>
            </svg>
            <div class="item-verification__icon--important">
              <svg
                v-if="
                  nodr.checkConfigurationsNodr.check_config_nodr.bandwidth.result ===
                    false && nodr.bandwidth !== null
                ">
                <use
                  xlink:href="@/assets/img/sprite/sprite-nodr.svg#verification-important"></use>
              </svg>
              <div class="item-verification__icon-message">
                <span class="font-small-bold color_coral">
                  This parameter is lower than required minimum.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      v-if="isNodrIndex" class="nodr-body__add-block"
      :class="{'nodr-body__add-block--active': isActiveBtnNewAddNodr}"
      @click="addNodr"
    >
      <svg>
        <use xlink:href="@/assets/img/sprite/sprite-nodr.svg#plus"></use>
      </svg>
    </div>
  </div>
</template>

<script>
import Vuex from 'vuex'
import {getterTypes as getterTypesNodrs} from '@/store/modules/nodrs'
import {bitesToString} from "@/common/helpers/bitesToString";
import {osToString} from "@/common/helpers/osToString";
export default {
  name: 'NodrNodrs',
  props: {
    nodr: {
      type: Object,
      require: true,
    },
    isNodrIndex: {
      type: Boolean,
      require: true
    }
  },
  data() {
    return {
      isActiveBtnNewAddNodr: false,
      nameNodr: this.nodr.name,
      isActiveInputName: false,
      maxLengthInput: 320,
      checkConfigurationsNodrResult: false,
    }
  },
  computed: {
    ...Vuex.mapGetters({
      // checkConfigurationsNodr: getterTypes.checkConfigurationsNodr,
      nodrs: getterTypesNodrs.nodrs
    }),
    findCheckCurrentNodrBandwidth(){
      return bitesToString(this.nodr.checkConfigurationsNodr.check_config_nodr.bandwidth.used)
    },
    findCheckCurrentNodrOs() {
      return osToString(this.nodr.checkConfigurationsNodr.check_config_nodr.os.used)
    },
    findCheckCurrentNodrRam() {
      return bitesToString(this.nodr.checkConfigurationsNodr.check_config_nodr.ram.used)
    },
    findCheckCurrentNodrStorage() {
      return bitesToString(this.nodr.checkConfigurationsNodr.check_config_nodr.storage.used)
    },
    nodrConfigBandwidth() {
     return bitesToString(this.nodr.config.bandwidth)
    },
    nodrConfigRam() {
     return bitesToString(this.nodr.config.ram)
    },
    nodrConfigStorage() {
      return bitesToString(this.nodr.config.storage)
    }
  },
  methods: {
    activeDownCurrentNodr(){
      console.log('>> activeDownCurrentNodr <<')
      this.nodr.isShowBody = !this.nodr.isShowBody
    },
    openModalPublicKey(nodr) {
      this.$emit('openModalPublicKeyParams', nodr)
    },
    onFocus(nodr) {
      this.isActiveInputName = true
      this.nameNodr = nodr.name

      const input = document.getElementById(
        `js-nodr-header__name-block-${nodr.id}`
      )

      input.addEventListener('input', () => {
        this.nameNodr = input.value
      })
    },
    onBlur(nodr) {
      this.isActiveInputName = false
      nodr.name = this.nameNodr

      // console.log('>> nodr.name', nodr.name)

      const newNameNodr = Object.assign(
        {
          name: nodr.name,
        },
        nodr
      )

      this.$emit('optionEditNodrName', {
        newNameNodr,
      })
    },
    activeNodr(nodr) {
      this.$emit('activeNodrParams', nodr)
    },
    settingsNodr(nodr) {
      // console.log('settingsNodr', nodr)
      this.$emit('settingsNodrParams', nodr)
    },
    deleteNodr(nodr) {
      this.$emit('deleteNodrParams', nodr)
    },
    addNodr() {
      this.$emit('addNodrParams', true)
      this.nodrs.length >= 3 ? this.isActiveBtnNewAddNodr = false : this.isActiveBtnNewAddNodr = !this.isActiveBtnNewAddNodr
    },
    createDate(createDate) {
      let options = {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      }
      return new Date(createDate).toLocaleString('en-GB', options)
    },
  },
  mounted() {
    // console.log(bitesToString(999))
    if (this.nodr.checkConfigurationsNodr) {
      // console.log('3333', this.nodr.checkConfigurationsNodr)

      this.checkConfigurationsNodrResult = this.nodr.checkConfigurationsNodr.result
    } else {
      this.checkConfigurationsNodrResult = false
    }
    // console.log('RESULT', this.checkConfigurationsNodrResult)
  },
}
</script>
