import axios from '../services/api.axios'
import Config from '../common/config'

const dictionaries = (locales) => {
  return axios.get(Config.apiURL + '/dictionaries/' + locales.locale, {})
}

export default {
  dictionaries,
}
