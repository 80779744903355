var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidebar"},[_c('div',{staticClass:"sidebar-top"},[_vm._m(0),_c('div',{staticClass:"name-workspace"},[_c('span',{staticClass:"font-h5 color_white"},[_vm._v(" "+_vm._s(_vm.firstTeam)+" ")])]),_c('div',{staticClass:"sidebar-links"},[_c('div',{staticClass:"sidebar-link"},[_c('router-link',{staticClass:"sidebar-link__item",class:{
            'link-active': _vm.isActive(
              _vm.$router.options.routes,
              'statistics',
              _vm.$route.name
            ),
          },attrs:{"to":"/statistics"}},[_c('svg',[_c('use',{attrs:{"xlink:href":require("../assets/img/sprite/sprite-navbar.svg") + "#n-statistic"}})]),_c('span',{staticClass:"font-menu"},[_vm._v("Statistics")])])],1),_c('div',{staticClass:"sidebar-link"},[_c('router-link',{staticClass:"sidebar-link__item",class:{
            'link-active': _vm.isActive(
              _vm.$router.options.routes,
              'myNodr',
              _vm.$route.name
            ),
          },attrs:{"to":"/myNodr"}},[_c('svg',[_c('use',{attrs:{"xlink:href":require("../assets/img/sprite/sprite-navbar.svg") + "#n-integration"}})]),_c('span',{staticClass:"font-menu"},[_vm._v("My NODR")])])],1),_c('div',{staticClass:"sidebar-link"},[_c('router-link',{staticClass:"sidebar-link__item",class:{'link-active': _vm.$route.name === 'settings'},attrs:{"to":"/settings"}},[_c('svg',[_c('use',{attrs:{"xlink:href":require("../assets/img/sprite/sprite-navbar.svg") + "#n-account"}})]),_c('span',{staticClass:"font-menu"},[_vm._v("Settings")])])],1)])]),_c('div',{staticClass:"sidebar-bottom"},[_c('div',{staticClass:"logout"},[_c('a',{on:{"click":_vm.logoutUser}},[_c('svg',{staticClass:"navbar-icon"},[_c('use',{attrs:{"xlink:href":require("../assets/img/sprite/sprite-navbar.svg") + "#n-logout"}})]),_c('span',{staticClass:"font-menu"},[_vm._v("Logout")])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('img',{attrs:{"src":require("../assets/img/svg/logo-icon-bg-blue60.svg"),"alt":"logo company"}})])
}]

export { render, staticRenderFns }