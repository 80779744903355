<template>
  <div class="profile-details">
    <div class="profile-details__title">
      <span class="font-h5 color_dark-gray">Profile details</span>
    </div>
    <div class="profile-details__inputs">
      <div class="profile-details__input">
        <input
          id="js-input-name"
          class="input"
          @focus="onFocus(currentUser, 'name')"
          @blur="onBlur(currentUser)"
          :value="currentUser.name"
          placeholder="Name"
          type="text"
          :maxlength="maxLengthInput" />
        <svg :class="isActiveInputName ? 'active' : ''">
          <use
            xlink:href="../../assets/img/sprite/sprite-domain-up.svg#edit"></use>
        </svg>
      </div>
      <div class="profile-details__input">
        <input
          id="js-input-surname"
          class="input"
          @focus="onFocus(currentUser, 'surname')"
          @blur="onBlur(currentUser)"
          :value="currentUser.surname"
          placeholder="Surname"
          type="text"
          :maxlength="maxLengthInput" />
        <svg :class="isActiveInputSurname ? 'active' : ''">
          <use
            xlink:href="../../assets/img/sprite/sprite-domain-up.svg#edit"></use>
        </svg>
      </div>
      <div class="profile-details__input">
        <input
          id="js-input-phone"
          class="input"
          @focus="onFocus(currentUser, 'phone')"
          @blur="onBlur(currentUser)"
          :value="currentUser.phone"
          placeholder="Phone"
          type="text"
          :maxlength="maxLengthInput"
          inputmode="numeric" />
        <svg :class="isActiveInputPhone ? 'active' : ''">
          <use
            xlink:href="../../assets/img/sprite/sprite-domain-up.svg#edit"></use>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TlprtProfileDetails',
  props: {
    currentUser: {
      type: Object,
      require: true,
    },
  },
  data() {
    return {
      isActiveInputName: false,
      isActiveInputSurname: false,
      isActiveInputPhone: false,
      userDetails: {
        name: this.currentUser.name,
        surname: this.currentUser.surname,
        phone: this.currentUser.phone,
      },
      maxLengthInput: 30,
    }
  },
  methods: {
    onFocus(currentUser, type) {
      if (type === 'name') {
        this.isActiveInputName = true
        this.userDetails.name = currentUser.name

        const input = document.getElementById('js-input-name')

        input.addEventListener('input', () => {
          this.userDetails.name = input.value
        })
      }

      if (type === 'surname') {
        this.isActiveInputSurname = true
        this.userDetails.surname = currentUser.surname

        const input = document.getElementById('js-input-surname')

        input.addEventListener('input', () => {
          this.userDetails.surname = input.value
        })
      }

      if (type === 'phone') {
        this.isActiveInputPhone = true
        this.userDetails.phone = currentUser.phone

        const input = document.getElementById('js-input-phone')

        input.addEventListener('input', () => {
          this.userDetails.phone = input.value
        })
      }
    },
    onBlur(currentUser) {
      this.isActiveInputName = false
      this.isActiveInputSurname = false
      this.isActiveInputPhone = false

      currentUser.name = this.userDetails.name
      currentUser.surname = this.userDetails.surname
      currentUser.phone = this.userDetails.phone

      const newCurrentUser = Object.assign(
        {
          name: currentUser.name,
          surname: currentUser.surname,
          phone: currentUser.phone,
        },
        currentUser
      )

      this.$emit('optionEditProfileDetails', {
        newCurrentUser,
      })
    },
  },
}
</script>
