import teamApi from '../../services/api.team'

const state = {
  team: [],
  teams: {},
  isLoading: false,
}

export const mutationTypes = {
  searchTeamsStart: '[team] Search teams',
  searchTeamsSuccess: '[team] Search teams',
  searchTeamsFailure: '[team] Search teams',

  getTeamStart: '[team] Get team start',
  getTeamSuccess: '[team] Get team success',
  getTeamFailure: '[team] Get team failure',

  updateTeamStart: '[team] Update team start',
  updateTeamSuccess: '[team] Update team success',
  updateTeamFailure: '[team] Update team failure',
}

export const actionTypes = {
  searchTeams: '[team] Search teams',
  getTeam: '[team] Get team',
  updateTeam: '[team] Update team',
}

export const getterTypes = {
  team: '[team] Team',
  teams: '[team] Search teams',
  isLoading: '[team] isLoading',
}

const getters = {
  [getterTypes.team]: (state) => {
    return state.team
  },
  [getterTypes.teams]: (state) => {
    return state.teams
  },
  [getterTypes.isLoading]: (state) => {
    return state.isLoading
  },
}

const mutations = {
  [mutationTypes.searchTeamsStart](state) {
    state.teams = null
  },
  [mutationTypes.searchTeamsSuccess](state, payload) {
    state.teams = payload
  },
  [mutationTypes.searchTeamsFailure](state) {
    state.teams = null
  },
  [mutationTypes.getTeamStart](state) {
    state.isLoading = true
    state.team = null
  },
  [mutationTypes.getTeamSuccess](state, payload) {
    state.isLoading = false
    state.team = payload
  },
  [mutationTypes.getTeamFailure](state) {
    state.isLoading = false
    state.team = null
  },
  [mutationTypes.updateTeamStart](state) {
    state.isLoading = true
    state.team = null
  },
  [mutationTypes.updateTeamSuccess](state, payload) {
    state.isLoading = false
    state.team = payload
  },
  [mutationTypes.updateTeamFailure](state) {
    state.isLoading = false
    state.team = null
  },
}

const actions = {
  [actionTypes.searchTeams](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.searchTeamsStart)
      teamApi
        .searchTeams(params)
        .then((response) => {
          context.commit(mutationTypes.searchTeamsSuccess, response.data)
          resolve(response.data)
        })
        .catch((error) => {
          context.commit(mutationTypes.searchTeamsFailure)
          error.response.data.errors
        })
    })
  },
  [actionTypes.getTeam](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.getTeamStart)
      teamApi
        .getTeams(params)
        .then((response) => {
          context.commit(mutationTypes.getTeamSuccess, response.data)
          resolve(response.data)
        })
        .catch((result) => {
          context.commit(mutationTypes.getTeamFailure)
          result.response.data.errors
        })
    })
  },
  [actionTypes.updateTeam](context, params) {
    return new Promise((resolve) => {
      context.commit(mutationTypes.updateTeamStart)
      teamApi
        .updateTeams(params)
        .then((response) => {
          context.commit(mutationTypes.updateTeamSuccess, response.data)
          resolve(response.data)
        })
        .catch((result) => {
          context.commit(mutationTypes.updateTeamFailure)
          result.response.data.errors
        })
    })
  },
}

export default {
  state,
  actions,
  mutations,
  getters,
}
